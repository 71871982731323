<template>
 
<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-8 w-auto" src="../../src/assets/dlaPsychologa-logo.png" alt="dlaPsychologa" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Zaloguj się na swoje konto</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="handleSubmit">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
          <div class="mt-2">
            <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-dla-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Hasło</label>
            <div class="text-sm">
              <router-link :to="{ name: 'ForgotPassword' }" class="font-semibold text-green-dla-400 hover:text-green-dla-500">Nie pamiętam hasła</router-link>
            </div>
          </div>
          <div class="mt-2">
            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-dla-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-green-dla-400 px-3 py-3 text-sm font-semibold leading-6 text-green-dla-900 shadow-sm hover:bg-green-dla-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-dla-500">Zaloguj</button>
        </div>
        <div v-if="error">
          <AlertElement :error="error" />
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        Nie masz jeszcze konta?
        <a href="/signup" class="font-semibold leading-6  text-green-dla-400 hover:text-green-dla-500">Zakładam nowe konto</a>
      </p>
    </div>
</div>


</template>




<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useLogin from '../composables/useLogin';
import AlertElement from '@/components/AlertElement.vue';

export default {
  components: { AlertElement },
   setup() {
     const email = ref('');
     const password = ref('');
    
      const { login, error } = useLogin();
      const router = useRouter();


      const handleSubmit = async () => {
         await login(email.value, password.value);


          if (!error.value) {
            router.push({ name: 'Dashboard' });
          }
          else {
            if (error.value.includes("(auth/wrong-password)")) {
                error.value = "Ups... błędny login lub hasło";
            } else if (error.value.includes("(auth/too-many-requests)")) {
                error.value = "Dostęp do tego konta został tymczasowo zablokowany z powodu wielu nieudanych prób logowania. Możesz go natychmiast przywrócić, resetując swoje hasło, lub spróbować ponownie później.";
            }
          } 
          };

          return { email, password, handleSubmit, error };
      },
    };
</script>

