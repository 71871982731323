const ids2raw = {
  505230: [
    {
      id: 1,
      name: "Klocki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 7,
        },
        8: {
          min: 8,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 32,
        },
        19: {
          min: 33,
          max: 80,
        },
      },
    },
    {
      id: 2,
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 0,
          max: 0,
        },
        6: {
          min: 1,
          max: 2,
        },
        7: {
          min: 3,
          max: 4,
        },
        8: {
          min: 5,
          max: 6,
        },
        9: {
          min: 7,
          max: 8,
        },
        10: {
          min: 9,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 26,
        },
        18: {
          min: 27,
          max: 29,
        },
        19: {
          min: 30,
          max: 38,
        },
      },
    },
    {
      id: 3,
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 0,
          max: 4,
        },
        6: {
          min: 5,
          max: 8,
        },
        7: {
          min: 9,
          max: 13,
        },
        8: {
          min: 14,
          max: 17,
        },
        9: {
          min: 18,
          max: 21,
        },
        10: {
          min: 22,
          max: 24,
        },
        11: {
          min: 25,
          max: 27,
        },
        12: {
          min: 28,
          max: 30,
        },
        13: {
          min: 31,
          max: 33,
        },
        14: {
          min: 34,
          max: 36,
        },
        15: {
          min: 37,
          max: 39,
        },
        16: {
          min: 40,
          max: 41,
        },
        17: {
          min: 42,
          max: 44,
        },
        18: {
          min: 45,
          max: 46,
        },
        19: {
          min: 47,
          max: 56,
        },
      },
    },
    {
      id: 4,

      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 40,
        },
      },
    },

    {
      name: "Bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 2,
          max: 2,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 3,
          max: 3,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 4,
          max: 4,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 5,
          max: 5,
        },
        13: {
          min: 6,
          max: 6,
        },
        14: {
          min: 7,
          max: 7,
        },
        15: {
          min: 8,
          max: 8,
        },
        16: {
          min: 9,
          max: 9,
        },
        17: {
          min: 10,
          max: 10,
        },
        18: {
          min: 11,
          max: 11,
        },
        19: {
          min: 12,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 1,
        },
        5: {
          min: 2,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 8,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 12,
          max: 12,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 13,
          max: 13,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 14,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 1,
        },
        5: {
          min: 2,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 24,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 1,
        },
        5: {
          min: 2,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 6,
        },
        5: {
          min: 7,
          max: 10,
        },
        6: {
          min: 11,
          max: 15,
        },
        7: {
          min: 16,
          max: 19,
        },
        8: {
          min: 20,
          max: 24,
        },
        9: {
          min: 25,
          max: 28,
        },
        10: {
          min: 29,
          max: 32,
        },
        11: {
          min: 33,
          max: 36,
        },
        12: {
          min: 37,
          max: 40,
        },
        13: {
          min: 41,
          max: 44,
        },
        14: {
          min: 45,
          max: 48,
        },
        15: {
          min: 49,
          max: 51,
        },
        16: {
          min: 52,
          max: 55,
        },
        17: {
          min: 56,
          max: 59,
        },
        18: {
          min: 60,
          max: 62,
        },
        19: {
          min: 63,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 2,
          max: 2,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 3,
          max: 3,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 4,
          max: 4,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 5,
          max: 5,
        },
        12: {
          min: 6,
          max: 6,
        },
        13: {
          min: 7,
          max: 7,
        },
        14: {
          min: 8,
          max: 8,
        },
        15: {
          min: 9,
          max: 9,
        },
        16: {
          min: 10,
          max: 10,
        },
        17: {
          min: 11,
          max: 11,
        },
        18: {
          min: 12,
          max: 12,
        },
        19: {
          min: 13,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 1,
        },
        5: {
          min: 2,
          max: 2,
        },
        6: {
          min: 3,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 19,
        },
        19: {
          min: 20,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 19,
        },
        19: {
          min: 20,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 14,
          max: 14,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 15,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  535530: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 28,
        },
        17: {
          min: 29,
          max: 31,
        },
        18: {
          min: 32,
          max: 33,
        },
        19: {
          min: 34,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 0,
        },
        5: {
          min: 1,
          max: 1,
        },
        6: {
          min: 2,
          max: 3,
        },
        7: {
          min: 4,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 8,
        },
        10: {
          min: 9,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 0,
        },
        5: {
          min: 1,
          max: 5,
        },
        6: {
          min: 6,
          max: 10,
        },
        7: {
          min: 11,
          max: 14,
        },
        8: {
          min: 15,
          max: 18,
        },
        9: {
          min: 19,
          max: 22,
        },
        10: {
          min: 23,
          max: 26,
        },
        11: {
          min: 27,
          max: 29,
        },
        12: {
          min: 30,
          max: 32,
        },
        13: {
          min: 33,
          max: 35,
        },
        14: {
          min: 36,
          max: 37,
        },
        15: {
          min: 38,
          max: 40,
        },
        16: {
          min: 41,
          max: 43,
        },
        17: {
          min: 44,
          max: 45,
        },
        18: {
          min: 46,
          max: 48,
        },
        19: {
          min: 49,
          max: 56,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 3,
          max: 3,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 4,
          max: 4,
        },
        11: {
          min: 5,
          max: 5,
        },
        12: {
          min: 6,
          max: 6,
        },
        13: {
          min: 7,
          max: 7,
        },
        14: {
          min: 8,
          max: 8,
        },
        15: {
          min: 9,
          max: 9,
        },
        16: {
          min: 10,
          max: 10,
        },
        17: {
          min: 11,
          max: 11,
        },
        18: {
          min: 12,
          max: 12,
        },
        19: {
          min: 13,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 13,
          max: 13,
        },
        17: {
          min: 14,
          max: 14,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 15,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 24,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 3,
        },
        4: {
          min: 4,
          max: 8,
        },
        5: {
          min: 9,
          max: 13,
        },
        6: {
          min: 14,
          max: 17,
        },
        7: {
          min: 18,
          max: 22,
        },
        8: {
          min: 23,
          max: 26,
        },
        9: {
          min: 27,
          max: 30,
        },
        10: {
          min: 31,
          max: 35,
        },
        11: {
          min: 36,
          max: 39,
        },
        12: {
          min: 40,
          max: 43,
        },
        13: {
          min: 44,
          max: 46,
        },
        14: {
          min: 47,
          max: 50,
        },
        15: {
          min: 51,
          max: 54,
        },
        16: {
          min: 55,
          max: 58,
        },
        17: {
          min: 59,
          max: 61,
        },
        18: {
          min: 62,
          max: 65,
        },
        19: {
          min: 66,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 2,
          max: 2,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 3,
          max: 3,
        },
        8: {
          min: 4,
          max: 4,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 5,
          max: 5,
        },
        11: {
          min: 6,
          max: 6,
        },
        12: {
          min: 7,
          max: 7,
        },
        13: {
          min: 8,
          max: 8,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 9,
          max: 9,
        },
        16: {
          min: 10,
          max: 10,
        },
        17: {
          min: 11,
          max: 11,
        },
        18: {
          min: 12,
          max: 12,
        },
        19: {
          min: 13,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 1,
        },
        5: {
          min: 2,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 15,
          max: 15,
        },
        19: {
          min: 16,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  565830: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 32,
        },
        18: {
          min: 33,
          max: 35,
        },
        19: {
          min: 36,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 0,
        },
        5: {
          min: 1,
          max: 2,
        },
        6: {
          min: 3,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 7,
        },
        9: {
          min: 8,
          max: 9,
        },
        10: {
          min: 10,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 30,
        },
        19: {
          min: 31,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 2,
        },
        5: {
          min: 3,
          max: 6,
        },
        6: {
          min: 7,
          max: 11,
        },
        7: {
          min: 12,
          max: 15,
        },
        8: {
          min: 16,
          max: 20,
        },
        9: {
          min: 21,
          max: 23,
        },
        10: {
          min: 24,
          max: 27,
        },
        11: {
          min: 28,
          max: 30,
        },
        12: {
          min: 31,
          max: 33,
        },
        13: {
          min: 34,
          max: 36,
        },
        14: {
          min: 37,
          max: 39,
        },
        15: {
          min: 40,
          max: 41,
        },
        16: {
          min: 42,
          max: 44,
        },
        17: {
          min: 45,
          max: 46,
        },
        18: {
          min: 47,
          max: 49,
        },
        19: {
          min: 50,
          max: 56,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 3,
          max: 3,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 4,
          max: 4,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 5,
          max: 5,
        },
        12: {
          min: 6,
          max: 6,
        },
        13: {
          min: 7,
          max: 7,
        },
        14: {
          min: 8,
          max: 8,
        },
        15: {
          min: 9,
          max: 9,
        },
        16: {
          min: 10,
          max: 10,
        },
        17: {
          min: 11,
          max: 11,
        },
        18: {
          min: 12,
          max: 12,
        },
        19: {
          min: 13,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 15,
          max: 15,
        },
        19: {
          min: 16,
          max: 34,
        },
      },
      id: 7,
    },
    ,
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 22,
        },
      },
      id: 9,
    },
    ,
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 6,
        },
        4: {
          min: 7,
          max: 11,
        },
        5: {
          min: 12,
          max: 15,
        },
        6: {
          min: 16,
          max: 20,
        },
        7: {
          min: 21,
          max: 24,
        },
        8: {
          min: 25,
          max: 29,
        },
        9: {
          min: 30,
          max: 33,
        },
        10: {
          min: 34,
          max: 37,
        },
        11: {
          min: 38,
          max: 41,
        },
        12: {
          min: 42,
          max: 45,
        },
        13: {
          min: 46,
          max: 49,
        },
        14: {
          min: 50,
          max: 53,
        },
        15: {
          min: 54,
          max: 56,
        },
        16: {
          min: 57,
          max: 60,
        },
        17: {
          min: 61,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 3,
          max: 3,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 4,
          max: 4,
        },
        9: {
          min: 5,
          max: 5,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 6,
          max: 6,
        },
        12: {
          min: 7,
          max: 7,
        },
        13: {
          min: 8,
          max: 8,
        },
        14: {
          min: 9,
          max: 9,
        },
        15: {
          min: 10,
          max: 10,
        },
        16: {
          min: 11,
          max: 11,
        },
        17: {
          min: 12,
          max: 12,
        },
        18: {
          min: 13,
          max: 13,
        },
        19: {
          min: 14,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 16,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  5951130: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 28,
        },
        16: {
          min: 29,
          max: 30,
        },
        17: {
          min: 31,
          max: 33,
        },
        18: {
          min: 34,
          max: 36,
        },
        19: {
          min: 37,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 1,
        },
        5: {
          min: 2,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 6,
        },
        8: {
          min: 7,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 31,
        },
        19: {
          min: 32,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 0,
          max: 3,
        },
        5: {
          min: 4,
          max: 8,
        },
        6: {
          min: 9,
          max: 12,
        },
        7: {
          min: 13,
          max: 17,
        },
        8: {
          min: 18,
          max: 21,
        },
        9: {
          min: 22,
          max: 25,
        },
        10: {
          min: 26,
          max: 28,
        },
        11: {
          min: 29,
          max: 32,
        },
        12: {
          min: 33,
          max: 35,
        },
        13: {
          min: 36,
          max: 38,
        },
        14: {
          min: 39,
          max: 40,
        },
        15: {
          min: 41,
          max: 43,
        },
        16: {
          min: 44,
          max: 45,
        },
        17: {
          min: 46,
          max: 48,
        },
        18: {
          min: 49,
          max: 50,
        },
        19: {
          min: 51,
          max: 56,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 3,
          max: 3,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 4,
          max: 4,
        },
        10: {
          min: 5,
          max: 5,
        },
        11: {
          min: 6,
          max: 6,
        },
        12: {
          min: 7,
          max: 7,
        },
        13: {
          min: 8,
          max: 8,
        },
        14: {
          min: 9,
          max: 9,
        },
        15: {
          min: 10,
          max: 10,
        },
        16: {
          min: 11,
          max: 11,
        },
        17: {
          min: 12,
          max: 12,
        },
        18: {
          min: 13,
          max: 13,
        },
        19: {
          min: 14,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 16,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 3,
        },
        3: {
          min: 4,
          max: 8,
        },
        4: {
          min: 9,
          max: 13,
        },
        5: {
          min: 14,
          max: 18,
        },
        6: {
          min: 19,
          max: 22,
        },
        7: {
          min: 23,
          max: 27,
        },
        8: {
          min: 28,
          max: 31,
        },
        9: {
          min: 32,
          max: 35,
        },
        10: {
          min: 36,
          max: 39,
        },
        11: {
          min: 40,
          max: 43,
        },
        12: {
          min: 44,
          max: 47,
        },
        13: {
          min: 48,
          max: 51,
        },
        14: {
          min: 52,
          max: 55,
        },
        15: {
          min: 56,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 66,
        },
        18: {
          min: 67,
          max: 70,
        },
        19: {
          min: 71,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 3,
          max: 3,
        },
        7: {
          min: 4,
          max: 4,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 5,
          max: 5,
        },
        10: {
          min: 6,
          max: 6,
        },
        11: {
          min: 7,
          max: 7,
        },
        12: {
          min: 8,
          max: 8,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 9,
          max: 9,
        },
        15: {
          min: 10,
          max: 10,
        },
        16: {
          min: 11,
          max: 11,
        },
        17: {
          min: 12,
          max: 12,
        },
        18: {
          min: 13,
          max: 13,
        },
        19: {
          min: 14,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 16,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  606230: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 29,
        },
        16: {
          min: 30,
          max: 32,
        },
        17: {
          min: 33,
          max: 34,
        },
        18: {
          min: 35,
          max: 37,
        },
        19: {
          min: 38,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 5,
        },
        7: {
          min: 6,
          max: 7,
        },
        8: {
          min: 8,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 38,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 0,
        },
        4: {
          min: 1,
          max: 4,
        },
        5: {
          min: 5,
          max: 9,
        },
        6: {
          min: 10,
          max: 14,
        },
        7: {
          min: 15,
          max: 18,
        },
        8: {
          min: 19,
          max: 22,
        },
        9: {
          min: 23,
          max: 26,
        },
        10: {
          min: 27,
          max: 30,
        },
        11: {
          min: 31,
          max: 33,
        },
        12: {
          min: 34,
          max: 36,
        },
        13: {
          min: 37,
          max: 39,
        },
        14: {
          min: 40,
          max: 42,
        },
        15: {
          min: 43,
          max: 44,
        },
        16: {
          min: 45,
          max: 47,
        },
        17: {
          min: 48,
          max: 49,
        },
        18: {
          min: 50,
          max: 52,
        },
        19: {
          min: 53,
          max: 56,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 4,
          max: 4,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 5,
          max: 5,
        },
        11: {
          min: 6,
          max: 6,
        },
        12: {
          min: 7,
          max: 7,
        },
        13: {
          min: 8,
          max: 8,
        },
        14: {
          min: 9,
          max: 9,
        },
        15: {
          min: 10,
          max: 10,
        },
        16: {
          min: 11,
          max: 11,
        },
        17: {
          min: 12,
          max: 13,
        },
        18: {
          min: 14,
          max: 14,
        },
        19: {
          min: 15,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 22,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 6,
        },
        3: {
          min: 7,
          max: 11,
        },
        4: {
          min: 12,
          max: 15,
        },
        5: {
          min: 16,
          max: 20,
        },
        6: {
          min: 21,
          max: 25,
        },
        7: {
          min: 26,
          max: 29,
        },
        8: {
          min: 30,
          max: 33,
        },
        9: {
          min: 34,
          max: 38,
        },
        10: {
          min: 39,
          max: 42,
        },
        11: {
          min: 43,
          max: 46,
        },
        12: {
          min: 47,
          max: 50,
        },
        13: {
          min: 51,
          max: 54,
        },
        14: {
          min: 55,
          max: 57,
        },
        15: {
          min: 58,
          max: 61,
        },
        16: {
          min: 62,
          max: 65,
        },
        17: {
          min: 66,
          max: 68,
        },
        18: {
          min: 69,
          max: 72,
        },
        19: {
          min: 73,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 4,
          max: 4,
        },
        8: {
          min: 5,
          max: 5,
        },
        9: {
          min: 6,
          max: 6,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 7,
          max: 7,
        },
        12: {
          min: 8,
          max: 8,
        },
        13: {
          min: 9,
          max: 9,
        },
        14: {
          min: 10,
          max: 10,
        },
        15: {
          min: 11,
          max: 11,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 12,
          max: 12,
        },
        18: {
          min: 13,
          max: 13,
        },
        19: {
          min: 14,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 15,
        },
        15: {
          min: 16,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 20,
        },
        18: {
          min: 21,
          max: 22,
        },
        19: {
          min: 23,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 34,
        },
      },
    },
  ],

   
  636530: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 3,
        },
        3: {
          min: 4,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 23,
        },
        13: {
          min: 24,
          max: 25,
        },
        14: {
          min: 26,
          max: 28,
        },
        15: {
          min: 29,
          max: 30,
        },
        16: {
          min: 31,
          max: 33,
        },
        17: {
          min: 34,
          max: 36,
        },
        18: {
          min: 37,
          max: 38,
        },
        19: {
          min: 39,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 4,
        },
        6: {
          min: 5,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 27,
        },
        17: {
          min: 28,
          max: 30,
        },
        18: {
          min: 31,
          max: 32,
        },
        19: {
          min: 33,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 1,
        },
        4: {
          min: 2,
          max: 6,
        },
        5: {
          min: 7,
          max: 11,
        },
        6: {
          min: 12,
          max: 15,
        },
        7: {
          min: 16,
          max: 20,
        },
        8: {
          min: 21,
          max: 24,
        },
        9: {
          min: 25,
          max: 28,
        },
        10: {
          min: 29,
          max: 31,
        },
        11: {
          min: 32,
          max: 34,
        },
        12: {
          min: 35,
          max: 37,
        },
        13: {
          min: 38,
          max: 40,
        },
        14: {
          min: 41,
          max: 43,
        },
        15: {
          min: 44,
          max: 46,
        },
        16: {
          min: 47,
          max: 48,
        },
        17: {
          min: 49,
          max: 51,
        },
        18: {
          min: 52,
          max: 53,
        },
        19: {
          min: 54,
          max: 56,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 4,
          max: 4,
        },
        9: {
          min: 5,
          max: 5,
        },
        10: {
          min: 6,
          max: 6,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 7,
          max: 7,
        },
        13: {
          min: 8,
          max: 9,
        },
        14: {
          min: 10,
          max: 10,
        },
        15: {
          min: 11,
          max: 11,
        },
        16: {
          min: 12,
          max: 12,
        },
        17: {
          min: 13,
          max: 13,
        },
        18: {
          min: 14,
          max: 14,
        },
        19: {
          min: 15,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 8,
        },
        3: {
          min: 9,
          max: 13,
        },
        4: {
          min: 14,
          max: 18,
        },
        5: {
          min: 19,
          max: 22,
        },
        6: {
          min: 23,
          max: 27,
        },
        7: {
          min: 28,
          max: 31,
        },
        8: {
          min: 32,
          max: 36,
        },
        9: {
          min: 37,
          max: 40,
        },
        10: {
          min: 41,
          max: 44,
        },
        11: {
          min: 45,
          max: 48,
        },
        12: {
          min: 49,
          max: 52,
        },
        13: {
          min: 53,
          max: 56,
        },
        14: {
          min: 57,
          max: 60,
        },
        15: {
          min: 61,
          max: 63,
        },
        16: {
          min: 64,
          max: 67,
        },
        17: {
          min: 68,
          max: 71,
        },
        18: {
          min: 72,
          max: 74,
        },
        19: {
          min: 75,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 2,
          max: 2,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 5,
          max: 5,
        },
        9: {
          min: 6,
          max: 6,
        },
        10: {
          min: 7,
          max: 7,
        },
        11: {
          min: 8,
          max: 8,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 9,
          max: 9,
        },
        14: {
          min: 10,
          max: 10,
        },
        15: {
          min: 11,
          max: 11,
        },
        16: {
          min: 12,
          max: 12,
        },
        17: {
          min: 13,
          max: 13,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 14,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 17,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  666830: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 9,
        },
        6: {
          min: 10,
          max: 11,
        },
        7: {
          min: 12,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 29,
        },
        15: {
          min: 30,
          max: 31,
        },
        16: {
          min: 32,
          max: 34,
        },
        17: {
          min: 35,
          max: 37,
        },
        18: {
          min: 38,
          max: 40,
        },
        19: {
          min: 41,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 28,
        },
        17: {
          min: 29,
          max: 30,
        },
        18: {
          min: 31,
          max: 33,
        },
        19: {
          min: 34,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 2,
        },
        4: {
          min: 3,
          max: 7,
        },
        5: {
          min: 8,
          max: 12,
        },
        6: {
          min: 13,
          max: 17,
        },
        7: {
          min: 18,
          max: 21,
        },
        8: {
          min: 22,
          max: 25,
        },
        9: {
          min: 26,
          max: 29,
        },
        10: {
          min: 30,
          max: 32,
        },
        11: {
          min: 33,
          max: 36,
        },
        12: {
          min: 37,
          max: 39,
        },
        13: {
          min: 40,
          max: 42,
        },
        14: {
          min: 43,
          max: 44,
        },
        15: {
          min: 45,
          max: 47,
        },
        16: {
          min: 48,
          max: 50,
        },
        17: {
          min: 51,
          max: 52,
        },
        18: {
          min: 53,
          max: 54,
        },
        19: {
          min: 55,
          max: 56,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 4,
          max: 4,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 5,
          max: 5,
        },
        10: {
          min: 6,
          max: 6,
        },
        11: {
          min: 7,
          max: 7,
        },
        12: {
          min: 8,
          max: 8,
        },
        13: {
          min: 9,
          max: 9,
        },
        14: {
          min: 10,
          max: 10,
        },
        15: {
          min: 11,
          max: 11,
        },
        16: {
          min: 12,
          max: 13,
        },
        17: {
          min: 14,
          max: 14,
        },
        18: {
          min: 15,
          max: 15,
        },
        19: {
          min: 16,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 10,
        },
        3: {
          min: 11,
          max: 15,
        },
        4: {
          min: 16,
          max: 20,
        },
        5: {
          min: 21,
          max: 24,
        },
        6: {
          min: 25,
          max: 29,
        },
        7: {
          min: 30,
          max: 34,
        },
        8: {
          min: 35,
          max: 38,
        },
        9: {
          min: 39,
          max: 42,
        },
        10: {
          min: 43,
          max: 46,
        },
        11: {
          min: 47,
          max: 50,
        },
        12: {
          min: 51,
          max: 54,
        },
        13: {
          min: 55,
          max: 58,
        },
        14: {
          min: 59,
          max: 62,
        },
        15: {
          min: 63,
          max: 66,
        },
        16: {
          min: 67,
          max: 69,
        },
        17: {
          min: 70,
          max: 73,
        },
        18: {
          min: 74,
          max: 77,
        },
        19: {
          min: 78,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 3,
          max: 3,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 7,
          max: 7,
        },
        11: {
          min: 8,
          max: 8,
        },
        12: {
          min: 9,
          max: 9,
        },
        13: {
          min: 10,
          max: 10,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 11,
          max: 11,
        },
        16: {
          min: 12,
          max: 12,
        },
        17: {
          min: 13,
          max: 13,
        },
        18: {
          min: 14,
          max: 14,
        },
        19: {
          min: 15,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 16,
        },
        15: {
          min: 17,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 17,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  6961130: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 6,
        },
        4: {
          min: 7,
          max: 8,
        },
        5: {
          min: 9,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 14,
        },
        8: {
          min: 15,
          max: 16,
        },
        9: {
          min: 17,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 23,
        },
        12: {
          min: 24,
          max: 25,
        },
        13: {
          min: 26,
          max: 27,
        },
        14: {
          min: 28,
          max: 30,
        },
        15: {
          min: 31,
          max: 33,
        },
        16: {
          min: 34,
          max: 35,
        },
        17: {
          min: 36,
          max: 38,
        },
        18: {
          min: 39,
          max: 41,
        },
        19: {
          min: 42,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 4,
        },
        5: {
          min: 5,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 31,
        },
        18: {
          min: 32,
          max: 34,
        },
        19: {
          min: 35,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 0,
          max: 4,
        },
        4: {
          min: 5,
          max: 9,
        },
        5: {
          min: 10,
          max: 13,
        },
        6: {
          min: 14,
          max: 18,
        },
        7: {
          min: 19,
          max: 22,
        },
        8: {
          min: 23,
          max: 26,
        },
        9: {
          min: 27,
          max: 30,
        },
        10: {
          min: 31,
          max: 34,
        },
        11: {
          min: 35,
          max: 37,
        },
        12: {
          min: 38,
          max: 40,
        },
        13: {
          min: 41,
          max: 43,
        },
        14: {
          min: 44,
          max: 46,
        },
        15: {
          min: 47,
          max: 48,
        },
        16: {
          min: 49,
          max: 51,
        },
        17: {
          min: 52,
          max: 53,
        },
        18: {
          min: 54,
          max: 56,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 5,
          max: 5,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 6,
          max: 6,
        },
        11: {
          min: 7,
          max: 7,
        },
        12: {
          min: 8,
          max: 8,
        },
        13: {
          min: 9,
          max: 9,
        },
        14: {
          min: 10,
          max: 11,
        },
        15: {
          min: 12,
          max: 12,
        },
        16: {
          min: 13,
          max: 13,
        },
        17: {
          min: 14,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 12,
        },
        3: {
          min: 13,
          max: 17,
        },
        4: {
          min: 18,
          max: 22,
        },
        5: {
          min: 23,
          max: 27,
        },
        6: {
          min: 28,
          max: 31,
        },
        7: {
          min: 32,
          max: 36,
        },
        8: {
          min: 37,
          max: 40,
        },
        9: {
          min: 41,
          max: 44,
        },
        10: {
          min: 45,
          max: 48,
        },
        11: {
          min: 49,
          max: 52,
        },
        12: {
          min: 53,
          max: 56,
        },
        13: {
          min: 57,
          max: 60,
        },
        14: {
          min: 61,
          max: 64,
        },
        15: {
          min: 65,
          max: 68,
        },
        16: {
          min: 69,
          max: 71,
        },
        17: {
          min: 72,
          max: 75,
        },
        18: {
          min: 76,
          max: 79,
        },
        19: {
          min: 80,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 9,
          max: 9,
        },
        13: {
          min: 10,
          max: 10,
        },
        14: {
          min: 11,
          max: 11,
        },
        15: {
          min: 12,
          max: 12,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 13,
          max: 13,
        },
        18: {
          min: 14,
          max: 14,
        },
        19: {
          min: 15,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 25,
        },
        19: {
          min: 26,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  707230: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 9,
        },
        5: {
          min: 10,
          max: 11,
        },
        6: {
          min: 12,
          max: 13,
        },
        7: {
          min: 14,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 24,
        },
        12: {
          min: 25,
          max: 26,
        },
        13: {
          min: 27,
          max: 28,
        },
        14: {
          min: 29,
          max: 31,
        },
        15: {
          min: 32,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 39,
        },
        18: {
          min: 40,
          max: 42,
        },
        19: {
          min: 43,
          max: 80,
        },
      },
      id: 1,
    },
    
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 32,
        },
        18: {
          min: 33,
          max: 34,
        },
        19: {
          min: 35,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 0,
        },
        3: {
          min: 1,
          max: 5,
        },
        4: {
          min: 6,
          max: 10,
        },
        5: {
          min: 11,
          max: 15,
        },
        6: {
          min: 16,
          max: 19,
        },
        7: {
          min: 20,
          max: 24,
        },
        8: {
          min: 25,
          max: 28,
        },
        9: {
          min: 29,
          max: 32,
        },
        10: {
          min: 33,
          max: 35,
        },
        11: {
          min: 36,
          max: 38,
        },
        12: {
          min: 39,
          max: 42,
        },
        13: {
          min: 43,
          max: 44,
        },
        14: {
          min: 45,
          max: 47,
        },
        15: {
          min: 48,
          max: 50,
        },
        16: {
          min: 51,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 57,
        },
        19: {
          min: 58,
          max: 104,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 25,
        },
        19: {
          min: 26,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 4,
          max: 4,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 5,
          max: 5,
        },
        9: {
          min: 6,
          max: 6,
        },
        10: {
          min: 7,
          max: 7,
        },
        11: {
          min: 8,
          max: 8,
        },
        12: {
          min: 9,
          max: 9,
        },
        13: {
          min: 10,
          max: 10,
        },
        14: {
          min: 11,
          max: 11,
        },
        15: {
          min: 12,
          max: 12,
        },
        16: {
          min: 13,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 14,
        },
        3: {
          min: 15,
          max: 19,
        },
        4: {
          min: 20,
          max: 24,
        },
        5: {
          min: 25,
          max: 29,
        },
        6: {
          min: 30,
          max: 33,
        },
        7: {
          min: 34,
          max: 38,
        },
        8: {
          min: 39,
          max: 42,
        },
        9: {
          min: 43,
          max: 46,
        },
        10: {
          min: 47,
          max: 50,
        },
        11: {
          min: 51,
          max: 54,
        },
        12: {
          min: 55,
          max: 58,
        },
        13: {
          min: 59,
          max: 62,
        },
        14: {
          min: 63,
          max: 66,
        },
        15: {
          min: 67,
          max: 70,
        },
        16: {
          min: 71,
          max: 73,
        },
        17: {
          min: 74,
          max: 77,
        },
        18: {
          min: 78,
          max: 81,
        },
        19: {
          min: 82,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 2,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 11,
          max: 11,
        },
        15: {
          min: 12,
          max: 12,
        },
        16: {
          min: 13,
          max: 13,
        },
        17: {
          min: 14,
          max: 14,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 15,
          max: 36,
        },
      },
      id: 11,
    },
    
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 18,
          max: 34,
        },
      },
      id: 14,
    },
  ],
  737530: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 22,
        },
        11: {
          min: 23,
          max: 25,
        },
        12: {
          min: 26,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 32,
        },
        15: {
          min: 33,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 40,
        },
        18: {
          min: 41,
          max: 43,
        },
        19: {
          min: 44,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 27,
        },
        16: {
          min: 28,
          max: 30,
        },
        17: {
          min: 31,
          max: 32,
        },
        18: {
          min: 33,
          max: 35,
        },
        19: {
          min: 36,
          max: 38,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 2,
        },
        3: {
          min: 3,
          max: 7,
        },
        4: {
          min: 8,
          max: 11,
        },
        5: {
          min: 12,
          max: 16,
        },
        6: {
          min: 17,
          max: 21,
        },
        7: {
          min: 22,
          max: 25,
        },
        8: {
          min: 26,
          max: 29,
        },
        9: {
          min: 30,
          max: 33,
        },
        10: {
          min: 34,
          max: 37,
        },
        11: {
          min: 38,
          max: 40,
        },
        12: {
          min: 41,
          max: 43,
        },
        13: {
          min: 44,
          max: 46,
        },
        14: {
          min: 47,
          max: 49,
        },
        15: {
          min: 50,
          max: 51,
        },
        16: {
          min: 52,
          max: 54,
        },
        17: {
          min: 55,
          max: 56,
        },
        18: {
          min: 57,
          max: 59,
        },
        19: {
          min: 60,
          max: 104,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 26,
        },
        19: {
          min: 27,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 5,
          max: 5,
        },
        9: {
          min: 6,
          max: 6,
        },
        10: {
          min: 7,
          max: 7,
        },
        11: {
          min: 8,
          max: 8,
        },
        12: {
          min: 9,
          max: 9,
        },
        13: {
          min: 10,
          max: 10,
        },
        14: {
          min: 11,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 1,
        },
        3: {
          min: 2,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 22,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 16,
        },
        3: {
          min: 17,
          max: 21,
        },
        4: {
          min: 22,
          max: 26,
        },
        5: {
          min: 27,
          max: 31,
        },
        6: {
          min: 32,
          max: 35,
        },
        7: {
          min: 36,
          max: 40,
        },
        8: {
          min: 41,
          max: 44,
        },
        9: {
          min: 45,
          max: 48,
        },
        10: {
          min: 49,
          max: 52,
        },
        11: {
          min: 53,
          max: 56,
        },
        12: {
          min: 57,
          max: 60,
        },
        13: {
          min: 61,
          max: 64,
        },
        14: {
          min: 65,
          max: 68,
        },
        15: {
          min: 69,
          max: 72,
        },
        16: {
          min: 73,
          max: 75,
        },
        17: {
          min: 76,
          max: 79,
        },
        18: {
          min: 80,
          max: 83,
        },
        19: {
          min: 84,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 3,
          max: 3,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 13,
          max: 13,
        },
        17: {
          min: 14,
          max: 14,
        },
        18: {
          min: 15,
          max: 15,
        },
        19: {
          min: 16,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 25,
        },
        19: {
          min: 26,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  767830: [
    {
      name: "Klocki",
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 36,
        },
        16: {
          min: 37,
          max: 39,
        },
        17: {
          min: 40,
          max: 41,
        },
        18: {
          min: 42,
          max: 44,
        },
        19: {
          min: 45,
          max: 80,
        },
      },
      id: 1,
    },
    {
      name: "Opowiadanie",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 26,
        },
        15: {
          min: 27,
          max: 28,
        },
        16: {
          min: 29,
          max: 31,
        },
        17: {
          min: 32,
          max: 33,
        },
        18: {
          min: 34,
          max: 36,
        },
        19: {
          min: 37,
          max: 80,
        },
      },
      id: 2,
    },
    {
      name: "Papugi",
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 3,
        },
        3: {
          min: 4,
          max: 8,
        },
        4: {
          min: 9,
          max: 13,
        },
        5: {
          min: 14,
          max: 17,
        },
        6: {
          min: 18,
          max: 22,
        },
        7: {
          min: 23,
          max: 26,
        },
        8: {
          min: 27,
          max: 31,
        },
        9: {
          min: 32,
          max: 34,
        },
        10: {
          min: 35,
          max: 38,
        },
        11: {
          min: 39,
          max: 41,
        },
        12: {
          min: 42,
          max: 44,
        },
        13: {
          min: 45,
          max: 47,
        },
        14: {
          min: 48,
          max: 50,
        },
        15: {
          min: 51,
          max: 53,
        },
        16: {
          min: 54,
          max: 55,
        },
        17: {
          min: 56,
          max: 58,
        },
        18: {
          min: 59,
          max: 60,
        },
        19: {
          min: 61,
          max: 104,
        },
      },
      id: 3,
    },
    {
      name: "Szeregi jednorodne",
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 40,
        },
      },
      id: 4,
    },
    {
      name: "Bryły",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 59,
        },
      },
      id: 5,
    },
    {
      name: "Matryce",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 4,
          max: 4,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 6,
          max: 6,
        },
        10: {
          min: 7,
          max: 7,
        },
        11: {
          min: 8,
          max: 8,
        },
        12: {
          min: 9,
          max: 9,
        },
        13: {
          min: 10,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 18,
        },
        19: {
          min: 19,
          max: 35,
        },
      },
      id: 6,
    },
    {
      name: "Kategorie",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 34,
        },
      },
      id: 7,
    },
    {
      name: "Krążki",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 8,
    },
    {
      name: "Obrazek",
      rows: {
        1: {
          min: 0,
          max: 0,
        },
        2: {
          min: 1,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
      id: 9,
    },
    {
      name: "Kwadraty",
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: 14,
          max: 18,
        },
        3: {
          min: 19,
          max: 23,
        },
        4: {
          min: 24,
          max: 28,
        },
        5: {
          min: 29,
          max: 32,
        },
        6: {
          min: 33,
          max: 37,
        },
        7: {
          min: 38,
          max: 42,
        },
        8: {
          min: 43,
          max: 46,
        },
        9: {
          min: 47,
          max: 50,
        },
        10: {
          min: 51,
          max: 54,
        },
        11: {
          min: 55,
          max: 58,
        },
        12: {
          min: 59,
          max: 62,
        },
        13: {
          min: 63,
          max: 66,
        },
        14: {
          min: 67,
          max: 70,
        },
        15: {
          min: 71,
          max: 74,
        },
        16: {
          min: 75,
          max: 77,
        },
        17: {
          min: 78,
          max: 81,
        },
        18: {
          min: 82,
          max: 85,
        },
        19: {
          min: 86,
          max: 104,
        },
      },
      id: 10,
    },
    {
      name: "Szeregi mieszane",
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 15,
          max: 15,
        },
        19: {
          min: 16,
          max: 36,
        },
      },
      id: 11,
    },
    {
      name: "Zrotowane bryły",
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 59,
        },
      },
      id: 12,
    },
    {
      name: "Wykluczanie",
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 25,
        },
        19: {
          min: 26,
          max: 31,
        },
      },
      id: 13,
    },
    {
      name: "Przeciwieństwa",
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: null,
          max: null,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 34,
        },
      },
      id: 14,
    },
  ],

  7971130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 32,
        },
        14: {
          min: 33,
          max: 34,
        },
        15: {
          min: 35,
          max: 37,
        },
        16: {
          min: 38,
          max: 40,
        },
        17: {
          min: 41,
          max: 42,
        },
        18: {
          min: 43,
          max: 45,
        },
        19: {
          min: 46,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 29,
        },
        16: {
          min: 30,
          max: 31,
        },
        17: {
          min: 32,
          max: 34,
        },
        18: {
          min: 35,
          max: 36,
        },
        19: {
          min: 37,
          max: 38,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: null,
          max: null,
        },
        2: {
          min: 0,
          max: 4,
        },
        3: {
          min: 5,
          max: 9,
        },
        4: {
          min: 10,
          max: 14,
        },
        5: {
          min: 15,
          max: 19,
        },
        6: {
          min: 20,
          max: 23,
        },
        7: {
          min: 24,
          max: 28,
        },
        8: {
          min: 29,
          max: 32,
        },
        9: {
          min: 33,
          max: 36,
        },
        10: {
          min: 37,
          max: 39,
        },
        11: {
          min: 40,
          max: 43,
        },
        12: {
          min: 44,
          max: 46,
        },
        13: {
          min: 47,
          max: 49,
        },
        14: {
          min: 50,
          max: 51,
        },
        15: {
          min: 52,
          max: 54,
        },
        16: {
          min: 55,
          max: 56,
        },
        17: {
          min: 57,
          max: 59,
        },
        18: {
          min: 60,
          max: 61,
        },
        19: {
          min: 62,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 5,
          max: 5,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 11,
        },
        14: {
          min: 12,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 15,
        },
        2: {
          min: 16,
          max: 20,
        },
        3: {
          min: 21,
          max: 25,
        },
        4: {
          min: 26,
          max: 30,
        },
        5: {
          min: 31,
          max: 34,
        },
        6: {
          min: 35,
          max: 39,
        },
        7: {
          min: 40,
          max: 43,
        },
        8: {
          min: 44,
          max: 48,
        },
        9: {
          min: 49,
          max: 52,
        },
        10: {
          min: 53,
          max: 56,
        },
        11: {
          min: 57,
          max: 60,
        },
        12: {
          min: 61,
          max: 64,
        },
        13: {
          min: 65,
          max: 68,
        },
        14: {
          min: 69,
          max: 72,
        },
        15: {
          min: 73,
          max: 75,
        },
        16: {
          min: 76,
          max: 79,
        },
        17: {
          min: 80,
          max: 83,
        },
        18: {
          min: 84,
          max: 86,
        },
        19: {
          min: 87,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 12,
          max: 12,
        },
        15: {
          min: 13,
          max: 13,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: null,
          max: null,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 19,
          max: 34,
        },
      },
    },
  ],

  808230: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 8,
        },
        3: {
          min: 9,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 33,
        },
        14: {
          min: 34,
          max: 35,
        },
        15: {
          min: 36,
          max: 38,
        },
        16: {
          min: 39,
          max: 41,
        },
        17: {
          min: 42,
          max: 44,
        },
        18: {
          min: 45,
          max: 46,
        },
        19: {
          min: 47,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 6,
        },
        4: {
          min: 7,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 11,
        },
        7: {
          min: 12,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 30,
        },
        16: {
          min: 31,
          max: 32,
        },
        17: {
          min: 33,
          max: 35,
        },
        18: {
          min: 36,
          max: 37,
        },
        19: {
          min: 38,
          max: 38,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 6,
        },
        3: {
          min: 7,
          max: 11,
        },
        4: {
          min: 12,
          max: 16,
        },
        5: {
          min: 17,
          max: 20,
        },
        6: {
          min: 21,
          max: 25,
        },
        7: {
          min: 26,
          max: 29,
        },
        8: {
          min: 30,
          max: 33,
        },
        9: {
          min: 34,
          max: 37,
        },
        10: {
          min: 38,
          max: 41,
        },
        11: {
          min: 42,
          max: 44,
        },
        12: {
          min: 45,
          max: 47,
        },
        13: {
          min: 48,
          max: 50,
        },
        14: {
          min: 51,
          max: 53,
        },
        15: {
          min: 54,
          max: 55,
        },
        16: {
          min: 56,
          max: 58,
        },
        17: {
          min: 59,
          max: 60,
        },
        18: {
          min: 61,
          max: 63,
        },
        19: {
          min: 64,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 10,
        },
        13: {
          min: 11,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 19,
        },
        19: {
          min: 20,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 22,
        },
        3: {
          min: 23,
          max: 27,
        },
        4: {
          min: 28,
          max: 31,
        },
        5: {
          min: 32,
          max: 36,
        },
        6: {
          min: 37,
          max: 41,
        },
        7: {
          min: 42,
          max: 45,
        },
        8: {
          min: 46,
          max: 49,
        },
        9: {
          min: 50,
          max: 54,
        },
        10: {
          min: 55,
          max: 58,
        },
        11: {
          min: 59,
          max: 62,
        },
        12: {
          min: 63,
          max: 66,
        },
        13: {
          min: 67,
          max: 70,
        },
        14: {
          min: 71,
          max: 73,
        },
        15: {
          min: 74,
          max: 77,
        },
        16: {
          min: 78,
          max: 81,
        },
        17: {
          min: 82,
          max: 84,
        },
        18: {
          min: 85,
          max: 88,
        },
        19: {
          min: 89,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 14,
          max: 14,
        },
        17: {
          min: 15,
          max: 15,
        },
        18: {
          min: 16,
          max: 16,
        },
        19: {
          min: 17,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 34,
        },
      },
    },
  ],

  838530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 9,
        },
        3: {
          min: 10,
          max: 11,
        },
        4: {
          min: 12,
          max: 13,
        },
        5: {
          min: 14,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 22,
        },
        9: {
          min: 23,
          max: 24,
        },
        10: {
          min: 25,
          max: 26,
        },
        11: {
          min: 27,
          max: 29,
        },
        12: {
          min: 30,
          max: 31,
        },
        13: {
          min: 32,
          max: 34,
        },
        14: {
          min: 35,
          max: 36,
        },
        15: {
          min: 37,
          max: 39,
        },
        16: {
          min: 40,
          max: 42,
        },
        17: {
          min: 43,
          max: 45,
        },
        18: {
          min: 46,
          max: 47,
        },
        19: {
          min: 48,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 23,
        },
        13: {
          min: 24,
          max: 25,
        },
        14: {
          min: 26,
          max: 28,
        },
        15: {
          min: 29,
          max: 30,
        },
        16: {
          min: 31,
          max: 33,
        },
        17: {
          min: 34,
          max: 35,
        },
        18: {
          min: 36,
          max: 38,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 7,
        },
        3: {
          min: 8,
          max: 12,
        },
        4: {
          min: 13,
          max: 17,
        },
        5: {
          min: 18,
          max: 22,
        },
        6: {
          min: 23,
          max: 26,
        },
        7: {
          min: 27,
          max: 31,
        },
        8: {
          min: 32,
          max: 35,
        },
        9: {
          min: 36,
          max: 39,
        },
        10: {
          min: 40,
          max: 42,
        },
        11: {
          min: 43,
          max: 45,
        },
        12: {
          min: 46,
          max: 48,
        },
        13: {
          min: 49,
          max: 51,
        },
        14: {
          min: 52,
          max: 54,
        },
        15: {
          min: 55,
          max: 57,
        },
        16: {
          min: 58,
          max: 59,
        },
        17: {
          min: 60,
          max: 62,
        },
        18: {
          min: 63,
          max: 64,
        },
        19: {
          min: 65,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 6,
          max: 6,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 9,
        },
        12: {
          min: 10,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 2,
        },
        3: {
          min: 3,
          max: 3,
        },
        4: {
          min: 4,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 18,
        },
        2: {
          min: 19,
          max: 23,
        },
        3: {
          min: 24,
          max: 28,
        },
        4: {
          min: 29,
          max: 33,
        },
        5: {
          min: 34,
          max: 38,
        },
        6: {
          min: 39,
          max: 42,
        },
        7: {
          min: 43,
          max: 47,
        },
        8: {
          min: 48,
          max: 51,
        },
        9: {
          min: 52,
          max: 55,
        },
        10: {
          min: 56,
          max: 59,
        },
        11: {
          min: 60,
          max: 63,
        },
        12: {
          min: 64,
          max: 67,
        },
        13: {
          min: 68,
          max: 71,
        },
        14: {
          min: 72,
          max: 75,
        },
        15: {
          min: 76,
          max: 79,
        },
        16: {
          min: 80,
          max: 82,
        },
        17: {
          min: 83,
          max: 86,
        },
        18: {
          min: 87,
          max: 90,
        },
        19: {
          min: 91,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 4,
          max: 4,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: 17,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 34,
        },
      },
    },
  ],

  868830: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 10,
        },
        3: {
          min: 11,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 18,
        },
        7: {
          min: 19,
          max: 20,
        },
        8: {
          min: 21,
          max: 22,
        },
        9: {
          min: 23,
          max: 25,
        },
        10: {
          min: 26,
          max: 27,
        },
        11: {
          min: 28,
          max: 29,
        },
        12: {
          min: 30,
          max: 32,
        },
        13: {
          min: 33,
          max: 35,
        },
        14: {
          min: 36,
          max: 37,
        },
        15: {
          min: 38,
          max: 40,
        },
        16: {
          min: 41,
          max: 43,
        },
        17: {
          min: 44,
          max: 46,
        },
        18: {
          min: 47,
          max: 49,
        },
        19: {
          min: 50,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 9,
        },
        5: {
          min: 10,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 14,
        },
        8: {
          min: 15,
          max: 16,
        },
        9: {
          min: 17,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 29,
        },
        15: {
          min: 30,
          max: 31,
        },
        16: {
          min: 32,
          max: 34,
        },
        17: {
          min: 35,
          max: 36,
        },
        18: {
          min: 37,
          max: 38,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 8,
        },
        3: {
          min: 9,
          max: 13,
        },
        4: {
          min: 14,
          max: 18,
        },
        5: {
          min: 19,
          max: 23,
        },
        6: {
          min: 24,
          max: 28,
        },
        7: {
          min: 29,
          max: 32,
        },
        8: {
          min: 33,
          max: 36,
        },
        9: {
          min: 37,
          max: 40,
        },
        10: {
          min: 41,
          max: 44,
        },
        11: {
          min: 45,
          max: 47,
        },
        12: {
          min: 48,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 55,
        },
        15: {
          min: 56,
          max: 58,
        },
        16: {
          min: 59,
          max: 61,
        },
        17: {
          min: 62,
          max: 63,
        },
        18: {
          min: 64,
          max: 66,
        },
        19: {
          min: 67,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 5,
          max: 5,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 7,
          max: 7,
        },
        10: {
          min: 8,
          max: 8,
        },
        11: {
          min: 9,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 20,
        },
        19: {
          min: 21,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 1,
        },
        2: {
          min: 2,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 20,
        },
        2: {
          min: 21,
          max: 25,
        },
        3: {
          min: 26,
          max: 30,
        },
        4: {
          min: 31,
          max: 35,
        },
        5: {
          min: 36,
          max: 39,
        },
        6: {
          min: 40,
          max: 44,
        },
        7: {
          min: 45,
          max: 48,
        },
        8: {
          min: 49,
          max: 53,
        },
        9: {
          min: 54,
          max: 57,
        },
        10: {
          min: 58,
          max: 61,
        },
        11: {
          min: 62,
          max: 65,
        },
        12: {
          min: 66,
          max: 69,
        },
        13: {
          min: 70,
          max: 73,
        },
        14: {
          min: 74,
          max: 77,
        },
        15: {
          min: 78,
          max: 80,
        },
        16: {
          min: 81,
          max: 84,
        },
        17: {
          min: 85,
          max: 88,
        },
        18: {
          min: 89,
          max: 91,
        },
        19: {
          min: 92,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 12,
          max: 12,
        },
        14: {
          min: 13,
          max: 13,
        },
        15: {
          min: 14,
          max: 14,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 34,
        },
      },
    },
  ],

  8981130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 10,
        },
        3: {
          min: 11,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 17,
        },
        6: {
          min: 18,
          max: 19,
        },
        7: {
          min: 20,
          max: 21,
        },
        8: {
          min: 22,
          max: 23,
        },
        9: {
          min: 24,
          max: 26,
        },
        10: {
          min: 27,
          max: 28,
        },
        11: {
          min: 29,
          max: 30,
        },
        12: {
          min: 31,
          max: 33,
        },
        13: {
          min: 34,
          max: 36,
        },
        14: {
          min: 37,
          max: 38,
        },
        15: {
          min: 39,
          max: 41,
        },
        16: {
          min: 42,
          max: 44,
        },
        17: {
          min: 45,
          max: 47,
        },
        18: {
          min: 48,
          max: 50,
        },
        19: {
          min: 51,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 13,
        },
        7: {
          min: 14,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 25,
        },
        13: {
          min: 26,
          max: 27,
        },
        14: {
          min: 28,
          max: 29,
        },
        15: {
          min: 30,
          max: 32,
        },
        16: {
          min: 33,
          max: 34,
        },
        17: {
          min: 35,
          max: 37,
        },
        18: {
          min: 38,
          max: 38,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 10,
        },
        3: {
          min: 11,
          max: 15,
        },
        4: {
          min: 16,
          max: 20,
        },
        5: {
          min: 21,
          max: 24,
        },
        6: {
          min: 25,
          max: 29,
        },
        7: {
          min: 30,
          max: 33,
        },
        8: {
          min: 34,
          max: 37,
        },
        9: {
          min: 38,
          max: 41,
        },
        10: {
          min: 42,
          max: 45,
        },
        11: {
          min: 46,
          max: 48,
        },
        12: {
          min: 49,
          max: 51,
        },
        13: {
          min: 52,
          max: 54,
        },
        14: {
          min: 55,
          max: 57,
        },
        15: {
          min: 58,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 11,
        },
        13: {
          min: 12,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: null,
          max: null,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 22,
        },
        2: {
          min: 23,
          max: 27,
        },
        3: {
          min: 28,
          max: 31,
        },
        4: {
          min: 32,
          max: 36,
        },
        5: {
          min: 37,
          max: 41,
        },
        6: {
          min: 42,
          max: 45,
        },
        7: {
          min: 46,
          max: 50,
        },
        8: {
          min: 51,
          max: 54,
        },
        9: {
          min: 55,
          max: 58,
        },
        10: {
          min: 59,
          max: 63,
        },
        11: {
          min: 64,
          max: 67,
        },
        12: {
          min: 68,
          max: 71,
        },
        13: {
          min: 72,
          max: 74,
        },
        14: {
          min: 75,
          max: 78,
        },
        15: {
          min: 79,
          max: 82,
        },
        16: {
          min: 83,
          max: 86,
        },
        17: {
          min: 87,
          max: 89,
        },
        18: {
          min: 90,
          max: 93,
        },
        19: {
          min: 94,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 15,
          max: 15,
        },
        17: {
          min: 16,
          max: 16,
        },
        18: {
          min: 17,
          max: 17,
        },
        19: {
          min: 18,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: null,
          max: null,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 34,
        },
      },
    },
  ],

  909230: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 13,
        },
        4: {
          min: 14,
          max: 15,
        },
        5: {
          min: 16,
          max: 17,
        },
        6: {
          min: 18,
          max: 19,
        },
        7: {
          min: 20,
          max: 22,
        },
        8: {
          min: 23,
          max: 24,
        },
        9: {
          min: 25,
          max: 26,
        },
        10: {
          min: 27,
          max: 29,
        },
        11: {
          min: 30,
          max: 31,
        },
        12: {
          min: 32,
          max: 34,
        },
        13: {
          min: 35,
          max: 36,
        },
        14: {
          min: 37,
          max: 39,
        },
        15: {
          min: 40,
          max: 42,
        },
        16: {
          min: 43,
          max: 45,
        },
        17: {
          min: 46,
          max: 48,
        },
        18: {
          min: 49,
          max: 51,
        },
        19: {
          min: 52,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 10,
        },
        4: {
          min: 11,
          max: 14,
        },
        5: {
          min: 15,
          max: 17,
        },
        6: {
          min: 18,
          max: 21,
        },
        7: {
          min: 22,
          max: 24,
        },
        8: {
          min: 25,
          max: 27,
        },
        9: {
          min: 28,
          max: 31,
        },
        10: {
          min: 32,
          max: 34,
        },
        11: {
          min: 35,
          max: 37,
        },
        12: {
          min: 38,
          max: 40,
        },
        13: {
          min: 41,
          max: 43,
        },
        14: {
          min: 44,
          max: 46,
        },
        15: {
          min: 47,
          max: 49,
        },
        16: {
          min: 50,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 57,
        },
        19: {
          min: 58,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 11,
        },
        3: {
          min: 12,
          max: 16,
        },
        4: {
          min: 17,
          max: 21,
        },
        5: {
          min: 22,
          max: 26,
        },
        6: {
          min: 27,
          max: 30,
        },
        7: {
          min: 31,
          max: 35,
        },
        8: {
          min: 36,
          max: 39,
        },
        9: {
          min: 40,
          max: 43,
        },
        10: {
          min: 44,
          max: 46,
        },
        11: {
          min: 47,
          max: 50,
        },
        12: {
          min: 51,
          max: 53,
        },
        13: {
          min: 54,
          max: 55,
        },
        14: {
          min: 56,
          max: 58,
        },
        15: {
          min: 59,
          max: 61,
        },
        16: {
          min: 62,
          max: 63,
        },
        17: {
          min: 64,
          max: 66,
        },
        18: {
          min: 67,
          max: 68,
        },
        19: {
          min: 69,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 30,
        },
        18: {
          min: 31,
          max: 32,
        },
        19: {
          min: 33,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 6,
          max: 6,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 10,
        },
        12: {
          min: 11,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 31,
        },
        15: {
          min: 32,
          max: 33,
        },
        16: {
          min: 34,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 23,
        },
        2: {
          min: 24,
          max: 28,
        },
        3: {
          min: 29,
          max: 33,
        },
        4: {
          min: 34,
          max: 38,
        },
        5: {
          min: 39,
          max: 42,
        },
        6: {
          min: 43,
          max: 47,
        },
        7: {
          min: 48,
          max: 51,
        },
        8: {
          min: 52,
          max: 56,
        },
        9: {
          min: 57,
          max: 60,
        },
        10: {
          min: 61,
          max: 64,
        },
        11: {
          min: 65,
          max: 68,
        },
        12: {
          min: 69,
          max: 72,
        },
        13: {
          min: 73,
          max: 76,
        },
        14: {
          min: 77,
          max: 80,
        },
        15: {
          min: 81,
          max: 83,
        },
        16: {
          min: 84,
          max: 87,
        },
        17: {
          min: 88,
          max: 91,
        },
        18: {
          min: 92,
          max: 94,
        },
        19: {
          min: 95,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: null,
          max: null,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 34,
        },
      },
    },
  ],

  939530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 14,
        },
        4: {
          min: 15,
          max: 16,
        },
        5: {
          min: 17,
          max: 18,
        },
        6: {
          min: 19,
          max: 20,
        },
        7: {
          min: 21,
          max: 23,
        },
        8: {
          min: 24,
          max: 25,
        },
        9: {
          min: 26,
          max: 27,
        },
        10: {
          min: 28,
          max: 30,
        },
        11: {
          min: 31,
          max: 32,
        },
        12: {
          min: 33,
          max: 35,
        },
        13: {
          min: 36,
          max: 37,
        },
        14: {
          min: 38,
          max: 40,
        },
        15: {
          min: 41,
          max: 43,
        },
        16: {
          min: 44,
          max: 46,
        },
        17: {
          min: 47,
          max: 49,
        },
        18: {
          min: 50,
          max: 52,
        },
        19: {
          min: 53,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 11,
        },
        4: {
          min: 12,
          max: 14,
        },
        5: {
          min: 15,
          max: 18,
        },
        6: {
          min: 19,
          max: 21,
        },
        7: {
          min: 22,
          max: 24,
        },
        8: {
          min: 25,
          max: 28,
        },
        9: {
          min: 29,
          max: 31,
        },
        10: {
          min: 32,
          max: 34,
        },
        11: {
          min: 35,
          max: 37,
        },
        12: {
          min: 38,
          max: 40,
        },
        13: {
          min: 41,
          max: 43,
        },
        14: {
          min: 44,
          max: 46,
        },
        15: {
          min: 47,
          max: 49,
        },
        16: {
          min: 50,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 58,
        },
        19: {
          min: 59,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 13,
        },
        3: {
          min: 14,
          max: 18,
        },
        4: {
          min: 19,
          max: 22,
        },
        5: {
          min: 23,
          max: 27,
        },
        6: {
          min: 28,
          max: 32,
        },
        7: {
          min: 33,
          max: 36,
        },
        8: {
          min: 37,
          max: 40,
        },
        9: {
          min: 41,
          max: 44,
        },
        10: {
          min: 45,
          max: 48,
        },
        11: {
          min: 49,
          max: 51,
        },
        12: {
          min: 52,
          max: 54,
        },
        13: {
          min: 55,
          max: 57,
        },
        14: {
          min: 58,
          max: 60,
        },
        15: {
          min: 61,
          max: 62,
        },
        16: {
          min: 63,
          max: 65,
        },
        17: {
          min: 66,
          max: 67,
        },
        18: {
          min: 68,
          max: 70,
        },
        19: {
          min: 71,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 29,
        },
        17: {
          min: 30,
          max: 31,
        },
        18: {
          min: 32,
          max: 33,
        },
        19: {
          min: 34,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 7,
          max: 7,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 9,
        },
        11: {
          min: 10,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 15,
        },
        15: {
          min: 16,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 20,
        },
        18: {
          min: 21,
          max: 22,
        },
        19: {
          min: 23,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 42,
        },
        19: {
          min: 43,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 31,
        },
        15: {
          min: 32,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 24,
        },
        2: {
          min: 25,
          max: 29,
        },
        3: {
          min: 30,
          max: 34,
        },
        4: {
          min: 35,
          max: 39,
        },
        5: {
          min: 40,
          max: 44,
        },
        6: {
          min: 45,
          max: 48,
        },
        7: {
          min: 49,
          max: 53,
        },
        8: {
          min: 54,
          max: 57,
        },
        9: {
          min: 58,
          max: 61,
        },
        10: {
          min: 62,
          max: 65,
        },
        11: {
          min: 66,
          max: 69,
        },
        12: {
          min: 70,
          max: 73,
        },
        13: {
          min: 74,
          max: 77,
        },
        14: {
          min: 78,
          max: 81,
        },
        15: {
          min: 82,
          max: 85,
        },
        16: {
          min: 86,
          max: 89,
        },
        17: {
          min: 90,
          max: 92,
        },
        18: {
          min: 93,
          max: 96,
        },
        19: {
          min: 97,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 15,
        },
        16: {
          min: 16,
          max: 16,
        },
        17: {
          min: 17,
          max: 17,
        },
        18: {
          min: 18,
          max: 18,
        },
        19: {
          min: 19,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 31,
        },
        19: {
          min: 32,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: null,
          max: null,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 34,
        },
      },
    },
  ],

  969830: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 12,
        },
        3: {
          min: 13,
          max: 14,
        },
        4: {
          min: 15,
          max: 16,
        },
        5: {
          min: 17,
          max: 19,
        },
        6: {
          min: 20,
          max: 21,
        },
        7: {
          min: 22,
          max: 23,
        },
        8: {
          min: 24,
          max: 26,
        },
        9: {
          min: 27,
          max: 28,
        },
        10: {
          min: 29,
          max: 30,
        },
        11: {
          min: 31,
          max: 33,
        },
        12: {
          min: 34,
          max: 36,
        },
        13: {
          min: 37,
          max: 38,
        },
        14: {
          min: 39,
          max: 41,
        },
        15: {
          min: 42,
          max: 44,
        },
        16: {
          min: 45,
          max: 47,
        },
        17: {
          min: 48,
          max: 50,
        },
        18: {
          min: 51,
          max: 52,
        },
        19: {
          min: 53,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 11,
        },
        4: {
          min: 12,
          max: 15,
        },
        5: {
          min: 16,
          max: 18,
        },
        6: {
          min: 19,
          max: 21,
        },
        7: {
          min: 22,
          max: 25,
        },
        8: {
          min: 26,
          max: 28,
        },
        9: {
          min: 29,
          max: 31,
        },
        10: {
          min: 32,
          max: 34,
        },
        11: {
          min: 35,
          max: 37,
        },
        12: {
          min: 38,
          max: 40,
        },
        13: {
          min: 41,
          max: 43,
        },
        14: {
          min: 44,
          max: 46,
        },
        15: {
          min: 47,
          max: 49,
        },
        16: {
          min: 50,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 58,
        },
        19: {
          min: 59,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 14,
        },
        3: {
          min: 15,
          max: 19,
        },
        4: {
          min: 20,
          max: 24,
        },
        5: {
          min: 25,
          max: 29,
        },
        6: {
          min: 30,
          max: 33,
        },
        7: {
          min: 34,
          max: 38,
        },
        8: {
          min: 39,
          max: 42,
        },
        9: {
          min: 43,
          max: 45,
        },
        10: {
          min: 46,
          max: 49,
        },
        11: {
          min: 50,
          max: 52,
        },
        12: {
          min: 53,
          max: 55,
        },
        13: {
          min: 56,
          max: 58,
        },
        14: {
          min: 59,
          max: 61,
        },
        15: {
          min: 62,
          max: 64,
        },
        16: {
          min: 65,
          max: 66,
        },
        17: {
          min: 67,
          max: 69,
        },
        18: {
          min: 70,
          max: 71,
        },
        19: {
          min: 72,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 32,
        },
        18: {
          min: 33,
          max: 34,
        },
        19: {
          min: 35,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 5,
          max: 5,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 8,
          max: 8,
        },
        10: {
          min: 9,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 12,
        },
        13: {
          min: 13,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 22,
        },
        19: {
          min: 23,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 40,
        },
        18: {
          min: 41,
          max: 42,
        },
        19: {
          min: 43,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 26,
        },
        2: {
          min: 27,
          max: 31,
        },
        3: {
          min: 32,
          max: 36,
        },
        4: {
          min: 37,
          max: 40,
        },
        5: {
          min: 41,
          max: 45,
        },
        6: {
          min: 46,
          max: 50,
        },
        7: {
          min: 51,
          max: 54,
        },
        8: {
          min: 55,
          max: 58,
        },
        9: {
          min: 59,
          max: 63,
        },
        10: {
          min: 64,
          max: 67,
        },
        11: {
          min: 68,
          max: 71,
        },
        12: {
          min: 72,
          max: 75,
        },
        13: {
          min: 76,
          max: 79,
        },
        14: {
          min: 80,
          max: 82,
        },
        15: {
          min: 83,
          max: 86,
        },
        16: {
          min: 87,
          max: 90,
        },
        17: {
          min: 91,
          max: 93,
        },
        18: {
          min: 94,
          max: 97,
        },
        19: {
          min: 98,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: null,
          max: null,
        },
        13: {
          min: 13,
          max: 13,
        },
        14: {
          min: 14,
          max: 14,
        },
        15: {
          min: 15,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 34,
        },
      },
    },
  ],

  9991130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 13,
        },
        3: {
          min: 14,
          max: 15,
        },
        4: {
          min: 16,
          max: 17,
        },
        5: {
          min: 18,
          max: 19,
        },
        6: {
          min: 20,
          max: 22,
        },
        7: {
          min: 23,
          max: 24,
        },
        8: {
          min: 25,
          max: 26,
        },
        9: {
          min: 27,
          max: 29,
        },
        10: {
          min: 30,
          max: 31,
        },
        11: {
          min: 32,
          max: 34,
        },
        12: {
          min: 35,
          max: 36,
        },
        13: {
          min: 37,
          max: 39,
        },
        14: {
          min: 40,
          max: 42,
        },
        15: {
          min: 43,
          max: 45,
        },
        16: {
          min: 46,
          max: 48,
        },
        17: {
          min: 49,
          max: 50,
        },
        18: {
          min: 51,
          max: 53,
        },
        19: {
          min: 54,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 8,
        },
        3: {
          min: 9,
          max: 11,
        },
        4: {
          min: 12,
          max: 15,
        },
        5: {
          min: 16,
          max: 18,
        },
        6: {
          min: 19,
          max: 22,
        },
        7: {
          min: 23,
          max: 25,
        },
        8: {
          min: 26,
          max: 28,
        },
        9: {
          min: 29,
          max: 32,
        },
        10: {
          min: 33,
          max: 35,
        },
        11: {
          min: 36,
          max: 38,
        },
        12: {
          min: 39,
          max: 41,
        },
        13: {
          min: 42,
          max: 44,
        },
        14: {
          min: 45,
          max: 47,
        },
        15: {
          min: 48,
          max: 50,
        },
        16: {
          min: 51,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 58,
        },
        19: {
          min: 59,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 15,
        },
        3: {
          min: 16,
          max: 20,
        },
        4: {
          min: 21,
          max: 25,
        },
        5: {
          min: 26,
          max: 30,
        },
        6: {
          min: 31,
          max: 35,
        },
        7: {
          min: 36,
          max: 39,
        },
        8: {
          min: 40,
          max: 43,
        },
        9: {
          min: 44,
          max: 47,
        },
        10: {
          min: 48,
          max: 50,
        },
        11: {
          min: 51,
          max: 54,
        },
        12: {
          min: 55,
          max: 57,
        },
        13: {
          min: 58,
          max: 60,
        },
        14: {
          min: 61,
          max: 62,
        },
        15: {
          min: 63,
          max: 65,
        },
        16: {
          min: 66,
          max: 68,
        },
        17: {
          min: 69,
          max: 70,
        },
        18: {
          min: 71,
          max: 72,
        },
        19: {
          min: 73,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 25,
        },
        19: {
          min: 26,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 28,
        },
        16: {
          min: 29,
          max: 30,
        },
        17: {
          min: 31,
          max: 32,
        },
        18: {
          min: 33,
          max: 35,
        },
        19: {
          min: 36,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 11,
        },
        12: {
          min: 12,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 25,
        },
        19: {
          min: 26,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 8,
        },
        3: {
          min: 9,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 38,
        },
        17: {
          min: 39,
          max: 40,
        },
        18: {
          min: 41,
          max: 42,
        },
        19: {
          min: 43,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 27,
        },
        13: {
          min: 28,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 27,
        },
        2: {
          min: 28,
          max: 32,
        },
        3: {
          min: 33,
          max: 37,
        },
        4: {
          min: 38,
          max: 42,
        },
        5: {
          min: 43,
          max: 46,
        },
        6: {
          min: 47,
          max: 51,
        },
        7: {
          min: 52,
          max: 55,
        },
        8: {
          min: 56,
          max: 60,
        },
        9: {
          min: 61,
          max: 64,
        },
        10: {
          min: 65,
          max: 68,
        },
        11: {
          min: 69,
          max: 72,
        },
        12: {
          min: 73,
          max: 76,
        },
        13: {
          min: 77,
          max: 80,
        },
        14: {
          min: 81,
          max: 84,
        },
        15: {
          min: 85,
          max: 87,
        },
        16: {
          min: 88,
          max: 91,
        },
        17: {
          min: 92,
          max: 95,
        },
        18: {
          min: 96,
          max: 98,
        },
        19: {
          min: 99,
          max: 104,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 18,
        },
        18: {
          min: 19,
          max: 19,
        },
        19: {
          min: 20,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 32,
        },
        19: {
          min: 33,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 4,
        },
        4: {
          min: 5,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 34,
        },
      },
    },
  ],

  10010530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 13,
        },
        3: {
          min: 14,
          max: 16,
        },
        4: {
          min: 17,
          max: 18,
        },
        5: {
          min: 19,
          max: 20,
        },
        6: {
          min: 21,
          max: 23,
        },
        7: {
          min: 24,
          max: 25,
        },
        8: {
          min: 26,
          max: 28,
        },
        9: {
          min: 29,
          max: 30,
        },
        10: {
          min: 31,
          max: 33,
        },
        11: {
          min: 34,
          max: 35,
        },
        12: {
          min: 36,
          max: 38,
        },
        13: {
          min: 39,
          max: 40,
        },
        14: {
          min: 41,
          max: 43,
        },
        15: {
          min: 44,
          max: 46,
        },
        16: {
          min: 47,
          max: 49,
        },
        17: {
          min: 50,
          max: 52,
        },
        18: {
          min: 53,
          max: 55,
        },
        19: {
          min: 56,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 8,
        },
        3: {
          min: 9,
          max: 12,
        },
        4: {
          min: 13,
          max: 15,
        },
        5: {
          min: 16,
          max: 19,
        },
        6: {
          min: 20,
          max: 22,
        },
        7: {
          min: 23,
          max: 26,
        },
        8: {
          min: 27,
          max: 29,
        },
        9: {
          min: 30,
          max: 32,
        },
        10: {
          min: 33,
          max: 35,
        },
        11: {
          min: 36,
          max: 38,
        },
        12: {
          min: 39,
          max: 41,
        },
        13: {
          min: 42,
          max: 44,
        },
        14: {
          min: 45,
          max: 47,
        },
        15: {
          min: 48,
          max: 50,
        },
        16: {
          min: 51,
          max: 53,
        },
        17: {
          min: 54,
          max: 56,
        },
        18: {
          min: 57,
          max: 59,
        },
        19: {
          min: 60,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 28,
        },
        2: {
          min: 29,
          max: 33,
        },
        3: {
          min: 34,
          max: 38,
        },
        4: {
          min: 39,
          max: 44,
        },
        5: {
          min: 45,
          max: 49,
        },
        6: {
          min: 50,
          max: 54,
        },
        7: {
          min: 55,
          max: 59,
        },
        8: {
          min: 60,
          max: 64,
        },
        9: {
          min: 65,
          max: 68,
        },
        10: {
          min: 69,
          max: 73,
        },
        11: {
          min: 74,
          max: 78,
        },
        12: {
          min: 79,
          max: 83,
        },
        13: {
          min: 84,
          max: 87,
        },
        14: {
          min: 88,
          max: 92,
        },
        15: {
          min: 93,
          max: 96,
        },
        16: {
          min: 97,
          max: 101,
        },
        17: {
          min: 102,
          max: 105,
        },
        18: {
          min: 106,
          max: 110,
        },
        19: {
          min: 111,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: 25,
          max: 25,
        },
        19: {
          min: 26,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 28,
        },
        16: {
          min: 29,
          max: 31,
        },
        17: {
          min: 32,
          max: 33,
        },
        18: {
          min: 34,
          max: 36,
        },
        19: {
          min: 37,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 6,
          max: 6,
        },
        7: {
          min: 7,
          max: 7,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 9,
        },
        10: {
          min: 10,
          max: 10,
        },
        11: {
          min: 11,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 26,
        },
        19: {
          min: 27,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 8,
        },
        3: {
          min: 9,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 40,
        },
        18: {
          min: 41,
          max: 43,
        },
        19: {
          min: 44,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 6,
        },
        3: {
          min: 7,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 12,
        },
        6: {
          min: 13,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 27,
        },
        2: {
          min: 28,
          max: 32,
        },
        3: {
          min: 33,
          max: 38,
        },
        4: {
          min: 39,
          max: 45,
        },
        5: {
          min: 46,
          max: 52,
        },
        6: {
          min: 53,
          max: 59,
        },
        7: {
          min: 60,
          max: 66,
        },
        8: {
          min: 67,
          max: 72,
        },
        9: {
          min: 73,
          max: 79,
        },
        10: {
          min: 80,
          max: 85,
        },
        11: {
          min: 86,
          max: 91,
        },
        12: {
          min: 92,
          max: 98,
        },
        13: {
          min: 99,
          max: 104,
        },
        14: {
          min: 105,
          max: 110,
        },
        15: {
          min: 111,
          max: 116,
        },
        16: {
          min: 117,
          max: 122,
        },
        17: {
          min: 123,
          max: 127,
        },
        18: {
          min: 128,
          max: 133,
        },
        19: {
          min: 134,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 14,
        },
        14: {
          min: 15,
          max: 15,
        },
        15: {
          min: 16,
          max: 16,
        },
        16: {
          min: 17,
          max: 17,
        },
        17: {
          min: 18,
          max: 19,
        },
        18: {
          min: 20,
          max: 20,
        },
        19: {
          min: 21,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 30,
        },
        18: {
          min: 31,
          max: 32,
        },
        19: {
          min: 33,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 3,
        },
        3: {
          min: 4,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 29,
        },
        19: {
          min: 30,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 34,
        },
      },
    },
  ],

  106101130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 14,
        },
        3: {
          min: 15,
          max: 17,
        },
        4: {
          min: 18,
          max: 19,
        },
        5: {
          min: 20,
          max: 22,
        },
        6: {
          min: 23,
          max: 24,
        },
        7: {
          min: 25,
          max: 26,
        },
        8: {
          min: 27,
          max: 29,
        },
        9: {
          min: 30,
          max: 31,
        },
        10: {
          min: 32,
          max: 34,
        },
        11: {
          min: 35,
          max: 37,
        },
        12: {
          min: 38,
          max: 39,
        },
        13: {
          min: 40,
          max: 42,
        },
        14: {
          min: 43,
          max: 45,
        },
        15: {
          min: 46,
          max: 48,
        },
        16: {
          min: 49,
          max: 51,
        },
        17: {
          min: 52,
          max: 54,
        },
        18: {
          min: 55,
          max: 57,
        },
        19: {
          min: 58,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 9,
        },
        3: {
          min: 10,
          max: 12,
        },
        4: {
          min: 13,
          max: 16,
        },
        5: {
          min: 17,
          max: 19,
        },
        6: {
          min: 20,
          max: 23,
        },
        7: {
          min: 24,
          max: 26,
        },
        8: {
          min: 27,
          max: 29,
        },
        9: {
          min: 30,
          max: 33,
        },
        10: {
          min: 34,
          max: 36,
        },
        11: {
          min: 37,
          max: 39,
        },
        12: {
          min: 40,
          max: 42,
        },
        13: {
          min: 43,
          max: 45,
        },
        14: {
          min: 46,
          max: 48,
        },
        15: {
          min: 49,
          max: 51,
        },
        16: {
          min: 52,
          max: 53,
        },
        17: {
          min: 54,
          max: 56,
        },
        18: {
          min: 57,
          max: 59,
        },
        19: {
          min: 60,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 29,
        },
        2: {
          min: 30,
          max: 35,
        },
        3: {
          min: 36,
          max: 40,
        },
        4: {
          min: 41,
          max: 46,
        },
        5: {
          min: 47,
          max: 51,
        },
        6: {
          min: 52,
          max: 56,
        },
        7: {
          min: 57,
          max: 61,
        },
        8: {
          min: 62,
          max: 66,
        },
        9: {
          min: 67,
          max: 71,
        },
        10: {
          min: 72,
          max: 76,
        },
        11: {
          min: 77,
          max: 81,
        },
        12: {
          min: 82,
          max: 86,
        },
        13: {
          min: 87,
          max: 90,
        },
        14: {
          min: 91,
          max: 95,
        },
        15: {
          min: 96,
          max: 100,
        },
        16: {
          min: 101,
          max: 104,
        },
        17: {
          min: 105,
          max: 109,
        },
        18: {
          min: 110,
          max: 113,
        },
        19: {
          min: 114,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 30,
        },
        16: {
          min: 31,
          max: 32,
        },
        17: {
          min: 33,
          max: 35,
        },
        18: {
          min: 36,
          max: 37,
        },
        19: {
          min: 38,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 8,
          max: 8,
        },
        9: {
          min: 9,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 27,
        },
        19: {
          min: 28,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 9,
        },
        3: {
          min: 10,
          max: 11,
        },
        4: {
          min: 12,
          max: 13,
        },
        5: {
          min: 14,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 34,
        },
        15: {
          min: 35,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 41,
        },
        18: {
          min: 42,
          max: 43,
        },
        19: {
          min: 44,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 6,
        },
        3: {
          min: 7,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 28,
        },
        2: {
          min: 29,
          max: 35,
        },
        3: {
          min: 36,
          max: 43,
        },
        4: {
          min: 44,
          max: 50,
        },
        5: {
          min: 51,
          max: 57,
        },
        6: {
          min: 58,
          max: 64,
        },
        7: {
          min: 65,
          max: 71,
        },
        8: {
          min: 72,
          max: 77,
        },
        9: {
          min: 78,
          max: 84,
        },
        10: {
          min: 85,
          max: 90,
        },
        11: {
          min: 91,
          max: 97,
        },
        12: {
          min: 98,
          max: 103,
        },
        13: {
          min: 104,
          max: 109,
        },
        14: {
          min: 110,
          max: 115,
        },
        15: {
          min: 116,
          max: 121,
        },
        16: {
          min: 122,
          max: 127,
        },
        17: {
          min: 128,
          max: 133,
        },
        18: {
          min: 134,
          max: 139,
        },
        19: {
          min: 140,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 12,
        },
        12: {
          min: 13,
          max: 13,
        },
        13: {
          min: 14,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 18,
        },
        17: {
          min: 19,
          max: 19,
        },
        18: {
          min: 20,
          max: 21,
        },
        19: {
          min: 22,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 31,
        },
        18: {
          min: 32,
          max: 33,
        },
        19: {
          min: 34,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 2,
        },
        2: {
          min: 3,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 34,
        },
      },
    },
  ],

  11011530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: 14,
          max: 15,
        },
        3: {
          min: 16,
          max: 18,
        },
        4: {
          min: 19,
          max: 20,
        },
        5: {
          min: 21,
          max: 23,
        },
        6: {
          min: 24,
          max: 25,
        },
        7: {
          min: 26,
          max: 28,
        },
        8: {
          min: 29,
          max: 30,
        },
        9: {
          min: 31,
          max: 33,
        },
        10: {
          min: 34,
          max: 35,
        },
        11: {
          min: 36,
          max: 38,
        },
        12: {
          min: 39,
          max: 41,
        },
        13: {
          min: 42,
          max: 44,
        },
        14: {
          min: 45,
          max: 46,
        },
        15: {
          min: 47,
          max: 49,
        },
        16: {
          min: 50,
          max: 52,
        },
        17: {
          min: 53,
          max: 55,
        },
        18: {
          min: 56,
          max: 58,
        },
        19: {
          min: 59,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 9,
        },
        3: {
          min: 10,
          max: 13,
        },
        4: {
          min: 14,
          max: 16,
        },
        5: {
          min: 17,
          max: 20,
        },
        6: {
          min: 21,
          max: 23,
        },
        7: {
          min: 24,
          max: 27,
        },
        8: {
          min: 28,
          max: 30,
        },
        9: {
          min: 31,
          max: 33,
        },
        10: {
          min: 34,
          max: 36,
        },
        11: {
          min: 37,
          max: 39,
        },
        12: {
          min: 40,
          max: 42,
        },
        13: {
          min: 43,
          max: 45,
        },
        14: {
          min: 46,
          max: 48,
        },
        15: {
          min: 49,
          max: 51,
        },
        16: {
          min: 52,
          max: 54,
        },
        17: {
          min: 55,
          max: 57,
        },
        18: {
          min: 58,
          max: 59,
        },
        19: {
          min: 60,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 31,
        },
        2: {
          min: 32,
          max: 37,
        },
        3: {
          min: 38,
          max: 42,
        },
        4: {
          min: 43,
          max: 48,
        },
        5: {
          min: 49,
          max: 53,
        },
        6: {
          min: 54,
          max: 58,
        },
        7: {
          min: 59,
          max: 64,
        },
        8: {
          min: 65,
          max: 69,
        },
        9: {
          min: 70,
          max: 74,
        },
        10: {
          min: 75,
          max: 79,
        },
        11: {
          min: 80,
          max: 84,
        },
        12: {
          min: 85,
          max: 89,
        },
        13: {
          min: 90,
          max: 93,
        },
        14: {
          min: 94,
          max: 98,
        },
        15: {
          min: 99,
          max: 103,
        },
        16: {
          min: 104,
          max: 108,
        },
        17: {
          min: 109,
          max: 112,
        },
        18: {
          min: 113,
          max: 117,
        },
        19: {
          min: 118,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: 25,
          max: 25,
        },
        18: {
          min: 26,
          max: 26,
        },
        19: {
          min: 27,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 23,
        },
        13: {
          min: 24,
          max: 25,
        },
        14: {
          min: 26,
          max: 28,
        },
        15: {
          min: 29,
          max: 31,
        },
        16: {
          min: 32,
          max: 33,
        },
        17: {
          min: 34,
          max: 36,
        },
        18: {
          min: 37,
          max: 39,
        },
        19: {
          min: 40,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 5,
          max: 5,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 11,
        },
        11: {
          min: 12,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 25,
        },
        19: {
          min: 26,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 28,
        },
        19: {
          min: 29,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 9,
        },
        3: {
          min: 10,
          max: 11,
        },
        4: {
          min: 12,
          max: 13,
        },
        5: {
          min: 14,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 32,
        },
        14: {
          min: 33,
          max: 34,
        },
        15: {
          min: 35,
          max: 37,
        },
        16: {
          min: 38,
          max: 39,
        },
        17: {
          min: 40,
          max: 41,
        },
        18: {
          min: 42,
          max: 44,
        },
        19: {
          min: 45,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 8,
        },
        4: {
          min: 9,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 32,
        },
        2: {
          min: 33,
          max: 39,
        },
        3: {
          min: 40,
          max: 47,
        },
        4: {
          min: 48,
          max: 54,
        },
        5: {
          min: 55,
          max: 61,
        },
        6: {
          min: 62,
          max: 68,
        },
        7: {
          min: 69,
          max: 75,
        },
        8: {
          min: 76,
          max: 82,
        },
        9: {
          min: 83,
          max: 88,
        },
        10: {
          min: 89,
          max: 95,
        },
        11: {
          min: 96,
          max: 101,
        },
        12: {
          min: 102,
          max: 108,
        },
        13: {
          min: 109,
          max: 114,
        },
        14: {
          min: 115,
          max: 120,
        },
        15: {
          min: 121,
          max: 126,
        },
        16: {
          min: 127,
          max: 132,
        },
        17: {
          min: 133,
          max: 138,
        },
        18: {
          min: 139,
          max: 144,
        },
        19: {
          min: 145,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 6,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 16,
        },
        15: {
          min: 17,
          max: 17,
        },
        16: {
          min: 18,
          max: 19,
        },
        17: {
          min: 20,
          max: 20,
        },
        18: {
          min: 21,
          max: 21,
        },
        19: {
          min: 22,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 32,
        },
        18: {
          min: 33,
          max: 34,
        },
        19: {
          min: 35,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 25,
        },
        19: {
          min: 26,
          max: 34,
        },
      },
    },
  ],

  116111130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: 15,
          max: 16,
        },
        3: {
          min: 17,
          max: 19,
        },
        4: {
          min: 20,
          max: 21,
        },
        5: {
          min: 22,
          max: 24,
        },
        6: {
          min: 25,
          max: 26,
        },
        7: {
          min: 27,
          max: 29,
        },
        8: {
          min: 30,
          max: 32,
        },
        9: {
          min: 33,
          max: 34,
        },
        10: {
          min: 35,
          max: 37,
        },
        11: {
          min: 38,
          max: 40,
        },
        12: {
          min: 41,
          max: 42,
        },
        13: {
          min: 43,
          max: 45,
        },
        14: {
          min: 46,
          max: 48,
        },
        15: {
          min: 49,
          max: 51,
        },
        16: {
          min: 52,
          max: 54,
        },
        17: {
          min: 55,
          max: 57,
        },
        18: {
          min: 58,
          max: 60,
        },
        19: {
          min: 61,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 9,
        },
        3: {
          min: 10,
          max: 13,
        },
        4: {
          min: 14,
          max: 17,
        },
        5: {
          min: 18,
          max: 20,
        },
        6: {
          min: 21,
          max: 24,
        },
        7: {
          min: 25,
          max: 27,
        },
        8: {
          min: 28,
          max: 31,
        },
        9: {
          min: 32,
          max: 34,
        },
        10: {
          min: 35,
          max: 37,
        },
        11: {
          min: 38,
          max: 40,
        },
        12: {
          min: 41,
          max: 43,
        },
        13: {
          min: 44,
          max: 46,
        },
        14: {
          min: 47,
          max: 49,
        },
        15: {
          min: 50,
          max: 51,
        },
        16: {
          min: 52,
          max: 54,
        },
        17: {
          min: 55,
          max: 57,
        },
        18: {
          min: 58,
          max: 60,
        },
        19: {
          min: 61,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 33,
        },
        2: {
          min: 34,
          max: 38,
        },
        3: {
          min: 39,
          max: 44,
        },
        4: {
          min: 45,
          max: 50,
        },
        5: {
          min: 51,
          max: 55,
        },
        6: {
          min: 56,
          max: 60,
        },
        7: {
          min: 61,
          max: 66,
        },
        8: {
          min: 67,
          max: 71,
        },
        9: {
          min: 72,
          max: 76,
        },
        10: {
          min: 77,
          max: 81,
        },
        11: {
          min: 82,
          max: 86,
        },
        12: {
          min: 87,
          max: 91,
        },
        13: {
          min: 92,
          max: 96,
        },
        14: {
          min: 97,
          max: 101,
        },
        15: {
          min: 102,
          max: 106,
        },
        16: {
          min: 107,
          max: 111,
        },
        17: {
          min: 112,
          max: 116,
        },
        18: {
          min: 117,
          max: 121,
        },
        19: {
          min: 122,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: 25,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 15,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 29,
        },
        15: {
          min: 30,
          max: 31,
        },
        16: {
          min: 32,
          max: 34,
        },
        17: {
          min: 35,
          max: 37,
        },
        18: {
          min: 38,
          max: 40,
        },
        19: {
          min: 41,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 6,
          max: 6,
        },
        6: {
          min: 7,
          max: 7,
        },
        7: {
          min: 8,
          max: 8,
        },
        8: {
          min: 9,
          max: 9,
        },
        9: {
          min: 10,
          max: 10,
        },
        10: {
          min: 11,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 22,
        },
        17: {
          min: 23,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 9,
        },
        4: {
          min: 10,
          max: 10,
        },
        5: {
          min: 11,
          max: 11,
        },
        6: {
          min: 12,
          max: 12,
        },
        7: {
          min: 13,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 29,
        },
        19: {
          min: 30,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 10,
        },
        3: {
          min: 11,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 18,
        },
        7: {
          min: 19,
          max: 20,
        },
        8: {
          min: 21,
          max: 22,
        },
        9: {
          min: 23,
          max: 24,
        },
        10: {
          min: 25,
          max: 26,
        },
        11: {
          min: 27,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 33,
        },
        14: {
          min: 34,
          max: 35,
        },
        15: {
          min: 36,
          max: 37,
        },
        16: {
          min: 38,
          max: 40,
        },
        17: {
          min: 41,
          max: 42,
        },
        18: {
          min: 43,
          max: 45,
        },
        19: {
          min: 46,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 35,
        },
        2: {
          min: 36,
          max: 42,
        },
        3: {
          min: 43,
          max: 50,
        },
        4: {
          min: 51,
          max: 57,
        },
        5: {
          min: 58,
          max: 65,
        },
        6: {
          min: 66,
          max: 72,
        },
        7: {
          min: 73,
          max: 79,
        },
        8: {
          min: 80,
          max: 86,
        },
        9: {
          min: 87,
          max: 92,
        },
        10: {
          min: 93,
          max: 99,
        },
        11: {
          min: 100,
          max: 105,
        },
        12: {
          min: 106,
          max: 112,
        },
        13: {
          min: 113,
          max: 118,
        },
        14: {
          min: 119,
          max: 124,
        },
        15: {
          min: 125,
          max: 131,
        },
        16: {
          min: 132,
          max: 137,
        },
        17: {
          min: 138,
          max: 143,
        },
        18: {
          min: 144,
          max: 149,
        },
        19: {
          min: 150,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 15,
        },
        14: {
          min: 16,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 19,
        },
        17: {
          min: 20,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 28,
        },
        16: {
          min: 29,
          max: 30,
        },
        17: {
          min: 31,
          max: 32,
        },
        18: {
          min: 33,
          max: 35,
        },
        19: {
          min: 36,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 22,
        },
        16: {
          min: 23,
          max: 23,
        },
        17: {
          min: 24,
          max: 24,
        },
        18: {
          min: 25,
          max: 26,
        },
        19: {
          min: 27,
          max: 34,
        },
      },
    },
  ],

  12012530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: 15,
          max: 17,
        },
        3: {
          min: 18,
          max: 20,
        },
        4: {
          min: 21,
          max: 22,
        },
        5: {
          min: 23,
          max: 25,
        },
        6: {
          min: 26,
          max: 27,
        },
        7: {
          min: 28,
          max: 30,
        },
        8: {
          min: 31,
          max: 33,
        },
        9: {
          min: 34,
          max: 35,
        },
        10: {
          min: 36,
          max: 38,
        },
        11: {
          min: 39,
          max: 41,
        },
        12: {
          min: 42,
          max: 44,
        },
        13: {
          min: 45,
          max: 47,
        },
        14: {
          min: 48,
          max: 49,
        },
        15: {
          min: 50,
          max: 52,
        },
        16: {
          min: 53,
          max: 55,
        },
        17: {
          min: 56,
          max: 58,
        },
        18: {
          min: 59,
          max: 61,
        },
        19: {
          min: 62,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 10,
        },
        3: {
          min: 11,
          max: 13,
        },
        4: {
          min: 14,
          max: 17,
        },
        5: {
          min: 18,
          max: 21,
        },
        6: {
          min: 22,
          max: 24,
        },
        7: {
          min: 25,
          max: 28,
        },
        8: {
          min: 29,
          max: 31,
        },
        9: {
          min: 32,
          max: 34,
        },
        10: {
          min: 35,
          max: 37,
        },
        11: {
          min: 38,
          max: 40,
        },
        12: {
          min: 41,
          max: 43,
        },
        13: {
          min: 44,
          max: 46,
        },
        14: {
          min: 47,
          max: 49,
        },
        15: {
          min: 50,
          max: 52,
        },
        16: {
          min: 53,
          max: 55,
        },
        17: {
          min: 56,
          max: 57,
        },
        18: {
          min: 58,
          max: 60,
        },
        19: {
          min: 61,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 34,
        },
        2: {
          min: 35,
          max: 40,
        },
        3: {
          min: 41,
          max: 46,
        },
        4: {
          min: 47,
          max: 51,
        },
        5: {
          min: 52,
          max: 57,
        },
        6: {
          min: 58,
          max: 63,
        },
        7: {
          min: 64,
          max: 68,
        },
        8: {
          min: 69,
          max: 73,
        },
        9: {
          min: 74,
          max: 79,
        },
        10: {
          min: 80,
          max: 84,
        },
        11: {
          min: 85,
          max: 89,
        },
        12: {
          min: 90,
          max: 94,
        },
        13: {
          min: 95,
          max: 99,
        },
        14: {
          min: 100,
          max: 104,
        },
        15: {
          min: 105,
          max: 109,
        },
        16: {
          min: 110,
          max: 114,
        },
        17: {
          min: 115,
          max: 119,
        },
        18: {
          min: 120,
          max: 124,
        },
        19: {
          min: 125,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 27,
        },
        19: {
          min: 28,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 27,
        },
        14: {
          min: 28,
          max: 30,
        },
        15: {
          min: 31,
          max: 32,
        },
        16: {
          min: 33,
          max: 35,
        },
        17: {
          min: 36,
          max: 38,
        },
        18: {
          min: 39,
          max: 41,
        },
        19: {
          min: 42,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 8,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 8,
        },
        3: {
          min: 9,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 18,
        },
        7: {
          min: 19,
          max: 20,
        },
        8: {
          min: 21,
          max: 22,
        },
        9: {
          min: 23,
          max: 24,
        },
        10: {
          min: 25,
          max: 26,
        },
        11: {
          min: 27,
          max: 29,
        },
        12: {
          min: 30,
          max: 31,
        },
        13: {
          min: 32,
          max: 33,
        },
        14: {
          min: 34,
          max: 35,
        },
        15: {
          min: 36,
          max: 38,
        },
        16: {
          min: 39,
          max: 40,
        },
        17: {
          min: 41,
          max: 43,
        },
        18: {
          min: 44,
          max: 45,
        },
        19: {
          min: 46,
          max: 46,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 25,
        },
        11: {
          min: 26,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 38,
        },
        2: {
          min: 39,
          max: 45,
        },
        3: {
          min: 46,
          max: 53,
        },
        4: {
          min: 54,
          max: 60,
        },
        5: {
          min: 61,
          max: 68,
        },
        6: {
          min: 69,
          max: 75,
        },
        7: {
          min: 76,
          max: 82,
        },
        8: {
          min: 83,
          max: 89,
        },
        9: {
          min: 90,
          max: 96,
        },
        10: {
          min: 97,
          max: 102,
        },
        11: {
          min: 103,
          max: 109,
        },
        12: {
          min: 110,
          max: 116,
        },
        13: {
          min: 117,
          max: 122,
        },
        14: {
          min: 123,
          max: 128,
        },
        15: {
          min: 129,
          max: 135,
        },
        16: {
          min: 136,
          max: 141,
        },
        17: {
          min: 142,
          max: 147,
        },
        18: {
          min: 148,
          max: 153,
        },
        19: {
          min: 154,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 12,
        },
        11: {
          min: 13,
          max: 13,
        },
        12: {
          min: 14,
          max: 14,
        },
        13: {
          min: 15,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 18,
        },
        16: {
          min: 19,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 22,
        },
        19: {
          min: 23,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 29,
        },
        16: {
          min: 30,
          max: 31,
        },
        17: {
          min: 32,
          max: 33,
        },
        18: {
          min: 34,
          max: 36,
        },
        19: {
          min: 37,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 24,
        },
        17: {
          min: 25,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 34,
        },
      },
    },
  ],


  126121130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 15,
        },
        2: {
          min: 16,
          max: 18,
        },
        3: {
          min: 19,
          max: 20,
        },
        4: {
          min: 21,
          max: 23,
        },
        5: {
          min: 24,
          max: 26,
        },
        6: {
          min: 27,
          max: 28,
        },
        7: {
          min: 29,
          max: 31,
        },
        8: {
          min: 32,
          max: 34,
        },
        9: {
          min: 35,
          max: 37,
        },
        10: {
          min: 38,
          max: 39,
        },
        11: {
          min: 40,
          max: 42,
        },
        12: {
          min: 43,
          max: 45,
        },
        13: {
          min: 46,
          max: 48,
        },
        14: {
          min: 49,
          max: 51,
        },
        15: {
          min: 52,
          max: 54,
        },
        16: {
          min: 55,
          max: 57,
        },
        17: {
          min: 58,
          max: 60,
        },
        18: {
          min: 61,
          max: 63,
        },
        19: {
          min: 64,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 10,
        },
        3: {
          min: 11,
          max: 14,
        },
        4: {
          min: 15,
          max: 18,
        },
        5: {
          min: 19,
          max: 21,
        },
        6: {
          min: 22,
          max: 25,
        },
        7: {
          min: 26,
          max: 28,
        },
        8: {
          min: 29,
          max: 31,
        },
        9: {
          min: 32,
          max: 35,
        },
        10: {
          min: 36,
          max: 38,
        },
        11: {
          min: 39,
          max: 41,
        },
        12: {
          min: 42,
          max: 44,
        },
        13: {
          min: 45,
          max: 47,
        },
        14: {
          min: 48,
          max: 50,
        },
        15: {
          min: 51,
          max: 52,
        },
        16: {
          min: 53,
          max: 55,
        },
        17: {
          min: 56,
          max: 58,
        },
        18: {
          min: 59,
          max: 60,
        },
        19: {
          min: 61,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 35,
        },
        2: {
          min: 36,
          max: 41,
        },
        3: {
          min: 42,
          max: 47,
        },
        4: {
          min: 48,
          max: 53,
        },
        5: {
          min: 54,
          max: 59,
        },
        6: {
          min: 60,
          max: 65,
        },
        7: {
          min: 66,
          max: 70,
        },
        8: {
          min: 71,
          max: 76,
        },
        9: {
          min: 77,
          max: 81,
        },
        10: {
          min: 82,
          max: 86,
        },
        11: {
          min: 87,
          max: 92,
        },
        12: {
          min: 93,
          max: 97,
        },
        13: {
          min: 98,
          max: 102,
        },
        14: {
          min: 103,
          max: 107,
        },
        15: {
          min: 108,
          max: 112,
        },
        16: {
          min: 113,
          max: 117,
        },
        17: {
          min: 118,
          max: 122,
        },
        18: {
          min: 123,
          max: 127,
        },
        19: {
          min: 128,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 27,
        },
        19: {
          min: 28,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 13,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 23,
        },
        12: {
          min: 24,
          max: 25,
        },
        13: {
          min: 26,
          max: 28,
        },
        14: {
          min: 29,
          max: 30,
        },
        15: {
          min: 31,
          max: 33,
        },
        16: {
          min: 34,
          max: 36,
        },
        17: {
          min: 37,
          max: 39,
        },
        18: {
          min: 40,
          max: 42,
        },
        19: {
          min: 43,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 11,
        },
        10: {
          min: 12,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 23,
        },
        17: {
          min: 24,
          max: 25,
        },
        18: {
          min: 26,
          max: 27,
        },
        19: {
          min: 28,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 13,
        },
        4: {
          min: 14,
          max: 15,
        },
        5: {
          min: 16,
          max: 17,
        },
        6: {
          min: 18,
          max: 19,
        },
        7: {
          min: 20,
          max: 21,
        },
        8: {
          min: 22,
          max: 23,
        },
        9: {
          min: 24,
          max: 25,
        },
        10: {
          min: 26,
          max: 27,
        },
        11: {
          min: 28,
          max: 29,
        },
        12: {
          min: 30,
          max: 31,
        },
        13: {
          min: 32,
          max: 34,
        },
        14: {
          min: 35,
          max: 36,
        },
        15: {
          min: 37,
          max: 38,
        },
        16: {
          min: 39,
          max: 41,
        },
        17: {
          min: 42,
          max: 43,
        },
        18: {
          min: 44,
          max: 46,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 9,
        },
        4: {
          min: 10,
          max: 11,
        },
        5: {
          min: 12,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 40,
        },
        2: {
          min: 41,
          max: 48,
        },
        3: {
          min: 49,
          max: 55,
        },
        4: {
          min: 56,
          max: 63,
        },
        5: {
          min: 64,
          max: 70,
        },
        6: {
          min: 71,
          max: 78,
        },
        7: {
          min: 79,
          max: 85,
        },
        8: {
          min: 86,
          max: 92,
        },
        9: {
          min: 93,
          max: 99,
        },
        10: {
          min: 100,
          max: 106,
        },
        11: {
          min: 107,
          max: 112,
        },
        12: {
          min: 113,
          max: 119,
        },
        13: {
          min: 120,
          max: 125,
        },
        14: {
          min: 126,
          max: 132,
        },
        15: {
          min: 133,
          max: 138,
        },
        16: {
          min: 139,
          max: 144,
        },
        17: {
          min: 145,
          max: 151,
        },
        18: {
          min: 152,
          max: 157,
        },
        19: {
          min: 158,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 7,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 17,
        },
        15: {
          min: 18,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 21,
        },
        18: {
          min: 22,
          max: 23,
        },
        19: {
          min: 24,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 14,
        },
        8: {
          min: 15,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 23,
        },
        13: {
          min: 24,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 29,
        },
        16: {
          min: 30,
          max: 32,
        },
        17: {
          min: 33,
          max: 34,
        },
        18: {
          min: 35,
          max: 36,
        },
        19: {
          min: 37,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: null,
          max: null,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 25,
        },
        17: {
          min: 26,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 34,
        },
      },
    },
  ],
// tu skonczone
  13013530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 16,
        },
        2: {
          min: 17,
          max: 18,
        },
        3: {
          min: 19,
          max: 21,
        },
        4: {
          min: 22,
          max: 24,
        },
        5: {
          min: 25,
          max: 27,
        },
        6: {
          min: 28,
          max: 29,
        },
        7: {
          min: 30,
          max: 32,
        },
        8: {
          min: 33,
          max: 35,
        },
        9: {
          min: 36,
          max: 38,
        },
        10: {
          min: 39,
          max: 41,
        },
        11: {
          min: 42,
          max: 43,
        },
        12: {
          min: 44,
          max: 46,
        },
        13: {
          min: 47,
          max: 49,
        },
        14: {
          min: 50,
          max: 52,
        },
        15: {
          min: 53,
          max: 55,
        },
        16: {
          min: 56,
          max: 58,
        },
        17: {
          min: 59,
          max: 61,
        },
        18: {
          min: 62,
          max: 64,
        },
        19: {
          min: 65,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 10,
        },
        3: {
          min: 11,
          max: 14,
        },
        4: {
          min: 15,
          max: 18,
        },
        5: {
          min: 19,
          max: 22,
        },
        6: {
          min: 23,
          max: 25,
        },
        7: {
          min: 26,
          max: 29,
        },
        8: {
          min: 30,
          max: 32,
        },
        9: {
          min: 33,
          max: 35,
        },
        10: {
          min: 36,
          max: 38,
        },
        11: {
          min: 39,
          max: 41,
        },
        12: {
          min: 42,
          max: 44,
        },
        13: {
          min: 45,
          max: 47,
        },
        14: {
          min: 48,
          max: 50,
        },
        15: {
          min: 51,
          max: 53,
        },
        16: {
          min: 54,
          max: 55,
        },
        17: {
          min: 56,
          max: 58,
        },
        18: {
          min: 59,
          max: 61,
        },
        19: {
          min: 62,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 36,
        },
        2: {
          min: 37,
          max: 43,
        },
        3: {
          min: 44,
          max: 49,
        },
        4: {
          min: 50,
          max: 55,
        },
        5: {
          min: 56,
          max: 61,
        },
        6: {
          min: 62,
          max: 66,
        },
        7: {
          min: 67,
          max: 72,
        },
        8: {
          min: 73,
          max: 78,
        },
        9: {
          min: 79,
          max: 83,
        },
        10: {
          min: 84,
          max: 89,
        },
        11: {
          min: 90,
          max: 94,
        },
        12: {
          min: 95,
          max: 100,
        },
        13: {
          min: 101,
          max: 105,
        },
        14: {
          min: 106,
          max: 110,
        },
        15: {
          min: 111,
          max: 115,
        },
        16: {
          min: 116,
          max: 120,
        },
        17: {
          min: 121,
          max: 126,
        },
        18: {
          min: 127,
          max: 131,
        },
        19: {
          min: 132,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 28,
        },
        19: {
          min: 29,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 23,
        },
        12: {
          min: 24,
          max: 26,
        },
        13: {
          min: 27,
          max: 28,
        },
        14: {
          min: 29,
          max: 31,
        },
        15: {
          min: 32,
          max: 34,
        },
        16: {
          min: 35,
          max: 37,
        },
        17: {
          min: 38,
          max: 40,
        },
        18: {
          min: 41,
          max: 43,
        },
        19: {
          min: 44,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 10,
        },
        9: {
          min: 11,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 11,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 25,
        },
        15: {
          min: 26,
          max: 27,
        },
        16: {
          min: 28,
          max: 28,
        },
        17: {
          min: 29,
          max: 30,
        },
        18: {
          min: 31,
          max: 32,
        },
        19: {
          min: 33,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 16,
        },
        3: {
          min: 17,
          max: 19,
        },
        4: {
          min: 20,
          max: 23,
        },
        5: {
          min: 24,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 33,
        },
        8: {
          min: 34,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 43,
        },
        11: {
          min: 44,
          max: 46,
        },
        12: {
          min: 47,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 61,
        },
        17: {
          min: 62,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 12,
        },
        5: {
          min: 13,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 19,
        },
        8: {
          min: 20,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 27,
        },
        12: {
          min: 28,
          max: 29,
        },
        13: {
          min: 30,
          max: 31,
        },
        14: {
          min: 32,
          max: 33,
        },
        15: {
          min: 34,
          max: 35,
        },
        16: {
          min: 36,
          max: 37,
        },
        17: {
          min: 38,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 42,
        },
        2: {
          min: 43,
          max: 50,
        },
        3: {
          min: 51,
          max: 57,
        },
        4: {
          min: 58,
          max: 65,
        },
        5: {
          min: 66,
          max: 73,
        },
        6: {
          min: 74,
          max: 80,
        },
        7: {
          min: 81,
          max: 87,
        },
        8: {
          min: 88,
          max: 94,
        },
        9: {
          min: 95,
          max: 101,
        },
        10: {
          min: 102,
          max: 108,
        },
        11: {
          min: 109,
          max: 115,
        },
        12: {
          min: 116,
          max: 122,
        },
        13: {
          min: 123,
          max: 128,
        },
        14: {
          min: 129,
          max: 135,
        },
        15: {
          min: 136,
          max: 141,
        },
        16: {
          min: 142,
          max: 148,
        },
        17: {
          min: 149,
          max: 154,
        },
        18: {
          min: 155,
          max: 160,
        },
        19: {
          min: 161,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 16,
        },
        14: {
          min: 17,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 20,
        },
        17: {
          min: 21,
          max: 22,
        },
        18: {
          min: 23,
          max: 23,
        },
        19: {
          min: 24,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 13,
        },
        7: {
          min: 14,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 21,
        },
        12: {
          min: 22,
          max: 23,
        },
        13: {
          min: 24,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 30,
        },
        16: {
          min: 31,
          max: 32,
        },
        17: {
          min: 33,
          max: 35,
        },
        18: {
          min: 36,
          max: 37,
        },
        19: {
          min: 38,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 34,
        },
      },
    },
  ],

  136131130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 16,
        },
        2: {
          min: 17,
          max: 19,
        },
        3: {
          min: 20,
          max: 22,
        },
        4: {
          min: 23,
          max: 25,
        },
        5: {
          min: 26,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 33,
        },
        8: {
          min: 34,
          max: 36,
        },
        9: {
          min: 37,
          max: 39,
        },
        10: {
          min: 40,
          max: 42,
        },
        11: {
          min: 43,
          max: 45,
        },
        12: {
          min: 46,
          max: 47,
        },
        13: {
          min: 48,
          max: 50,
        },
        14: {
          min: 51,
          max: 53,
        },
        15: {
          min: 54,
          max: 56,
        },
        16: {
          min: 57,
          max: 59,
        },
        17: {
          min: 60,
          max: 62,
        },
        18: {
          min: 63,
          max: 65,
        },
        19: {
          min: 66,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 11,
        },
        3: {
          min: 12,
          max: 14,
        },
        4: {
          min: 15,
          max: 18,
        },
        5: {
          min: 19,
          max: 22,
        },
        6: {
          min: 23,
          max: 26,
        },
        7: {
          min: 27,
          max: 29,
        },
        8: {
          min: 30,
          max: 32,
        },
        9: {
          min: 33,
          max: 36,
        },
        10: {
          min: 37,
          max: 39,
        },
        11: {
          min: 40,
          max: 42,
        },
        12: {
          min: 43,
          max: 45,
        },
        13: {
          min: 46,
          max: 47,
        },
        14: {
          min: 48,
          max: 50,
        },
        15: {
          min: 51,
          max: 53,
        },
        16: {
          min: 54,
          max: 56,
        },
        17: {
          min: 57,
          max: 58,
        },
        18: {
          min: 59,
          max: 61,
        },
        19: {
          min: 62,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 38,
        },
        2: {
          min: 39,
          max: 44,
        },
        3: {
          min: 45,
          max: 50,
        },
        4: {
          min: 51,
          max: 56,
        },
        5: {
          min: 57,
          max: 62,
        },
        6: {
          min: 63,
          max: 68,
        },
        7: {
          min: 69,
          max: 74,
        },
        8: {
          min: 75,
          max: 80,
        },
        9: {
          min: 81,
          max: 85,
        },
        10: {
          min: 86,
          max: 91,
        },
        11: {
          min: 92,
          max: 97,
        },
        12: {
          min: 98,
          max: 102,
        },
        13: {
          min: 103,
          max: 107,
        },
        14: {
          min: 108,
          max: 113,
        },
        15: {
          min: 114,
          max: 118,
        },
        16: {
          min: 119,
          max: 123,
        },
        17: {
          min: 124,
          max: 129,
        },
        18: {
          min: 130,
          max: 134,
        },
        19: {
          min: 135,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 27,
        },
        18: {
          min: 28,
          max: 28,
        },
        19: {
          min: 29,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 24,
        },
        12: {
          min: 25,
          max: 26,
        },
        13: {
          min: 27,
          max: 29,
        },
        14: {
          min: 30,
          max: 32,
        },
        15: {
          min: 33,
          max: 35,
        },
        16: {
          min: 36,
          max: 38,
        },
        17: {
          min: 39,
          max: 41,
        },
        18: {
          min: 42,
          max: 44,
        },
        19: {
          min: 45,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 9,
        },
        8: {
          min: 10,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 20,
        },
        15: {
          min: 21,
          max: 22,
        },
        16: {
          min: 23,
          max: 24,
        },
        17: {
          min: 25,
          max: 26,
        },
        18: {
          min: 27,
          max: 28,
        },
        19: {
          min: 29,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: 12,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 19,
        },
        10: {
          min: 20,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 23,
        },
        13: {
          min: 24,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 28,
        },
        16: {
          min: 29,
          max: 29,
        },
        17: {
          min: 30,
          max: 31,
        },
        18: {
          min: 32,
          max: 33,
        },
        19: {
          min: 34,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 16,
        },
        3: {
          min: 17,
          max: 19,
        },
        4: {
          min: 20,
          max: 23,
        },
        5: {
          min: 24,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 33,
        },
        8: {
          min: 34,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 43,
        },
        11: {
          min: 44,
          max: 46,
        },
        12: {
          min: 47,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 61,
        },
        17: {
          min: 62,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 7,
        },
        3: {
          min: 8,
          max: 9,
        },
        4: {
          min: 10,
          max: 12,
        },
        5: {
          min: 13,
          max: 14,
        },
        6: {
          min: 15,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 26,
        },
        11: {
          min: 27,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 32,
        },
        14: {
          min: 33,
          max: 34,
        },
        15: {
          min: 35,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 39,
        },
        18: {
          min: 40,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 43,
        },
        2: {
          min: 44,
          max: 51,
        },
        3: {
          min: 52,
          max: 59,
        },
        4: {
          min: 60,
          max: 67,
        },
        5: {
          min: 68,
          max: 75,
        },
        6: {
          min: 76,
          max: 82,
        },
        7: {
          min: 83,
          max: 89,
        },
        8: {
          min: 90,
          max: 97,
        },
        9: {
          min: 98,
          max: 104,
        },
        10: {
          min: 105,
          max: 111,
        },
        11: {
          min: 112,
          max: 117,
        },
        12: {
          min: 118,
          max: 124,
        },
        13: {
          min: 125,
          max: 131,
        },
        14: {
          min: 132,
          max: 137,
        },
        15: {
          min: 138,
          max: 144,
        },
        16: {
          min: 145,
          max: 150,
        },
        17: {
          min: 151,
          max: 157,
        },
        18: {
          min: 158,
          max: 163,
        },
        19: {
          min: 164,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: null,
          max: null,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 15,
        },
        13: {
          min: 16,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 19,
        },
        16: {
          min: 20,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 12,
        },
        6: {
          min: 13,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 16,
        },
        9: {
          min: 17,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 28,
        },
        15: {
          min: 29,
          max: 30,
        },
        16: {
          min: 31,
          max: 33,
        },
        17: {
          min: 34,
          max: 35,
        },
        18: {
          min: 36,
          max: 38,
        },
        19: {
          min: 39,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 6,
        },
        4: {
          min: 7,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 15,
        },
        10: {
          min: 16,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 17,
          max: 17,
        },
        10: {
          min: 18,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 34,
        },
      },
    },
  ],

  14014530: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 16,
        },
        2: {
          min: 17,
          max: 19,
        },
        3: {
          min: 20,
          max: 22,
        },
        4: {
          min: 23,
          max: 25,
        },
        5: {
          min: 26,
          max: 28,
        },
        6: {
          min: 29,
          max: 31,
        },
        7: {
          min: 32,
          max: 34,
        },
        8: {
          min: 35,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 43,
        },
        11: {
          min: 44,
          max: 46,
        },
        12: {
          min: 47,
          max: 49,
        },
        13: {
          min: 50,
          max: 51,
        },
        14: {
          min: 52,
          max: 54,
        },
        15: {
          min: 55,
          max: 57,
        },
        16: {
          min: 58,
          max: 60,
        },
        17: {
          min: 61,
          max: 63,
        },
        18: {
          min: 64,
          max: 67,
        },
        19: {
          min: 68,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 11,
        },
        3: {
          min: 12,
          max: 15,
        },
        4: {
          min: 16,
          max: 19,
        },
        5: {
          min: 20,
          max: 22,
        },
        6: {
          min: 23,
          max: 26,
        },
        7: {
          min: 27,
          max: 29,
        },
        8: {
          min: 30,
          max: 33,
        },
        9: {
          min: 34,
          max: 36,
        },
        10: {
          min: 37,
          max: 39,
        },
        11: {
          min: 40,
          max: 42,
        },
        12: {
          min: 43,
          max: 45,
        },
        13: {
          min: 46,
          max: 48,
        },
        14: {
          min: 49,
          max: 51,
        },
        15: {
          min: 52,
          max: 53,
        },
        16: {
          min: 54,
          max: 56,
        },
        17: {
          min: 57,
          max: 59,
        },
        18: {
          min: 60,
          max: 61,
        },
        19: {
          min: 62,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 39,
        },
        2: {
          min: 40,
          max: 45,
        },
        3: {
          min: 46,
          max: 51,
        },
        4: {
          min: 52,
          max: 58,
        },
        5: {
          min: 59,
          max: 64,
        },
        6: {
          min: 65,
          max: 70,
        },
        7: {
          min: 71,
          max: 76,
        },
        8: {
          min: 77,
          max: 82,
        },
        9: {
          min: 83,
          max: 87,
        },
        10: {
          min: 88,
          max: 93,
        },
        11: {
          min: 94,
          max: 99,
        },
        12: {
          min: 100,
          max: 104,
        },
        13: {
          min: 105,
          max: 110,
        },
        14: {
          min: 111,
          max: 115,
        },
        15: {
          min: 116,
          max: 121,
        },
        16: {
          min: 122,
          max: 126,
        },
        17: {
          min: 127,
          max: 131,
        },
        18: {
          min: 132,
          max: 137,
        },
        19: {
          min: 138,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 26,
        },
        17: {
          min: 27,
          max: 27,
        },
        18: {
          min: 28,
          max: 28,
        },
        19: {
          min: 29,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 14,
          max: 14,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 22,
        },
        11: {
          min: 23,
          max: 24,
        },
        12: {
          min: 25,
          max: 27,
        },
        13: {
          min: 28,
          max: 30,
        },
        14: {
          min: 31,
          max: 32,
        },
        15: {
          min: 33,
          max: 36,
        },
        16: {
          min: 37,
          max: 39,
        },
        17: {
          min: 40,
          max: 42,
        },
        18: {
          min: 43,
          max: 45,
        },
        19: {
          min: 46,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 8,
        },
        7: {
          min: 9,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 17,
        },
        13: {
          min: 18,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 11,
        },
        4: {
          min: 12,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 28,
        },
        16: {
          min: 29,
          max: 30,
        },
        17: {
          min: 31,
          max: 32,
        },
        18: {
          min: 33,
          max: 33,
        },
        19: {
          min: 34,
          max: 34,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 16,
        },
        3: {
          min: 17,
          max: 19,
        },
        4: {
          min: 20,
          max: 23,
        },
        5: {
          min: 24,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 33,
        },
        8: {
          min: 34,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 43,
        },
        11: {
          min: 44,
          max: 46,
        },
        12: {
          min: 47,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 61,
        },
        17: {
          min: 62,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 7,
        },
        3: {
          min: 8,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 22,
        },
        9: {
          min: 23,
          max: 24,
        },
        10: {
          min: 25,
          max: 26,
        },
        11: {
          min: 27,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 32,
        },
        14: {
          min: 33,
          max: 34,
        },
        15: {
          min: 35,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 40,
        },
        18: {
          min: 41,
          max: 41,
        },
        19: {
          min: 42,
          max: 42,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 45,
        },
        2: {
          min: 46,
          max: 53,
        },
        3: {
          min: 54,
          max: 61,
        },
        4: {
          min: 62,
          max: 68,
        },
        5: {
          min: 69,
          max: 76,
        },
        6: {
          min: 77,
          max: 84,
        },
        7: {
          min: 85,
          max: 91,
        },
        8: {
          min: 92,
          max: 99,
        },
        9: {
          min: 100,
          max: 106,
        },
        10: {
          min: 107,
          max: 113,
        },
        11: {
          min: 114,
          max: 120,
        },
        12: {
          min: 121,
          max: 127,
        },
        13: {
          min: 128,
          max: 133,
        },
        14: {
          min: 134,
          max: 140,
        },
        15: {
          min: 141,
          max: 147,
        },
        16: {
          min: 148,
          max: 153,
        },
        17: {
          min: 154,
          max: 160,
        },
        18: {
          min: 161,
          max: 166,
        },
        19: {
          min: 167,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: null,
          max: null,
        },
        9: {
          min: 12,
          max: 12,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 14,
        },
        12: {
          min: 15,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 22,
        },
        18: {
          min: 23,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 29,
        },
        15: {
          min: 30,
          max: 31,
        },
        16: {
          min: 32,
          max: 33,
        },
        17: {
          min: 34,
          max: 36,
        },
        18: {
          min: 37,
          max: 39,
        },
        19: {
          min: 40,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 9,
        },
        6: {
          min: 10,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 14,
        },
        9: {
          min: 15,
          max: 15,
        },
        10: {
          min: 16,
          max: 17,
        },
        11: {
          min: 18,
          max: 18,
        },
        12: {
          min: 19,
          max: 20,
        },
        13: {
          min: 21,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 22,
        },
        13: {
          min: 23,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 30,
        },
        19: {
          min: 31,
          max: 34,
        },
      },
    },
  ],

  146141130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 20,
        },
        3: {
          min: 21,
          max: 23,
        },
        4: {
          min: 24,
          max: 26,
        },
        5: {
          min: 27,
          max: 29,
        },
        6: {
          min: 30,
          max: 32,
        },
        7: {
          min: 33,
          max: 35,
        },
        8: {
          min: 36,
          max: 38,
        },
        9: {
          min: 39,
          max: 41,
        },
        10: {
          min: 42,
          max: 44,
        },
        11: {
          min: 45,
          max: 47,
        },
        12: {
          min: 48,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 65,
        },
        18: {
          min: 66,
          max: 68,
        },
        19: {
          min: 69,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 11,
        },
        3: {
          min: 12,
          max: 15,
        },
        4: {
          min: 16,
          max: 19,
        },
        5: {
          min: 20,
          max: 23,
        },
        6: {
          min: 24,
          max: 26,
        },
        7: {
          min: 27,
          max: 30,
        },
        8: {
          min: 31,
          max: 33,
        },
        9: {
          min: 34,
          max: 36,
        },
        10: {
          min: 37,
          max: 39,
        },
        11: {
          min: 40,
          max: 42,
        },
        12: {
          min: 43,
          max: 45,
        },
        13: {
          min: 46,
          max: 48,
        },
        14: {
          min: 49,
          max: 51,
        },
        15: {
          min: 52,
          max: 54,
        },
        16: {
          min: 55,
          max: 56,
        },
        17: {
          min: 57,
          max: 59,
        },
        18: {
          min: 60,
          max: 61,
        },
        19: {
          min: 62,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 40,
        },
        2: {
          min: 41,
          max: 46,
        },
        3: {
          min: 47,
          max: 53,
        },
        4: {
          min: 54,
          max: 59,
        },
        5: {
          min: 60,
          max: 65,
        },
        6: {
          min: 66,
          max: 71,
        },
        7: {
          min: 72,
          max: 77,
        },
        8: {
          min: 78,
          max: 83,
        },
        9: {
          min: 84,
          max: 89,
        },
        10: {
          min: 90,
          max: 95,
        },
        11: {
          min: 96,
          max: 101,
        },
        12: {
          min: 102,
          max: 107,
        },
        13: {
          min: 108,
          max: 112,
        },
        14: {
          min: 113,
          max: 118,
        },
        15: {
          min: 119,
          max: 123,
        },
        16: {
          min: 124,
          max: 129,
        },
        17: {
          min: 130,
          max: 134,
        },
        18: {
          min: 135,
          max: 140,
        },
        19: {
          min: 141,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 10,
        },
        2: {
          min: 11,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 19,
        },
        11: {
          min: 20,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 26,
        },
        17: {
          min: 27,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 14,
          max: 14,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 22,
        },
        11: {
          min: 23,
          max: 24,
        },
        12: {
          min: 25,
          max: 27,
        },
        13: {
          min: 28,
          max: 30,
        },
        14: {
          min: 31,
          max: 33,
        },
        15: {
          min: 34,
          max: 36,
        },
        16: {
          min: 37,
          max: 39,
        },
        17: {
          min: 40,
          max: 43,
        },
        18: {
          min: 44,
          max: 46,
        },
        19: {
          min: 47,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 6,
        },
        5: {
          min: 7,
          max: 7,
        },
        6: {
          min: 8,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 11,
        },
        9: {
          min: 12,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 19,
        },
        14: {
          min: 20,
          max: 21,
        },
        15: {
          min: 22,
          max: 23,
        },
        16: {
          min: 24,
          max: 25,
        },
        17: {
          min: 26,
          max: 27,
        },
        18: {
          min: 28,
          max: 29,
        },
        19: {
          min: 30,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 10,
        },
        3: {
          min: 11,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 14,
        },
        6: {
          min: 15,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 21,
        },
        11: {
          min: 22,
          max: 23,
        },
        12: {
          min: 24,
          max: 24,
        },
        13: {
          min: 25,
          max: 26,
        },
        14: {
          min: 27,
          max: 27,
        },
        15: {
          min: 28,
          max: 29,
        },
        16: {
          min: 30,
          max: 31,
        },
        17: {
          min: 32,
          max: 32,
        },
        18: {
          min: 33,
          max: 34,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 16,
        },
        3: {
          min: 17,
          max: 19,
        },
        4: {
          min: 20,
          max: 23,
        },
        5: {
          min: 24,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 34,
        },
        8: {
          min: 35,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 43,
        },
        11: {
          min: 44,
          max: 46,
        },
        12: {
          min: 47,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 64,
        },
        18: {
          min: 65,
          max: 67,
        },
        19: {
          min: 68,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 20,
        },
        8: {
          min: 21,
          max: 22,
        },
        9: {
          min: 23,
          max: 24,
        },
        10: {
          min: 25,
          max: 26,
        },
        11: {
          min: 27,
          max: 28,
        },
        12: {
          min: 29,
          max: 30,
        },
        13: {
          min: 31,
          max: 32,
        },
        14: {
          min: 33,
          max: 34,
        },
        15: {
          min: 35,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 40,
        },
        18: {
          min: 41,
          max: 42,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 46,
        },
        2: {
          min: 47,
          max: 54,
        },
        3: {
          min: 55,
          max: 62,
        },
        4: {
          min: 63,
          max: 70,
        },
        5: {
          min: 71,
          max: 78,
        },
        6: {
          min: 79,
          max: 85,
        },
        7: {
          min: 86,
          max: 93,
        },
        8: {
          min: 94,
          max: 100,
        },
        9: {
          min: 101,
          max: 107,
        },
        10: {
          min: 108,
          max: 115,
        },
        11: {
          min: 116,
          max: 122,
        },
        12: {
          min: 123,
          max: 129,
        },
        13: {
          min: 130,
          max: 135,
        },
        14: {
          min: 136,
          max: 142,
        },
        15: {
          min: 143,
          max: 149,
        },
        16: {
          min: 150,
          max: 155,
        },
        17: {
          min: 156,
          max: 162,
        },
        18: {
          min: 163,
          max: 169,
        },
        19: {
          min: 170,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: null,
          max: null,
        },
        10: {
          min: 13,
          max: 13,
        },
        11: {
          min: 14,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 18,
        },
        15: {
          min: 19,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 11,
        },
        5: {
          min: 12,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 15,
        },
        8: {
          min: 16,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 27,
        },
        14: {
          min: 28,
          max: 29,
        },
        15: {
          min: 30,
          max: 32,
        },
        16: {
          min: 33,
          max: 34,
        },
        17: {
          min: 35,
          max: 37,
        },
        18: {
          min: 38,
          max: 39,
        },
        19: {
          min: 40,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 5,
        },
        3: {
          min: 6,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: null,
          max: null,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 22,
        },
        13: {
          min: 23,
          max: 23,
        },
        14: {
          min: 24,
          max: 25,
        },
        15: {
          min: 26,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 30,
        },
        18: {
          min: 31,
          max: 31,
        },
        19: {
          min: 32,
          max: 34,
        },
      },
    },
  ],
// tu skonczone
  150151130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 20,
        },
        3: {
          min: 21,
          max: 23,
        },
        4: {
          min: 24,
          max: 26,
        },
        5: {
          min: 27,
          max: 29,
        },
        6: {
          min: 30,
          max: 33,
        },
        7: {
          min: 34,
          max: 36,
        },
        8: {
          min: 37,
          max: 39,
        },
        9: {
          min: 40,
          max: 42,
        },
        10: {
          min: 43,
          max: 45,
        },
        11: {
          min: 46,
          max: 48,
        },
        12: {
          min: 49,
          max: 51,
        },
        13: {
          min: 52,
          max: 54,
        },
        14: {
          min: 55,
          max: 57,
        },
        15: {
          min: 58,
          max: 60,
        },
        16: {
          min: 61,
          max: 63,
        },
        17: {
          min: 64,
          max: 66,
        },
        18: {
          min: 67,
          max: 69,
        },
        19: {
          min: 70,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 11,
        },
        3: {
          min: 12,
          max: 15,
        },
        4: {
          min: 16,
          max: 19,
        },
        5: {
          min: 20,
          max: 23,
        },
        6: {
          min: 24,
          max: 27,
        },
        7: {
          min: 28,
          max: 30,
        },
        8: {
          min: 31,
          max: 34,
        },
        9: {
          min: 35,
          max: 37,
        },
        10: {
          min: 38,
          max: 40,
        },
        11: {
          min: 41,
          max: 43,
        },
        12: {
          min: 44,
          max: 46,
        },
        13: {
          min: 47,
          max: 48,
        },
        14: {
          min: 49,
          max: 51,
        },
        15: {
          min: 52,
          max: 54,
        },
        16: {
          min: 55,
          max: 56,
        },
        17: {
          min: 57,
          max: 59,
        },
        18: {
          min: 60,
          max: 62,
        },
        19: {
          min: 63,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 41,
        },
        2: {
          min: 42,
          max: 48,
        },
        3: {
          min: 49,
          max: 54,
        },
        4: {
          min: 55,
          max: 61,
        },
        5: {
          min: 62,
          max: 67,
        },
        6: {
          min: 68,
          max: 74,
        },
        7: {
          min: 75,
          max: 80,
        },
        8: {
          min: 81,
          max: 86,
        },
        9: {
          min: 87,
          max: 92,
        },
        10: {
          min: 93,
          max: 98,
        },
        11: {
          min: 99,
          max: 104,
        },
        12: {
          min: 105,
          max: 110,
        },
        13: {
          min: 111,
          max: 116,
        },
        14: {
          min: 117,
          max: 121,
        },
        15: {
          min: 122,
          max: 127,
        },
        16: {
          min: 128,
          max: 133,
        },
        17: {
          min: 134,
          max: 138,
        },
        18: {
          min: 139,
          max: 144,
        },
        19: {
          min: 145,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 15,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 18,
        },
        10: {
          min: 19,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 22,
        },
        13: {
          min: 23,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 29,
        },
        19: {
          min: 30,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: 14,
          max: 14,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 22,
        },
        11: {
          min: 23,
          max: 25,
        },
        12: {
          min: 26,
          max: 28,
        },
        13: {
          min: 29,
          max: 31,
        },
        14: {
          min: 32,
          max: 34,
        },
        15: {
          min: 35,
          max: 37,
        },
        16: {
          min: 38,
          max: 41,
        },
        17: {
          min: 42,
          max: 44,
        },
        18: {
          min: 45,
          max: 48,
        },
        19: {
          min: 49,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 10,
        },
        8: {
          min: 11,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 18,
        },
        13: {
          min: 19,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 13,
        },
        5: {
          min: 14,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 22,
        },
        11: {
          min: 23,
          max: 24,
        },
        12: {
          min: 25,
          max: 25,
        },
        13: {
          min: 26,
          max: 27,
        },
        14: {
          min: 28,
          max: 28,
        },
        15: {
          min: 29,
          max: 30,
        },
        16: {
          min: 31,
          max: 31,
        },
        17: {
          min: 32,
          max: 33,
        },
        18: {
          min: 34,
          max: 34,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 16,
        },
        3: {
          min: 17,
          max: 20,
        },
        4: {
          min: 21,
          max: 23,
        },
        5: {
          min: 24,
          max: 27,
        },
        6: {
          min: 28,
          max: 30,
        },
        7: {
          min: 31,
          max: 34,
        },
        8: {
          min: 35,
          max: 37,
        },
        9: {
          min: 38,
          max: 40,
        },
        10: {
          min: 41,
          max: 44,
        },
        11: {
          min: 45,
          max: 47,
        },
        12: {
          min: 48,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 65,
        },
        18: {
          min: 66,
          max: 67,
        },
        19: {
          min: 68,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 7,
        },
        3: {
          min: 8,
          max: 10,
        },
        4: {
          min: 11,
          max: 13,
        },
        5: {
          min: 14,
          max: 15,
        },
        6: {
          min: 16,
          max: 18,
        },
        7: {
          min: 19,
          max: 20,
        },
        8: {
          min: 21,
          max: 22,
        },
        9: {
          min: 23,
          max: 25,
        },
        10: {
          min: 26,
          max: 27,
        },
        11: {
          min: 28,
          max: 29,
        },
        12: {
          min: 30,
          max: 31,
        },
        13: {
          min: 32,
          max: 33,
        },
        14: {
          min: 34,
          max: 35,
        },
        15: {
          min: 36,
          max: 36,
        },
        16: {
          min: 37,
          max: 38,
        },
        17: {
          min: 39,
          max: 40,
        },
        18: {
          min: 41,
          max: 42,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 47,
        },
        2: {
          min: 48,
          max: 55,
        },
        3: {
          min: 56,
          max: 63,
        },
        4: {
          min: 64,
          max: 71,
        },
        5: {
          min: 72,
          max: 79,
        },
        6: {
          min: 80,
          max: 87,
        },
        7: {
          min: 88,
          max: 95,
        },
        8: {
          min: 96,
          max: 102,
        },
        9: {
          min: 103,
          max: 110,
        },
        10: {
          min: 111,
          max: 117,
        },
        11: {
          min: 118,
          max: 124,
        },
        12: {
          min: 125,
          max: 131,
        },
        13: {
          min: 132,
          max: 138,
        },
        14: {
          min: 139,
          max: 145,
        },
        15: {
          min: 146,
          max: 152,
        },
        16: {
          min: 153,
          max: 159,
        },
        17: {
          min: 160,
          max: 165,
        },
        18: {
          min: 166,
          max: 172,
        },
        19: {
          min: 173,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 17,
        },
        14: {
          min: 18,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 17,
        },
        9: {
          min: 18,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 23,
        },
        12: {
          min: 24,
          max: 25,
        },
        13: {
          min: 26,
          max: 27,
        },
        14: {
          min: 28,
          max: 30,
        },
        15: {
          min: 31,
          max: 32,
        },
        16: {
          min: 33,
          max: 35,
        },
        17: {
          min: 36,
          max: 38,
        },
        18: {
          min: 39,
          max: 40,
        },
        19: {
          min: 41,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 8,
        },
        5: {
          min: 9,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 13,
        },
        8: {
          min: 14,
          max: 14,
        },
        9: {
          min: 15,
          max: 16,
        },
        10: {
          min: 17,
          max: 17,
        },
        11: {
          min: 18,
          max: 19,
        },
        12: {
          min: 20,
          max: 20,
        },
        13: {
          min: 21,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 20,
        },
        11: {
          min: 21,
          max: 22,
        },
        12: {
          min: 23,
          max: 23,
        },
        13: {
          min: 24,
          max: 24,
        },
        14: {
          min: 25,
          max: 26,
        },
        15: {
          min: 27,
          max: 27,
        },
        16: {
          min: 28,
          max: 29,
        },
        17: {
          min: 30,
          max: 31,
        },
        18: {
          min: 32,
          max: 32,
        },
        19: {
          min: 33,
          max: 34,
        },
      },
    },
  ],

  160161130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 20,
        },
        3: {
          min: 21,
          max: 24,
        },
        4: {
          min: 25,
          max: 27,
        },
        5: {
          min: 28,
          max: 30,
        },
        6: {
          min: 31,
          max: 33,
        },
        7: {
          min: 34,
          max: 37,
        },
        8: {
          min: 38,
          max: 40,
        },
        9: {
          min: 41,
          max: 43,
        },
        10: {
          min: 44,
          max: 46,
        },
        11: {
          min: 47,
          max: 49,
        },
        12: {
          min: 50,
          max: 52,
        },
        13: {
          min: 53,
          max: 56,
        },
        14: {
          min: 57,
          max: 59,
        },
        15: {
          min: 60,
          max: 62,
        },
        16: {
          min: 63,
          max: 65,
        },
        17: {
          min: 66,
          max: 68,
        },
        18: {
          min: 69,
          max: 71,
        },
        19: {
          min: 72,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 11,
        },
        3: {
          min: 12,
          max: 15,
        },
        4: {
          min: 16,
          max: 19,
        },
        5: {
          min: 20,
          max: 23,
        },
        6: {
          min: 24,
          max: 27,
        },
        7: {
          min: 28,
          max: 31,
        },
        8: {
          min: 32,
          max: 34,
        },
        9: {
          min: 35,
          max: 37,
        },
        10: {
          min: 38,
          max: 40,
        },
        11: {
          min: 41,
          max: 43,
        },
        12: {
          min: 44,
          max: 46,
        },
        13: {
          min: 47,
          max: 49,
        },
        14: {
          min: 50,
          max: 52,
        },
        15: {
          min: 53,
          max: 54,
        },
        16: {
          min: 55,
          max: 57,
        },
        17: {
          min: 58,
          max: 59,
        },
        18: {
          min: 60,
          max: 62,
        },
        19: {
          min: 63,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 42,
        },
        2: {
          min: 43,
          max: 49,
        },
        3: {
          min: 50,
          max: 56,
        },
        4: {
          min: 57,
          max: 63,
        },
        5: {
          min: 64,
          max: 69,
        },
        6: {
          min: 70,
          max: 76,
        },
        7: {
          min: 77,
          max: 83,
        },
        8: {
          min: 84,
          max: 89,
        },
        9: {
          min: 90,
          max: 95,
        },
        10: {
          min: 96,
          max: 102,
        },
        11: {
          min: 103,
          max: 108,
        },
        12: {
          min: 109,
          max: 114,
        },
        13: {
          min: 115,
          max: 120,
        },
        14: {
          min: 121,
          max: 126,
        },
        15: {
          min: 127,
          max: 132,
        },
        16: {
          min: 133,
          max: 138,
        },
        17: {
          min: 139,
          max: 143,
        },
        18: {
          min: 144,
          max: 149,
        },
        19: {
          min: 150,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 22,
        },
        13: {
          min: 23,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: null,
          max: null,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 26,
        },
        12: {
          min: 27,
          max: 29,
        },
        13: {
          min: 30,
          max: 32,
        },
        14: {
          min: 33,
          max: 35,
        },
        15: {
          min: 36,
          max: 39,
        },
        16: {
          min: 40,
          max: 42,
        },
        17: {
          min: 43,
          max: 46,
        },
        18: {
          min: 47,
          max: 49,
        },
        19: {
          min: 50,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 9,
        },
        7: {
          min: 10,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 20,
        },
        14: {
          min: 21,
          max: 22,
        },
        15: {
          min: 23,
          max: 24,
        },
        16: {
          min: 25,
          max: 26,
        },
        17: {
          min: 27,
          max: 28,
        },
        18: {
          min: 29,
          max: 31,
        },
        19: {
          min: 32,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 17,
        },
        7: {
          min: 18,
          max: 18,
        },
        8: {
          min: 19,
          max: 20,
        },
        9: {
          min: 21,
          max: 22,
        },
        10: {
          min: 23,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 26,
        },
        13: {
          min: 27,
          max: 28,
        },
        14: {
          min: 29,
          max: 29,
        },
        15: {
          min: 30,
          max: 31,
        },
        16: {
          min: 32,
          max: 32,
        },
        17: {
          min: 33,
          max: 34,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: 14,
          max: 16,
        },
        3: {
          min: 17,
          max: 20,
        },
        4: {
          min: 21,
          max: 24,
        },
        5: {
          min: 25,
          max: 27,
        },
        6: {
          min: 28,
          max: 31,
        },
        7: {
          min: 32,
          max: 34,
        },
        8: {
          min: 35,
          max: 37,
        },
        9: {
          min: 38,
          max: 41,
        },
        10: {
          min: 42,
          max: 44,
        },
        11: {
          min: 45,
          max: 47,
        },
        12: {
          min: 48,
          max: 50,
        },
        13: {
          min: 51,
          max: 53,
        },
        14: {
          min: 54,
          max: 56,
        },
        15: {
          min: 57,
          max: 59,
        },
        16: {
          min: 60,
          max: 62,
        },
        17: {
          min: 63,
          max: 65,
        },
        18: {
          min: 66,
          max: 68,
        },
        19: {
          min: 69,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 8,
        },
        3: {
          min: 9,
          max: 11,
        },
        4: {
          min: 12,
          max: 13,
        },
        5: {
          min: 14,
          max: 16,
        },
        6: {
          min: 17,
          max: 18,
        },
        7: {
          min: 19,
          max: 21,
        },
        8: {
          min: 22,
          max: 23,
        },
        9: {
          min: 24,
          max: 25,
        },
        10: {
          min: 26,
          max: 27,
        },
        11: {
          min: 28,
          max: 29,
        },
        12: {
          min: 30,
          max: 31,
        },
        13: {
          min: 32,
          max: 33,
        },
        14: {
          min: 34,
          max: 35,
        },
        15: {
          min: 36,
          max: 37,
        },
        16: {
          min: 38,
          max: 39,
        },
        17: {
          min: 40,
          max: 40,
        },
        18: {
          min: 41,
          max: 42,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 48,
        },
        2: {
          min: 49,
          max: 57,
        },
        3: {
          min: 58,
          max: 65,
        },
        4: {
          min: 66,
          max: 73,
        },
        5: {
          min: 74,
          max: 81,
        },
        6: {
          min: 82,
          max: 89,
        },
        7: {
          min: 90,
          max: 97,
        },
        8: {
          min: 98,
          max: 105,
        },
        9: {
          min: 106,
          max: 113,
        },
        10: {
          min: 114,
          max: 120,
        },
        11: {
          min: 121,
          max: 127,
        },
        12: {
          min: 128,
          max: 135,
        },
        13: {
          min: 136,
          max: 142,
        },
        14: {
          min: 143,
          max: 149,
        },
        15: {
          min: 150,
          max: 156,
        },
        16: {
          min: 157,
          max: 163,
        },
        17: {
          min: 164,
          max: 169,
        },
        18: {
          min: 170,
          max: 176,
        },
        19: {
          min: 177,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 10,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 13,
        },
        10: {
          min: 14,
          max: 14,
        },
        11: {
          min: 15,
          max: 15,
        },
        12: {
          min: 16,
          max: 16,
        },
        13: {
          min: 17,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 14,
        },
        7: {
          min: 15,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 21,
        },
        11: {
          min: 22,
          max: 24,
        },
        12: {
          min: 25,
          max: 26,
        },
        13: {
          min: 27,
          max: 28,
        },
        14: {
          min: 29,
          max: 31,
        },
        15: {
          min: 32,
          max: 34,
        },
        16: {
          min: 35,
          max: 36,
        },
        17: {
          min: 37,
          max: 39,
        },
        18: {
          min: 40,
          max: 42,
        },
        19: {
          min: 43,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 16,
        },
        10: {
          min: 17,
          max: 18,
        },
        11: {
          min: 19,
          max: 19,
        },
        12: {
          min: 20,
          max: 21,
        },
        13: {
          min: 22,
          max: 22,
        },
        14: {
          min: 23,
          max: 24,
        },
        15: {
          min: 25,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 20,
        },
        10: {
          min: 21,
          max: 21,
        },
        11: {
          min: 22,
          max: 22,
        },
        12: {
          min: 23,
          max: 24,
        },
        13: {
          min: 25,
          max: 25,
        },
        14: {
          min: 26,
          max: 27,
        },
        15: {
          min: 28,
          max: 28,
        },
        16: {
          min: 29,
          max: 30,
        },
        17: {
          min: 31,
          max: 32,
        },
        18: {
          min: 33,
          max: 33,
        },
        19: {
          min: 34,
          max: 34,
        },
      },
    },
  ],

  170171130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 20,
        },
        3: {
          min: 21,
          max: 24,
        },
        4: {
          min: 25,
          max: 27,
        },
        5: {
          min: 28,
          max: 31,
        },
        6: {
          min: 32,
          max: 34,
        },
        7: {
          min: 35,
          max: 37,
        },
        8: {
          min: 38,
          max: 41,
        },
        9: {
          min: 42,
          max: 44,
        },
        10: {
          min: 45,
          max: 47,
        },
        11: {
          min: 48,
          max: 51,
        },
        12: {
          min: 52,
          max: 54,
        },
        13: {
          min: 55,
          max: 57,
        },
        14: {
          min: 58,
          max: 60,
        },
        15: {
          min: 61,
          max: 63,
        },
        16: {
          min: 64,
          max: 66,
        },
        17: {
          min: 67,
          max: 69,
        },
        18: {
          min: 70,
          max: 72,
        },
        19: {
          min: 73,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 12,
        },
        3: {
          min: 13,
          max: 16,
        },
        4: {
          min: 17,
          max: 20,
        },
        5: {
          min: 21,
          max: 24,
        },
        6: {
          min: 25,
          max: 27,
        },
        7: {
          min: 28,
          max: 31,
        },
        8: {
          min: 32,
          max: 34,
        },
        9: {
          min: 35,
          max: 38,
        },
        10: {
          min: 39,
          max: 41,
        },
        11: {
          min: 42,
          max: 44,
        },
        12: {
          min: 45,
          max: 46,
        },
        13: {
          min: 47,
          max: 49,
        },
        14: {
          min: 50,
          max: 52,
        },
        15: {
          min: 53,
          max: 54,
        },
        16: {
          min: 55,
          max: 57,
        },
        17: {
          min: 58,
          max: 59,
        },
        18: {
          min: 60,
          max: 62,
        },
        19: {
          min: 63,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 43,
        },
        2: {
          min: 44,
          max: 50,
        },
        3: {
          min: 51,
          max: 57,
        },
        4: {
          min: 58,
          max: 64,
        },
        5: {
          min: 65,
          max: 71,
        },
        6: {
          min: 72,
          max: 78,
        },
        7: {
          min: 79,
          max: 85,
        },
        8: {
          min: 86,
          max: 92,
        },
        9: {
          min: 93,
          max: 98,
        },
        10: {
          min: 99,
          max: 105,
        },
        11: {
          min: 106,
          max: 111,
        },
        12: {
          min: 112,
          max: 117,
        },
        13: {
          min: 118,
          max: 124,
        },
        14: {
          min: 125,
          max: 130,
        },
        15: {
          min: 131,
          max: 136,
        },
        16: {
          min: 137,
          max: 142,
        },
        17: {
          min: 143,
          max: 148,
        },
        18: {
          min: 149,
          max: 154,
        },
        19: {
          min: 155,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 15,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 19,
        },
        10: {
          min: 20,
          max: 20,
        },
        11: {
          min: 21,
          max: 21,
        },
        12: {
          min: 22,
          max: 22,
        },
        13: {
          min: 23,
          max: 24,
        },
        14: {
          min: 25,
          max: 25,
        },
        15: {
          min: 26,
          max: 26,
        },
        16: {
          min: 27,
          max: 28,
        },
        17: {
          min: 29,
          max: 29,
        },
        18: {
          min: 30,
          max: 30,
        },
        19: {
          min: 31,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 15,
          max: 15,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 30,
        },
        13: {
          min: 31,
          max: 33,
        },
        14: {
          min: 34,
          max: 36,
        },
        15: {
          min: 37,
          max: 40,
        },
        16: {
          min: 41,
          max: 44,
        },
        17: {
          min: 45,
          max: 47,
        },
        18: {
          min: 48,
          max: 51,
        },
        19: {
          min: 52,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 5,
        },
        4: {
          min: 6,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 12,
        },
        9: {
          min: 13,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 17,
        },
        12: {
          min: 18,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 21,
        },
        9: {
          min: 22,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 26,
        },
        12: {
          min: 27,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 30,
        },
        15: {
          min: 31,
          max: 31,
        },
        16: {
          min: 32,
          max: 33,
        },
        17: {
          min: 34,
          max: 34,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 13,
        },
        2: {
          min: 14,
          max: 17,
        },
        3: {
          min: 18,
          max: 21,
        },
        4: {
          min: 22,
          max: 24,
        },
        5: {
          min: 25,
          max: 28,
        },
        6: {
          min: 29,
          max: 31,
        },
        7: {
          min: 32,
          max: 35,
        },
        8: {
          min: 36,
          max: 38,
        },
        9: {
          min: 39,
          max: 41,
        },
        10: {
          min: 42,
          max: 44,
        },
        11: {
          min: 45,
          max: 48,
        },
        12: {
          min: 49,
          max: 51,
        },
        13: {
          min: 52,
          max: 54,
        },
        14: {
          min: 55,
          max: 57,
        },
        15: {
          min: 58,
          max: 60,
        },
        16: {
          min: 61,
          max: 63,
        },
        17: {
          min: 64,
          max: 66,
        },
        18: {
          min: 67,
          max: 68,
        },
        19: {
          min: 69,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 5,
        },
        2: {
          min: 6,
          max: 8,
        },
        3: {
          min: 9,
          max: 11,
        },
        4: {
          min: 12,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 19,
        },
        7: {
          min: 20,
          max: 21,
        },
        8: {
          min: 22,
          max: 24,
        },
        9: {
          min: 25,
          max: 26,
        },
        10: {
          min: 27,
          max: 28,
        },
        11: {
          min: 29,
          max: 30,
        },
        12: {
          min: 31,
          max: 32,
        },
        13: {
          min: 33,
          max: 34,
        },
        14: {
          min: 35,
          max: 36,
        },
        15: {
          min: 37,
          max: 37,
        },
        16: {
          min: 38,
          max: 39,
        },
        17: {
          min: 40,
          max: 41,
        },
        18: {
          min: 42,
          max: 42,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 49,
        },
        2: {
          min: 50,
          max: 58,
        },
        3: {
          min: 59,
          max: 67,
        },
        4: {
          min: 68,
          max: 75,
        },
        5: {
          min: 76,
          max: 83,
        },
        6: {
          min: 84,
          max: 92,
        },
        7: {
          min: 93,
          max: 100,
        },
        8: {
          min: 101,
          max: 108,
        },
        9: {
          min: 109,
          max: 115,
        },
        10: {
          min: 116,
          max: 123,
        },
        11: {
          min: 124,
          max: 130,
        },
        12: {
          min: 131,
          max: 138,
        },
        13: {
          min: 139,
          max: 145,
        },
        14: {
          min: 146,
          max: 152,
        },
        15: {
          min: 153,
          max: 159,
        },
        16: {
          min: 160,
          max: 166,
        },
        17: {
          min: 167,
          max: 173,
        },
        18: {
          min: 174,
          max: 180,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 20,
        },
        16: {
          min: 21,
          max: 21,
        },
        17: {
          min: 22,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 16,
        },
        8: {
          min: 17,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 22,
        },
        11: {
          min: 23,
          max: 24,
        },
        12: {
          min: 25,
          max: 27,
        },
        13: {
          min: 28,
          max: 29,
        },
        14: {
          min: 30,
          max: 32,
        },
        15: {
          min: 33,
          max: 35,
        },
        16: {
          min: 36,
          max: 38,
        },
        17: {
          min: 39,
          max: 41,
        },
        18: {
          min: 42,
          max: 44,
        },
        19: {
          min: 45,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 13,
        },
        8: {
          min: 14,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 27,
        },
        17: {
          min: 28,
          max: 28,
        },
        18: {
          min: 29,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 17,
        },
        7: {
          min: 18,
          max: 18,
        },
        8: {
          min: 19,
          max: 19,
        },
        9: {
          min: 20,
          max: 21,
        },
        10: {
          min: 22,
          max: 22,
        },
        11: {
          min: 23,
          max: 23,
        },
        12: {
          min: 24,
          max: 25,
        },
        13: {
          min: 26,
          max: 26,
        },
        14: {
          min: 27,
          max: 28,
        },
        15: {
          min: 29,
          max: 29,
        },
        16: {
          min: 30,
          max: 31,
        },
        17: {
          min: 32,
          max: 32,
        },
        18: {
          min: 33,
          max: 34,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
  ],

  180181130: [
    {
      name: "Klocki",
      id: 1,
      rows: {
        1: {
          min: 0,
          max: 17,
        },
        2: {
          min: 18,
          max: 20,
        },
        3: {
          min: 21,
          max: 24,
        },
        4: {
          min: 25,
          max: 27,
        },
        5: {
          min: 28,
          max: 31,
        },
        6: {
          min: 32,
          max: 34,
        },
        7: {
          min: 35,
          max: 38,
        },
        8: {
          min: 39,
          max: 41,
        },
        9: {
          min: 42,
          max: 45,
        },
        10: {
          min: 46,
          max: 48,
        },
        11: {
          min: 49,
          max: 51,
        },
        12: {
          min: 52,
          max: 55,
        },
        13: {
          min: 56,
          max: 58,
        },
        14: {
          min: 59,
          max: 61,
        },
        15: {
          min: 62,
          max: 64,
        },
        16: {
          min: 65,
          max: 67,
        },
        17: {
          min: 68,
          max: 70,
        },
        18: {
          min: 71,
          max: 74,
        },
        19: {
          min: 75,
          max: 80,
        },
      },
    },
    {
      name: "Opowiadanie",
      id: 2,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: 8,
          max: 12,
        },
        3: {
          min: 13,
          max: 16,
        },
        4: {
          min: 17,
          max: 20,
        },
        5: {
          min: 21,
          max: 24,
        },
        6: {
          min: 25,
          max: 27,
        },
        7: {
          min: 28,
          max: 31,
        },
        8: {
          min: 32,
          max: 35,
        },
        9: {
          min: 36,
          max: 38,
        },
        10: {
          min: 39,
          max: 41,
        },
        11: {
          min: 42,
          max: 44,
        },
        12: {
          min: 45,
          max: 47,
        },
        13: {
          min: 48,
          max: 49,
        },
        14: {
          min: 50,
          max: 52,
        },
        15: {
          min: 53,
          max: 54,
        },
        16: {
          min: 55,
          max: 57,
        },
        17: {
          min: 58,
          max: 59,
        },
        18: {
          min: 60,
          max: 62,
        },
        19: {
          min: 63,
          max: 64,
        },
      },
    },
    {
      name: "Papugi",
      id: 3,
      rows: {
        1: {
          min: 0,
          max: 43,
        },
        2: {
          min: 44,
          max: 51,
        },
        3: {
          min: 52,
          max: 58,
        },
        4: {
          min: 59,
          max: 66,
        },
        5: {
          min: 67,
          max: 73,
        },
        6: {
          min: 74,
          max: 80,
        },
        7: {
          min: 81,
          max: 87,
        },
        8: {
          min: 88,
          max: 94,
        },
        9: {
          min: 95,
          max: 101,
        },
        10: {
          min: 102,
          max: 107,
        },
        11: {
          min: 108,
          max: 114,
        },
        12: {
          min: 115,
          max: 121,
        },
        13: {
          min: 122,
          max: 127,
        },
        14: {
          min: 128,
          max: 133,
        },
        15: {
          min: 134,
          max: 140,
        },
        16: {
          min: 141,
          max: 146,
        },
        17: {
          min: 147,
          max: 152,
        },
        18: {
          min: 153,
          max: 159,
        },
        19: {
          min: 160,
          max: 180,
        },
      },
    },
    {
      name: "Szeregi jednorodne",
      id: 4,
      rows: {
        1: {
          min: 0,
          max: 12,
        },
        2: {
          min: 13,
          max: 13,
        },
        3: {
          min: 14,
          max: 14,
        },
        4: {
          min: 15,
          max: 15,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 21,
        },
        11: {
          min: 22,
          max: 22,
        },
        12: {
          min: 23,
          max: 23,
        },
        13: {
          min: 24,
          max: 24,
        },
        14: {
          min: 25,
          max: 25,
        },
        15: {
          min: 26,
          max: 27,
        },
        16: {
          min: 28,
          max: 28,
        },
        17: {
          min: 29,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 40,
        },
      },
    },
    {
      name: "Bryły",
      id: 5,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: null,
          max: null,
        },
        4: {
          min: 15,
          max: 15,
        },
        5: {
          min: null,
          max: null,
        },
        6: {
          min: 16,
          max: 16,
        },
        7: {
          min: 17,
          max: 18,
        },
        8: {
          min: 19,
          max: 19,
        },
        9: {
          min: 20,
          max: 22,
        },
        10: {
          min: 23,
          max: 24,
        },
        11: {
          min: 25,
          max: 27,
        },
        12: {
          min: 28,
          max: 30,
        },
        13: {
          min: 31,
          max: 34,
        },
        14: {
          min: 35,
          max: 37,
        },
        15: {
          min: 38,
          max: 41,
        },
        16: {
          min: 42,
          max: 45,
        },
        17: {
          min: 46,
          max: 48,
        },
        18: {
          min: 49,
          max: 52,
        },
        19: {
          min: 53,
          max: 59,
        },
      },
    },
    {
      name: "Matryce",
      id: 6,
      rows: {
        1: {
          min: 0,
          max: 3,
        },
        2: {
          min: 4,
          max: 4,
        },
        3: {
          min: 5,
          max: 6,
        },
        4: {
          min: 7,
          max: 7,
        },
        5: {
          min: 8,
          max: 8,
        },
        6: {
          min: 9,
          max: 10,
        },
        7: {
          min: 11,
          max: 11,
        },
        8: {
          min: 12,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 16,
        },
        11: {
          min: 17,
          max: 18,
        },
        12: {
          min: 19,
          max: 19,
        },
        13: {
          min: 20,
          max: 21,
        },
        14: {
          min: 22,
          max: 23,
        },
        15: {
          min: 24,
          max: 25,
        },
        16: {
          min: 26,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 31,
        },
        19: {
          min: 32,
          max: 35,
        },
      },
    },
    {
      name: "Kategorie",
      id: 7,
      rows: {
        1: {
          min: 0,
          max: 9,
        },
        2: {
          min: 10,
          max: 11,
        },
        3: {
          min: 12,
          max: 12,
        },
        4: {
          min: 13,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 18,
        },
        7: {
          min: 19,
          max: 20,
        },
        8: {
          min: 21,
          max: 21,
        },
        9: {
          min: 22,
          max: 23,
        },
        10: {
          min: 24,
          max: 25,
        },
        11: {
          min: 26,
          max: 26,
        },
        12: {
          min: 27,
          max: 28,
        },
        13: {
          min: 29,
          max: 29,
        },
        14: {
          min: 30,
          max: 30,
        },
        15: {
          min: 31,
          max: 32,
        },
        16: {
          min: 33,
          max: 33,
        },
        17: {
          min: 34,
          max: 34,
        },
        18: {
          min: null,
          max: null,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Krążki",
      id: 8,
      rows: {
        1: {
          min: 0,
          max: 14,
        },
        2: {
          min: 15,
          max: 18,
        },
        3: {
          min: 19,
          max: 21,
        },
        4: {
          min: 22,
          max: 25,
        },
        5: {
          min: 26,
          max: 28,
        },
        6: {
          min: 29,
          max: 32,
        },
        7: {
          min: 33,
          max: 35,
        },
        8: {
          min: 36,
          max: 39,
        },
        9: {
          min: 40,
          max: 42,
        },
        10: {
          min: 43,
          max: 45,
        },
        11: {
          min: 46,
          max: 48,
        },
        12: {
          min: 49,
          max: 51,
        },
        13: {
          min: 52,
          max: 54,
        },
        14: {
          min: 55,
          max: 57,
        },
        15: {
          min: 58,
          max: 60,
        },
        16: {
          min: 61,
          max: 63,
        },
        17: {
          min: 64,
          max: 66,
        },
        18: {
          min: 67,
          max: 69,
        },
        19: {
          min: 70,
          max: 76,
        },
      },
    },
    {
      name: "Obrazek",
      id: 9,
      rows: {
        1: {
          min: 0,
          max: 6,
        },
        2: {
          min: 7,
          max: 9,
        },
        3: {
          min: 10,
          max: 11,
        },
        4: {
          min: 12,
          max: 14,
        },
        5: {
          min: 15,
          max: 17,
        },
        6: {
          min: 18,
          max: 19,
        },
        7: {
          min: 20,
          max: 22,
        },
        8: {
          min: 23,
          max: 24,
        },
        9: {
          min: 25,
          max: 26,
        },
        10: {
          min: 27,
          max: 29,
        },
        11: {
          min: 30,
          max: 31,
        },
        12: {
          min: 32,
          max: 33,
        },
        13: {
          min: 34,
          max: 34,
        },
        14: {
          min: 35,
          max: 36,
        },
        15: {
          min: 37,
          max: 38,
        },
        16: {
          min: 39,
          max: 40,
        },
        17: {
          min: 41,
          max: 41,
        },
        18: {
          min: 42,
          max: 42,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Kwadraty",
      id: 10,
      rows: {
        1: {
          min: 0,
          max: 51,
        },
        2: {
          min: 52,
          max: 60,
        },
        3: {
          min: 61,
          max: 69,
        },
        4: {
          min: 70,
          max: 77,
        },
        5: {
          min: 78,
          max: 86,
        },
        6: {
          min: 87,
          max: 94,
        },
        7: {
          min: 95,
          max: 103,
        },
        8: {
          min: 104,
          max: 111,
        },
        9: {
          min: 112,
          max: 118,
        },
        10: {
          min: 119,
          max: 126,
        },
        11: {
          min: 127,
          max: 134,
        },
        12: {
          min: 135,
          max: 141,
        },
        13: {
          min: 142,
          max: 149,
        },
        14: {
          min: 150,
          max: 156,
        },
        15: {
          min: 157,
          max: 164,
        },
        16: {
          min: 165,
          max: 171,
        },
        17: {
          min: 172,
          max: 178,
        },
        18: {
          min: 179,
          max: 180,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
    {
      name: "Szeregi mieszane",
      id: 11,
      rows: {
        1: {
          min: 0,
          max: 7,
        },
        2: {
          min: null,
          max: null,
        },
        3: {
          min: 8,
          max: 8,
        },
        4: {
          min: 9,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 11,
        },
        7: {
          min: 12,
          max: 12,
        },
        8: {
          min: 13,
          max: 13,
        },
        9: {
          min: 14,
          max: 14,
        },
        10: {
          min: 15,
          max: 15,
        },
        11: {
          min: 16,
          max: 16,
        },
        12: {
          min: 17,
          max: 17,
        },
        13: {
          min: 18,
          max: 18,
        },
        14: {
          min: 19,
          max: 19,
        },
        15: {
          min: 20,
          max: 21,
        },
        16: {
          min: 22,
          max: 22,
        },
        17: {
          min: 23,
          max: 23,
        },
        18: {
          min: 24,
          max: 24,
        },
        19: {
          min: 25,
          max: 36,
        },
      },
    },
    {
      name: "Zrotowane bryły",
      id: 12,
      rows: {
        1: {
          min: 0,
          max: 8,
        },
        2: {
          min: 9,
          max: 9,
        },
        3: {
          min: 10,
          max: 10,
        },
        4: {
          min: 11,
          max: 12,
        },
        5: {
          min: 13,
          max: 13,
        },
        6: {
          min: 14,
          max: 15,
        },
        7: {
          min: 16,
          max: 17,
        },
        8: {
          min: 18,
          max: 18,
        },
        9: {
          min: 19,
          max: 20,
        },
        10: {
          min: 21,
          max: 23,
        },
        11: {
          min: 24,
          max: 25,
        },
        12: {
          min: 26,
          max: 28,
        },
        13: {
          min: 29,
          max: 30,
        },
        14: {
          min: 31,
          max: 33,
        },
        15: {
          min: 34,
          max: 36,
        },
        16: {
          min: 37,
          max: 39,
        },
        17: {
          min: 40,
          max: 43,
        },
        18: {
          min: 44,
          max: 46,
        },
        19: {
          min: 47,
          max: 59,
        },
      },
    },
    {
      name: "Wykluczanie",
      id: 13,
      rows: {
        1: {
          min: 0,
          max: 4,
        },
        2: {
          min: 5,
          max: 6,
        },
        3: {
          min: 7,
          max: 7,
        },
        4: {
          min: 8,
          max: 9,
        },
        5: {
          min: 10,
          max: 10,
        },
        6: {
          min: 11,
          max: 12,
        },
        7: {
          min: 13,
          max: 14,
        },
        8: {
          min: 15,
          max: 15,
        },
        9: {
          min: 16,
          max: 17,
        },
        10: {
          min: 18,
          max: 18,
        },
        11: {
          min: 19,
          max: 20,
        },
        12: {
          min: 21,
          max: 21,
        },
        13: {
          min: 22,
          max: 23,
        },
        14: {
          min: 24,
          max: 24,
        },
        15: {
          min: 25,
          max: 26,
        },
        16: {
          min: 27,
          max: 27,
        },
        17: {
          min: 28,
          max: 29,
        },
        18: {
          min: 30,
          max: 30,
        },
        19: {
          min: 31,
          max: 31,
        },
      },
    },
    {
      name: "Przeciwieństwa",
      id: 14,
      rows: {
        1: {
          min: 0,
          max: 11,
        },
        2: {
          min: 12,
          max: 12,
        },
        3: {
          min: 13,
          max: 13,
        },
        4: {
          min: 14,
          max: 14,
        },
        5: {
          min: 15,
          max: 16,
        },
        6: {
          min: 17,
          max: 17,
        },
        7: {
          min: 18,
          max: 19,
        },
        8: {
          min: 20,
          max: 20,
        },
        9: {
          min: 21,
          max: 21,
        },
        10: {
          min: 22,
          max: 23,
        },
        11: {
          min: 24,
          max: 24,
        },
        12: {
          min: 25,
          max: 25,
        },
        13: {
          min: 26,
          max: 27,
        },
        14: {
          min: 28,
          max: 28,
        },
        15: {
          min: 29,
          max: 30,
        },
        16: {
          min: 31,
          max: 31,
        },
        17: {
          min: 32,
          max: 33,
        },
        18: {
          min: 34,
          max: 34,
        },
        19: {
          min: null,
          max: null,
        },
      },
    },
  ],


///////////////////// tu skonczone

190191130: [
  {
    name: "Klocki",
    id: 1,
    rows: {
      1: {
        min: 0,
        max: 17
      },
      2: {
        min: 18,
        max: 20
      },
      3: {
        min: 21,
        max: 24
      },
      4: {
        min: 25,
        max: 27
      },
      5: {
        min: 28,
        max: 31
      },
      6: {
        min: 32,
        max: 34
      },
      7: {
        min: 35,
        max: 38
      },
      8: {
        min: 39,
        max: 42
      },
      9: {
        min: 43,
        max: 45
      },
      10: {
        min: 46,
        max: 49
      },
      11: {
        min: 50,
        max: 52
      },
      12: {
        min: 53,
        max: 55
      },
      13: {
        min: 56,
        max: 59
      },
      14: {
        min: 60,
        max: 62
      },
      15: {
        min: 63,
        max: 65
      },
      16: {
        min: 66,
        max: 68
      },
      17: {
        min: 69,
        max: 71
      },
      18: {
        min: 72,
        max: 74
      },
      19: {
        min: 75,
        max: 80
      }
    }
  },

  {
    name: "Opowiadanie",
    id: 2,
    rows: {
      1: {
        min: 0,
        max: 7
      },
      2: {
        min: 8,
        max: 12
      },
      3: {
        min: 13,
        max: 16
      },
      4: {
        min: 17,
        max: 20
      },
      5: {
        min: 21,
        max: 24
      },
      6: {
        min: 25,
        max: 27
      },
      7: {
        min: 28,
        max: 31
      },
      8: {
        min: 32,
        max: 35
      },
      9: {
        min: 36,
        max: 38
      },
      10: {
        min: 39,
        max: 41
      },
      11: {
        min: 42,
        max: 44
      },
      12: {
        min: 45,
        max: 47
      },
      13: {
        min: 48,
        max: 49
      },
      14: {
        min: 50,
        max: 52
      },
      15: {
        min: 53,
        max: 54
      },
      16: {
        min: 55,
        max: 57
      },
      17: {
        min: 58,
        max: 59
      },
      18: {
        min: 60,
        max: 62
      },
      19: {
        min: 63,
        max: 64
      }
    }
  },
  {
    name: "Papugi",
    id: 3,
    rows: {
      1:{
        min: 0,
        max: 44
      },
      2:{
        min: 45,
        max: 51
      },
      3:{
        min: 52,
        max: 59
      },
      4:{
        min: 60,
        max: 67
      },
      5:{
        min: 68,
        max: 74
      },
      6:{
        min: 75,
        max: 81
      },
      7:{
        min: 82,
        max: 89
      },
      8:{
        min: 90,
        max: 96
      },
      9:{
        min: 97,
        max: 103
      },
      10:{
        min: 104,
        max: 110
      },
      11:{
        min: 111,
        max: 117
      },
      12:{
        min: 118,
        max: 123
      },
      13:{
        min: 124,
        max: 130
      },
      14:{
        min: 131,
        max: 137
      },
      15:{
        min: 138,
        max: 143
      },
      16:{
        min: 144,
        max: 150
      },
      17:{
        min: 151,
        max: 156
      },
      18:{
        min: 157,
        max: 163
      },
      19: {
        min: 164,
        max: 180
      }
    }
  },
  {
    name: "Szeregi jednorodne",
    id: 4,
    rows: {
      1:{
        min: 0,
        max: 12
      },
      2:{
        min: 13,
        max: 13
      },
      3:{
        min: 14,
        max: 14
      },
      4:{
        min: 15,
        max: 15
      },
      5:{
        min: 16,
        max: 16
      },
      6:{
        min: 17,
        max: 17
      },
      7:{
        min: 18,
        max: 18
      },
      8:{
        min: 19,
        max: 19
      },
      9:{
        min: 20,
        max: 20
      },
      10:{
        min: 21,
        max: 21
      },
      11:{
        min: 22,
        max: 22
      },
      12:{
        min: 23,
        max: 23
      },
      13:{
        min: 24,
        max: 25
      },
      14:{
        min: 26,
        max: 26
      },
      15:{
        min: 27,
        max: 27
      },
      16:{
        min: 28,
        max: 29
      },
      17:{
        min: 30,
        max: 30
      },
      18:{
        min: 31,
        max: 31
      },
      19:{
        min: 32,
        max: 40
      }
    }
  },
  {
    name: "Bryły",
    id: 5,
    rows: {
      1:{
        min: 0,
        max: 14
      },
      2:{
        min: null,
        max: null
      },
      3:{
        min: null,
        max: null
      },
      4:{
        min: 15,
        max: 15
      },
      5:{
        min: null,
        max: null
      },
      6:{
        min: 16,
        max: 16
      },
      7:{
        min: 17,
        max: 18
      },
      8:{
        min: 19,
        max: 20
      },
      9:{
        min: 21,
        max: 22
      },
      10:{
        min: 23,
        max: 25
      },
      11:{
        min: 26,
        max: 28
      },
      12:{
        min: 29,
        max: 31
      },
      13:{
        min: 32,
        max: 34
      },
      14:{
        min: 35,
        max: 38
      },
      15:{
        min: 39,
        max: 41
      },
      16:{
        min: 42,
        max: 45
      },
      17:{
        min: 46,
        max: 49
      },
      18:{
        min: 50,
        max: 53
      },
      19:{
        min: 54,
        max: 59
      }
    }
  },
  {
    name: "Matryce",
    id: 6,
    rows: {
     1: {
        min: 0,
        max: 3
      },
      2:{
        min: 4,
        max: 4
      },
      3:{
        min: 5,
        max: 6
      },
      4:{
        min: 7,
        max: 7
      },
      5:{
        min: 8,
        max: 8
      },
      6:{
        min: 9,
        max: 10
      },
      7:{
        min: 11,
        max: 11
      },
      8:{
        min: 12,
        max: 13
      },
      9:{
        min: 14,
        max: 15
      },
      10:{
        min: 16,
        max: 16
      },
      11:{
        min: 17,
        max: 18
      },
      12:{
        min: 19,
        max: 20
      },
      13:{
        min: 21,
        max: 21
      },
      14:{
        min: 22,
        max: 23
      },
      15:{
        min: 24,
        max: 25
      },
      16:{
        min: 26,
        max: 27
      },
      17:{
        min: 28,
        max: 29
      },
      18:{
        min: 30,
        max: 31
      },
      19:{
        min: 32,
        max: 35
      }
    }
  },
  {
    name: "Kategorie",
    id: 7,
    rows: {
      1:{
        min: 0,
        max: 9
      },
      2:{
        min: 10,
        max: 11
      },
      3:{
        min: 12,
        max: 12
      },
      4:{
        min: 13,
        max: 14
      },
      5:{
        min: 15,
        max: 16
      },
      6:{
        min: 17,
        max: 18
      },
      7:{
        min: 19,
        max: 20
      },
      8:{
        min: 21,
        max: 22
      },
      9:{
        min: 23,
        max: 24
      },
      10:{
        min: 25,
        max: 25
      },
      11:{
        min: 26,
        max: 27
      },
      12:{
        min: 28,
        max: 28
      },
      13:{
        min: 29,
        max: 29
      },
      14:{
        min: 30,
        max: 31
      },
      15:{
        min: 32,
        max: 32
      },
      16:{
        min: 33,
        max: 33
      },
      17:{
        min: 34,
        max: 34
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Krążki",
    id: 8,
    rows: {
      1:{
        min: 0,
        max: 15
      },
      2:{
        min: 16,
        max: 19
      },
      3:{
        min: 20,
        max: 22
      },
      4:{
        min: 23,
        max: 26
      },
      5:{
        min: 27,
        max: 29
      },
      6:{
        min: 30,
        max: 33
      },
      7:{
        min: 34,
        max: 36
      },
      8:{
        min: 37,
        max: 40
      },
      9:{
        min: 41,
        max: 43
      },
      10:{
        min: 44,
        max: 46
      },
      11:{
        min: 47,
        max: 49
      },
      12:{
        min: 50,
        max: 52
      },
      13:{
        min: 53,
        max: 55
      },
      14:{
        min: 56,
        max: 58
      },
      15:{
        min: 59,
        max: 61
      },
      16:{
        min: 62,
        max: 64
      },
      17:{
        min: 65,
        max: 67
      },
      18:{
        min: 68,
        max: 70
      },
      19:{
        min: 71,
        max: 76
      }
    }
  },
  {
    name: "Obrazek",
    id: 9,
    rows: {
      1:{
        min: 0,
        max: 6
      },
      2:{
        min: 7,
        max: 9
      },
      3:{
        min: 10,
        max: 12
      },
      4:{
        min: 13,
        max: 15
      },
      5:{
        min: 16,
        max: 17
      },
      6:{
        min: 18,
        max: 20
      },
      7:{
        min: 21,
        max: 22
      },
      8:{
        min: 23,
        max: 25
      },
      9:{
        min: 26,
        max: 27
      },
      10:{
        min: 28,
        max: 29
      },
      11:{
        min: 30,
        max: 31
      },
      12:{
        min: 32,
        max: 33
      },
      13:{
        min: 34,
        max: 35
      },
      14:{
        min: 36,
        max: 37
      },
      15:{
        min: 38,
        max: 38
      },
      16:{
        min: 39,
        max: 40
      },
      17:{
        min: 41,
        max: 42
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Kwadraty",
    id: 10,
    rows: {
      1:{
        min: 0,
        max: 53
      },
      2:{
        min: 54,
        max: 63
      },
      3:{
        min: 64,
        max: 72
      },
      4:{
        min: 73,
        max: 80
      },
      5:{
        min: 81,
        max: 89
      },
      6:{
        min: 90,
        max: 98
      },
      7:{
        min: 99,
        max: 106
      },
      8:{
        min: 107,
        max: 114
      },
      9:{
        min: 115,
        max: 122
      },
      10:{
        min: 123,
        max: 130
      },
      11:{
        min: 131,
        max: 138
      },
      12:{
        min: 139,
        max: 146
      },
      13:{
        min: 147,
        max: 154
      },
      14:{
        min: 155,
        max: 161
      },
      15:{
        min: 162,
        max: 169
      },
      16:{
        min: 170,
        max: 176
      },
      17:{
        min: 177,
        max: 180
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Szeregi mieszane",
    id: 11,
    rows: {
      1: {
        min: 0,
        max: 7
      },
      2: {
        min: null,
        max: null
      },
      3:{
        min: 8,
        max: 8
      },
      4:{
        min: 9,
        max: 9
      },
      5:{
        min: 10,
        max: 10
      },
      6:{
        min: 11,
        max: 11
      },
      7:{
        min: 12,
        max: 12
      },
      8:{
        min: 13,
        max: 13
      },
      9:{
        min: 14,
        max: 14
      },
      10:{
        min: 15,
        max: 15
      },
      11:{
        min: 16,
        max: 17
      },
      12:{
        min: 18,
        max: 18
      },
      13:{
        min: 19,
        max: 19
      },
      14:{
        min: 20,
        max: 20
      },
      15:{
        min: 21,
        max: 21
      },
      16:{
        min: 22,
        max: 22
      },
      17:{
        min: 23,
        max: 23
      },
      18:{
        min: 24,
        max: 24
      },
      19:{
        min: 25,
        max: 36
      }
    }
  },
  {
    name: "Zrotowane bryły",
    id: 12,
    rows: {
      1:{
        min: 0,
        max: 8
      },
      2:{
        min: 9,
        max: 10
      },
      3:{
        min: 11,
        max: 11
      },
      4:{
        min: 12,
        max: 12
      },
      5:{
        min: 13,
        max: 14
      },
      6:{
        min: 15,
        max: 15
      },
      7:{
        min: 16,
        max: 17
      },
      8:{
        min: 18,
        max: 19
      },
      9:{
        min: 20,
        max: 21
      },
      10:{
        min: 22,
        max: 23
      },
      11:{
        min: 24,
        max: 26
      },
      12:{
        min: 27,
        max: 29
      },
      13:{
        min: 30,
        max: 32
      },
      14:{
        min: 33,
        max: 35
      },
      15:{
        min: 36,
        max: 38
      },
      16:{
        min: 39,
        max: 41
      },
      17:{
        min: 42,
        max: 45
      },
      18:{
        min: 46,
        max: 48
      },
      19:{
        min: 49,
        max: 59
      }
    }
  },
  {
    name: "Wykluczanie",
    id: 13,
    rows: {
     1: {
        min: 0,
        max: 4
      },
      2:{
        min: 5,
        max: 6
      },
      3:{
        min: 7,
        max: 7
      },
      4:{
        min: 8,
        max: 9
      },
      5:{
        min: 10,
        max: 10
      },
      6:{
        min: 11,
        max: 12
      },
      7:{
        min: 13,
        max: 14
      },
      8:{
        min: 15,
        max: 15
      },
      9:{
        min: 16,
        max: 17
      },
      10:{
        min: 18,
        max: 19
      },
      11:{
        min: 20,
        max: 20
      },
      12:{
        min: 21,
        max: 22
      },
      13:{
        min: 23,
        max: 23
      },
      14:{
        min: 24,
        max: 25
      },
      15:{
        min: 26,
        max: 26
      },
      16:{
        min: 27,
        max: 27
      },
      17:{
        min: 28,
        max: 29
      },
      18:{
        min: 30,
        max: 30
      },
      19:{
        min: 31,
        max: 31
      }
    }
  },
  {
    name: "Przeciwieństwa",
    id: 14,
    rows: {
     1: {
        min: 0,
        max: 11
      },
      2: {
        min: 12,
        max: 12
      },
      3:{
        min: 13,
        max: 13
      },
      4:{
        min: 14,
        max: 14
      },
      5:{
        min: 15,
        max: 16
      },
      6:{
        min: 17,
        max: 18
      },
      7:{
        min: 19,
        max: 19
      },
      8:{
        min: 20,
        max: 21
      },
      9:{
        min: 22,
        max: 22
      },
      10:{
        min: 23,
        max: 23
      },
      11:{
        min: 24,
        max: 25
      },
      12:{
        min: 26,
        max: 26
      },
      13:{
        min: 27,
        max: 27
      },
      14:{
        min: 28,
        max: 29
      },
      15:{
        min: 30,
        max: 30
      },
      16:{
        min: 31,
        max: 31
      },
      17:{
        min: 32,
        max: 33
      },
      18:{
        min: 34,
        max: 34
      },
      19:{
        min: null,
        max: null
      }
    }
  }
],





200201130: [
  {
    name: "Klocki",
    id: 1,
    rows: {
      1:{
        min: 0,
        max: 17
      },
      2:{
        min: 18,
        max: 20
      },
      3:{
        min: 21,
        max: 24
      },
      4:{
        min: 25,
        max: 27
      },
      5:{
        min: 28,
        max: 31
      },
      6:{
        min: 32,
        max: 34
      },
     7: {
        min: 35,
        max: 38
      },
    8:{
        min: 39,
        max: 42
      },
      9:{
        min: 43,
        max: 45
      },
      10:{
        min: 46,
        max: 49
      },
      11:{
        min: 50,
        max: 52
      },
      12:{
        min: 53,
        max: 56
      },
      13:{
        min: 57,
        max: 59
      },
      14:{
        min: 60,
        max: 62
      },
      15:{
        min: 63,
        max: 65
      },
      16:{
        min: 66,
        max: 69
      },
      17:{
        min: 70,
        max: 72
      },
      18:{
        min: 73,
        max: 75
      },
      19:{
        min: 76,
        max: 80
      }
    }
  },
  {
    name: "Opowiadanie",
    id: 2,
    rows: {
     1: {
        min: 0,
        max: 7
      },
      2:{
        min: 8,
        max: 12
      },
      3:{
        min: 13,
        max: 16
      },
      4:{
        min: 17,
        max: 20
      },
      5:{
        min: 21,
        max: 24
      },
      6:{
        min: 25,
        max: 27
      },
      7:{
        min: 28,
        max: 31
      },
      8:{
        min: 32,
        max: 35
      },
      9:{
        min: 36,
        max: 38
      },
      10:{
        min: 39,
        max: 41
      },
      11:{
        min: 42,
        max: 44
      },
      12:{
        min: 45,
        max: 47
      },
      13:{
        min: 48,
        max: 49
      },
      14:{
        min: 50,
        max: 52
      },
      15:{
        min: 53,
        max: 54
      },
      16:{
        min: 55,
        max: 57
      },
      17:{
        min: 58,
        max: 59
      },
      18:{
        min: 60,
        max: 62
      },
      19:{
        min: 63,
        max: 64
      }
    }
  },
  {
    name: "Papugi",
    id: 3,
    rows: {
      1:{
        min: 0,
        max: 44
      },
      2:{
        min: 45,
        max: 51
      },
      3:{
        min: 52,
        max: 59
      },
      4:{
        min: 60,
        max: 67
      },
      5:{
        min: 68,
        max: 75
      },
      6:{
        min: 76,
        max: 82
      },
      7:{
        min: 83,
        max: 90
      },
      8:{
        min: 91,
        max: 97
      },
      9:{
        min: 98,
        max: 104
      },
      10:{
        min: 105,
        max: 112
      },
      11:{
        min: 113,
        max: 119
      },
      12:{
        min: 120,
        max: 126
      },
      13:{
        min: 127,
        max: 133
      },
      14:{
        min: 134,
        max: 139
      },
      15:{
        min: 140,
        max: 146
      },
      16:{
        min: 147,
        max: 153
      },
      17:{
        min: 154,
        max: 160
      },
      18:{
        min: 161,
        max: 166
      },
      19:{
        min: 167,
        max: 180
      }
    }
  },
  {
    name: "Szeregi jednorodne",
    id: 4,
    rows: {
     1: {
        min: 0,
        max: 13
      },
      2:{
        min: 14,
        max: 14
      },
      3:{
        min: 15,
        max: 15
      },
      4:{
        min: 16,
        max: 16
      },
      5:{
        min: null,
        max: null
      },
      6:{
        min: 17,
        max: 17
      },
      7:{
        min: 18,
        max: 18
      },
      8:{
        min: 19,
        max: 19
      },
      9:{
        min: 20,
        max: 20
      },
      10:{
        min: 21,
        max: 22
      },
      11:{
        min: 23,
        max: 23
      },
      12:{
        min: 24,
        max: 24
      },
      13:{
        min: 25,
        max: 25
      },
      14:{
        min: 26,
        max: 27
      },
      15:{
        min: 28,
        max: 28
      },
      16:{
        min: 29,
        max: 29
      },
      17:{
        min: 30,
        max: 31
      },
      18:{
        min: 32,
        max: 32
      },
      19:{
        min: 33,
        max: 40
      }
    }
  },
  {
    name: "Bryły",
    id: 5,
    rows: {
     1: {
        min: 0,
        max: 14
      },
      2:{
        min: null,
        max: null
      },
      3:{
        min: null,
        max: null
      },
      4:{
        min: 15,
        max: 15
      },
      5:{
        min: null,
        max: null
      },
      6:{
        min: 16,
        max: 16
      },
      7:{
        min: 17,
        max: 18
      },
      8:{
        min: 19,
        max: 20
      },
      9:{
        min: 21,
        max: 23
      },
      10:{
        min: 24,
        max: 26
      },
      11:{
        min: 27,
        max: 28
      },
      12:{
        min: 29,
        max: 31
      },
      13:{
        min: 32,
        max: 35
      },
      14:{
        min: 36,
        max: 38
      },
      15:{
        min: 39,
        max: 42
      },
      16:{
        min: 43,
        max: 45
      },
      17:{
        min: 46,
        max: 49
      },
      18:{
        min: 50,
        max: 53
      },
      19:{
        min: 54,
        max: 59
      }
    }
  },
  {
    name: "Matryce",
    id: 6,
    rows: {
     1: {
        min: 0,
        max: 3
      },
      2:{
        min: 4,
        max: 4
      },
      3:{
        min: 5,
        max: 6
      },
      4:{
        min: 7,
        max: 7
      },
      5:{
        min: 8,
        max: 8
      },
      6:{
        min: 9,
        max: 10
      },
      7:{
        min: 11,
        max: 12
      },
      8:{
        min: 13,
        max: 13
      },
      9:{
        min: 14,
        max: 15
      },
      10:{
        min: 16,
        max: 16
      },
      11:{
        min: 17,
        max: 18
      },
      12:{
        min: 19,
        max: 20
      },
      13:{
        min: 21,
        max: 22
      },
      14:{
        min: 23,
        max: 23
      },
      15:{
        min: 24,
        max: 25
      },
      16:{
        min: 26,
        max: 27
      },
      17:{
        min: 28,
        max: 29
      },
      18:{
        min: 30,
        max: 31
      },
      19:{
        min: 32,
        max: 35
      }
    }
  },
  {
    name: "Kategorie",
    id: 7,
    rows: {
      1:{
        min: 0,
        max: 9
      },
      2:{
        min: 10,
        max: 11
      },
      3:{
        min: 12,
        max: 12
      },
      4:{
        min: 13,
        max: 14
      },
      5:{
        min: 15,
        max: 16
      },
      6:{
        min: 17,
        max: 18
      },
      7:{
        min: 19,
        max: 20
      },
      8:{
        min: 21,
        max: 22
      },
      9:{
        min: 23,
        max: 24
      },
      10:{
        min: 25,
        max: 26
      },
      11:{
        min: 27,
        max: 27
      },
      12:{
        min: 28,
        max: 28
      },
      13:{
        min: 29,
        max: 29
      },
      14:{
        min: 30,
        max: 31
      },
      15:{
        min: 32,
        max: 32
      },
      16:{
        min: 33,
        max: 33
      },
      17:{
        min: 34,
        max: 34
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Krążki",
    id: 8,
    rows: {
     1: {
        min: 0,
        max: 16
      },
      2:{
        min: 17,
        max: 20
      },
      3:{
        min: 21,
        max: 23
      },
      4:{
        min: 24,
        max: 27
      },
      5:{
        min: 28,
        max: 30
      },
      6:{
        min: 31,
        max: 34
      },
      7:{
        min: 35,
        max: 37
      },
      8:{
        min: 38,
        max: 41
      },
      9:{
        min: 42,
        max: 44
      },
      10:{
        min: 45,
        max: 47
      },
      11:{
        min: 48,
        max: 50
      },
      12:{
        min: 51,
        max: 53
      },
      13:{
        min: 54,
        max: 56
      },
      14:{
        min: 57,
        max: 59
      },
      15:{
        min: 60,
        max: 62
      },
      16:{
        min: 63,
        max: 65
      },
      17:{
        min: 66,
        max: 68
      },
      18:{
        min: 69,
        max: 71
      },
      19:{
        min: 72,
        max: 76
      }
    }
  },
  {
    name: "Obrazek",
    id: 9,
    rows: {
      1:{
        min: 0,
        max: 6
      },
      2:{
        min: 7,
        max: 9
      },
      3:{
        min: 10,
        max: 12
      },
      4:{
        min: 13,
        max: 15
      },
      5:{
        min: 16,
        max: 18
      },
      6:{
        min: 19,
        max: 21
      },
      7:{
        min: 22,
        max: 23
      },
      8:{
        min: 24,
        max: 25
      },
      9:{
        min: 26,
        max: 28
      },
      10:{
        min: 29,
        max: 30
      },
      11:{
        min: 31,
        max: 32
      },
      12:{
        min: 33,
        max: 34
      },
      13:{
        min: 35,
        max: 35
      },
      14:{
        min: 36,
        max: 37
      },
      15:{
        min: 38,
        max: 39
      },
      16:{
        min: 40,
        max: 40
      },
      17:{
        min: 41,
        max: 42
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Kwadraty",
    id: 10,
    rows: {
      1:{
        min: 0,
        max: 57
      },
      2:{
        min: 58,
        max: 66
      },
      3:{
        min: 67,
        max: 76
      },
      4:{
        min: 77,
        max: 85
      },
      5:{
        min: 86,
        max: 94
      },
      6:{
        min: 95,
        max: 102
      },
      7:{
        min: 103,
        max: 111
      },
      8:{
        min: 112,
        max: 119
      },
      9:{
        min: 120,
        max: 128
      },
      10:{
        min: 129,
        max: 136
      },
      11:{
        min: 137,
        max: 144
      },
      12:{
        min: 145,
        max: 152
      },
      13:{
        min: 153,
        max: 159
      },
      14:{
        min: 160,
        max: 167
      },
      15:{
        min: 168,
        max: 175
      },
      16:{
        min: 176,
        max: 180
      },
      17:{
        min: null,
        max: null
      },
      18:{
        min: null,
        max: null
      },
      19:{
        min: null,
        max: null
      }
    }
  },
  {
    name: "Szeregi mieszane",
    id: 11,
    rows: {
     1: {
        min: 0,
        max: 8
      },
      2:{
        min: 9,
        max: 9
      },
      3:{
        min: null,
        max: null
      },
      4:{
        min: 10,
        max: 10
      },
      5:{
        min: 11,
        max: 11
      },
      6:{
        min: 12,
        max: 12
      },
      7:{
        min: 13,
        max: 13
      },
      8:{
        min: 14,
        max: 14
      },
      9:{
        min: 15,
        max: 15
      },
      10:{
        min: 16,
        max: 16
      },
      11:{
        min: 17,
        max: 17
      },
      12:{
        min: 18,
        max: 18
      },
      13:{
        min: 19,
        max: 19
      },
      14:{
        min: 20,
        max: 20
      },
      15:{
        min: 21,
        max: 22
      },
      16:{
        min: 23,
        max: 23
      },
      17:{
        min: 24,
        max: 25
      },
      18:{
        min: 26,
        max: 26
      },
      19:{
        min: 27,
        max: 36
      }
    }
  },
  {
    name: "Zrotowane bryły",
    id: 12,
    rows: {
     1: {
        min: 0,
        max: 10
      },
      2:{
        min: 11,
        max: 11
      },
      3:{
        min: 12,
        max: 12
      },
      4:{
        min: 13,
        max: 13
      },
      5:{
        min: 14,
        max: 14
      },
      6:{
        min: 15,
        max: 16
      },
      7:{
        min: 17,
        max: 17
      },
      8:{
        min: 18,
        max: 19
      },
      9:{
        min: 20,
        max: 21
      },
      10:{
        min: 22,
        max: 24
      },
      11:{
        min: 25,
        max: 27
      },
      12:{
        min: 28,
        max: 30
      },
      13:{
        min: 31,
        max: 33
      },
      14:{
        min: 34,
        max: 36
      },
      15:{
        min: 37,
        max: 40
      },
      16:{
        min: 41,
        max: 44
      },
      17:{
        min: 45,
        max: 47
      },
      18:{
        min: 48,
        max: 51
      },
      19:{
        min: 52,
        max: 59
      }
    }
  },
  {
    name: "Wykluczanie",
    id: 13,
    rows: {
     1: {
        min: 0,
        max: 4
      },
      2:{
        min: 5,
        max: 6
      },
      3:{
        min: 7,
        max: 7
      },
      4:{
        min: 8,
        max: 9
      },
      5:{
        min: 10,
        max: 10
      },
      6:{
        min: 11,
        max: 12
      },
      7:{
        min: 13,
        max: 14
      },
      8:{
        min: 15,
        max: 15
      },
      9:{
        min: 16,
        max: 17
      },
      10:{
        min: 18,
        max: 19
      },
      11:{
        min: 20,
        max: 20
      },
      12:{
        min: 21,
        max: 22
      },
      13:{
        min: 23,
        max: 23
      },
      14:{
        min: 24,
        max: 25
      },
      15:{
        min: 26,
        max: 26
      },
      16:{
        min: 27,
        max: 28
      },
      17:{
        min: 29,
        max: 29
      },
      18:{
        min: 30,
        max: 30
      },
      19:{
        min: 31,
        max: 31
      }
    }
  },
  {
    name: "Przeciwieństwa",
    id: 14,
    rows: {
     1: {
        min: 0,
        max: 11
      },
      2:{
        min: 12,
        max: 12
      },
      3:{
        min: 13,
        max: 13
      },
      4:{
        min: 14,
        max: 14
      },
      5:{
        min: 15,
        max: 16
      },
      6:{
        min: 17,
        max: 18
      },
      7:{
        min: 19,
        max: 20
      },
      8:{
        min: 21,
        max: 21
      },
      9:{
        min: 22,
        max: 23
      },
      10:{
        min: 24,
        max: 24
      },
      11:{
        min: 25,
        max: 25
      },
      12:{
        min: 26,
        max: 26
      },
      13:{
        min: 27,
        max: 27
      },
      14:{
        min: 28,
        max: 29
      },
      15:{
        min: 30,
        max: 30
      },
      16:{
        min: 31,
        max: 31
      },
      17:{
        min: 32,
        max: 33
      },
      18:{
        min: 34,
        max: 34
      },
      19:{
        min: null,
        max: null
      }
    }
  }
]

};


export default ids2raw;
