// useUserState.js
import { ref } from 'vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase/config'; // Adjust this to your Firebase configuration

const user = ref(null);

onAuthStateChanged(auth, (firebaseUser) => {
  user.value = firebaseUser;
});

export default function useUserState() {
  return { user };
}
