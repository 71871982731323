import { copyText } from 'vue3-clipboard';



const copyToClipboard = (valueItem) => {
    if (valueItem.length === 0) {
       valueItem = 'Nie było niczego do skopiowania';
    }

    copyText(valueItem, undefined, (error) => {
       if (error) {
          alert('Nie można skopiować');
       }
    });
 };


 export default copyToClipboard;