const firebaseTimestampToDate = (timestamp) => {
    // Sprawdzenie, czy timestamp ma metodę toDate
    if (!timestamp || typeof timestamp.toDate !== 'function') {
       console.error('Provided value is not a Firebase timestamp:', timestamp);
       return null;  // lub inna domyślna wartość
    }

    // Zamiana timestamp na JavaScript Date object
    const date = timestamp.toDate();

    // const day = ("0" + date.getDate()).slice(-2);
    // const month = ("0" + (date.getMonth() + 1)).slice(-2);
    // const year = date.getFullYear();
    // Formatowanie daty na żądany format, np. DD/MM/YYYY
    // return `${day}/${month}/${year}`;
    return date;
    };


export default firebaseTimestampToDate;    