import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/tailwind.css'
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase/config';

let app;


import Hotjar from '@hotjar/browser';

const siteId = 3891435;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);



onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App).use(router).mount('#app');
  }

  // const currentPath = router.currentRoute.value.path;
  // console.log("Current path:", currentPath, "User:", user);

  // if (!user && currentPath !== '/login' && currentPath !== '/signup') {
  //   console.log("Redirecting to login");
  //   router.push('/login');
  // }
});

export default app;




// onAuthStateChanged(auth, (user) => {
//   if (!app) {
//     app = createApp(App).use(router).mount('#app');
//   }

//   // if (!user) {
//   //   router.push('/login');
//   // }

//   if (!user && router.currentRoute.value.path !== '/login' && router.currentRoute.value.path !== '/signup') {
//     router.push('/login');
//   }
// });






/// vERSION 1
// let app

// onAuthStateChanged(auth, () => {
//     if (!app){
//         app = createApp(App).use(router).mount('#app')
//     }
// })


// router.beforeEach((to, from, next) => {
//   onAuthStateChanged(auth, (user) => {
//     if ((user && (to.path === '/login' || to.path === '/signup'))) {
//         // Jeżeli użytkownik jest zalogowany i próbuje wejść na '/login' lub '/signup', przekieruj go na '/'
//         next('/');
//       } else {
//         // W przeciwnym razie pozwól na nawigację
//         next();
//       }
      

//   });
// });



// //// version 2
// let app;
// let isUserLoggedIn = false;

// onAuthStateChanged(auth, (user) => {
//     isUserLoggedIn = !!user;
//     if (!app) {
//         app = createApp(App)
//             .use(router)
//             .mount('#app');
//     }
// });

// router.beforeEach((to, from, next) => {
//   if (isUserLoggedIn && (to.path === '/login' || to.path === '/signup')) {
//       // If user is logged in and tries to access '/login' or '/signup', redirect to '/'
//       next('/');
//   } else {
//       // Otherwise, allow navigation
//       next();
//   }
// });




