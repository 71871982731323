

const compare = (a, b) => {
    return Number(b.split('.')[0]) - Number(a.split('.')[0]);
};




export const isMarked = (sectionId, optionId, chosenOptions) => {
    if (chosenOptions.includes(optionId + '.' + sectionId)) return true;
};



export const chooseOption = (sectionId, optionId, chosenOptions) => {
    // console.log(`Section: ${sectionId}, option: ${optionId}`);
    let clickedEl = `${optionId}.${sectionId}`;

    let chosenOptionsSet = new Set(chosenOptions);

    if (chosenOptionsSet.has(clickedEl)) {
       chosenOptionsSet.delete(clickedEl);
    } else {
       for (let i = 1; i <= 19; i++) {
          chosenOptionsSet.delete(`${i}.${sectionId}`);
       }
       chosenOptionsSet.add(clickedEl);
    }

    return chosenOptions= Array.from(chosenOptionsSet).sort(compare);

};