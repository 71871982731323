<template>
 
 <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm text-center">
      <img class="mx-auto h-8 w-auto" src="../../src/assets/dlaPsychologa-logo.png" alt="dlaPsychologa" />
      <h2 class="mt-10 mb-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Oho! Wygląda na to, że się rozstajemy... na chwilę!
      </h2>
      <p class="mt-4 mb-4">Wróć szybko, bo nawet nasze algorytmy będą za&nbsp;Tobą&nbsp;tęsknić!</p>
      <a href="/login" class="font-semibold leading-6 mt-4 text-green-dla-400 hover:text-green-dla-500">
        Zaloguj ponownie
      </a>
    </div>
</div>








</template>



