<template>
 
<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <!-- <img class="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company"> -->
      <img class="mx-auto h-8 w-auto" src="../../src/assets/dlaPsychologa-logo.png" alt="dlaPsychologa" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Odzyskiwanie hasła</h2>
      <p class="text-center text-sm text-gray-500">Nie przejmuj się, to zdarza się nawet najlepszym.</p>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="handleSubmit">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Twój Email</label>
          <div class="mt-2">
            <input id="email" name="email" type="email" v-model="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-dla-600 sm:text-sm sm:leading-6">
          </div>
        </div>

        <!-- <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Hasło</label>
            <div class="text-sm">
              <a href="#" class="font-semibold text-green-dla-400 hover:text-green-dla-500">Nie pamiętasz hasła?</a>
            </div>
          </div>
          <div class="mt-2">
            <input id="password" name="password" type="password" v-model="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-dla-600 sm:text-sm sm:leading-6">
          </div>
        </div> -->

        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-green-dla-400 px-3 py-1.5 text-sm font-semibold leading-6 text-green-dla-900 shadow-sm hover:bg-green-dla-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-dla-500">wyślij mi link do resetowania hasła</button>
        </div>


        <div v-if="isSent">
        <!-- <div> -->
          <InfoElement :info="info" />
          <!-- <AlertElement :error="error" /> -->
          
          <button @click="handleGo" class="flex w-full justify-center rounded-md bg-green-dla-400 px-3 py-1.5 text-sm font-semibold leading-6 text-green-dla-900 shadow-sm hover:bg-green-dla-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-dla-500">Prejdź do logowania</button>
        </div>
      </form>
      <p class="mt-10 text-center text-sm text-gray-500">
        lub 
        <a href="/login" class="font-semibold leading-6  text-green-dla-400 hover:text-green-dla-500">Zaloguj się</a>
      </p>


    </div>
</div>






</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import InfoElement from '@/components/InfoElement.vue';

export default {
  components: { InfoElement },
   setup() {
      const auth = getAuth();
      const email = ref('');
      const isSent = ref(false);
      const error = ref(null)
      const router = useRouter();
      const info = ref("Jeśli konto z tym emailem istnieje, otrzymasz wiadomość z linkiem do resetowania hasła.")


      const handleGo = () => {
        router.push({ name: 'Login' })
      }



      const handleSubmit = async () => {  
        try {
          await sendPasswordResetEmail(auth, email.value)
          isSent.value = true;
        } catch (err) {
          console.error(err)
          error.value = err.message
        }

      };

      return { email, handleSubmit, error, isSent, handleGo, info };
   },
};
</script>

