const changeTextBasedOnSex = (text, isMale, firstName) => {
    if (isMale) {
        text = text.replaceAll('ucznia/uczennicy', 'ucznia');
        text = text.replaceAll('Uczeń/uczennica', 'Uczeń');
        text = text.replaceAll('mu/jej', 'mu');
    } else {
        text = text.replaceAll('ucznia/uczennicy', 'uczennicy');
        text = text.replaceAll('Uczeń/uczennica', 'Uczennica');
        text = text.replaceAll('mu/jej', 'jej');
    }
    text = text.replace(/{{imię}}/g, firstName);
    return text;
};


export default changeTextBasedOnSex;