function assignValue(char) {
    if (!isNaN(char)) {
        return parseInt(char, 10);
    } else {
        return char.toUpperCase().charCodeAt(0) - 55; // A=10, B=11, ..., Z=35
    }
}

function calculateControlDigit(number) {
    let sum = 0;
    for (let i = 0; i < number.length; i++) {
        sum += assignValue(number[i]);
    }
    const controlDigit = sum % 36;
    if (controlDigit < 10) {
        return controlDigit.toString();
    } else {
        return String.fromCharCode(controlDigit + 55); // 10 -> 'A', 11 -> 'B', ..., 35 -> 'Z'
    }
}

function generateNumber() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let number = '';
    for (let i = 0; i < 7; i++) {
        number += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    const controlDigit = calculateControlDigit(number);
    return number + controlDigit;
}

function isValidNumber(number) {
    if (number.length !== 8) {
        return false;
    }
    const controlDigit = number[number.length - 1];
    return controlDigit === calculateControlDigit(number.slice(0, -1));
}

// Przykładowe użycie
// const num = generateNumber();
// console.log(num, isValidNumber(num));


export default isValidNumber;
