<template>
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 pb-10 mb-10 rounded-md shadow-sm showSmooth">

            <div class="border-b border-gray-200 pb-5 mb-5">
               <h2 class="text-2xl font-medium leading-6 text-gray-900">Dane obserwacyjne</h2>
               <p class="mt-2 max-w-4xl text-sm text-gray-500">Zachowanie podczas procesu diagnostycznego z użyciem IDS-2.<br> Wybór jest opcjonalny. Możesz zaznaczyć dowolne opcje, wszystkie lub żadną.




</p>

            </div>
            
            <div class="home">
            
               <div v-for="item in ids2zachowanie" :key="item.id" class="mb-2 w-full">
            
                  <div class="border-b border-gray-200">
                     <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                           <dt class="pb-2 ext-base font-medium text-gray-900">{{ item.nazwa }}<br>
                           </dt>
                           <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div>
                                 <div >
                                    <div class="flex space-x-4 mb-4">
                                       <a v-for="choice in item.choices" :key="choice.nazwa"  @click="() => selectChoice(item, choice)" 
                                          :class="[item.current == choice.nazwa ? 'bg-green-dla-400 text-white hover:bg-green-dla-400 hover:text-white' : 'bg-white border border-gray-200 text-gray-900 hover:bg-grey-50', 'px-3 py-2 font-medium text-sm rounded-md']">{{ choice.nazwa }}</a>
                                    </div>
                                 </div>
                              </div>
            
            
                              <div v-if="item.selectedChoice">
                                 <div v-for="detail in item.selectedChoice.details" :key="detail" class="relative flex items-start mb-2">
                                    <div class="flex h-5 items-center mb-4">
                                       <input :id="detail" :value="detail" :name="detail" type="checkbox" 
                                          class="h-4 w-4 rounded text-green-dla-400" 
                                          @change="event => addBehaviour(detail, item.id, event.target.checked)" />
                                    </div>
                                    <div class="ml-3 text-sm">
                                    <label :for="detail" class="text-sm text-gray-500">{{ detail }}</label>
                                    </div>
                                 </div>
                              </div>
            
                           </dd>
                        </div>
                     </dl>
                  </div>
               </div>
            </div>
        </div>

</template>




<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';

const props = defineProps({
  isMale: Boolean,
  firstName: String
});



const emit = defineEmits();

const isMale = ref(props.isMale)
const firstName = ref(props.firstName)

const behaviors = ref([])
const ids2zachowanieBase = ref(null)
const ids2zachowanie = ref(null)


function selectChoice(item, choice) {
         item.selectedChoice = choice;  
         item.current = choice.nazwa;

         const existingIndex = behaviors.value.findIndex(behavior => behavior.id === item.id);

         if (existingIndex !== -1) {
            behaviors.value[existingIndex] = {
                  id: item.id,
                  nazwa: item.nazwa,
                  current: item.current,
                  sentences: [choice.sentence]
            };
         } else {
            behaviors.value.push({
                  id: item.id,
                  nazwa: item.nazwa,
                  current: item.current,
                  sentences: [choice.sentence]
            });
         }
      }


function addSentence(array, id, sentence) {
         const item = array.find(obj => obj.id === id);
         if (item) {
            if (!item.sentences) {
                  item.sentences = [];
            }
            item.sentences.push(sentence);
         }
         
      }


function removeSentence(array, id, sentenceToRemove) {
         const item = array.find(obj => obj.id === id);
         if (item && item.sentences) {
            item.sentences = item.sentences.filter(sentence => sentence !== sentenceToRemove);
         }
      }


const addBehaviour = (sentence, id, isChecked) => {
         if (isChecked){
            addSentence(behaviors.value, id, sentence)
         } else {
            removeSentence(behaviors.value, id, sentence)
         }
      };


function adjustSentencesForGender(sentences, isFemale) {
         return sentences.map(sentence => {
            return sentence.replace(/\{\{([^|}]+)\|([^}]+)\}\}/g, (match, male, female) => {
                  return isFemale ? female : male;
            });
         });
      }


function replaceName(sentence, firstName) {
         return sentence.replace(/\{\{Imię\}\}/g, firstName);
      }


function adjustIds2zachowanieForGender(ids2zachowanie, isFemale, firstName) {
         return ids2zachowanie.map(item => ({
            ...item, // Kopiowanie wszystkich istniejących właściwości obiektu
            choices: item.choices.map(choice => ({
                  ...choice, // Kopiowanie wszystkich istniejących właściwości 'choice'
                  sentence: replaceName(adjustSentencesForGender([choice.sentence], isFemale)[0], firstName), // Dostosowywanie 'sentence'
                  details: adjustSentencesForGender(choice.details, isFemale).map(sentence => replaceName(sentence, firstName)) // Dostosowywanie 'details'
            }))
         }));
      }


function joinBehaviors(array) {
         if (!Array.isArray(array) || array.length === 0) {
            return;
         }

         return array.map(obj => obj.sentences.join(' ')).join(' ');
         }



onMounted(async () => {
  if (isMale.value !== null && firstName.value !== null) {
    let importedData;
    const module = await import('@/data/ids2zachowanie.js');
    importedData = module.default;

    ids2zachowanieBase.value = importedData.map(item => ({
      ...item,
      selectedChoice: null 
    }));

   //  console.log("ids2zachowanieBase: ", ids2zachowanieBase.value);
    ids2zachowanie.value = adjustIds2zachowanieForGender(ids2zachowanieBase.value, !isMale.value, firstName.value);
  }
});



watch(behaviors.value, (newValue, oldValue) => {
   const behaviorSentences = joinBehaviors(newValue)
      emit('update:modelValue', behaviorSentences);
    });

watch(() => props.isMale, (newValue, oldValue) => {
      isMale.value = newValue;
      ids2zachowanie.value = adjustIds2zachowanieForGender(ids2zachowanieBase.value, !isMale.value, firstName.value);
    });

</script>