const wiscvScales = [
  // [0] VCI
  {
    2: { p: 52, c: "0.1", 95: "48-64", 85: "50-62" },
    3: { p: 56, c: "0.2", 95: "52-68", 85: "54-66" },
    4: { p: 60, c: "0.4", 95: "55-71", 85: "57-69" },
    5: { p: 63, c: "0.6", 95: "58-74", 85: "60-72" },
    6: { p: 64, c: "0.9", 95: "59-75", 85: "61-73" },
    7: { p: 66, c: "1", 95: "61-77", 85: "63-75" },
    8: { p: 68, c: "2", 95: "63-79", 85: "65-77" },
    9: { p: 71, c: "2", 95: "65-81", 85: "67-79" },
    10: { p: 73, c: "4", 95: "67-83", 85: "96-81" },
    11: { p: 76, c: "5", 95: "70-86", 85: "72-84" },
    12: { p: 78, c: "8", 95: "72-88", 85: "74-86" },
    13: { p: 81, c: "10", 95: "75-91", 85: "77-89" },
    14: { p: 83, c: "13", 95: "76-92", 85: "78-90" },
    15: { p: 86, c: "18", 95: "79-95", 85: "81-93" },
    16: { p: 89, c: "23", 95: "82-98", 85: "84-96" },
    17: { p: 92, c: "29", 95: "85-101", 85: "87-99" },
    18: { p: 94, c: "35", 95: "87-103", 85: "89-101" },
    19: { p: 97, c: "42", 95: "89-105", 85: "91-103" },
    20: { p: 100, c: "50", 95: "92-108", 85: "94-106" },
    21: { p: 103, c: "58", 95: "95-111", 85: "94-109" },
    22: { p: 106, c: "65", 95: "98-114", 85: "100-112" },
    23: { p: 108, c: "71", 95: "99-115", 85: "101-113" },
    24: { p: 111, c: "77", 95: "102-118", 85: "104-116" },
    25: { p: 114, c: "82", 95: "105-121", 85: "107-119" },
    26: { p: 116, c: "86", 95: "107-123", 85: "109-121" },
    27: { p: 119, c: "89", 95: "110-126", 85: "112-124" },
    28: { p: 121, c: "92", 95: "111-127", 85: "113-125" },
    29: { p: 124, c: "94", 95: "114-130", 85: "116-128" },
    30: { p: 127, c: "96", 95: "117-133", 85: "119-131" },
    31: { p: 130, c: "98", 95: "120-136", 85: "122-134" },
    32: { p: 133, c: "98", 95: "122-138", 85: "124-136" },
    33: { p: 135, c: "99.1", 95: "124-140", 85: "126-138" },
    34: { p: 139, c: "99.5", 95: "128-144", 85: "130-142" },
    35: { p: 143, c: "99.8", 95: "132-148", 85: "134-146" },
    36: { p: 146, c: "99.9", 95: "134-150", 85: "136-148" },
    37: { p: 148, c: "99.9", 95: "136-152", 85: "138-150" },
    38: { p: 153, c: ">99.9", 95: "141-157", 85: "143-155" },
  },

  // [1] VSI
  {
    2: { p: 50, c: "<0.1", 95: "47-61", 85: "49-59" },
    3: { p: 57, c: "0.2", 95: "53-67", 85: "55-65" },
    4: { p: 59, c: "0.3", 95: "55-69", 85: "57-67" },
    5: { p: 61, c: "0.5", 95: "57-71", 85: "59-69" },
    6: { p: 63, c: "0.6", 95: "59-73", 85: "61-71" },
    7: { p: 64, c: "0.8", 95: "60-74", 85: "62-72" },
    8: { p: 67, c: "1", 95: "62-76", 85: "64-74" },
    9: { p: 69, c: "2", 95: "64-78", 85: "66-76" },
    10: { p: 71, c: "3", 95: "66-80", 85: "68-78" },
    11: { p: 73, c: "4", 95: "68-82", 85: "70-80" },
    12: { p: 76, c: "6", 95: "71-85", 85: "73-83" },
    13: { p: 80, c: "9", 95: "74-88", 85: "76-86" },
    14: { p: 83, c: "13", 95: "77-91", 85: "79-89" },
    15: { p: 86, c: "18", 95: "80-94", 85: "82-92" },
    16: { p: 89, c: "24", 95: "93-97", 85: "85-95" },
    17: { p: 92, c: "30", 95: "86-100", 85: "88-98" },
    18: { p: 95, c: "37", 95: "88-102", 85: "90-100" },
    19: { p: 98, c: "44", 95: "91-105", 85: "93-103" },
    20: { p: 100, c: "50", 95: "93-107", 85: "95-105" },
    21: { p: 103, c: "57", 95: "96-110", 85: "98-108" },
    22: { p: 105, c: "64", 95: "98-112", 85: "100-110" },
    23: { p: 108, c: "71", 95: "100-114", 85: "102-112" },
    24: { p: 111, c: "77", 95: "103-117", 85: "105-115" },
    25: { p: 114, c: "82", 95: "106-120", 85: "108-118" },
    26: { p: 116, c: "86", 95: "108-122", 85: "110-120" },
    27: { p: 119, c: "90", 95: "111-125", 85: "113-123" },
    28: { p: 121, c: "92", 95: "113-127", 85: "115-125" },
    29: { p: 124, c: "95", 95: "115-129", 85: "117-127" },
    30: { p: 127, c: "97", 95: "118-132", 85: "120-130" },
    31: { p: 131, c: "98", 95: "122-136", 85: "124-134" },
    32: { p: 134, c: "99", 95: "125-139", 85: "127-137" },
    33: { p: 137, c: "99.3", 95: "127-141", 85: "129-139" },
    34: { p: 140, c: "99.6", 95: "130-144", 85: "132-142" },
    35: { p: 145, c: "99.9", 95: "135-149", 85: "137-147" },
    36: { p: 153, c: ">99.9", 95: "142-156", 85: "144-154" },
    37: { p: 155, c: ">99.9", 95: "144-158", 85: "146-156" },
    38: { p: 155, c: ">99.9", 95: "144-158", 85: "146-156" },
  },

  // [2] FRI
  {
    2: { p: 53, c: "0.1", 95: "49-61", 85: "50-60" },
    3: { p: 57, c: "0.2", 95: "53-65", 85: "54-64" },
    4: { p: 60, c: "0.4", 95: "56-68", 85: "57-67" },
    5: { p: 62, c: "0.6", 95: "58-70", 85: "59-69" },
    6: { p: 64, c: "0.8", 95: "60-72", 85: "61-71" },
    7: { p: 65, c: "1", 95: "61-73", 85: "62-72" },
    8: { p: 68, c: "2", 95: "64-76", 85: "65-75" },
    9: { p: 71, c: "2", 95: "67-79", 85: "68-78" },
    10: { p: 73, c: "4", 95: "68-80", 85: "69-79" },
    11: { p: 76, c: "5", 95: "71-83", 85: "72-82" },
    12: { p: 78, c: "7", 95: "73-85", 85: "74-84" },
    13: { p: 80, c: "9", 95: "75-87", 85: "76-86" },
    14: { p: 83, c: "13", 95: "78-90", 85: "79-89" },
    15: { p: 86, c: "17", 95: "81-93", 85: "82-92" },
    16: { p: 89, c: "22", 95: "84-96", 85: "85-95" },
    17: { p: 91, c: "28", 95: "86-98", 85: "87-97" },
    18: { p: 94, c: "35", 95: "88-100", 85: "89-99" },
    19: { p: 97, c: "42", 95: "91-103", 85: "92-102" },
    20: { p: 100, c: "50", 95: "94-106", 85: "95-105" },
    21: { p: 103, c: "58", 95: "97-109", 85: "98-108" },
    22: { p: 105, c: "64", 95: "99-111", 85: "100-110" },
    23: { p: 108, c: "71", 95: "102-114", 85: "103-113" },
    24: { p: 111, c: "78", 95: "105-117", 85: "106-116" },
    25: { p: 114, c: "83", 95: "107-117", 85: "108-118" },
    26: { p: 117, c: "88", 95: "110-122", 85: "111-121" },
    27: { p: 120, c: "91", 95: "113-125", 85: "114-124" },
    28: { p: 123, c: "94", 95: "116-128", 85: "117-127" },
    29: { p: 126, c: "96", 95: "119-131", 85: "120-130" },
    30: { p: 129, c: "97", 95: "122-134", 85: "123-133" },
    31: { p: 131, c: "98", 95: "124-136", 85: "125-135" },
    32: { p: 135, c: "99", 95: "127-139", 85: "128-138" },
    33: { p: 139, c: "99.5", 95: "131-143", 85: "132-142" },
    34: { p: 142, c: "99.7", 95: "134-146", 85: "135-145" },
    35: { p: 144, c: "99.8", 95: "136-148", 85: "137-147" },
    36: { p: 147, c: "99.9", 95: "139-151", 85: "140-150" },
    37: { p: 150, c: ">99.9", 95: "142-154", 85: "143-153" },
    38: { p: 153, c: ">99.9", 95: "144-156", 85: "145-155" },
  },

  // [3] WMI
  {
    2: { p: 54, c: "0.1", 95: "50-66", 85: "52-64" },
    3: { p: 58, c: "0.3", 95: "53-69", 85: "55-67" },
    4: { p: 59, c: "0.3", 95: "54-70", 85: "56-68" },
    5: { p: 61, c: "0.4", 95: "56-72", 85: "58-70" },
    6: { p: 62, c: "0.6", 95: "57-73", 85: "59-71" },
    7: { p: 64, c: "0.9", 95: "59-75", 85: "61-73" },
    8: { p: 67, c: "1", 95: "62-78", 85: "64-76" },
    9: { p: 69, c: "2", 95: "64-80", 85: "66-78" },
    10: { p: 71, c: "3", 95: "65-81", 85: "67-79" },
    11: { p: 74, c: "4", 95: "68-84", 85: "70-82" },
    12: { p: 76, c: "6", 95: "70-86", 85: "72-84" },
    13: { p: 79, c: "8", 95: "73-89", 85: "75-87" },
    14: { p: 82, c: "12", 95: "75-91", 85: "77-89" },
    15: { p: 85, c: "16", 95: "78-94", 85: "80-92" },
    16: { p: 89, c: "22", 95: "82-98", 85: "84-96" },
    17: { p: 91, c: "28", 95: "84-100", 85: "86-98" },
    18: { p: 94, c: "35", 95: "87-103", 85: "89-101" },
    19: { p: 97, c: "42", 95: "89-105", 85: "91-103" },
    20: { p: 100, c: "50", 95: "92-108", 85: "94-106" },
    21: { p: 103, c: "58", 95: "95-111", 85: "97-109" },
    22: { p: 106, c: "66", 95: "98-114", 85: "100-112" },
    23: { p: 109, c: "72", 95: "100-116", 85: "102-114" },
    24: { p: 112, c: "78", 95: "103-119", 85: "15-117" },
    25: { p: 114, c: "83", 95: "105-121", 85: "107-119" },
    26: { p: 117, c: "87", 95: "108-124", 85: "110-122" },
    27: { p: 120, c: "91", 95: "110-126", 85: "112-124" },
    28: { p: 123, c: "94", 95: "113-129", 85: "115-127" },
    29: { p: 126, c: "96", 95: "116-132", 85: "118-130" },
    30: { p: 129, c: "97", 95: "119-135", 85: "121-133" },
    31: { p: 132, c: "98", 95: "121-137", 85: "123-135" },
    32: { p: 135, c: "99.1", 95: "124-140", 85: "126-138" },
    33: { p: 139, c: "99.5", 95: "128-144", 85: "130-142" },
    34: { p: 143, c: "99.8", 95: "132-148", 85: "134-146" },
    35: { p: 144, c: "99.8", 95: "133-149", 85: "135-147" },
    36: { p: 145, c: "99.9", 95: "133-149", 85: "135-147" },
    37: { p: 147, c: "99.9", 95: "135-151", 85: "137-149" },
    38: { p: 153, c: ">99.9", 95: "141-157", 85: "143-155" },
  },

  // [4] PSI
  {
    2: { p: 54, c: "0.1", 95: "51-71", 85: "54-68" },
    3: { p: 58, c: "0.3", 95: "55-75", 85: "58-72" },
    4: { p: 59, c: "0.3", 95: "56-76", 85: "59-73" },
    5: { p: 61, c: "0.5", 95: "57-77", 85: "60-74" },
    6: { p: 62, c: "0.6", 95: "58-78", 85: "61-75" },
    7: { p: 63, c: "0.7", 95: "59-79", 85: "62-76" },
    8: { p: 65, c: "1", 95: "61-81", 85: "64-78" },
    9: { p: 68, c: "2", 95: "63-83", 85: "66-80" },
    10: { p: 70, c: "2", 95: "65-85", 85: "68-82" },
    11: { p: 73, c: "4", 95: "67-87", 85: "70-84" },
    12: { p: 75, c: "5", 95: "69-89", 85: "72-86" },
    13: { p: 78, c: "7", 95: "72-92", 85: "75-89" },
    14: { p: 81, c: "11", 95: "74-94", 85: "77-91" },
    15: { p: 85, c: "15", 95: "77-97", 85: "80-94" },
    16: { p: 87, c: "20", 95: "79-99", 85: "82-96" },
    17: { p: 91, c: "26", 95: "82-102", 85: "85-99" },
    18: { p: 94, c: "34", 95: "85-105", 85: "88-102" },
    19: { p: 97, c: "42", 95: "88-108", 85: "91-105" },
    20: { p: 100, c: "50", 95: "90-110", 85: "93-107" },
    21: { p: 103, c: "58", 95: "93-113", 85: "96-110" },
    22: { p: 106, c: "66", 95: "95-115", 85: "98-112" },
    23: { p: 109, c: "73", 95: "98-118", 85: "101-115" },
    24: { p: 113, c: "80", 95: "101-121", 85: "104-118" },
    25: { p: 115, c: "84", 95: "103-123", 85: "106-120" },
    26: { p: 118, c: "88", 95: "105-125", 85: "108-122" },
    27: { p: 120, c: "91", 95: "107-127", 85: "110-124" },
    28: { p: 122, c: "93", 95: "109-129", 85: "112-126" },
    29: { p: 125, c: "95", 95: "111-131", 85: "114-128" },
    30: { p: 129, c: "97", 95: "114-134", 85: "117-131" },
    31: { p: 131, c: "98", 95: "116-136", 85: "119-133" },
    32: { p: 132, c: "98", 95: "117-137", 85: "120-134" },
    33: { p: 135, c: "99", 95: "119-139", 85: "122-136" },
    34: { p: 138, c: "99.4", 95: "122-142", 85: "125-139" },
    35: { p: 142, c: "99.7", 95: "125-145", 85: "128-142" },
    36: { p: 144, c: "99.8", 95: "127-147", 85: "130-144" },
    37: { p: 146, c: "99.9", 95: "129-149", 85: "132-146" },
    38: { p: 150, c: ">99.9", 95: "132-152", 85: "135-149" },
  },

  // [5] FISQ

  {
    7: { p: 40, c: "<0.1", 95: "36-48", 85: "38-46" },
    8: { p: 47, c: "<0.1", 95: "43-55", 85: "45-53" },
    9: { p: 50, c: "<0.1", 95: "46-58", 85: "58-56" },
    10: { p: 52, c: "0.1", 95: "48-60", 85: "50-58" },
    11: { p: 53, c: "0.1", 95: "49-61", 85: "51-59" },
    12: { p: 54, c: "0.1", 95: "50-62", 85: "52-60" },
    13: { p: 56, c: "0.2", 95: "52-64", 85: "54-62" },
    14: { p: 57, c: "0.2", 95: "53-65", 85: "55-63" },
    15: { p: 58, c: "0.3", 95: "54-66", 85: "56-64" },
    16: { p: 59, c: "0.3", 95: "55-67", 85: "57-65" },
    17: { p: 60, c: "0.4", 95: "56-68", 85: "58-66" },
    18: { p: 61, c: "0.4", 95: "57-69", 85: "59-67" },
    19: { p: 61, c: "0.5", 95: "57-69", 85: "59-67" },
    20: { p: 61, c: "0.5", 95: "57-69", 85: "59-67" },
    21: { p: 61, c: "0.5", 95: "57-69", 85: "59-67" },
    22: { p: 62, c: "0.5", 95: "58-70", 85: "60-68" },
    23: { p: 62, c: "0.6", 95: "58-70", 85: "60-68" },
    24: { p: 63, c: "0.6", 95: "59-71", 85: "61-69" },
    25: { p: 63, c: "0.6", 95: "59-71", 85: "61-69" },
    26: { p: 63, c: "0.7", 95: "59-71", 85: "61-69" },
    27: { p: 63, c: "0.7", 95: "59-71", 85: "61-69" },
    28: { p: 64, c: "0.8", 95: "59-71", 85: "61-69" },
    29: { p: 64, c: "0.8", 95: "59-71", 85: "61-69" },
    30: { p: 65, c: "0.9", 95: "60-72", 85: "62-70" },
    31: { p: 65, c: "1", 95: "60-72", 85: "62-70" },
    32: { p: 65, c: "1", 95: "60-72", 85: "62-70" },
    33: { p: 66, c: "1", 95: "61-73", 85: "63-71" },
    34: { p: 66, c: "1", 95: "61-73", 85: "63-71" },
    35: { p: 67, c: "1", 95: "62-74", 85: "64-72" },
    36: { p: 68, c: "2", 95: "63-75", 85: "65-73" },
    37: { p: 68, c: "2", 95: "63-75", 85: "65-73" },
    38: { p: 69, c: "2", 95: "64-76", 85: "66-74" },
    39: { p: 70, c: "2", 95: "65-77", 85: "67-75" },
    40: { p: 70, c: "2", 95: "65-77", 85: "67-75" },
    41: { p: 71, c: "3", 95: "66-78", 85: "68-76" },
    42: { p: 72, c: "3", 95: "67-79", 85: "69-77" },
    43: { p: 73, c: "4", 95: "68-80", 85: "70-78" },
    44: { p: 73, c: "4", 95: "68-80", 85: "70-78" },
    45: { p: 74, c: "4", 95: "69-81", 85: "71-79" },
    46: { p: 75, c: "5", 95: "70-82", 85: "72-80" },
    47: { p: 76, c: "6", 95: "71-83", 85: "73-81" },
    48: { p: 77, c: "6", 95: "72-84", 85: "74-82" },
    49: { p: 78, c: "7", 95: "73-85", 85: "75-83" },
    50: { p: 79, c: "8", 95: "74-86", 85: "76-84" },
    51: { p: 80, c: "9", 95: "75-87", 85: "77-85" },
    52: { p: 81, c: "10", 95: "76-88", 85: "78-86" },
    53: { p: 82, c: "12", 95: "77-89", 85: "79-87" },
    54: { p: 83, c: "13", 95: "78-90", 85: "80-88" },
    55: { p: 84, c: "14", 95: "79-91", 85: "81-89" },
    56: { p: 85, c: "16", 95: "80-92", 85: "82-90" },
    57: { p: 86, c: "18", 95: "81-93", 85: "83-91" },
    58: { p: 87, c: "19", 95: "82-94", 85: "84-92" },
    59: { p: 88, c: "21", 95: "83-95", 85: "85-93" },
    60: { p: 89, c: "24", 95: "83-95", 85: "85-93" },
    61: { p: 90, c: "26", 95: "84-96", 85: "86-94" },
    62: { p: 92, c: "29", 95: "86-98", 85: "88-96" },
    63: { p: 93, c: "31", 95: "87-99", 85: "89-97" },
    64: { p: 94, c: "33", 95: "88-100", 85: "90-98" },
    65: { p: 95, c: "36", 95: "89-101", 85: "91-99" },
    66: { p: 96, c: "39", 95: "90-102", 85: "92-100" },
    67: { p: 97, c: "42", 95: "91-103", 85: "93-101" },
    68: { p: 98, c: "44", 95: "92-104", 85: "94-102" },
    69: { p: 99, c: "47", 95: "93-105", 85: "95-103" },
    70: { p: 100, c: "49", 95: "94-106", 85: "96-104" },
    71: { p: 101, c: "52", 95: "95-107", 85: "97-105" },
    72: { p: 102, c: "54", 95: "96-108", 85: "98-106" },
    73: { p: 103, c: "57", 95: "97-109", 85: "99-107" },
    74: { p: 104, c: "60", 95: "98-110", 85: "100-108" },
    75: { p: 105, c: "63", 95: "99-111", 85: "101-109" },
    76: { p: 106, c: "66", 95: "100-112", 85: "102-110" },
    77: { p: 107, c: "68", 95: "101-113", 85: "103-111" },
    78: { p: 108, c: "70", 95: "102-114", 85: "104-112" },
    79: { p: 109, c: "73", 95: "103-115", 85: "105-113" },
    80: { p: 110, c: "75", 95: "104-116", 85: "106-114" },
    81: { p: 111, c: "77", 95: "105-117", 85: "107-115" },
    82: { p: 112, c: "79", 95: "106-118", 85: "108-116" },
    83: { p: 113, c: "81", 95: "107-119", 85: "109-117" },
    84: { p: 114, c: "83", 95: "107-119", 85: "109-117" },
    85: { p: 116, c: "85", 95: "109-121", 85: "111-119" },
    86: { p: 117, c: "86", 95: "110-122", 85: "112-120" },

    87: { p: 118, c: "88", 95: "111-123", 85: "113-121" },
    88: { p: 118, c: "89", 95: "111-123", 85: "113-121" },
    89: { p: 119, c: "90", 95: "112-124", 85: "114-122" },
    90: { p: 120, c: "91", 95: "113-125", 85: "115-123" },
    91: { p: 121, c: "92", 95: "114-126", 85: "116-124" },
    92: { p: 123, c: "93", 95: "116-128", 85: "118-126" },
    93: { p: 124, c: "94", 95: "117-129", 85: "119-127" },
    94: { p: 125, c: "95", 95: "118-130", 85: "120-128" },
    95: { p: 126, c: "96", 95: "119-131", 85: "121-129" },
    96: { p: 127, c: "96", 95: "120-132", 85: "122-130" },
    97: { p: 128, c: "97", 95: "121-133", 85: "123-131" },
    98: { p: 129, c: "97", 95: "122-134", 85: "124-132" },
    99: { p: 130, c: "98", 95: "123-135", 85: "125-133" },
    100: { p: 131, c: "98", 95: "124-136", 85: "126-134" },
    101: { p: 133, c: "99", 95: "126-138", 85: "128-136" },
    102: { p: 134, c: "99", 95: "127-139", 85: "129-137" },
    103: { p: 136, c: "99.1", 95: "129-141", 85: "131-137" },
    104: { p: 137, c: "99.4", 95: "130-142", 85: "132-140" },
    105: { p: 138, c: "99.4", 95: "131-143", 85: "133-141" },
    106: { p: 139, c: "99.5", 95: "131-143", 85: "133-141" },
    107: { p: 139, c: "99.5", 95: "131-143", 85: "133-141" },
    108: { p: 140, c: "99.6", 95: "132-144", 85: "134-142" },
    109: { p: 142, c: "99.7", 95: "134-146", 85: "136-144" },
    110: { p: 143, c: "99.8", 95: "135-147", 85: "137-145" },
    111: { p: 146, c: "99.9", 95: "138-150", 85: "140-148" },
    112: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    113: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    114: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    115: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    116: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    117: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    118: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    119: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    120: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    121: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    122: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    123: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    124: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    125: { p: 150, c: ">99.9", 95: "142-154", 85: "144-152" },
    126: { p: 153, c: ">99.9", 95: "145-157", 85: "147-152" },
    127: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    128: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    129: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    130: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    131: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    132: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
    133: { p: 160, c: ">99.9", 95: "152-164", 85: "152-162" },
 
  },
];

export default wiscvScales;
