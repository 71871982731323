const ids2data = {
   testType: 'IDS',
   sections: {
      6: {
         id: 6,
         title: 'Matryce',
         options: {
            1: [
               'Bardzo słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            2: [
               'Bardzo słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            3: [
               'Bardzo słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            4: [
               'Słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            5: [
               'Słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do słabszych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            6: [
               'Słabo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Poniżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Poniżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            7: [
               'Poniżej przeciętnej przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Poniżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Poniżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            8: [
               'Dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            9: [
               'Dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            10: [
               'Dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            11: [
               'Dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            12: [
               'Dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Prawidłowo przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            13: [
               'Powyżej przeciętnej przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            14: [
               'Powyżej przeciętnej przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            15: [
               'Bardzo dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Powyżej norm wiekowych kształtuje się rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            16: [
               'Bardzo dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            17: [
               'Bardzo dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            18: [
               'Bardzo dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
            19: [
               'Bardzo dobrze przebiega proces rozumowania abstrakcyjnego dotyczącego wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
               'Do mocnych stron badanego ucznia zaliczyć należy rozumowanie abstrakcyjne dotyczące wykrywania relacji między elementami matryc lub wykrywania cech wspólnych.',
            ],
         },
      },

      7: {
         id: 7,
         title: 'Kategorie',
         options: {
            1: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo słabo rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
            ],
            2: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo słabo rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
            ],
            3: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo słabo rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie poniżej norm wiekowych.',
            ],
            4: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego rozwija się znacznie poniżej normy dla wieku.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
            ],
            5: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego rozwija się znacznie poniżej normy dla wieku.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
            ],
            6: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego rozwija się znacznie poniżej normy dla wieku.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
            ],
            7: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego rozwija się poniżej przeciętnej dla wieku.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie niższym niż u większości rówieśników.',
            ],
            8: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
            ],
            9: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
            ],
            10: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
            ],
            11: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie norm wiekowych.',
            ],
            12: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
            ],
            13: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego znajduje się powyżej przeciętnej.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
            ],
            14: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego znajduje się powyżej przeciętnej.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
            ],
            15: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego znajduje się na poziomie wysokim.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
            ],
            16: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego znajduje się na poziomie wysokim.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się na poziomie wyższym niż u większości rówieśników.',
            ],
            17: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych. ',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych. ',
            ],
            18: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych.',
            ],
            19: [
               'Rozumowanie werbalne, czyli umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego jest bardzo dobrze rozwinięte.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych.',
               'Umiejętność analizowania informacji i rozwiązywania problemów za pomocą rozumowania językowego inaczej rozumowanie werbalne kształtuje się znacznie powyżej norm wiekowych.',
            ],
         },
      },

      
   },
};

export default ids2data;
