<template>
 <div class="min-h-full">


    <!-- <Navbar /> -->
   <header class="bg-white shadow">

      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">TEST</h1>
      </div>
    </header>
    <main>
      <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div class="px-4 py-4 sm:px-0">
          <!-- <TableComponent :doneDescriptions="doneDescriptions" /> -->

        <div v-if="users">
          <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-xl font-semibold text-gray-900">{{users.length}} uzytkowników</h1>
              <p class="mt-2 text-sm text-gray-700"></p>
            </div>
        
          </div>
          <div class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Imię Nazwisko</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Email</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Opisy</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data utworzenia</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Zobacz</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="user in users" :key="user.createdAt">
                  <td class="w-1/2 max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {{ user.name }} {{ user.surname }}
                    <dl class="font-normal lg:hidden">
                      <dd class="mt-1 truncate text-gray-700">{{ user.email }}</dd>
                      <dt class="sr-only sm:hidden">Imię</dt>
                      <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ user.doneDescCount }}</dd>
                    </dl>
                  </td>
                  <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ user.email }}</td>
                  <td v-if="user.doneDescCount  == 0" class="hidden px-3 py-4 text-sm text-red-500 sm:table-cell">{{ user.doneDescCount }}</td>
                  <td v-else class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ user.doneDescCount }}</td>

                  <td class="px-3 py-4 text-sm text-gray-500">{{ formatDate(user.createdAt) }}</td>
                  <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <!-- <router-link :to="{ name: description.type, params: { id: description.id }}" class="text-indigo-600 hover:text-indigo-900">Zobacz<span class="sr-only">, {{ description.dataUrodzenia }}</span></router-link> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>







        <div v-if="doneDescriptions">
          <div class="sm:flex sm:items-center mt-10">
            <div class="sm:flex-auto">
              <h1 class="text-xl font-semibold text-gray-900">{{doneDescriptions.length}} ostatnich opisów</h1>
              <p class="mt-2 text-sm text-gray-700"></p>
            </div>
        
          </div>
          <div class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Imię Nazwisko</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Email</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Opisy</th>
                  <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Opis ID</th>

                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data utworzenia</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Zobacz</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="item in doneDescriptions" :key="item.editedAt">
                  <td class="w-1/2 max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                   <span v-if="findUserNameByUID(users, item.userUID, 'surname') == 'Rychlica'" class="text-gray-300" >{{ findUserNameByUID(users, item.userUID, "name") }} {{ findUserNameByUID(users, item.userUID, "surname") }}</span>
                   <span v-else>{{ findUserNameByUID(users, item.userUID, "name") }} {{ findUserNameByUID(users, item.userUID, "surname") }}</span>

                   

                    <dl class="font-normal lg:hidden">
                      <dd class="mt-1 truncate text-gray-700">{{ item.userUID }}</dd>
                      <dt class="sr-only sm:hidden">Imię</dt>
                      <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ item.type }}</dd>
                    </dl>
                  </td>
                  <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ findUserNameByUID(users, item.userUID, "email") }}</td>
                  <td v-if="item.userUID  == 0" class="hidden px-3 py-4 text-sm text-red-500 sm:table-cell">{{ item.userUID }}</td>
                  <td v-else class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ item.type }}</td>
                 
                  <td class="px-3 py-4 text-sm text-gray-500">{{ item.id }}</td>

                  <td class="px-3 py-4 text-sm text-gray-500">{{ formatDateTime(item.editedAt) }}</td>
                  <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <!-- <router-link :to="{ name: description.type, params: { id: description.id }}" class="text-indigo-600 hover:text-indigo-900">Zobacz<span class="sr-only">, {{ description.dataUrodzenia }}</span></router-link> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>





        <div v-if="doneDescriptions">
          <div class="sm:flex sm:items-center mt-10">
            <div class="sm:flex-auto">
              <h1 class="text-xl font-semibold text-gray-900">{{doneDescriptions.length}} ostatnich opisów - podzial na testy</h1>
              <p class="mt-2 text-sm text-gray-700"></p>
            </div>
        
          </div>
          <div class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">test</th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">ilosc</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <!-- <tr v-for="item in countTypes(doneDescriptions)" :key="item.editedAt"> -->
                  <tr v-for="(value, key) in countTypesValue" :key="key">

                  <td class="w-1/2 max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {{ key }}
                   

                  </td>
                  <td class="px-3 py-4 text-sm text-gray-500">{{ value }}</td>

               
                </tr>
              </tbody>
            </table>
          </div>
        </div>




        
  

        </div>
      </div>




      <div><p class="mt-10 text-center text-xs text-gray-300">dlaPsychologa wersja BETA</p></div>
    </main>
   
  </div>
</template>


<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
// import getCollection from '@/composables/getCollection';
import getDescriptionsById from '@/composables/getDescriptionsById';
import getUser from '@/composables/getUser';

// firebase imports
import { db } from '../firebase/config';
import { collection, limit, orderBy, onSnapshot, query, where } from 'firebase/firestore';



const getCollection2 = (c, q, l, orderByParam) => {
  const documents = ref(null);

  // collection reference
  let colRef = collection(db, c);

  // Obsługa filtrów, jeśli q nie jest null
  if (q) {
    q.forEach((condition) => {
      colRef = query(colRef, where(...condition));
    });
  }

  // Obsługa sortowania
  if (orderByParam) {
    orderByParam.forEach((param) => {
      colRef = query(colRef, orderBy(...param));
    });
  }

  // Obsługa limitu, jeśli l nie jest 0
  if (l > 0) {
    colRef = query(colRef, limit(l));
  }

  const unsub = onSnapshot(colRef, (snapshot) => {
    let results = [];
    snapshot.docs.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    // update values
    documents.value = results;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  console.log('documents: ', documents);
  return { documents };
};



const { user } = getUser();
const myUid = user.value.uid;
// const { documents: doneDescriptions } = getCollection('descriptions', ['userUID', '==', myUid], 5, true); //
const { documents: users } = getCollection2('users', null, 0, [['createdAt', 'desc']]);

const { documents: doneDescriptions } = getCollection2('descriptions', null, 25,  [['editedAt', 'desc']]);




function findUserNameByUID(users, userUID, valueName) {

  if (!users) {
    console.error('Lista użytkowników jest pusta lub niezdefiniowana');
    return '--'; // Lub obsłuż tę sytuację inaczej
  }

  // Używa metody find, aby znaleźć użytkownika z podanym userUID
  const user = users.find(user => user.id === userUID);

  // Jeśli użytkownik zostanie znaleziony, zwraca wartość dla klucza określonego przez valueName.
  // W przeciwnym razie zwraca 'Użytkownik nieznaleziony'.
  return user ? user[valueName] : '--';
}





const formatDate = (timestamp) => {
            // Zamiana timestamp na JavaScript Date object
            const date = timestamp.toDate();
            // pobieranie dnia, miesiąca i roku
            const day = ("0" + date.getDate()).slice(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            // Formatowanie daty na żądany format, np. DD/MM/YYYY
            return `${day}/${month}/${year}`;
        };



const formatDateTime = (timestamp) => {
    // Zamiana timestamp na JavaScript Date object
    const date = timestamp.toDate();
    // Pobieranie dnia, miesiąca i roku
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    // Pobieranie godziny i minuty
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    // Formatowanie daty i czasu na żądany format, np. DD/MM/YYYY HH:MM
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};


function countTypes(data) {
  const typeCounts = {};
  
  data.forEach(item => {
    const type = item.type;
    if (typeCounts[type]) {
      typeCounts[type] += 1;
    } else {
      typeCounts[type] = 1;
    }
  });
  
  return typeCounts;
}


const countTypesValue = computed(() => {
  if (doneDescriptions.value) {
    return countTypes(doneDescriptions.value);
  }
});


    //    // Allow to read only authenticated users
    //    match /users/{document=**} {
    //   allow read: if request.auth != null;

    // }

</script>


