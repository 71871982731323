<template>



<div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-8 w-auto" src="../../src/assets/dlaPsychologa-logo.png" alt="dlaPsychologa" />
      <h2 class="mt-10 mb-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Rejestracja na zaproszenie</h2>
      <p>Rejestracja w aplikacji dla psychologów jest obecnie ograniczona i odbywa się na zasadzie zaproszeń.<br><br></p>
      <p>Aby znaleźć się na liście oczekujących, kliknij w poniższy przycisk i podaj swój adres email.<br><br></p>
      <a href="https://dlapsychologa.com/waitlist/" class="flex w-full justify-center rounded-md bg-green-dla-400 px-3 py-3 text-sm font-semibold leading-6 text-green-dla-900 shadow-sm hover:bg-green-dla-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-dla-500">Dodaj się do listy oczekujących</a>

      <p><br>Skontaktujemy się z Tobą, jak tylko będziemy gotowi!</p>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6"  @submit.prevent="handleSubmit">


    </form>

    <div class="relative mt-6">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-gray-100 px-2 text-gray-500">lub</span>
              </div>
            </div>

            <p class="mt-5 text-center text-sm text-gray-500">
             
        <a href="/login" class="font-semibold leading-6  text-green-dla-400 hover:text-green-dla-500">Chcę się zalogować</a>
      </p>


  </div>
</div>

<div v-if="false" class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-8 w-auto" src="../../src/assets/dlaPsychologa-logo.png" alt="dlaPsychologa" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Nowe konto</h2>
  </div>

  <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form class="space-y-6"  @submit.prevent="handleSubmit">


      <div>
      <div class="flex flex-col sm:flex-row gap-4">
        <!-- Imię -->
        <div class="flex-1 sm:flex-[2]">
          <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Imię</label>
          <div class="mt-2">
            <input id="first-name" name="first-name" v-model="firstName" type="text" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
        <!-- Nazwisko -->
        <div class="flex-1 sm:flex-[3]">
          <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nazwisko</label>
          <div class="mt-2">
            <input id="last-name" name="last-name" v-model="lastName" type="text" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
          </div>
        </div>
      </div>
    </div>





      <div>
        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
        <div class="mt-2">
          <input id="email" name="email"  v-model="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div>
        <div class="flex items-center justify-between">
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Hasło</label>
         
        </div>
        <div class="mt-2">
          <input id="password" name="password" v-model="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div  class="mt-0 text-left text-sm text-gray-500">

        <fieldset class="space-y-5">
            <legend class="sr-only">Zgody</legend>
            <div class="relative flex items-start">
              <div class="flex h-5 items-center">
                <input v-model="termsAccepted" id="terms" aria-describedby="terms-description"  type="checkbox"  name="terms" required class="h-4 w-4 rounded border-gray-300 text-green-dla-500 focus:ring-green-dla-500" />
              </div>
              <div class="ml-3 text-sm">
                <span id="terms-description" class="text-gray-500">Akceptuję <a href="https://dlapsychologa.com/regulamin/" class="underline" target="_blank">regulamin</a> i <a href="https://dlapsychologa.com/polityka-prywatnosci/" target="_blank" class="underline">politykę prywatności</a>.</span>

              </div>
            </div>

        </fieldset>

      </div>

      <div v-if="termsAccepted">
        <button type="submit" class="flex w-full justify-center rounded-md bg-green-dla-400 px-3 py-3 text-sm font-semibold leading-6 text-green-dla-900 shadow-sm hover:bg-green-dla-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-dla-500">Zakładam konto</button>
      </div>
      <div v-if="!termsAccepted">
        <button disabled class="flex w-full justify-center rounded-md bg-gray-200 px-3 py-3 text-sm font-semibold leading-6 text-gray-500  hover:bg-gray-300  hover:text-gray-500 cursor-not-allowed ">Zakładam konto</button>
      </div>
      <div v-if="error">{{ error }}</div>
    </form>

    <div class="relative mt-6">
              <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-gray-100 px-2 text-gray-500">lub</span>
              </div>
            </div>

            <p class="mt-5 text-center text-sm text-gray-500">
             
        <a href="/login" class="font-semibold leading-6  text-green-dla-400 hover:text-green-dla-500">Chcę się zalogować</a>
      </p>


  </div>
</div>





</template>

<script>
import { ref } from 'vue';

import useSignup from '../composables/useSignup';
import getUser from '@/composables/getUser';
import addDocumentWithId from '../composables/addDocumentWithId.js';
import { serverTimestamp } from 'firebase/firestore';

import isValidNumber from '@/composables/detail/generateInvitation/';


export default {
   setup() {


      const termsAccepted = ref(false);

      const firstName = ref('');
      const lastName = ref('');
      const email = ref('');
      const password = ref('');

      const { signup, error } = useSignup();

      


      const handleSubmit = async () => {
         await signup(email.value, password.value, firstName.value, lastName.value);

         if (!error.value) {
          const { user } = getUser();
          await addDocumentWithId("users", user.value.uid,         
         {
            email: user.value.email,
            doneDescCount: 0,
            createdAt: serverTimestamp(),
         })

            router.push('/');
         }
      };

      return { email, password, firstName, lastName, handleSubmit, error, termsAccepted };
   },
};
</script>
