import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';


// const configuration = new Configuration({
//    organization: functions.config().openai.organization,
//    apiKey: functions.config().openai.api_key,
//  });



const firebaseConfig = {
   apiKey: 'AIzaSyBpJ16Ar8UNi_z0kybX9IhVQN2b9CeCwfw',
   authDomain: 'dlapsychologa-be9d0.firebaseapp.com',
   projectId: 'dlapsychologa-be9d0',
   storageBucket: 'dlapsychologa-be9d0.appspot.com',
   messagingSenderId: '198349497975',
   appId: '1:198349497975:web:5eac94063a1d0754e20872',
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
const auth = getAuth();

export { db, auth };
