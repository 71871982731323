import { db } from '../firebase/config';
import { ref } from 'vue';
import { doc, setDoc } from "firebase/firestore";

const addDocumentWithId = async (collectionName, id, newDocument) => {
  const error = ref(null);
  const isPending = ref(true); // Ustaw początkowo na true, ponieważ operacja rozpoczęła się

  try {
    const docRef = doc(db, collectionName, id); // Poprawne wywołanie
    await setDoc(docRef, newDocument);
    error.value = null; // Ustaw na null, ponieważ operacja zakończyła się sukcesem
    isPending.value = false; // Ustaw na false, ponieważ operacja zakończyła się sukcesem
    return { error, isPending };

  } catch (err) {
    console.log(err.message);
    error.value = 'could not add the document'; // Ustaw wiadomość o błędzie, jeśli wystąpił błąd
    isPending.value = false; // Ustaw na false, ponieważ operacja zakończyła się błędem

    return { error, isPending };
  }
};

export default addDocumentWithId;
