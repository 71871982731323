import { getAuth, getIdToken } from 'firebase/auth';
import axios from 'axios';

const apiUrl = 'https://us-central1-dlapsychologa-be9d0.cloudfunctions.net/askGPT';
// const apiUrl = 'http://127.0.0.1:5002/dlapsychologa-be9d0/us-central1/askGPT'; 
               

export default async function askGPT(prompt) {
   try {
    //  console.log("askGPT...");
     const auth = getAuth();
     const user = auth.currentUser;
     
     if (!user) {
       throw new Error('User not authenticated');
     }
 
     const idToken = await getIdToken(user);
    //  const message = promptTEMPLATE.replace('{{prompt}}', prompt); 
     const message = prompt; 
     const headers = { Authorization: `Bearer ${idToken}` };
     
    //  console.log("idToken", idToken);
    //  console.log(`${prompt.system}\n\n${prompt.message}`);
    //  console.log("headers", headers);
 
     const response = await axios.post(apiUrl, message, { headers });
     
    //  console.log(response.data.answer);
     return response.data.answer;
     
   } catch (error) {
     console.error(error);
     throw error;
   }
 }





// export default async function askGPT(prompt) {
//    try {
//       const message = promptTEMPLATE.replace('{{prompt}}', prompt);
//       // console.log(message);
//       // const message = promptTEMPLATE; // Przykładowa wiadomość do przekazania do funkcji
//       const response = await axios.post(apiUrl, { message });
//       // console.log(response.data);
//       console.log(response.data.answer);
//       return response.data.answer
//    } catch (error) {
//       console.error(error);
//       throw error;
//    }
// }



