<template>
  <div class="min-h-full" v-if="user" id="top">
    <Disclosure as="nav" class="bg-green-dla-400" v-slot="{ open }">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link :to="{ name: 'Dashboard' }">
                <img
                  class="h-8"
                  src="@/assets/dlaPsychologa-logo.svg"
                  alt="dlaPsychologa"
                  id="dlaPsychologa-logo"
                />
              </router-link>
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-green-dla-500 text-green-dla-900' : 'text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a> -->
                <!-- <router-link v-for="item in navigation" :key="item.name" :to="{ name: item.routName }" :class="[item.current() ? 'bg-green-dla-500 text-green-dla-900' : 'text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current() ? 'page' : undefined">{{ item.name }}</router-link> -->

                <template v-for="item in navigation" :key="item.name">
                  <router-link
                    v-if="!item.external"
                    :to="{ name: item.routName }"
                    :class="[
                      item.current()
                        ? 'bg-green-dla-500 text-green-dla-900'
                        : 'text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75',
                      'px-3 py-2 rounded-md text-sm font-medium',
                    ]"
                    :aria-current="item.current() ? 'page' : undefined"
                  >
                    {{ item.name }}
                  </router-link>
                  <a
                    v-else
                    :href="item.href"
                    target="_blank"
                    rel="noopener noreferrer"
                    :class="[
                      'text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75',
                      'px-3 py-2 rounded-md text-sm font-medium',
                    ]"
                  >
                    {{ item.name }}
                  </a>
                </template>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <!-- <button type="button" class="rounded-full bg-green-dla-600 p-1 text-green-dla--200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-dla-600">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button> -->

              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="flex max-w-xs text-base items-center text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75 hover:text-green-dla-900 px-3 py-2 rounded-md font-medium focus:outline-none focus:ring-offset-green-dla-600"
                  >
                    {{ user.displayName || user.email.split("@")[0] }}

                    <span class="sr-only">Open user menu</span>
                    <!-- <img class="h-8 w-8 rounded-full" :src="user.imageUrl" alt="" /> -->
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active }"
                    >
                      <a
                        :href="item.href"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        ]"
                        @click="item.action && item.action()"
                      >
                        {{ item.name }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md bg-green-dla-500 p-2 text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-dla-600"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="md:hidden">
        <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
          <DisclosureButton
            v-for="item in navigation"
            :key="item.name"
            as="a"
            @click="goTo(item.routName)"
            :class="[
              item.current()
                ? 'bg-green-dla-500 text-green-dla-900'
                : 'text-green-dla-900 hover:bg-green-dla-300 hover:bg-opacity-75',
              'block px-3 py-2 rounded-md text-base font-medium',
            ]"
            :aria-current="item.current() ? 'page' : undefined"
            >{{ item.name }}</DisclosureButton
          >
        </div>
        <div class="border-t border-green-dla-500 pt-4 pb-3">
          <div class="flex items-center px-5">
            <!-- <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" /> 
            </div>-->
            <div>
              <!-- <div class="text-base font-medium text-white">{{ user.name }}</div> -->
              <div class="text-base font-medium text-green-dla-600">
                {{ user.displayName || user.email.split("@")[0] }}
              </div>
            </div>
            <!-- <button type="button" class="ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-green-dla-500 p-1 text-green-dla-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-green-dla-600">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->
          </div>
          <div class="mt-3 space-y-1 px-2">
            <DisclosureButton
              v-for="item in userNavigation"
              :key="item.name"
              as="a"
              :href="item.href"
              class="block rounded-md px-3 py-2 text-base font-medium text-green-dla-900 hover:bg-green-dla-500 hover:bg-opacity-75"
              @click="item.action && item.action()"
              >{{ item.name }}</DisclosureButton
            >
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import { useRoute, useRouter } from "vue-router";
import { watchEffect } from "vue";
import getUser from "@/composables/getUser";

const router = useRouter();
const route = useRoute();
const { user } = getUser();

const handleSignOut = async () => {
  console.log("Attempting to sign out");
  try {
    await signOut(auth); // Wait for the sign out to complete
    console.log("User signed out, redirecting to login");
    // router.push({ name: 'Login' }); // Redirect to the login page
    router.push({ name: "ThankYou" });
    // router.push('/thankyou');
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};

const goTo = (routeName) => {
  router.push({ name: routeName });
};

// { name: 'Moje opisy', href: '/', current: true },
// { name: '+ Nowy opis', href: '/wybierz-test', current: false },
let navigation = [
  {
    name: "Moje opisy",
    routName: "Dashboard",
    current: () => route.name === "Dashboard",
  },
  {
    name: "+ Nowy opis",
    routName: "ChooseTest",
    current: () => route.name === "ChooseTest",
  },
  {
    name: "☕ Wsparcie",
    href: "https://buycoffee.to/dlapsychologa",
    external: true,
    current: () => false,
  },
];

const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  { name: "Wyloguj się", href: "#", action: handleSignOut }, // dodajemy pole "action"
];
</script>

<style scope></style>
