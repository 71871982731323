
const validateName = (name) => {
    if (!name) return 'Proszę wpisać imię.';
    return null;
 };

 const validateBirthdate = (birthdate) => {
    if (!birthdate) return 'Podaj datę urodzenia';
    return null;
 };

 const validateForm = (firstName, dataUrodzenia) => {
    const errors = {
       firstName: validateName(firstName.value),
       dataUrodzenia: validateBirthdate(dataUrodzenia.value),
    };

    const isValid = !Object.values(errors).some(Boolean);

    const errorsText = Object.values(errors).filter(Boolean).join(' ');

    return {
       isValid,
       errors,
       errorsText,
    };
 };


 export default validateForm;