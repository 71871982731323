const ids2data = {
   testType: 'IDS',
   sections: {
      1: {
         id: 1,
         title: 'Klocki',
         // options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      2: {
         id: 2,
         title: 'Opowiadanie',
         // options: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      3: {
         id: 3,
         title: 'Papugi',
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      4: {
         id: 4,
         title: 'Szeregi jednorodne',
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      5: {
         id: 5,
         title: 'Bryły',
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      6: {
         id: 6,
         title: 'Matryce',
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

      7: {
         id: 7,
         title: 'Kategorie',
         options: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
         },
      },

     
   },
};

export default ids2data;
