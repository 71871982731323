import { ref, watchEffect } from 'vue';

// firebase imports
import { db } from '../firebase/config';
import { collection, limit, orderBy, onSnapshot, query, where } from 'firebase/firestore';

const getCollection = (c, q, l, ob) => {
   const documents = ref(null);

   // collection reference
   let colRef = collection(db, c);


   if (q && l && ob) {
      // console.log(...q);
      colRef = query(colRef, where(...q), orderBy('editedAt', 'desc'), limit(l));
   } else if (q && l) {
      // console.log(...q);
      colRef = query(colRef, where(...q), limit(l));
   } else if (q) {
      // console.log(...q);
      colRef = query(colRef, where(...q));
   }




   const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
         results.push({ ...doc.data(), id: doc.id });
      });

      // update values
      documents.value = results;
   });

   watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
   });

   return { documents };
};

export default getCollection;
