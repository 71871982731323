


export const waitForMs = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};







export const typeSentence = async (elementRef, sentence, shouldCancel, delay = 25) => {
  const words = sentence.split(" ");
  let i = 0;

  while (i < words.length) {
    if (shouldCancel() || elementRef.value === null) {
      console.log("Exiting because of if condition");
      return false;
    }

    await waitForMs(getRandomNumber(12, 70));
    // await waitForMs(getRandomNumber(500, 2000));

    if (elementRef.value !== null) {
      if (words[i] === "\n") {
        elementRef.value.innerHTML += "<br>";
      } else {
        const parts = splitWord(words[i]);
        for (const part of parts) {
          elementRef.value.innerHTML += part;
          await waitForMs(getRandomNumber(12, 70));
          // await waitForMs(getRandomNumber(500, 2000));
        }
        elementRef.value.innerHTML += " ";  // Dodanie spacji po całym wyrazie
      }
    }

    i++;
  }

  return true;
};



const splitWord = (word) => {
  const len = word.length;
  let parts = [];

  let remaining = len;
  let index = 0;

  while (remaining > 0) {
    let chunkSize = remaining > 4 ? 4 : remaining;
    parts.push(word.substring(index, index + chunkSize));
    index += chunkSize;
    remaining -= chunkSize;
  }

  return parts;
};














// PISANIE WYRAZU PO WYRAZIE
// export const typeSentence = async (elementRef, sentence, shouldCancel, delay = 25) => {
//   const words = sentence.split(" "); // Dzieli zdanie na wyrazy
//   let i = 0;

//   while (i < words.length) {
//     // Sprawdź, czy operację należy anulować lub czy elementRef.value jest null
//     if (shouldCancel() || elementRef.value === null) {
//       console.log("Exiting because of if condition");
//       return false; // anuluj operację
//     }

//     await waitForMs(getRandomNumber(12, 50));

//     // Ponowne sprawdzenie, czy elementRef.value jest null, jest opcjonalne ale zalecane
//     if (elementRef.value !== null) {
//       if (words[i] === "\n") {
//         elementRef.value.innerHTML += "<br>";
//       } else {
//         elementRef.value.innerHTML += words[i] + " "; // Dodaje spacje po każdym wyrazie
//       }
//     }

//     i++;
//   }
  
//   return true;
// };



// PISANIE LITERA PO LITERZE
// export const typeSentence = async (elementRef, sentence, shouldCancel, delay = 25) => {
//   const letters = sentence.split("");
//   let i = 0;

//   while (i < letters.length) {
//     // Sprawdź, czy operację należy anulować lub czy elementRef.value jest null
//     if (shouldCancel() || elementRef.value === null) {
//       console.log("Exiting because of if condition");
//       return false; // anuluj operację
//     }
    
//     await waitForMs(getRandomNumber(12, 50));
    
//     // Ponowne sprawdzenie, czy elementRef.value jest null, jest opcjonalne ale zalecane
//     // if (elementRef.value !== null) {
//     //   elementRef.value.innerHTML += letters[i];
//     // }
    
//      // Ponowne sprawdzenie, czy elementRef.value jest null, jest opcjonalne ale zalecane
//      if (elementRef.value !== null) {
//       if (letters[i] === "\n") {
//         elementRef.value.innerHTML += "<br>";
//       } else {
//         elementRef.value.innerHTML += letters[i];
//       }
//     }



//     i++;
//   }
  
//   return true;
// };
