export const daneIQprzeliczoneOgolny = [
	{ 'IQ': 30, 'min': 7, 'max': 7},
	{ 'IQ': 31, 'min': 8, 'max': 8},
	{ 'IQ': 32, 'min': 9, 'max': 9},
	{ 'IQ': 33, 'min': 10, 'max': 10},
	{ 'IQ': 34, 'min': 11, 'max': 11},
	{ 'IQ': 36, 'min': 12, 'max': 12},
	{ 'IQ': 37, 'min': 13, 'max': 13},
	{ 'IQ': 38, 'min': 14, 'max': 14},
	{ 'IQ': 39, 'min': 15, 'max': 15},
	{ 'IQ': 40, 'min': 16, 'max': 16},
	{ 'IQ': 41, 'min': 17, 'max': 17},
	{ 'IQ': 42, 'min': 18, 'max': 18},
	{ 'IQ': 43, 'min': 19, 'max': 19},
	{ 'IQ': 44, 'min': 20, 'max': 20},
	{ 'IQ': 46, 'min': 21, 'max': 21},
	{ 'IQ': 47, 'min': 22, 'max': 22},
	{ 'IQ': 48, 'min': 23, 'max': 23},
	{ 'IQ': 49, 'min': 24, 'max': 24},
	{ 'IQ': 50, 'min': 25, 'max': 25},
	{ 'IQ': 51, 'min': 26, 'max': 26},
	{ 'IQ': 52, 'min': 27, 'max': 27},
	{ 'IQ': 53, 'min': 28, 'max': 28},
	{ 'IQ': 54, 'min': 29, 'max': 29},
	{ 'IQ': 56, 'min': 30, 'max': 30},
	{ 'IQ': 57, 'min': 31, 'max': 31},
	{ 'IQ': 58, 'min': 32, 'max': 32},
	{ 'IQ': 59, 'min': 33, 'max': 33},
	{ 'IQ': 60, 'min': 34, 'max': 34},
	{ 'IQ': 61, 'min': 35, 'max': 35},
	{ 'IQ': 62, 'min': 36, 'max': 36},
	{ 'IQ': 63, 'min': 37, 'max': 37},
	{ 'IQ': 64, 'min': 38, 'max': 38},
	{ 'IQ': 66, 'min': 39, 'max': 39},
	{ 'IQ': 67, 'min': 40, 'max': 40},
	{ 'IQ': 68, 'min': 41, 'max': 41},
	{ 'IQ': 69, 'min': 42, 'max': 42},
	{ 'IQ': 70, 'min': 43, 'max': 43},
	{ 'IQ': 71, 'min': 44, 'max': 44},
	{ 'IQ': 72, 'min': 45, 'max': 45},
	{ 'IQ': 73, 'min': 46, 'max': 46},
	{ 'IQ': 74, 'min': 47, 'max': 47},
	{ 'IQ': 76, 'min': 48, 'max': 48},
	{ 'IQ': 77, 'min': 49, 'max': 49},
	{ 'IQ': 78, 'min': 50, 'max': 50},
	{ 'IQ': 79, 'min': 51, 'max': 51},
	{ 'IQ': 80, 'min': 52, 'max': 52},
	{ 'IQ': 81, 'min': 53, 'max': 53},
	{ 'IQ': 82, 'min': 54, 'max': 54},
	{ 'IQ': 83, 'min': 55, 'max': 55},
	{ 'IQ': 85, 'min': 56, 'max': 56},
	{ 'IQ': 86, 'min': 57, 'max': 57},
	{ 'IQ': 87, 'min': 58, 'max': 58},
	{ 'IQ': 88, 'min': 59, 'max': 59},
	{ 'IQ': 89, 'min': 60, 'max': 60},
	{ 'IQ': 90, 'min': 61, 'max': 61},
	{ 'IQ': 91, 'min': 62, 'max': 62},
	{ 'IQ': 92, 'min': 63, 'max': 63},
	{ 'IQ': 93, 'min': 64, 'max': 64},
	{ 'IQ': 95, 'min': 65, 'max': 65},
	{ 'IQ': 96, 'min': 66, 'max': 66},
	{ 'IQ': 97, 'min': 67, 'max': 67},
	{ 'IQ': 98, 'min': 68, 'max': 68},
	{ 'IQ': 99, 'min': 69, 'max': 69},
	{ 'IQ': 100, 'min': 70, 'max': 70},
	{ 'IQ': 101, 'min': 71, 'max': 71},
	{ 'IQ': 102, 'min': 72, 'max': 72},
	{ 'IQ': 103, 'min': 73, 'max': 73},
	{ 'IQ': 105, 'min': 74, 'max': 74},
	{ 'IQ': 106, 'min': 75, 'max': 75},
	{ 'IQ': 107, 'min': 76, 'max': 76},
	{ 'IQ': 108, 'min': 77, 'max': 77},
	{ 'IQ': 109, 'min': 78, 'max': 78},
	{ 'IQ': 110, 'min': 79, 'max': 79},
	{ 'IQ': 111, 'min': 80, 'max': 80},
	{ 'IQ': 112, 'min': 81, 'max': 81},
	{ 'IQ': 113, 'min': 82, 'max': 82},
	{ 'IQ': 115, 'min': 83, 'max': 83},
	{ 'IQ': 116, 'min': 84, 'max': 84},
	{ 'IQ': 117, 'min': 85, 'max': 85},
	{ 'IQ': 118, 'min': 86, 'max': 86},
	{ 'IQ': 119, 'min': 87, 'max': 87},
	{ 'IQ': 120, 'min': 88, 'max': 88},
	{ 'IQ': 121, 'min': 89, 'max': 89},
	{ 'IQ': 122, 'min': 90, 'max': 90},
	{ 'IQ': 123, 'min': 91, 'max': 91},
	{ 'IQ': 125, 'min': 92, 'max': 92},
	{ 'IQ': 126, 'min': 93, 'max': 93},
	{ 'IQ': 127, 'min': 94, 'max': 94},
	{ 'IQ': 128, 'min': 95, 'max': 95},
	{ 'IQ': 129, 'min': 96, 'max': 96},
	{ 'IQ': 130, 'min': 97, 'max': 97},
	{ 'IQ': 131, 'min': 98, 'max': 98},
	{ 'IQ': 132, 'min': 99, 'max': 99},
	{ 'IQ': 133, 'min': 100, 'max': 100},
	{ 'IQ': 135, 'min': 101, 'max': 101},
	{ 'IQ': 136, 'min': 102, 'max': 102},
	{ 'IQ': 137, 'min': 103, 'max': 103},
	{ 'IQ': 138, 'min': 104, 'max': 104},
	{ 'IQ': 139, 'min': 105, 'max': 105},
	{ 'IQ': 140, 'min': 106, 'max': 106},
	{ 'IQ': 141, 'min': 107, 'max': 107},
	{ 'IQ': 142, 'min': 108, 'max': 108},
	{ 'IQ': 143, 'min': 109, 'max': 109},
	{ 'IQ': 145, 'min': 110, 'max': 110},
	{ 'IQ': 146, 'min': 111, 'max': 111},
	{ 'IQ': 147, 'min': 112, 'max': 112},
	{ 'IQ': 148, 'min': 113, 'max': 113},
	{ 'IQ': 149, 'min': 114, 'max': 114},
	{ 'IQ': 150, 'min': 115, 'max': 115},
	{ 'IQ': 151, 'min': 116, 'max': 116},
	{ 'IQ': 152, 'min': 117, 'max': 117},
	{ 'IQ': 153, 'min': 118, 'max': 118},
	{ 'IQ': 155, 'min': 119, 'max': 119},
	{ 'IQ': 156, 'min': 120, 'max': 120},
	{ 'IQ': 157, 'min': 121, 'max': 121},
	{ 'IQ': 158, 'min': 122, 'max': 122},
	{ 'IQ': 159, 'min': 123, 'max': 123},
	{ 'IQ': 160, 'min': 124, 'max': 124},
	{ 'IQ': 161, 'min': 125, 'max': 125},
	{ 'IQ': 162, 'min': 126, 'max': 126},
	{ 'IQ': 163, 'min': 127, 'max': 127},
	{ 'IQ': 165, 'min': 128, 'max': 128},
	{ 'IQ': 166, 'min': 129, 'max': 129},
	{ 'IQ': 167, 'min': 130, 'max': 130},
	{ 'IQ': 168, 'min': 131, 'max': 131},
	{ 'IQ': 169, 'min': 132, 'max': 132},
	{ 'IQ': 170, 'min': 133, 'max': 133},
];

export const daneIQprzeliczonePelny = [
	{ 'IQ':30, 'min':4, 'max':26},
	{ 'IQ':31, 'min':27, 'max':28},
	{ 'IQ':32, 'min':29, 'max':30},
	{ 'IQ':33, 'min':31, 'max':31},
	{ 'IQ':34, 'min':32, 'max':33},
	{ 'IQ':35, 'min':34, 'max':34},
	{ 'IQ':36, 'min':35, 'max':36},
	{ 'IQ':37, 'min':34, 'max':38},
	{ 'IQ':38, 'min':39, 'max':39},
	{ 'IQ':39, 'min':40, 'max':41},
	{ 'IQ':40, 'min':42, 'max':43},
	{ 'IQ':41, 'min':44, 'max':44},
	{ 'IQ':42, 'min':45, 'max':46},
	{ 'IQ':43, 'min':47, 'max':47},
	{ 'IQ':44, 'min':48, 'max':49},
	{ 'IQ':45, 'min':50, 'max':51},
	{ 'IQ':46, 'min':52, 'max':52},
	{ 'IQ':47, 'min':53, 'max':54},
	{ 'IQ':48, 'min':55, 'max':56},
	{ 'IQ':49, 'min':57, 'max':57},
	{ 'IQ':50, 'min':58, 'max':59},
	{ 'IQ':51, 'min':60, 'max':61},
	{ 'IQ':52, 'min':62, 'max':62},
	{ 'IQ':53, 'min':63, 'max':64},
	{ 'IQ':54, 'min':65, 'max':65},
	{ 'IQ':55, 'min':66, 'max':67},
	{ 'IQ':56, 'min':68, 'max':69},
	{ 'IQ':57, 'min':70, 'max':70},
	{ 'IQ':58, 'min':71, 'max':72},
	{ 'IQ':59, 'min':73, 'max':74},
	{ 'IQ':60, 'min':75, 'max':75},
	{ 'IQ':61, 'min':76, 'max':77},
	{ 'IQ':62, 'min':78, 'max':78},
	{ 'IQ':63, 'min':79, 'max':80},
	{ 'IQ':64, 'min':81, 'max':82},
	{ 'IQ':65, 'min':83, 'max':83},
	{ 'IQ':66, 'min':84, 'max':85},
	{ 'IQ':67, 'min':86, 'max':87},
	{ 'IQ':68, 'min':88, 'max':88},
	{ 'IQ':69, 'min':89, 'max':90},
	{ 'IQ':70, 'min':91, 'max':91},
	{ 'IQ':71, 'min':92, 'max':93},
	{ 'IQ':72, 'min':94, 'max':95},
	{ 'IQ':73, 'min':96, 'max':96},
	{ 'IQ':74, 'min':97, 'max':98},
	{ 'IQ':75, 'min':99, 'max':100},
	{ 'IQ':76, 'min':101, 'max':101},
	{ 'IQ':77, 'min':102, 'max':103},
	{ 'IQ':78, 'min':104, 'max':105},
	{ 'IQ':79, 'min':106, 'max':106},
	{ 'IQ':80, 'min':107, 'max':108},
	{ 'IQ':81, 'min':109, 'max':109},
	{ 'IQ':82, 'min':110, 'max':111},
	{ 'IQ':83, 'min':112, 'max':113},
	{ 'IQ':84, 'min':114, 'max':114},
	{ 'IQ':85, 'min':115, 'max':116},
	{ 'IQ':86, 'min':117, 'max':118},
	{ 'IQ':87, 'min':119, 'max':119},
	{ 'IQ':88, 'min':120, 'max':121},
	{ 'IQ':89, 'min':122, 'max':122},
	{ 'IQ':90, 'min':123, 'max':124},
	{ 'IQ':91, 'min':125, 'max':126},
	{ 'IQ':92, 'min':127, 'max':127},
	{ 'IQ':93, 'min':128, 'max':129},
	{ 'IQ':94, 'min':130, 'max':131},
	{ 'IQ':95, 'min':132, 'max':132},
	{ 'IQ':96, 'min':133, 'max':134},
	{ 'IQ':97, 'min':135, 'max':135},
	{ 'IQ':98, 'min':136, 'max':137},
	{ 'IQ':99, 'min':135, 'max':139},
	{ 'IQ':100, 'min':140, 'max':140},
	{ 'IQ':101, 'min':141, 'max':142},
	{ 'IQ':102, 'min':143, 'max':144},
	{ 'IQ':103, 'min':145, 'max':145},
	{ 'IQ':104, 'min':146, 'max':147},
	{ 'IQ':105, 'min':148, 'max':149},
	{ 'IQ':106, 'min':150, 'max':150},
	{ 'IQ':107, 'min':151, 'max':152},
	{ 'IQ':108, 'min':153, 'max':153},
	{ 'IQ':109, 'min':154, 'max':155},
	{ 'IQ':110, 'min':156, 'max':157},
	{ 'IQ':111, 'min':158, 'max':158},
	{ 'IQ':112, 'min':159, 'max':160},
	{ 'IQ':113, 'min':161, 'max':162},
	{ 'IQ':114, 'min':163, 'max':163},
	{ 'IQ':115, 'min':164, 'max':165},
	{ 'IQ':116, 'min':166, 'max':166},
	{ 'IQ':117, 'min':167, 'max':168},
	{ 'IQ':118, 'min':169, 'max':170},
	{ 'IQ':119, 'min':171, 'max':171},
	{ 'IQ':120, 'min':172, 'max':173},
	{ 'IQ':121, 'min':174, 'max':175},
	{ 'IQ':122, 'min':176, 'max':176},
	{ 'IQ':123, 'min':177, 'max':178},
	{ 'IQ':124, 'min':179, 'max':180},
	{ 'IQ':125, 'min':181, 'max':181},
	{ 'IQ':126, 'min':182, 'max':183},
	{ 'IQ':127, 'min':184, 'max':184},
	{ 'IQ':128, 'min':185, 'max':186},
	{ 'IQ':129, 'min':187, 'max':188},
	{ 'IQ':130, 'min':189, 'max':189},
	{ 'IQ':131, 'min':190, 'max':191},
	{ 'IQ':132, 'min':192, 'max':193},
	{ 'IQ':133, 'min':194, 'max':194},
	{ 'IQ':134, 'min':195, 'max':196},
	{ 'IQ':135, 'min':197, 'max':197},
	{ 'IQ':136, 'min':198, 'max':199},
	{ 'IQ':137, 'min':200, 'max':201},
	{ 'IQ':138, 'min':202, 'max':202},
	{ 'IQ':139, 'min':203, 'max':204},
	{ 'IQ':140, 'min':205, 'max':206},
	{ 'IQ':141, 'min':207, 'max':207},
	{ 'IQ':142, 'min':208, 'max':209},
	{ 'IQ':143, 'min':210, 'max':210},
	{ 'IQ':144, 'min':211, 'max':212},
	{ 'IQ':145, 'min':213, 'max':214},
	{ 'IQ':146, 'min':215, 'max':215},
	{ 'IQ':147, 'min':216, 'max':217},
	{ 'IQ':148, 'min':218, 'max':219},
	{ 'IQ':149, 'min':220, 'max':220},
	{ 'IQ':150, 'min':221, 'max':222},
	{ 'IQ':151, 'min':223, 'max':224},
	{ 'IQ':152, 'min':225, 'max':225},
	{ 'IQ':153, 'min':226, 'max':227},
	{ 'IQ':154, 'min':228, 'max':228},
	{ 'IQ':155, 'min':229, 'max':230},
	{ 'IQ':156, 'min':231, 'max':232},
	{ 'IQ':157, 'min':233, 'max':233},
	{ 'IQ':158, 'min':234, 'max':235},
	{ 'IQ':159, 'min':236, 'max':237},
	{ 'IQ':160, 'min':238, 'max':238},
	{ 'IQ':161, 'min':239, 'max':240},
	{ 'IQ':162, 'min':241, 'max':241},
	{ 'IQ':163, 'min':242, 'max':243},
	{ 'IQ':164, 'min':244, 'max':245},
	{ 'IQ':165, 'min':246, 'max':246},
	{ 'IQ':166, 'min':247, 'max':248},
	{ 'IQ':167, 'min':249, 'max':250},
	{ 'IQ':168, 'min':251, 'max':251},
	{ 'IQ':169, 'min':252, 'max':253},
	{ 'IQ':170, 'min':254, 'max':266},
];


export const daneIQprzeliczonePrzesiewowy = [
	{ 'IQ':45, 'min':2, 'max':2},
	{ 'IQ':49, 'min':3, 'max':3},
	{ 'IQ':52, 'min':4, 'max':4},
	{ 'IQ':55, 'min':5, 'max':5},
	{ 'IQ':58, 'min':6, 'max':6},
	{ 'IQ':61, 'min':7, 'max':7},
	{ 'IQ':64, 'min':8, 'max':8},
	{ 'IQ':67, 'min':9, 'max':9},
	{ 'IQ':70, 'min':10, 'max':10},
	{ 'IQ':73, 'min':11, 'max':11},
	{ 'IQ':76, 'min':12, 'max':12},
	{ 'IQ':79, 'min':13, 'max':13},
	{ 'IQ':82, 'min':14, 'max':14},
	{ 'IQ':85, 'min':15, 'max':15},
	{ 'IQ':88, 'min':16, 'max':16},
	{ 'IQ':91, 'min':17, 'max':17},
	{ 'IQ':94, 'min':18, 'max':18},
	{ 'IQ':97, 'min':19, 'max':19},
	{ 'IQ':100, 'min':20, 'max':20},
	{ 'IQ':103, 'min':21, 'max':21},
	{ 'IQ':106, 'min':22, 'max':22},
	{ 'IQ':109, 'min':23, 'max':23},
	{ 'IQ':112, 'min':24, 'max':24},
	{ 'IQ':115, 'min':25, 'max':25},
	{ 'IQ':118, 'min':26, 'max':26},
	{ 'IQ':121, 'min':27, 'max':27},
	{ 'IQ':124, 'min':28, 'max':28},
	{ 'IQ':127, 'min':29, 'max':29},
	{ 'IQ':130, 'min':30, 'max':30},
	{ 'IQ':133, 'min':31, 'max':31},
	{ 'IQ':136, 'min':32, 'max':32},
	{ 'IQ':139, 'min':33, 'max':33},
	{ 'IQ':142, 'min':34, 'max':34},
	{ 'IQ':145, 'min':35, 'max':35},
	{ 'IQ':148, 'min':36, 'max':36},
	{ 'IQ':151, 'min':37, 'max':37},
	{ 'IQ':155, 'min':38, 'max':38},
];





// export const daneIQprzeliczonePelny = [
// 	{ 'IQ': 30, 'min': 4, 'max': 26},
// 	{ 'IQ': 31, 'min': 27, 'max': 28},
// 	{ 'IQ': 32, 'min': 29, 'max': 30},
// 	{ 'IQ': 33, 'min': 31, 'max': 31},
// 	{ 'IQ': 34, 'min': 32, 'max': 33},
// 	{ 'IQ': 35, 'min': 34, 'max': 34},
// 	{ 'IQ': 36, 'min': 35, 'max': 36},
// 	{ 'IQ': 37, 'min': 37, 'max': 38},
// 	{ 'IQ': 38, 'min': 39, 'max': 39},
// 	{ 'IQ': 39, 'min': 40, 'max': 41},
// 	{ 'IQ': 40, 'min': 42, 'max': 43},
// 	{ 'IQ': 41, 'min': 44, 'max': 44},
// 	{ 'IQ': 42, 'min': 45, 'max': 46},
// 	{ 'IQ': 43, 'min': 47, 'max': 47},
// 	{ 'IQ': 44, 'min': 48, 'max': 49},
// 	{ 'IQ': 46, 'min': 52, 'max': 52},
// 	{ 'IQ': 47, 'min': 53, 'max': 54},
// 	{ 'IQ': 48, 'min': 55, 'max': 56},
// 	{ 'IQ': 49, 'min': 24, 'max': 24},
// 	{ 'IQ': 50, 'min': 25, 'max': 25},
// 	{ 'IQ': 51, 'min': 26, 'max': 26},
// 	{ 'IQ': 52, 'min': 27, 'max': 27},
// 	{ 'IQ': 53, 'min': 28, 'max': 28},
// 	{ 'IQ': 54, 'min': 29, 'max': 29},
// 	{ 'IQ': 56, 'min': 30, 'max': 30},
// 	{ 'IQ': 57, 'min': 31, 'max': 31},
// 	{ 'IQ': 58, 'min': 32, 'max': 32},
// 	{ 'IQ': 59, 'min': 33, 'max': 33},
// 	{ 'IQ': 60, 'min': 34, 'max': 34},
// 	{ 'IQ': 61, 'min': 35, 'max': 35},
// 	{ 'IQ': 62, 'min': 36, 'max': 36},
// 	{ 'IQ': 63, 'min': 37, 'max': 37},
// 	{ 'IQ': 64, 'min': 38, 'max': 38},
// 	{ 'IQ': 66, 'min': 39, 'max': 39},
// 	{ 'IQ': 67, 'min': 40, 'max': 40},
// 	{ 'IQ': 68, 'min': 41, 'max': 41},
// 	{ 'IQ': 69, 'min': 42, 'max': 42},
// 	{ 'IQ': 70, 'min': 43, 'max': 43},
// 	{ 'IQ': 71, 'min': 44, 'max': 44},
// 	{ 'IQ': 72, 'min': 45, 'max': 45},
// 	{ 'IQ': 73, 'min': 46, 'max': 46},
// 	{ 'IQ': 74, 'min': 47, 'max': 47},
// 	{ 'IQ': 76, 'min': 48, 'max': 48},
// 	{ 'IQ': 77, 'min': 49, 'max': 49},
// 	{ 'IQ': 78, 'min': 50, 'max': 50},
// 	{ 'IQ': 79, 'min': 51, 'max': 51},
// 	{ 'IQ': 80, 'min': 52, 'max': 52},
// 	{ 'IQ': 81, 'min': 53, 'max': 53},
// 	{ 'IQ': 82, 'min': 54, 'max': 54},
// 	{ 'IQ': 83, 'min': 55, 'max': 55},
// 	{ 'IQ': 85, 'min': 56, 'max': 56},
// 	{ 'IQ': 86, 'min': 57, 'max': 57},
// 	{ 'IQ': 87, 'min': 58, 'max': 58},
// 	{ 'IQ': 88, 'min': 59, 'max': 59},
// 	{ 'IQ': 89, 'min': 60, 'max': 60},
// 	{ 'IQ': 90, 'min': 61, 'max': 61},
// 	{ 'IQ': 91, 'min': 62, 'max': 62},
// 	{ 'IQ': 92, 'min': 63, 'max': 63},
// 	{ 'IQ': 93, 'min': 64, 'max': 64},
// 	{ 'IQ': 95, 'min': 65, 'max': 65},
// 	{ 'IQ': 96, 'min': 66, 'max': 66},
// 	{ 'IQ': 97, 'min': 67, 'max': 67},
// 	{ 'IQ': 98, 'min': 68, 'max': 68},
// 	{ 'IQ': 99, 'min': 69, 'max': 69},
// 	{ 'IQ': 100, 'min': 70, 'max': 70},
// 	{ 'IQ': 101, 'min': 71, 'max': 71},
// 	{ 'IQ': 102, 'min': 72, 'max': 72},
// 	{ 'IQ': 103, 'min': 73, 'max': 73},
// 	{ 'IQ': 105, 'min': 74, 'max': 74},
// 	{ 'IQ': 106, 'min': 75, 'max': 75},
// 	{ 'IQ': 107, 'min': 76, 'max': 76},
// 	{ 'IQ': 108, 'min': 77, 'max': 77},
// 	{ 'IQ': 109, 'min': 78, 'max': 78},
// 	{ 'IQ': 110, 'min': 79, 'max': 79},
// 	{ 'IQ': 111, 'min': 80, 'max': 80},
// 	{ 'IQ': 112, 'min': 81, 'max': 81},
// 	{ 'IQ': 113, 'min': 82, 'max': 82},
// 	{ 'IQ': 115, 'min': 83, 'max': 83},
// 	{ 'IQ': 116, 'min': 84, 'max': 84},
// 	{ 'IQ': 117, 'min': 85, 'max': 85},
// 	{ 'IQ': 118, 'min': 86, 'max': 86},
// 	{ 'IQ': 119, 'min': 87, 'max': 87},
// 	{ 'IQ': 120, 'min': 88, 'max': 88},
// 	{ 'IQ': 121, 'min': 89, 'max': 89},
// 	{ 'IQ': 122, 'min': 90, 'max': 90},
// 	{ 'IQ': 123, 'min': 91, 'max': 91},
// 	{ 'IQ': 125, 'min': 92, 'max': 92},
// 	{ 'IQ': 126, 'min': 93, 'max': 93},
// 	{ 'IQ': 127, 'min': 94, 'max': 94},
// 	{ 'IQ': 128, 'min': 95, 'max': 95},
// 	{ 'IQ': 129, 'min': 96, 'max': 96},
// 	{ 'IQ': 130, 'min': 97, 'max': 97},
// 	{ 'IQ': 131, 'min': 98, 'max': 98},
// 	{ 'IQ': 132, 'min': 99, 'max': 99},
// 	{ 'IQ': 133, 'min': 100, 'max': 100},
// 	{ 'IQ': 135, 'min': 101, 'max': 101},
// 	{ 'IQ': 136, 'min': 102, 'max': 102},
// 	{ 'IQ': 137, 'min': 103, 'max': 103},
// 	{ 'IQ': 138, 'min': 104, 'max': 104},
// 	{ 'IQ': 139, 'min': 105, 'max': 105},
// 	{ 'IQ': 140, 'min': 106, 'max': 106},
// 	{ 'IQ': 141, 'min': 107, 'max': 107},
// 	{ 'IQ': 142, 'min': 108, 'max': 108},
// 	{ 'IQ': 143, 'min': 109, 'max': 109},
// 	{ 'IQ': 145, 'min': 110, 'max': 110},
// 	{ 'IQ': 146, 'min': 111, 'max': 111},
// 	{ 'IQ': 147, 'min': 112, 'max': 112},
// 	{ 'IQ': 148, 'min': 113, 'max': 113},
// 	{ 'IQ': 149, 'min': 114, 'max': 114},
// 	{ 'IQ': 150, 'min': 115, 'max': 115},
// 	{ 'IQ': 151, 'min': 116, 'max': 116},
// 	{ 'IQ': 152, 'min': 117, 'max': 117},
// 	{ 'IQ': 153, 'min': 118, 'max': 118},
// 	{ 'IQ': 155, 'min': 119, 'max': 119},
// 	{ 'IQ': 156, 'min': 120, 'max': 120},
// 	{ 'IQ': 157, 'min': 121, 'max': 121},
// 	{ 'IQ': 158, 'min': 122, 'max': 122},
// 	{ 'IQ': 159, 'min': 123, 'max': 123},
// 	{ 'IQ': 160, 'min': 124, 'max': 124},
// 	{ 'IQ': 161, 'min': 125, 'max': 125},
// 	{ 'IQ': 162, 'min': 126, 'max': 126},
// 	{ 'IQ': 163, 'min': 127, 'max': 127},
// 	{ 'IQ': 165, 'min': 128, 'max': 128},
// 	{ 'IQ': 166, 'min': 129, 'max': 129},
// 	{ 'IQ': 167, 'min': 130, 'max': 130},
// 	{ 'IQ': 168, 'min': 131, 'max': 131},
// 	{ 'IQ': 169, 'min': 132, 'max': 132},
// 	{ 'IQ': 170, 'min': 133, 'max': 133},
// ];


