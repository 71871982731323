import { ref } from 'vue';

// firebase imports
import { auth } from '../firebase/config';
import { createUserWithEmailAndPassword, updateProfile  } from 'firebase/auth';

const error = ref(null);
const isPending = ref(false);

const signup = async (email, password, firstName, lastName) => {
   error.value = null;
   isPending.value = true;

   try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (!userCredential) {
         throw new Error('Could not complete signup');
      } else {
         const user = userCredential.user;
         // Aktualizacja profilu użytkownika
         await updateProfile(user, {
            displayName: `${firstName} ${lastName}`
         });
      }

      error.value = null;
      isPending.value = false;
   } catch (err) {
      console.log(err.message);
      error.value = err.message;
      isPending.value = false;
   }
};

const useSignup = () => {
   return { error, isPending, signup };
};

export default useSignup;
