<template>
<div class="flex flex-col justify-center items-center col-auto bg-orange-100 py-5 px-5  mt-0 mb-7  w-full mx-auto rounded-2xl border-orange-600 border-2 text-center">    
  <!-- <div class="flex flex-col justify-center items-center col-auto py-0 px-5  mt-0 mb-7  w-full mx-auto rounded-2xl text-center">     -->

  <h2 class="text-xl font-medium pb-2">Uważasz ze dlaPsychologa to przydatne narzędzie?</h2>
    <p class="text-sm ">Wesprzyj nas wirtualna ☕ kawą.
      Wasze wsparcie jest dla nas niezwykle ważne!<br>
      Każda wirtualna kawa to nie tylko wsparcie finansowe, ale również ogromna motywacja do dalszej pracy nad rozwijaniem projektu.
    </p>


 <button type="button" @click="gotourl('https://buycoffee.to/dlapsychologa')" class="inline-flex items-center justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 mt-4 text-sm font-medium text-white shadow-sm hover:bg-orange-600  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">☕  dołączam do wsparcia</button>



  </div>
</template>















<script setup>

const gotourl = (url) => {
  window.open(url, '_blank');
  console.log(url);
}

</script>