<template>
  <div>
    <div class="flex items-center justify-between">
      <h2 class="text-sm font-medium text-gray-900">Wybierz płeć</h2>
      <!-- <a href="#" class="text-sm font-medium text-indigo-600 hover:text-indigo-500">See performance specs</a> -->
    </div>

    <RadioGroup v-model="plec" class="mt-2">
      <!-- <RadioGroupLabel class="sr-only"> Wybierz płeć </RadioGroupLabel> -->
      <div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
        <RadioGroupOption as="template" v-for="option in plecOptions" :key="option.name" :value="option.isMale" :disabled="!option.inStock" v-slot="{ active, checked }">
          <div :class="[option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-green-dla-400' : '', checked ? 'bg-green-dla-400 border-transparent text-white hover:bg-green-dla-500' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
            <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>


<script setup>
import { ref, defineEmits, watch, onMounted } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: null,
  },
})


const plecOptions = [
  { name: '👨 M', inStock: true, isMale: true },
  { name: '👩 K', inStock: true, isMale: false },
]


let plec = ref(null)

onMounted(() => {
  plec.value = props.modelValue
  // console.log("Mounted with value: ", props.modelValue) // logujemy wartość modelValue po zamontowaniu
})



const emit = defineEmits(['update:modelValue'])

watch(plec, (newVal) => {
  if (newVal !== null) {
    // console.log("Emitted value: ", newVal) // logujemy wartość, którą emitujemy
    emit('update:modelValue', newVal)
  }
})
</script>








<!-- <script setup>
import { ref, defineEmits, watch } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const plecOptions = [
  { name: '👨 M', inStock: true, isMale: true },
  { name: '👩 K', inStock: true, isMale: false },
]

const plec = ref()

// Zdefiniuj, jakie zdarzenia mogą być emitowane przez ten komponent
const emit = defineEmits(['update:selectedSex'])

// Obserwuj zmiany w `plec` i emituj zdarzenie, gdy się zmieni
watch(plec, () => {
  emit('update:selectedSex', plec.value.isMale)
})

</script> -->
