export function odmienMiesiace(liczba) {
    if (liczba === 1) {
       return 'miesiąc';
    } else if ([2, 3, 4].includes(liczba % 10) && ![12, 13, 14].includes(liczba % 100)) {
       return 'miesiące';
    } else {
       return 'miesięcy';
    }
    }

export  function odmienDni(liczba) {
    if (liczba === 1) {
       return 'dzień';
    } else {
       return 'dni';
    }
    }
