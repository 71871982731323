import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Count from '../views/Count.vue';

import ChooseTest from '../views/ChooseTest.vue';
import Login from '../views/Login.vue';
import Signup from '../views/Signup.vue';
import Invitation from '../views/Invitation.vue';
import ThankYou from '../views/ThankYou.vue';
import ForgotPassword from '../views/ForgotPassword.vue';

import Ids2ogolny from '../tests/Ids2-ogolny.vue';
import Ids2przesiewowy from '../tests/Ids2-przesiewowy.vue';
import Ids2pelny from '../tests/Ids2-pelny.vue';
import Ids2kompetencje from '../tests/Ids2-kompetencje.vue';

import Ids2pelnySurowy from '../tests/Ids2-surowy-pelny.vue';
import Ids2ogolnySurowy from '../tests/Ids2-surowy-ogolny.vue';
import Ids2przesiewowySurowy from '../tests/Ids2-surowy-przesiewowy.vue';

import IdsPogolnySurowy from '../tests/IdsP-surowy-ogolny.vue';
import WiscVsurowy from '../tests/Wisc-v-surowy.vue';

// import exportData from '../tests/export.vue';




// firebase imports
// import { auth } from '../firebase/config';

import useUserState from '@/composables/useUserState';


const requireAuth = (to, from, next) => {
   // console.log("Inside requireAuth");
   const { user } = useUserState();

   if (!user.value) {
      // console.log("requireAuth - no user, redirecting to Login");
      next({ name: 'Login' });
   } else {
      // console.log("requireAuth - user found, continuing");
      next();
   }
};


// const requireNoAuth = (to, from, next) => {
//    console.log("Inside requireNoAuth");
//    const { user } = useUserState();

//    if (user.value) {
//        console.log("requireNoAuth - user found, redirecting to Dashboard");
//        next({ name: 'Dashboard' });
//    } else {
//        console.log("requireNoAuth - no user, continuing");
//        next();
//    }
// };

const requireNoAuth = (to, from, next) => {
   // console.log("Inside requireNoAuth");
   const { user } = useUserState();
 
   if (user.value && (to.name === 'Login' || to.name === 'Signup')) {
       console.log("requireNoAuth - user found, redirecting to Dashboard");
       next({ name: 'Dashboard' });
   } else {
      //  console.log("requireNoAuth - no user, continuing");
       next();
   }
 };
 



const routes = [
   {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      beforeEnter: requireNoAuth,
   },

   {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: requireNoAuth,
   },
   {
      path: '/signup',
      name: 'Signup',
      component: Signup,
      beforeEnter: requireNoAuth,
   },
   {
      path: '/invitation',
      name: 'Invitation',
      component: Invitation,
      beforeEnter: requireNoAuth,
   },
   {
      path: '/thankyou',
      name: 'ThankYou',
      component: ThankYou,
      beforeEnter: requireNoAuth,
   },

   {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: requireAuth,
   },

   {
      path: '/wybierz-test',
      name: 'ChooseTest',
      component: ChooseTest,
      beforeEnter: requireAuth,
   },
   


   // tests
   // IDS-2
   {
      path: '/ids-2/:id',
      name: 'IDS-2 pełny',
      component: Ids2pelny,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-ogolny/:id',
      name: 'IDS-2 ogólny',
      component: Ids2ogolny,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-przesiewowy/:id',
      name: 'IDS-2 przesiewowy',
      component: Ids2przesiewowy,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-kompetencje/:id',
      name: 'IDS-2 kompetencje',
      component: Ids2kompetencje,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-surowy/:id',
      name: 'IDS-2 pełny surowy',
      component: Ids2pelnySurowy,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-ogolny-surowy/:id',
      name: 'IDS-2 ogólny surowy',
      component: Ids2ogolnySurowy,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-2-przesiewowy-surowy/:id',
      name: 'IDS-2 przesiewowy surowy',
      component: Ids2przesiewowySurowy,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/ids-p-ogolny-surowy/:id',
      name: 'IDS-P ogólny surowy',
      component: IdsPogolnySurowy,
      beforeEnter: requireAuth,
      props: true,
   },
   {
      path: '/wisc-v-surowy/:id',
      name: 'WISC-V surowy',
      component: WiscVsurowy,
      beforeEnter: requireAuth,
      props: true,
   },
   
   
   {
      path: '/count',
      name: 'Count',
      component: Count,
      beforeEnter: requireAuth,
   },
   // {
   //    path: '/export/',
   //    name: 'exportData',
   //    component: exportData,
   //    beforeEnter: requireAuth,
   //    props: true,
   // },
];

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
 
    
});

export default router;
