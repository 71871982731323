const ids2data = {
  testType: "IDS",
  sections: {
    1: {
      id: 1,
      group: "Umiejętności psychomotoryczne",
      title: "Motoryka",
      description: "Motoryka duża, sprawność ruchowa całego ciała.",
      options: {
        1: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest bardzo słabo rozwinięta.",
          "Motoryka duża odnosząca się do ruchomości całego ciała jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą, jest bardzo słabo rozwinięta.",
        ],
        2: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest bardzo słabo rozwinięta.",
          "Motoryka duża odnosząca się do ruchomości całego ciała jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą, jest bardzo słabo rozwinięta.",
        ],
        3: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest bardzo słabo rozwinięta.",
          "Motoryka duża odnosząca się do ruchomości całego ciała jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą, jest bardzo słabo rozwinięta.",
        ],

        4: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest niska.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą jest niska.",
          "Sprawność ruchowa ciała, określana jako motoryka duża, jest słabo rozwinięta.",
        ],
        5: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest niska.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą jest niska.",
          "Sprawność ruchowa ciała, określana jako motoryka duża, jest słabo rozwinięta.",
        ],
        6: [
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest niska.",
          "Sprawność ruchowa całego ciała, nazywana motoryką dużą jest niska.",
          "Sprawność ruchowa ciała, określana jako motoryka duża, jest słabo rozwinięta.",
        ],

        7: [
          "Sprawność ruchowa całego ciała jest obniżona.",
          "Motoryka duża, czyli sprawność ruchowa całego ciała jest obniżona.",
          "Obniżona jest motoryka duża, dotycząca sprawności ruchowej całego ciała.",
        ],
        8: [
         "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
         "Dobrze rozwinięta jest motoryka duża, czyli sprawność ruchowa całego ciała.",
         "Poziom sprawności ruchowej całego ciała jest adekwatny do wieku.",
       ],

        9: [
          "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
          "Dobrze rozwinięta jest motoryka duża, czyli sprawność ruchowa całego ciała.",
          "Poziom sprawności ruchowej całego ciała jest adekwatny do wieku.",
        ],
        10: [
          "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
          "Dobrze rozwinięta jest motoryka duża, czyli sprawność ruchowa całego ciała.",
          "Poziom sprawności ruchowej całego ciała jest adekwatny do wieku.",
        ],
        11: [
          "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
          "Dobrze rozwinięta jest motoryka duża, czyli sprawność ruchowa całego ciała.",
          "Poziom sprawności ruchowej całego ciała jest adekwatny do wieku.",
        ],
        12: [
          "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
          "Dobrze rozwinięta jest motoryka duża, czyli sprawność ruchowa całego ciała.",
          "Poziom sprawności ruchowej całego ciała jest adekwatny do wieku.",
        ],

        13: [
          "Dziecko jest sprawne fizycznie, bez problemu chodzi wzdłuż liny, przeskakuje bokiem przez linę,  łapie i rzuca piłkę — rzuty oburącz są skoordynowane.",
          "Dziecko jest sprawne motorycznie, bez problemów chodzi wzdłuż liny, przeskakuje przez nią bokiem, rzuca i łapie piłkę, a rzuty oburącz są dobrze skoordynowane.",
          "Dziecko nie ma problemów z koordynacją ruchową – swobodnie chodzi wzdłuż liny, przeskakuje przez nią bokiem, a także łapie i rzuca piłkę, przy czym rzuty oburącz są skoordynowane.",
        ],
        14: [
          "Dziecko jest sprawne fizycznie, bez problemu chodzi wzdłuż liny, przeskakuje bokiem przez linę,  łapie i rzuca piłkę — rzuty oburącz są skoordynowane.",
          "Dziecko jest sprawne motorycznie, bez problemów chodzi wzdłuż liny, przeskakuje przez nią bokiem, rzuca i łapie piłkę, a rzuty oburącz są dobrze skoordynowane.",
          "Dziecko nie ma problemów z koordynacją ruchową – swobodnie chodzi wzdłuż liny, przeskakuje przez nią bokiem, a także łapie i rzuca piłkę, przy czym rzuty oburącz są skoordynowane.",
        ],

        15: [
          "Dziecko jest bardzo sprawne fizycznie, bez problemu chodzi wzdłuż liny, z otwartymi, jak i z zamkniętymi oczami, przeskakuje bokiem przez linę, łapie i rzuca piłkę oburącz, jego rzuty są skoordynowane.",
          "Dziecko cechuje duża sprawność fizyczna — bez trudu porusza się wzdłuż liny z otwartymi i zamkniętymi oczami, przeskakuje bokiem przez linę, a także łapie i rzuca piłkę obiema rękami, wykonując skoordynowane rzuty.",
          "Dziecko wykazuje dużą sprawność fizyczną: chodzi pewnie wzdłuż liny z otwartymi i zamkniętymi oczami, skacze bokiem przez nią, łapie i rzuca piłkę za pomocą obu rąk, ruchy są skoordynowane.",
        ],
        16: [
          "Dziecko jest bardzo sprawne fizycznie, bez problemu chodzi wzdłuż liny, z otwartymi, jak i z zamkniętymi oczami, przeskakuje bokiem przez linę, łapie i rzuca piłkę oburącz, jego rzuty są skoordynowane.",
          "Dziecko cechuje duża sprawność fizyczna — bez trudu porusza się wzdłuż liny z otwartymi i zamkniętymi oczami, przeskakuje bokiem przez linę, a także łapie i rzuca piłkę obiema rękami, wykonując skoordynowane rzuty.",
          "Dziecko wykazuje dużą sprawność fizyczną: chodzi pewnie wzdłuż liny z otwartymi i zamkniętymi oczami, skacze bokiem przez nią, łapie i rzuca piłkę za pomocą obu rąk, ruchy są skoordynowane.",
        ],

        17: [
          "Dziecko jest bardzo sprawne fizycznie, bez problemu chodzi wzdłuż liny, z otwartymi, jak i z zamkniętymi oczami, przeskakuje bokiem przez linę, łapie i rzuca piłkę oburącz, jego rzuty są skoordynowane.",
          "Dziecko cechuje się dużą sprawnością fizyczną: bez trudu chodzi wzdłuż liny z oczami zarówno otwartymi, jak i zamkniętymi, przeskakuje bokiem przez linę, a także łapie i rzuca piłkę obiema rękami, wykazując skoordynowane rzuty.",
          "Dziecko wykazuje się wysoką sprawnością fizyczną, chodząc pewnie wzdłuż liny niezależnie od tego, czy ma otwarte, czy zamknięte oczy. Ponadto z łatwością przeskakuje linię bokiem, a umiejętność łapania i rzucania piłki oburącz jest dobrze skoordynowana.",
        ],
        18: [
          "Dziecko jest bardzo sprawne fizycznie, bez problemu chodzi wzdłuż liny, z otwartymi, jak i z zamkniętymi oczami, przeskakuje bokiem przez linę, łapie i rzuca piłkę oburącz, jego rzuty są skoordynowane.",
          "Dziecko cechuje się dużą sprawnością fizyczną: bez trudu chodzi wzdłuż liny z oczami zarówno otwartymi, jak i zamkniętymi, przeskakuje bokiem przez linę, a także łapie i rzuca piłkę obiema rękami, wykazując skoordynowane rzuty.",
          "Dziecko wykazuje się wysoką sprawnością fizyczną, chodząc pewnie wzdłuż liny niezależnie od tego, czy ma otwarte, czy zamknięte oczy. Ponadto z łatwością przeskakuje linię bokiem, a umiejętność łapania i rzucania piłki oburącz jest dobrze skoordynowana.",
        ],
        19: [
          "Dziecko jest bardzo sprawne fizycznie, bez problemu chodzi wzdłuż liny, z otwartymi, jak i z zamkniętymi oczami, przeskakuje bokiem przez linę, łapie i rzuca piłkę oburącz, jego rzuty są skoordynowane.",
          "Dziecko cechuje się dużą sprawnością fizyczną: bez trudu chodzi wzdłuż liny z oczami zarówno otwartymi, jak i zamkniętymi, przeskakuje bokiem przez linę, a także łapie i rzuca piłkę obiema rękami, wykazując skoordynowane rzuty.",
          "Dziecko wykazuje się wysoką sprawnością fizyczną, chodząc pewnie wzdłuż liny niezależnie od tego, czy ma otwarte, czy zamknięte oczy. Ponadto z łatwością przeskakuje linię bokiem, a umiejętność łapania i rzucania piłki oburącz jest dobrze skoordynowana.",
        ],
      },
    },

    2: {
      id: 2,
      group: "Umiejętności psychomotoryczne",
      title: "Manipulacja — jakość",
      description:
        "Manipulacja – inaczej motoryka mała, sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów.",
      options: {
        1: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa rąk podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Umiejętność  wykonywania precyzyjnych i szybkich ruchów rąk, jak w przypadku nawlekania koralików czy odkręcania nakrętek, jest bardzo słabo rozwinięta.",
        ],
        2: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa rąk podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Umiejętność  wykonywania precyzyjnych i szybkich ruchów rąk, jak w przypadku nawlekania koralików czy odkręcania nakrętek, jest bardzo słabo rozwinięta.",
        ],
        3: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Sprawność ruchowa rąk podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest bardzo słabo rozwinięta.",
          "Umiejętność  wykonywania precyzyjnych i szybkich ruchów rąk, jak w przypadku nawlekania koralików czy odkręcania nakrętek, jest bardzo słabo rozwinięta.",
        ],

        4: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie niskim.",
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest znacznie obniżona.",
          "Precyzyjność i szybkość ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcania nakrętek jest słabo rozwinięta.",
        ],
        5: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie niskim.",
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest znacznie obniżona.",
          "Precyzyjność i szybkość ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcania nakrętek jest słabo rozwinięta.",
        ],
        6: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie niskim.",
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest znacznie obniżona.",
          "Precyzyjność i szybkość ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcania nakrętek jest słabo rozwinięta.",
        ],

        7: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek jest obniżona w stosunku do wieku życia.",
          "Sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów, takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, jest obniżona w stosunku do wieku.",
          "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcanie nakrętek kształtuje się na poziomie niższym niż przeciętna dla wieku.",
        ],
        8: [
         "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się adekwatnie do wieku życia.",
         "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików czy odkręcania nakrętek, rozwija się odpowiednio do wieku.",
         "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
       ],

        9: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się adekwatnie do wieku życia.",
          "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików czy odkręcania nakrętek, rozwija się odpowiednio do wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
        ],
        10: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się adekwatnie do wieku życia.",
          "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików czy odkręcania nakrętek, rozwija się odpowiednio do wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
        ],
        11: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się adekwatnie do wieku życia.",
          "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików czy odkręcania nakrętek, rozwija się odpowiednio do wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
        ],
        12: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się adekwatnie do wieku życia.",
          "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików czy odkręcania nakrętek, rozwija się odpowiednio do wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
        ],

        13: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się powyżej norm dla wieku życia.",
          "Motoryka mała, odnosząca się do precyzyjności i szybkości ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcanie nakrętek, rozwija się lepiej niż przeciętnie dla danego wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się powyżej normy dla określonego wieku życia.",
        ],
        14: [
          "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek rozwija się powyżej norm dla wieku życia.",
          "Motoryka mała, odnosząca się do precyzyjności i szybkości ruchów rąk podczas takich czynności jak nawlekanie koralików czy odkręcanie nakrętek, rozwija się lepiej niż przeciętnie dla danego wieku.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek, nazywana motoryką małą, kształtuje się powyżej normy dla określonego wieku życia.",
        ],

        15: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie wysokim.",
          "Sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek, znajduje się na wysokim poziomie.",
          "Manipulacyjna sprawność ruchowa rąk, dotycząca precyzji i szybkości ruchów podczas nawlekania koralików na sznurek czy odkręcania nakrętek ze śrub, jest na wysokim poziomie.",
        ],
        16: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie wysokim.",
          "Sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek, znajduje się na wysokim poziomie.",
          "Manipulacyjna sprawność ruchowa rąk, dotycząca precyzji i szybkości ruchów podczas nawlekania koralików na sznurek czy odkręcania nakrętek ze śrub, jest na wysokim poziomie.",
        ],

        17: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie bardzo wysokim.",
          "Sprawność ruchowa rąk podczas takich czynności jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek osiąga bardzo wysoki poziom.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików czy odkręcanie nakrętek znajduje się na bardzo wysokim poziomie.",
        ],
        18: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie bardzo wysokim.",
          "Sprawność ruchowa rąk podczas takich czynności jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek osiąga bardzo wysoki poziom.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików czy odkręcanie nakrętek znajduje się na bardzo wysokim poziomie.",
        ],
        19: [
          "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek i odkręcania nakrętek ze śrubek znajduje się na poziomie bardzo wysokim.",
          "Sprawność ruchowa rąk podczas takich czynności jak nawlekanie koralików na sznurek czy odkręcanie nakrętek ze śrubek osiąga bardzo wysoki poziom.",
          "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików czy odkręcanie nakrętek znajduje się na bardzo wysokim poziomie.",
        ],
      },
    },

    3: {
      id: 3,
      group: "Umiejętności psychomotoryczne",
      title: "Koordynacja wzrokowo-ruchowa",
      description:
        "Zdolność zsynchronizowania ruchów z tym, co dostrzegamy wzrokiem. Rozumiana jako zdolność do zsynchronizowania ruchów z tym, co dostrzegamy wzrokiem. Współdziałanie funkcji wzrokowych i ruchowych.",
      options: {
        1: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo słabo rozwinięta.",
          "Zintegrowanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, jest bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięta jest koordynacja wzrokowo-ruchowa, polegająca na współdziałaniu funkcji wzrokowych i ruchowych.",
        ],
        2: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo słabo rozwinięta.",
          "Zintegrowanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, jest bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięta jest koordynacja wzrokowo-ruchowa, polegająca na współdziałaniu funkcji wzrokowych i ruchowych.",
        ],
        3: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo słabo rozwinięta.",
          "Zintegrowanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, jest bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięta jest koordynacja wzrokowo-ruchowa, polegająca na współdziałaniu funkcji wzrokowych i ruchowych.",
        ],

        4: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest słabo rozwinięta.",
          "Koordynacja wzrokowo-ruchowa rozwija się na poziomie niskim.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa,  jest słabo rozwinięta.",
        ],
        5: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest słabo rozwinięta.",
          "Koordynacja wzrokowo-ruchowa rozwija się na poziomie niskim.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa,  jest słabo rozwinięta.",
        ],
        6: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest słabo rozwinięta.",
          "Koordynacja wzrokowo-ruchowa rozwija się na poziomie niskim.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa,  jest słabo rozwinięta.",
        ],

        7: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest obniżona w stosunku do wieku życia.",
          "Koordynacja wzrokowo-ruchowa jest obniżona w stosunku do wieku życia.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, znajduje się poniżej przeciętnej dla wieku.",
        ],
        8: [
         "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
         "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
         "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową, jest adekwatna do wieku.",
       ],

        9: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
          "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową, jest adekwatna do wieku.",
        ],
        10: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
          "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową, jest adekwatna do wieku.",
        ],
        11: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
          "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową, jest adekwatna do wieku.",
        ],
        12: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
          "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową, jest adekwatna do wieku.",
        ],

        13: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych rozwija się powyżej norm dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa, czyli synchronizacja funkcji wzrokowych i ruchowych, znajduje się na poziomie wyższym niż przeciętna dla wieku.",
          "Współdziałanie funkcji wzrokowych i ruchowych, inaczej  koordynacja wzrokowo-ruchowa, rozwija się w stopniu wyższym niż przeciętna.",
        ],
        14: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych rozwija się powyżej norm dla wieku życia.",
          "Koordynacja wzrokowo-ruchowa, czyli synchronizacja funkcji wzrokowych i ruchowych, znajduje się na poziomie wyższym niż przeciętna dla wieku.",
          "Współdziałanie funkcji wzrokowych i ruchowych, inaczej  koordynacja wzrokowo-ruchowa, rozwija się w stopniu wyższym niż przeciętna.",
        ],

        15: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo dobrze rozwinięta.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, jest bardzo dobrze rozwinięte.",
          "Koordynacja wzrokowo-ruchowa, czyli zdolność do zsynchronizowania ruchów ciała z tym co dostrzega wzrok, znajduje się na wysokim poziomie.",
        ],
        16: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo dobrze rozwinięta.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa, jest bardzo dobrze rozwinięte.",
          "Koordynacja wzrokowo-ruchowa, czyli zdolność do zsynchronizowania ruchów ciała z tym co dostrzega wzrok, znajduje się na wysokim poziomie.",
        ],

        17: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo dobrze rozwinięta.",
          "Koordynacja wzrokowo-ruchowa, czyli współpraca funkcji wzrokowych i ruchowych, jest doskonale rozwinięta.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa kształtuje się na poziomie bardzo wysokim.",
        ],
        18: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo dobrze rozwinięta.",
          "Koordynacja wzrokowo-ruchowa, czyli współpraca funkcji wzrokowych i ruchowych, jest doskonale rozwinięta.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa kształtuje się na poziomie bardzo wysokim.",
        ],
        19: [
          "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest bardzo dobrze rozwinięta.",
          "Koordynacja wzrokowo-ruchowa, czyli współpraca funkcji wzrokowych i ruchowych, jest doskonale rozwinięta.",
          "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa kształtuje się na poziomie bardzo wysokim.",
        ],
      },
    },

    4: {
      id: 4,
      group: "Kompetencje społeczno-emocjonalne",
      title: "Rozpoznawanie emocji",
      description: "",
      options: {
        1: [
          "{{imię}} nie rozpoznaje i nie nazywa emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} nie jest w stanie rozpoznać i nazwać emocji na podstawie wyrazu twarzy osób zaprezentowanych na fotografiach.",
          "{{imię}} ma trudności z nazwaniem i rozpoznawaniem emocji dzieci na podstawie ich wyrazów twarzy na fotografiach.",
        ],
        2: [
          "{{imię}} nie rozpoznaje i nie nazywa emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} nie jest w stanie rozpoznać i nazwać emocji na podstawie wyrazu twarzy osób zaprezentowanych na fotografiach.",
          "{{imię}} ma trudności z nazwaniem i rozpoznawaniem emocji dzieci na podstawie ich wyrazów twarzy na fotografiach.",
        ],
        3: [
          "{{imię}} nie rozpoznaje i nie nazywa emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} nie jest w stanie rozpoznać i nazwać emocji na podstawie wyrazu twarzy osób zaprezentowanych na fotografiach.",
          "{{imię}} ma trudności z nazwaniem i rozpoznawaniem emocji dzieci na podstawie ich wyrazów twarzy na fotografiach.",
        ],
        4: [
          "{{imię}} ma duże trudności z rozpoznawaniem i nazywaniem emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Rozpoznawanie i nazywanie emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach jest u ucznia/uczennicy znacznie obniżone.",
          "{{imię}} nie radzi sobie z rozpoznawaniem i nazywaniem emocji dzieci przedstawionych na zdjęciach.",
        ],
        5: [
          "{{imię}} ma duże trudności z rozpoznawaniem i nazywaniem emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Rozpoznawanie i nazywanie emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach jest u ucznia/uczennicy znacznie obniżone.",
          "{{imię}} nie radzi sobie z rozpoznawaniem i nazywaniem emocji dzieci przedstawionych na zdjęciach.",
        ],
        6: [
          "{{imię}} ma duże trudności z rozpoznawaniem i nazywaniem emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Rozpoznawanie i nazywanie emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach jest u ucznia/uczennicy znacznie obniżone.",
          "{{imię}} nie radzi sobie z rozpoznawaniem i nazywaniem emocji dzieci przedstawionych na zdjęciach.",
        ],
        7: [
          "{{imię}} ma trudności z rozpoznawaniem i nazywaniem emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Rozpoznawanie i nazywanie emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach jest u ucznia/uczennicy obniżone.",
          "Na podstawie zdjęć twarzy dzieci, {{imię}} ma trudności z identyfikacją i nazywaniem ich emocji.",
        ],
        8: [
         "{{imię}} dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
         "{{imię}} skutecznie rozpoznaje i nazywa emocje dzieci patrząc na ich twarze na zdjęciach.",
         "Na podstawie wyrazu twarzy dzieci na fotografiach, {{imię}} prawidłowo nazywa ich emocje.",
       ],
        9: [
          "{{imię}} dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} skutecznie rozpoznaje i nazywa emocje dzieci patrząc na ich twarze na zdjęciach.",
          "Na podstawie wyrazu twarzy dzieci na fotografiach, {{imię}} prawidłowo nazywa ich emocje.",
        ],
        10: [
          "{{imię}} dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} skutecznie rozpoznaje i nazywa emocje dzieci patrząc na ich twarze na zdjęciach.",
          "Na podstawie wyrazu twarzy dzieci na fotografiach, {{imię}} prawidłowo nazywa ich emocje.",
        ],

        11: [
          "{{imię}} dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} skutecznie rozpoznaje i nazywa emocje dzieci patrząc na ich twarze na zdjęciach.",
          "Na podstawie wyrazu twarzy dzieci na fotografiach, {{imię}} prawidłowo nazywa ich emocje.",
        ],
        12: [
          "{{imię}} dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "{{imię}} skutecznie rozpoznaje i nazywa emocje dzieci patrząc na ich twarze na zdjęciach.",
          "Na podstawie wyrazu twarzy dzieci na fotografiach, {{imię}} prawidłowo nazywa ich emocje.",
        ],
        13: [
          "{{imię}} rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach na poziomie wyższym niż większość rówieśników.",
          "Na podstawie wyrazów twarzy dzieci na fotografiach, {{imię}} potrafi nazywać emocje lepiej niż większość rówieśników.",
          "{{imię}} wykazuje się wyższą umiejętnością rozpoznawania emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach w porównaniu do większości swoich rówieśników.",
        ],
        14: [
          "{{imię}} rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach na poziomie wyższym niż większość rówieśników.",
          "Na podstawie wyrazów twarzy dzieci na fotografiach, {{imię}} potrafi nazywać emocje lepiej niż większość rówieśników.",
          "{{imię}} wykazuje się wyższą umiejętnością rozpoznawania emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach w porównaniu do większości swoich rówieśników.",
        ],
        15: [
          "{{imię}} bardzo dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Na podstawie zdjęć z wyrazami twarzy dzieci, {{imię}} sprawnie identyfikuje i nazywa ich emocje.",
          "{{imię}} potrafi precyzyjnie określić i nazwać emocje dzieci obserwując ich twarze na fotografiach.",
        ],
        16: [
          "{{imię}} bardzo dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Na podstawie zdjęć z wyrazami twarzy dzieci, {{imię}} sprawnie identyfikuje i nazywa ich emocje.",
          "{{imię}} potrafi precyzyjnie określić i nazwać emocje dzieci obserwując ich twarze na fotografiach.",
        ],
        17: [
          "{{imię}} bardzo dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Na podstawie zdjęć z wyrazami twarzy dzieci, {{imię}} sprawnie identyfikuje i nazywa ich emocje.",
          "{{imię}} potrafi precyzyjnie rozpoznać i nazwać emocje dzieci obserwując ich twarze na fotografiach.",
        ],
        18: [
          "{{imię}} bardzo dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Na podstawie zdjęć z wyrazami twarzy dzieci, {{imię}} sprawnie identyfikuje i nazywa ich emocje.",
          "{{imię}} potrafi precyzyjnie rozpoznać i nazwać emocje dzieci obserwując ich twarze na fotografiach.",
        ],
        19: [
          "{{imię}} bardzo dobrze rozpoznaje i nazywa emocje na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach.",
          "Na podstawie zdjęć z wyrazami twarzy dzieci, {{imię}} sprawnie identyfikuje i nazywa ich emocje.",
          "{{imię}} potrafi precyzyjnie rozpoznać i nazwać emocje dzieci obserwując ich twarze na fotografiach.",
        ],
      },
    },

    5: {
      id: 5,
      group: "Kompetencje społeczno-emocjonalne",
      title: "Regulacja emocji",
      description:
        "Regulacja emocji to oznacza takie kierowanie swoimi doświadczeniami emocjonalnymi, które sprawia, że emocje nam służą: dostarczają ważnych informacji, prowadzą do poczucia pełni życia oraz prawdy o sobie.",
      options: {
        1: [
          "Nie potrafi wskazać adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        2: [
          "Nie potrafi wskazać adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        3: [
          "Nie potrafi wskazać adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        4: [
          "Ma duże trudność ze wskazaniem adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        5: [
          "Ma duże trudność ze wskazaniem adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        6: [
          "Ma duże trudność ze wskazaniem adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Nie potrafi wskazać odpowiednich strategii radzenia sobie z emocjami takimi, jak wściekłość, strach i smutek.",
          "Brakuje mu/jej umiejętności adaptacyjnego radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
        ],
        7: [
          "Ma trudność ze wskazaniem adaptacyjnych sposobów radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Ma problem ze wskazaniem zdrowych sposobów radzenia sobie z emocjami takimi jak: wściekłość, strach i smutek.",
          "Słabiej niż większość jego rówieśników wie, jak poradzić sobie z trudnymi emocjami takimi jak wściekłość, strach i smutek.",
        ],
        8: [
         "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
         "Prawidłowo wymienia adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
         "Zna adaptacyjne metody radzenia sobie z trudnymi uczuciami takimi jak wściekłość, strach i smutek.",
       ],
        9: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Prawidłowo wymienia adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Zna adaptacyjne metody radzenia sobie z trudnymi uczuciami takimi jak wściekłość, strach i smutek.",
        ],
        10: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Prawidłowo wymienia adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Zna adaptacyjne metody radzenia sobie z trudnymi uczuciami takimi jak wściekłość, strach i smutek.",
        ],
        11: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Prawidłowo wymienia adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Zna adaptacyjne metody radzenia sobie z trudnymi uczuciami takimi jak wściekłość, strach i smutek.",
        ],
        12: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Prawidłowo wymienia adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Zna adaptacyjne metody radzenia sobie z trudnymi uczuciami takimi jak wściekłość, strach i smutek.",
        ],
        13: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek na poziomie wyższym niż większość jego rówieśników.",
          "W zakresie radzenia sobie z trudnymi emocjami takimi jak wściekłość, strach i smutek, przejawia umiejętności wyższe niż większość osób w swoim wieku.",
          "W porównaniu z większością rówieśników, lepiej radzi sobie z trudnymi emocjami, takimi jak wściekłość, strach i smutek, dzięki adaptacyjnym strategiom.",
        ],
        14: [
          "Potrafi wskazać adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek na poziomie wyższym niż większość jego rówieśników.",
          "W zakresie radzenia sobie z trudnymi emocjami takimi jak wściekłość, strach i smutek, przejawia umiejętności wyższe niż większość osób w swoim wieku.",
          "W porównaniu z większością rówieśników, lepiej radzi sobie z trudnymi emocjami, takimi jak wściekłość, strach i smutek, dzięki adaptacyjnym strategiom.",
        ],
        15: [
          "Bardzo dobrze wskazuje adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Skutecznie przedstawia adaptacyjne metody radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
          "Na bardzo wysokim poziomie opisuje sposoby adaptacyjnego radzenia sobie z emocjami, takimi jak wściekłość, strach i smutek.",
        ],
        16: [
          "Bardzo dobrze wskazuje adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Skutecznie przedstawia adaptacyjne metody radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
          "Na bardzo wysokim poziomie opisuje sposoby adaptacyjnego radzenia sobie z emocjami, takimi jak wściekłość, strach i smutek.",
        ],
        17: [
          "Bardzo dobrze wskazuje adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Skutecznie przedstawia adaptacyjne metody radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
          "Na poziomie wybitnym opisuje sposoby adaptacyjnego radzenia sobie z emocjami, takimi jak wściekłość, strach i smutek.",
        ],
        18: [
          "Bardzo dobrze wskazuje adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Skutecznie przedstawia adaptacyjne metody radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
          "Na poziomie wybitnym opisuje sposoby adaptacyjnego radzenia sobie z emocjami, takimi jak wściekłość, strach i smutek.",
        ],
        19: [
          "Bardzo dobrze wskazuje adaptacyjne sposoby radzenia sobie z trudnymi emocjami takimi jak: wściekłość, strach i smutek.",
          "Skutecznie przedstawia adaptacyjne metody radzenia sobie z emocjami takimi jak wściekłość, strach i smutek.",
          "Na poziomie wybitnym opisuje sposoby adaptacyjnego radzenia sobie z emocjami, takimi jak wściekłość, strach i smutek.",
        ],
      },
    },

    6: {
      id: 6,
      group: "Kompetencje społeczno-emocjonalne",
      title: "Strategie społeczne",
      description:
        "Umiejętność poruszania się w społeczeństwie w zgodzie z zasadami i temu co służy naszemu dobrostanu psychicznemu.",
      options: {
        1: [
          "Nie potrafi podać najlepszej w danej sytuacji społecznej strategii zachowania.",
          "{{imię}} nie zna najlepszej strategii postępowania w podanych sytuacjach społecznych.",
          "Nie jest w stanie podać najlepszego sposobu postępowania w konkretnej sytuacji społecznej.",
        ],
        2: [
          "Nie potrafi podać najlepszej w danej sytuacji społecznej strategii zachowania.",
          "{{imię}} nie zna najlepszej strategii postępowania w podanych sytuacjach społecznych.",
          "Nie jest w stanie podać najlepszego sposobu postępowania w konkretnej sytuacji społecznej.",
        ],
        3: [
          "Nie potrafi podać najlepszej w danej sytuacji społecznej strategii zachowania.",
          "{{imię}} nie zna najlepszej strategii postępowania w podanych sytuacjach społecznych.",
          "Nie jest w stanie podać najlepszego sposobu postępowania w konkretnej sytuacji społecznej.",
        ],
        4: [
          "Słabo orientuje się w zakresie strategii społecznych.",
          "Nie orientuje się w dziedzinie strategii społecznych.",
          "W zakresie strategii społecznych ma niewielką wiedzę.",
        ],
        5: [
          "Słabo orientuje się w zakresie strategii społecznych.",
          "Nie orientuje się w dziedzinie strategii społecznych.",
          "W zakresie strategii społecznych ma niewielką wiedzę.",
        ],
        6: [
          "Słabo orientuje się w zakresie strategii społecznych.",
          "Nie orientuje się w dziedzinie strategii społecznych.",
          "W zakresie strategii społecznych ma niewielką wiedzę.",
        ],

        7: [
          "Znajomość strategii społecznych jest obniżona w stosunku do wieku.",
          "W zakresie strategii społecznych ma obniżoną wiedzę.",
          "Rozeznanie w dziedzinie strategii społecznych jest obniżone w stosunku do wieku.",
        ],
        8: [
         "Dobrze orientuje się w zakresie strategii społecznych, podaje adekwatne strategie zachowania się w podanych sytuacjach społecznych.",
         "Posiada wiedzę na temat strategii społecznych i wskazuje adekwatne zachowanie w danym kontekście społecznym.",
         "Wykazuje wiedzę na temat strategii społecznych i wskazuje odpowiednie zachowania w konkretnych sytuacjach społecznych.",
       ],

        9: [
          "Dobrze orientuje się w zakresie strategii społecznych, podaje adekwatne strategie zachowania się w podanych sytuacjach społecznych.",
          "Posiada wiedzę na temat strategii społecznych i wskazuje adekwatne zachowanie w danym kontekście społecznym.",
          "Wykazuje wiedzę na temat strategii społecznych i wskazuje odpowiednie zachowania w konkretnych sytuacjach społecznych.",
        ],
        10: [
          "Dobrze orientuje się w zakresie strategii społecznych, podaje adekwatne strategie zachowania się w podanych sytuacjach społecznych.",
          "Posiada wiedzę na temat strategii społecznych i wskazuje adekwatne zachowanie w danym kontekście społecznym.",
          "Wykazuje wiedzę na temat strategii społecznych i wskazuje odpowiednie zachowania w konkretnych sytuacjach społecznych.",
        ],
        11: [
          "Dobrze orientuje się w zakresie strategii społecznych, podaje adekwatne strategie zachowania się w podanych sytuacjach społecznych.",
          "Posiada wiedzę na temat strategii społecznych i wskazuje adekwatne zachowanie w danym kontekście społecznym.",
          "Wykazuje wiedzę na temat strategii społecznych i wskazuje odpowiednie zachowania w konkretnych sytuacjach społecznych.",
        ],
        12: [
          "Dobrze orientuje się w zakresie strategii społecznych, podaje adekwatne strategie zachowania się w podanych sytuacjach społecznych.",
          "Posiada wiedzę na temat strategii społecznych i wskazuje adekwatne zachowanie w danym kontekście społecznym.",
          "Wykazuje wiedzę na temat strategii społecznych i wskazuje odpowiednie zachowania w konkretnych sytuacjach społecznych.",
        ],

        13: [
          "Orientuje się w zakresie strategii społecznych na poziomie wyższym niż większość rówieśników.",
          "Posiada większą wiedzę o strategiach społecznych niż jego rówieśnicy.",
          "W zakresie umiejętności poruszania się w społeczeństwie w zgodzie z zasadami, przewyższa większość rówieśników.",
        ],
        14: [
          "Orientuje się w zakresie strategii społecznych na poziomie wyższym niż większość rówieśników.",
          "Posiada większą wiedzę o strategiach społecznych niż jego rówieśnicy.",
          "W zakresie umiejętności poruszania się w społeczeństwie w zgodzie z zasadami, przewyższa większość rówieśników.",
        ],

        15: [
          "Bardzo dobrze wie, jak poruszać się w społeczeństwie zgodnie z zasadami, podaje najlepsze w danej sytuacji społecznej strategie zachowania.",
          "Doskonale wie, jak funkcjonować w społeczeństwie zgodnie z zasadami i zna odpowiednie strategie zachowania w danym kontekście społecznym.",
          "Bardzo dobrze zna zasady poruszania się w społeczeństwie i potrafi dobrać optymalne strategie zachowania w różnych sytuacjach społecznych.",
        ],
        16: [
          "Bardzo dobrze wie, jak poruszać się w społeczeństwie zgodnie z zasadami, podaje najlepsze w danej sytuacji społecznej strategie zachowania.",
          "Doskonale wie, jak funkcjonować w społeczeństwie zgodnie z zasadami i zna odpowiednie strategie zachowania w danym kontekście społecznym.",
          "Bardzo dobrze zna zasady poruszania się w społeczeństwie i potrafi dobrać optymalne strategie zachowania w różnych sytuacjach społecznych.",
        ],

        17: [
          "Bardzo dobrze wie, jak poruszać się w społeczeństwie zgodnie z zasadami, podaje najlepsze w danej sytuacji społecznej strategie zachowania.",
          "Doskonale wie, jak funkcjonować w społeczeństwie zgodnie z zasadami i zna odpowiednie strategie zachowania w danym kontekście społecznym.",
          "Bardzo dobrze zna zasady poruszania się w społeczeństwie i potrafi dobrać optymalne strategie zachowania w różnych sytuacjach społecznych.",
        ],
        18: [
          "Bardzo dobrze wie, jak poruszać się w społeczeństwie zgodnie z zasadami, podaje najlepsze w danej sytuacji społecznej strategie zachowania.",
          "Doskonale wie, jak funkcjonować w społeczeństwie zgodnie z zasadami i zna odpowiednie strategie zachowania w danym kontekście społecznym.",
          "Bardzo dobrze zna zasady poruszania się w społeczeństwie i potrafi dobrać optymalne strategie zachowania w różnych sytuacjach społecznych.",
        ],
        19: [
          "Bardzo dobrze wie, jak poruszać się w społeczeństwie zgodnie z zasadami, podaje najlepsze w danej sytuacji społecznej strategie zachowania.",
          "Doskonale wie, jak funkcjonować w społeczeństwie zgodnie z zasadami i zna odpowiednie strategie zachowania w danym kontekście społecznym.",
          "Bardzo dobrze zna zasady poruszania się w społeczeństwie i potrafi dobrać optymalne strategie zachowania w różnych sytuacjach społecznych.",
        ],
      },
    },

    7: {
      id: 7,
      group: "Kompetencje szkolne",
      title: "Rozumowanie logiczno-matematyczne",
      description: "Logiczne myślenie i rozwiązywanie zadań matematycznych.",

      options: {
        1: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
        2: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
        3: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],

        4: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięta.",
          "Na niskim poziomie kształtuje się rozumowanie logiczno-matematyczne.",
        ],
        5: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięta.",
          "Na niskim poziomie kształtuje się rozumowanie logiczno-matematyczne.",
        ],
        6: [
          "Rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięte.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest słabo rozwinięta.",
          "Na niskim poziomie kształtuje się rozumowanie logiczno-matematyczne.",
        ],

        7: [
          "Rozumowanie logiczno-matematyczne rozwija się na poziomie niższym niż przeciętny.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych jest obniżone w stosunku do wieku.",
          "Na poziomie niższym niż przeciętna dla wieku kształtuje się rozumowanie logiczno-matematyczne.",
        ],
        8: [
         "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
         "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie przeciętnym.",
         "Na poziomie przeciętnym kształtuje się rozumowanie arytmetyczne logiczno-matematyczne.",
       ],

        9: [
          "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie przeciętnym.",
          "Na poziomie przeciętnym kształtuje się rozumowanie arytmetyczne logiczno-matematyczne.",
        ],
        10: [
          "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie przeciętnym.",
          "Na poziomie przeciętnym kształtuje się rozumowanie arytmetyczne logiczno-matematyczne.",
        ],
        11: [
          "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie przeciętnym.",
          "Na poziomie przeciętnym kształtuje się rozumowanie arytmetyczne logiczno-matematyczne.",
        ],
        12: [
          "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie przeciętnym.",
          "Na poziomie przeciętnym kształtuje się rozumowanie arytmetyczne logiczno-matematyczne.",
        ],

        13: [
          "Rozumowanie logiczno-matematyczne rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Powyżej przeciętnej kształtuje się rozumowanie logiczno-matematyczne.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie wyższym niż przeciętny.",
        ],
        14: [
          "Rozumowanie logiczno-matematyczne rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Powyżej przeciętnej kształtuje się rozumowanie logiczno-matematyczne.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na poziomie wyższym niż przeciętny.",
        ],

        15: [
          "Rozumowanie logiczno-matematyczne rozwija się na bardzo wysokim poziomie.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na bardzo wysokim poziomie.",
          "Bardzo dobrze rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
        16: [
          "Rozumowanie logiczno-matematyczne rozwija się na bardzo wysokim poziomie.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na bardzo wysokim poziomie.",
          "Bardzo dobrze rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],

        17: [
          "Rozumowanie logiczno-matematyczne rozwija się na wybitnym poziomie.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na wybitnym poziomie.",
          "Wybitnie rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
        18: [
          "Rozumowanie logiczno-matematyczne rozwija się na wybitnym poziomie.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na wybitnym poziomie.",
          "Wybitnie rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
        19: [
          "Rozumowanie logiczno-matematyczne rozwija się na wybitnym poziomie.",
          "Umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych znajduje się na wybitnym poziomie.",
          "Wybitnie rozwinięte jest rozumowanie logiczno-matematyczne.",
        ],
      },
    },

    8: {
      id: 8,
      group: "Kompetencje szkolne",
      title: "Mowa bierna",
      description:
        "Zasób słów, które dziecko rozumie, umiejętność rozumienia mowy innych ludzi/ czyli zdolność rozumienia mowy.",

      options: {
        1: [
          "Bardzo słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na bardzo niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest bardzo słabo rozwinięta.",
        ],
        2: [
          "Bardzo słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na bardzo niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest bardzo słabo rozwinięta.",
        ],
        3: [
          "Bardzo słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na bardzo niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest bardzo słabo rozwinięta.",
        ],

        4: [
          "Słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest słabo rozwinięta.",
        ],
        5: [
          "Słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest słabo rozwinięta.",
        ],
        6: [
          "Słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na niskim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest słabo rozwinięta.",
        ],

        7: [
          "Zdolność rozumienia mowy, czyli mowa bierna jest obniżona w stosunku do wieku.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie niższym niż przeciętny.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest obniżona w stosunku do wieku.",
        ],
        8: [
         "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
         "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
         "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
       ],

        9: [
          "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
        ],
        10: [
          "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
        ],
        11: [
          "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
        ],
        12: [
          "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
          "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
          "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
        ],

        13: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Umiejętność rozumienia mowy, czyli mowa bierna, kształtuje się wyżej niż przeciętna dla wieku.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na poziomie wyższym niż przeciętny.",
        ],
        14: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Umiejętność rozumienia mowy, czyli mowa bierna, kształtuje się wyżej niż przeciętna dla wieku.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na poziomie wyższym niż przeciętny.",
        ],

        15: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na wysokim poziomie.",
          "Umiejętność rozumienia mowy, czyli mowa bierna, jest na wysokim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na wysokim poziomie.",
        ],
        16: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na wysokim poziomie.",
          "Umiejętność rozumienia mowy, czyli mowa bierna, jest na wysokim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na wysokim poziomie.",
        ],

        17: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na bardzo wysokim poziomie.",
          "Rozwój zdolności do rozumienia mowy, czyli mowa bierna, jest na bardzo wysokim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na bardzo wysokim poziomie.",
        ],
        18: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na bardzo wysokim poziomie.",
          "Rozwój zdolności do rozumienia mowy, czyli mowa bierna, jest na bardzo wysokim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na bardzo wysokim poziomie.",
        ],
        19: [
          "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na bardzo wysokim poziomie.",
          "Rozwój zdolności do rozumienia mowy, czyli mowa bierna, jest na bardzo wysokim poziomie.",
          "Mowa bierna, czyli zdolność rozumienia mowy rozwija się na bardzo wysokim poziomie.",
        ],
      },
    },
    9: {
      id: 9,
      group: "Kompetencje szkolne",
      title: "Mowa czynna",
      description:
        "Zasób słów, które dziecko rozumie i używa w swoich wypowiedziach. Zasób słów i umiejętność konstrukcji wypowiedzi.",

      options: {
        1: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte są umiejętności związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa.",
          "Kompetencje związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa są bardzo słabo rozwinięte.",
        ],
        2: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte są umiejętności związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa.",
          "Kompetencje związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa są bardzo słabo rozwinięte.",
        ],
        3: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest bardzo słabo rozwinięta.",
          "Bardzo słabo rozwinięte są umiejętności związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa.",
          "Kompetencje związane z mową czynną, takie jak zdolność do konstruowania wypowiedzi i zasób słownictwa są bardzo słabo rozwinięte.",
        ],

        4: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie niskim.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest słabo rozwinięta.",
          "Na niskim poziomie rozwija się umiejętność konstrukcji wypowiedzi i zasób słów w mowie czynnej.",
        ],
        5: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie niskim.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest słabo rozwinięta.",
          "Na niskim poziomie rozwija się umiejętność konstrukcji wypowiedzi i zasób słów w mowie czynnej.",
        ],
        6: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie niskim.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest słabo rozwinięta.",
          "Na niskim poziomie rozwija się umiejętność konstrukcji wypowiedzi i zasób słów w mowie czynnej.",
        ],

        7: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie niższym niż przeciętny.",
          "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się  na poziomie niższym niż średnia dla wieku.",
          "Mowa czynna, zasób słów i zdolność budowania wypowiedzi rozwijają się poniżej przeciętnej.",
        ],
        8: [
         "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie przeciętnym.",
         "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest dobrze rozwinięta.",
         "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
       ],

        9: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie przeciętnym.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest dobrze rozwinięta.",
          "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
        ],
        10: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie przeciętnym.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest dobrze rozwinięta.",
          "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
        ],
        11: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie przeciętnym.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest dobrze rozwinięta.",
          "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
        ],
        12: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie przeciętnym.",
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest dobrze rozwinięta.",
          "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
        ],

        13: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Mowa czynna, czyli rozwój zdolności konstrukcji wypowiedzi i słownictwa jest wyższa niż średnia dla wieku życia.",
          "Mowa czynna, czyli umiejętność formułowania wypowiedzi i znajomość słów kształtuje się wyżej niż przeciętna dla wieku.",
        ],
        14: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie wyższym niż przeciętny dla wieku.",
          "Mowa czynna, czyli rozwój zdolności konstrukcji wypowiedzi i słownictwa jest wyższa niż średnia dla wieku życia.",
          "Mowa czynna, czyli umiejętność formułowania wypowiedzi i znajomość słów kształtuje się wyżej niż przeciętna dla wieku.",
        ],

        15: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie wysokim.",
          "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na wysokim poziomie.",
          "Na poziomie wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
        ],
        16: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie wysokim.",
          "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na wysokim poziomie.",
          "Na poziomie wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
        ],

        17: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie bardzo wysokim.",
          "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na bardzo wysokim poziomie.",
          "Na poziomie bardzo wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
        ],
        18: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie bardzo wysokim.",
          "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na bardzo wysokim poziomie.",
          "Na poziomie bardzo wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
        ],
        19: [
          "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie bardzo wysokim.",
          "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na bardzo wysokim poziomie.",
          "Na poziomie bardzo wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
        ],
      },
    },
    10: {
      id: 10,
      group: "Kompetencje szkolne",
      title: "Funkcje słuchowo-językowe (dzieci 5-9 lat)",
      description:
        "Zasób słów, które dziecko rozumie i używa w swoich wypowiedziach. Zasób słów i umiejętność konstrukcji wypowiedzi.",

      options: {
        1: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięte są funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania.",
          "Funkcje słuchowo-językowe, które są istotne dla procesu nauki czytania i pisania znajdują się na poziomie niskim.",
        ],
        2: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięte są funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania.",
          "Funkcje słuchowo-językowe, które są istotne dla procesu nauki czytania i pisania znajdują się na poziomie niskim.",
        ],
        3: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo słabo rozwinięte.",
          "Bardzo słabo rozwinięte są funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania.",
          "Funkcje słuchowo-językowe, które są istotne dla procesu nauki czytania i pisania znajdują się na poziomie niskim.",
        ],

        4: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są słabo rozwinięte.",
          "Funkcje słuchowo-językowe istotne w procesie nauki pisania i czytania kształtują się na niskim poziomie.",
          "Słabo rozwinięte są funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],
        5: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są słabo rozwinięte.",
          "Funkcje słuchowo-językowe istotne w procesie nauki pisania i czytania kształtują się na niskim poziomie.",
          "Słabo rozwinięte są funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],
        6: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są słabo rozwinięte.",
          "Funkcje słuchowo-językowe istotne w procesie nauki pisania i czytania kształtują się na niskim poziomie.",
          "Słabo rozwinięte są funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],

        7: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na obniżonym poziomie.",
          "Funkcje słuchowo-językowe istotne w procesie nauki pisania i czytania są obniżone w stosunku do wieku życia.",
          "Proces nauki pisania i czytania jest hamowany z uwagi na obniżone funkcje słuchowo-językowe.",
        ],
        8: [
         "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na poziomie przeciętnym.",
         "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się adekwatnie do wieku.",
         "Na przeciętnym poziomie rozwijają się funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
       ],

        9: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na poziomie przeciętnym.",
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się adekwatnie do wieku.",
          "Na przeciętnym poziomie rozwijają się funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],
        10: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na poziomie przeciętnym.",
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się adekwatnie do wieku.",
          "Na przeciętnym poziomie rozwijają się funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],
        11: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na poziomie przeciętnym.",
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się adekwatnie do wieku.",
          "Na przeciętnym poziomie rozwijają się funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],
        12: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się na poziomie przeciętnym.",
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania rozwijają się adekwatnie do wieku.",
          "Na przeciętnym poziomie rozwijają się funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania.",
        ],

        13: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania znajdują się na poziomie wyższym niż przeciętny dla wieku.",
          "Powyżej przeciętnej dla wieku życia znajdują się funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania.",
          "Funkcje słuchowo-językowe znajdują się na poziomie wyższym niż średnia dla danego wieku.",
        ],
        14: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania znajdują się na poziomie wyższym niż przeciętny dla wieku.",
          "Powyżej przeciętnej dla wieku życia znajdują się funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania.",
          "Funkcje słuchowo-językowe znajdują się na poziomie wyższym niż średnia dla danego wieku.",
        ],

        15: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo dobrze rozwinięte. Dziecko dzieli wyrazy na sylaby, potrafi rozpoznać i wskazać rymy w wyrazach. Dobrze radzi sobie z wyodrębnianiem głosek początkowych i końcowych. Potrafi podzielić na głoski podane wyrazy.",
          "Dziecko ma wysoko rozwinięte funkcje słuchowo-językowe. Bez problemu dzieli słowa na sylaby, identyfikuje rymy i umie wskazać głoski początkowe oraz końcowe w wyrazach.",
          "Poziom rozwoju umiejętności słuchowo-językowe jest wysoki. Dziecko potrafi dzielić wyrazy na sylaby, identyfikować i wskazywać rymy, a także skutecznie wyodrębniać głoski początkowe i końcowe.",
        ],
        16: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo dobrze rozwinięte. Dziecko dzieli wyrazy na sylaby, potrafi rozpoznać i wskazać rymy w wyrazach. Dobrze radzi sobie z wyodrębnianiem głosek początkowych i końcowych. Potrafi podzielić na głoski podane wyrazy.",
          "Dziecko ma wysoko rozwinięte funkcje słuchowo-językowe. Bez problemu dzieli słowa na sylaby, identyfikuje rymy i umie wskazać głoski początkowe oraz końcowe w wyrazach.",
          "Poziom rozwoju umiejętności słuchowo-językowe jest wysoki. Dziecko potrafi dzielić wyrazy na sylaby, identyfikować i wskazywać rymy, a także skutecznie wyodrębniać głoski początkowe i końcowe.",
        ],

        17: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo dobrze rozwinięte. Dziecko dzieli wyrazy na sylaby, potrafi rozpoznać i wskazać rymy w wyrazach. Dobrze radzi sobie z wyodrębnianiem głosek początkowych i końcowych. Potrafi podzielić na głoski podane wyrazy.",
          "Dziecko ma bardzo dobrze rozwinięte funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania. Dzieli wyrazy na sylaby, identyfikować rymy i dobrze wyodrębnia głoski początkowe oraz końcowe w słowach.",
          "Poziom rozwoju umiejętności słuchowo-językowe jest bardzo wysoki. Dziecko potrafi dzielić wyrazy na sylaby, identyfikować i wskazywać rymy, a także skutecznie wyodrębniać głoski początkowe i końcowe.",
        ],
        18: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo dobrze rozwinięte. Dziecko dzieli wyrazy na sylaby, potrafi rozpoznać i wskazać rymy w wyrazach. Dobrze radzi sobie z wyodrębnianiem głosek początkowych i końcowych. Potrafi podzielić na głoski podane wyrazy.",
          "Dziecko ma bardzo dobrze rozwinięte funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania. Dzieli wyrazy na sylaby, identyfikować rymy i dobrze wyodrębnia głoski początkowe oraz końcowe w słowach.",
          "Poziom rozwoju umiejętności słuchowo-językowe jest bardzo wysoki. Dziecko potrafi dzielić wyrazy na sylaby, identyfikować i wskazywać rymy, a także skutecznie wyodrębniać głoski początkowe i końcowe.",
        ],
        19: [
          "Funkcje słuchowo-językowe ważne w procesie nauki pisania i czytania są bardzo dobrze rozwinięte. Dziecko dzieli wyrazy na sylaby, potrafi rozpoznać i wskazać rymy w wyrazach. Dobrze radzi sobie z wyodrębnianiem głosek początkowych i końcowych. Potrafi podzielić na głoski podane wyrazy.",
          "Dziecko ma bardzo dobrze rozwinięte funkcje słuchowo-językowe, które są kluczowe dla nauki czytania i pisania. Dzieli wyrazy na sylaby, identyfikować rymy i dobrze wyodrębnia głoski początkowe oraz końcowe w słowach.",
          "Poziom rozwoju umiejętności słuchowo-językowe jest bardzo wysoki. Dziecko potrafi dzielić wyrazy na sylaby, identyfikować i wskazywać rymy, a także skutecznie wyodrębniać głoski początkowe i końcowe.",
        ],
      },
    },
  },
};

export default ids2data;
