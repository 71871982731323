<template>
  
  <h2 class="text-base font-normal text-gray-500">Mam wynik surowy</h2>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-4 mt-2" v-if="psychotestRaw && psychotestRaw.length > 0">
    <div v-for="project in psychotestRaw" :key="project.id" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-5 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <div class="flex-shrink-0">
        <div :class="project.color" class="h-10 w-10 rounded-full"></div>
      </div>
      <div class="min-w-0 flex-1">
        <router-link :to="`/${project.slug}/new`"  class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true" />
          <p class="text-base font-medium text-gray-900">{{ project.testName }}</p>
          <p class="truncate text-sm text-gray-500">{{ project.title  }}</p>
        </router-link>
      </div>
    </div>
  </div>





  <h2 class="text-base mt-10 font-normal text-gray-500">Mam wynik przeliczony</h2>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-4 mt-2" v-if="psychotestPrzeliczony && psychotestPrzeliczony.length > 0" >
    <div v-for="project in psychotestPrzeliczony" :key="project.id" class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-5 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <div class="flex-shrink-0">
        <div :class="project.color" class="h-10 w-10 rounded-full"></div>
      </div>
      <div class="min-w-0 flex-1">
        <router-link :to="`/${project.slug}/new`"  class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true" />
          <p class="text-base font-medium text-gray-900">{{ project.testName }}</p>
          <p class="truncate text-sm text-gray-500">{{ project.title  }}</p>
        </router-link>
      </div>
    </div>
  </div>







</template>



<script setup>
import { defineProps, computed } from 'vue'

// Definiowanie props
const props = defineProps({
  psychotests: Array
})


// Funkcja pomocnicza do filtrowania psychotests
const filterPsychotests = (isRaw) => {
  return props.psychotests 
    ? props.psychotests.filter(project => project.isRaw === isRaw && project.isActive)
    : [];
}

const psychotestPrzeliczony = computed(() => {
  const filtered = filterPsychotests(false);
  return filtered.sort((a, b) => a.order - b.order);
});


const psychotestRaw = computed(() => {
  const filtered = filterPsychotests(true);
  return filtered.sort((a, b) => a.order - b.order);
});
</script>





<style>
  .bg-ids-pelny {
    --tw-bg-opacity: 1;
    background-color: #7D367D
  }
  .bg-ids-ogolny {
    --tw-bg-opacity: 1;
    background-color: #9E459E
  }
  .bg-ids-przesiewowy {
    --tw-bg-opacity: 1;
    background-color: #BA60BA
  }
  .bg-ids-kompetencje {
    --tw-bg-opacity: 1;
    background-color: #63AE35
  }
  .bg-idsp-ogolny{
    --tw-bg-opacity: 1;
    background-color: #002fff
  }
</style>