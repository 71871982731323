
function adjustSentencesForGender(sentences, isFemale) {
   return sentences.map(sentence => {
      return sentence.replace(/\{\{([^|}]+)\|([^}]+)\}\}/g, (match, male, female) => {
            return isFemale ? female : male;
      });
   });
}


function replaceName(sentence, firstName) {
   return sentence.replace(/\{\{Imię\}\}/g, firstName);
}



export const adjustGenderAndName = (text, isFemale, firstName) => {
   // Najpierw dostosuj tekst do płci
   let adjustedText = adjustSentencesForGender([text], isFemale)[0];
   // Następnie zastąp placeholder imieniem
   adjustedText = replaceName(adjustedText, firstName);
   return adjustedText;
 }


