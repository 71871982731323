<template>
  <div v-if="doneDescriptions && numDescriptions > 0">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{doneDescriptions.length}} ostatnich  opisów</h1>
        <p class="mt-2 text-sm text-gray-700">Lista przygotowanych opisów, typ testu, imię, data urodzenia badanego, data utworzenia/edycji opisu.</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button type="button"  @click="handleGo('ChooseTest')" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-dla-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dla-600  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">+ Nowy opis</button>
      </div>
    </div>
    <div class="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Test</th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Imię</th>
            <th scope="col" class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Data urodzenia</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Data edycji</th>
            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span class="sr-only">Zobacz</span>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="description in doneDescriptions" :key="description.editedAt">
            <td class="w-1/2 max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
              {{ description.type }}
              <dl class="font-normal lg:hidden">
                <dt class="sr-only">Test</dt>
                <dd class="mt-1 truncate text-gray-700">{{ description.firstName }}</dd>
                <dt class="sr-only sm:hidden">Imię</dt>
                <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ formatDate(description.dataUrodzenia) }}</dd>
              </dl>
            </td>
            <td class="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{{ description.firstName }}</td>
            <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{{ formatDate(description.dataUrodzenia) }}</td>
            <td class="px-3 py-4 text-sm text-gray-500">{{ formatDate(description.editedAt)}}</td>
            <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
             <router-link :to="{ name: description.type, params: { id: description.id }}" class="text-indigo-600 hover:text-indigo-900">Zobacz<span class="sr-only">, {{ description.dataUrodzenia }}</span></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- loading... -->
  <div v-if="!doneDescriptions">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
          <h5 class="mb-2 animate-pulse text-2xl font-medium leading-6 text-neutral-700  dark:text-white">
            <span class="inline-block min-h-[1.5em] w-2/12 flex-auto rounded-md cursor-wait  text-neutral-300 bg-current align-middle opacity-50"></span>
          </h5>
          <p  class="mb-4 mt-2 max-w-4xl animate-pulse text-base text-neutral-700 dark:text-white">
            <span class="inline-block min-h-[1em] rounded-md w-10/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
            <span class="inline-block min-h-[1em] rounded-md w-6/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp;     
          </p>
      </div>
    </div>
  </div>

  <!-- no descriptions -->
  <div v-if="doneDescriptions && numDescriptions == 0">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">Coś tu pusto, tu pojawią się Twoje opisy!</h1>
        <p class="mt-2 text-sm text-gray-700">Po stworzeniu pierwszego tekstu, poniżej pojawi się lista opracowanych opisów.
          <!-- <br>Na liście znajdziesz skrócone informacje o każdym opisie, takie jak typ testu, imię, data urodzenia badanego i data utworzenia/edycji. -->
        </p>
        <button type="button" @click="handleGo('ChooseTest')" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-dla-500 px-4 py-2 mt-4 text-sm font-medium text-white shadow-sm hover:bg-green-dla-600  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">+ nowy opis</button>
      </div>
      <!-- <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <button type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-green-dla-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-dla-600  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">+ nowy opis</button>
      </div> -->
    </div>

  </div>
</template>







<script>
import { watchEffect, ref } from 'vue';
import { useRouter } from 'vue-router'

export default {
    props: ["doneDescriptions"],
    setup(props) {
        const router = useRouter();
        const numDescriptions = ref(0);
        
        const handleGo = (routerName) => {
            router.push({ name: routerName });
        };
        
        const formatDate = (timestamp) => {
            // Zamiana timestamp na JavaScript Date object
            const date = timestamp.toDate();
            // pobieranie dnia, miesiąca i roku
            const day = ("0" + date.getDate()).slice(-2);
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            // Formatowanie daty na żądany format, np. DD/MM/YYYY
            return `${day}/${month}/${year}`;
        };

        watchEffect(() => {
            // console.log(props.doneDescriptions); // używaj props.doneDescriptions
            if (props.doneDescriptions) {
                numDescriptions.value = props.doneDescriptions.length; // używaj props.doneDescriptions
            }
        });
        return { formatDate, handleGo, numDescriptions };
    },
    
};
</script>
