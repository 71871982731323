
// firebase imposrts
import { db } from '@/firebase/config';
import { addDoc, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import updateDocument from '@/composables/updateDocument.js';




export const saveDescriptionToDb = async (descriptionGeneric, testTypeInfo, user, subjectDetails) => {
    const colRef = collection(db, 'descriptions')
    const docRef = await addDoc(colRef, {
       color: testTypeInfo.color,//
       type: testTypeInfo.type,//
       userUID: user.value.uid,//
       firstName: subjectDetails.firstName,
       dataUrodzenia: subjectDetails.dataUrodzenia,
       dataBadania: subjectDetails.dataBadania,
       selectedSex: subjectDetails.selectedSex,
       isMale: subjectDetails.isMale,
       chosenOptions: subjectDetails.chosenOptions,
       iqPrzeliczone: subjectDetails.iqPrzeliczone,
       ...(subjectDetails.detailsRaw !== undefined && { detailsRaw: subjectDetails.detailsRaw }),
       descriptionGeneric: descriptionGeneric,//
       editedAt: serverTimestamp()
    })
    // console.log('🟢', docRef.id);
    return docRef.id;
 }



export const saveGPTDescriptionToDb = async (description, documentId) => {
    await updateDocument("descriptions", documentId,         
    {
       descriptionGPT: description,
       editedAt: serverTimestamp()
    })
 };


 
export const markCopied = async (documentId, versionOftext) => {
   await updateDocument("descriptions", documentId,          
   {
      [versionOftext]: true,
      editedAt: serverTimestamp()
   })
};