const idsPdata = {
  testType: "IDS-P",
  sections: {
    "iq płynna": {
      id: "iq płynna",
      group: "IQ Płynna ",
      title: "IQ Płynna - interpretacja",
      sentence:
        "Na podstawie badania {{chłopca|dziewczynki}} Skalą Inteligencji i Rozwoju IDS-P z dn. {{dataBadania}} r. stwierdza się",
      options: [
        {
          range: [131, 145],
          result: [
            "bardzo wysoki rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny. Wynik ten oznacza, że dziecko posiada zdolności intelektualne sprzyjające skutecznemu przetwarzaniu informacji, dedukcji logicznej i adaptacji do różnorodnych wyzwań poznawczych.",
          ],
        },

        {
          range: [116, 130],
          result: [
            "wysoki rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny. Wynik ten oznacza, że dziecko posiada zdolności intelektualne sprzyjające skutecznemu przetwarzaniu informacji, dedukcji logicznej i adaptacji do różnorodnych wyzwań poznawczych.",
          ],
        },

        {
          range: [85, 115],
          result: [
            "przeciętny rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny.",
            "adekwatny do wieku rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny.",
          ],
        },

        {
          range: [70, 84],
          result: [
            "stwierdza się niski rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny. Wynik ten może sugerować ograniczenia w zakresie zdolności szybkiego myślenia, adaptacji do nowych sytuacji czy rozwiązywania problemów w sposób abstrakcyjny.",
          ],
        },

        {
          range: [55, 69],
          result: [
            "stwierdza się bardzo niski rozwój inteligencji płynnej, warunkowanej biologicznie. Jest to zdolność do szybkiego myślenia, radzenia sobie z nowymi sytuacjami, oraz rozwiązywania problemów w sposób abstrakcyjny. Wynik ten może sugerować ograniczenia w zakresie zdolności szybkiego myślenia, adaptacji do nowych sytuacji czy rozwiązywania problemów w sposób abstrakcyjny.",
          ],
        },
      ],
    },

    1: {
      id: 1,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Percepcja wzrokowa ",
      options: [
        {
          range: [14, 19],
          result: [
            "Percepcja wzrokowa, inaczej zdolność do rozpoznawania i różnicowania bodźców wzrokowych jest bardzo dobrze rozwinięta.",
            "Zdolność do rozpoznawania i różnicowania bodźców wzrokowych, czyli percepcja wzrokowa jest wysoko rozwinięta.",
            "Na poziomie wysokim kształtuje się pamięć wzrokowa inaczej zdolność do rozpoznawania i różnicowania bodźców wzrokowych.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Percepcja wzrokowa, czyli zdolność do rozpoznawania i różnicowania bodźców wzrokowych rozwija się na poziomie przeciętnym dla wieku życia.",
            "Zdolność do rozpoznawania i różnicowania bodźców wzrokowych inaczej percepcja wzrokowa jest prawidłowo rozwinięta.",
            "Adekwatnie do wieku kształtuje się pamięć wzrokowa inaczej zdolność do rozpoznawania i różnicowania bodźców wzrokowych.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Percepcja wzrokowa, czyli zdolność do rozpoznawania i różnicowania bodźców wzrokowych kształtuje się  na poziomie niskim dla wieku życia. Świadczy to o małej zdolności do różnicowania bodźców o charakterze wzrokowym.",
            "Zdolność do rozpoznawania i różnicowania bodźców wzrokowych inaczej percepcja wzrokowa jest słabo rozwinięta.",
            "Na poziomie niskim znajduje się percepcja wzrokowa inaczej zdolność do rozpoznawania i różnicowania bodźców wzrokowych.",
          ],
        },
      ],
    },

    2: {
      id: 2,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Pamięć słuchowa",
      options: [
        {
          range: [14, 19],
          result: [
            "Na poziomie wysokim dla wieku rozwija się pamięć słuchowa polegającą na odtworzeniu treści usłyszanego wcześniej opowiadania.",
            "Pamięć słuchowa polegającą na odtworzeniu treści usłyszanego wcześniej opowiadania kształtuje się na poziomie wysokim.",
            "Wysoko rozwinięta jest pamięć słuchowa, rozumiana jako zdolność do odtwarzania usłyszanych informacji.",
            "Zdolność do odtwarzania wysłuchanej narracji, czyli pamięć słuchowa jest bardzo dobrze rozwinięta.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Adekwatnie w stosunku do wieku rozwija się pamięć słuchowa polegającą na odtworzeniu treści usłyszanego wcześniej opowiadania.",
            "Pamięć słuchowa polegającą na odtworzeniu usłyszanego wcześniej opowiadania kształtuje się na poziomie przeciętnym.",
            "Prawidłowo rozwinięta jest pamięć słuchowa, rozumiana jako zdolność do odtwarzania usłyszanych informacji.",
            "Zdolność do odtwarzania wysłuchanej narracji, czyli pamięć słuchowa jest dobrze rozwinięta.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Pamięć słuchowa polegającą na odtworzeniu treści usłyszanego wcześniej opowiadania rozwija się na niskim poziomie.",
            "Pamięć słuchowa polegającą na odtworzeniu treści usłyszanego wcześniej opowiadania jest słabo rozwinięta.",
            "Słabo rozwinięta jest pamięć słuchowa, rozumiana jako zdolność do odtwarzania usłyszanych informacji.",
            "Zdolność do odtwarzania wysłuchanej narracji, czyli pamięć słuchowa jest bardzo dobrze rozwinięta.",
          ],
        },
      ],
    },

    3: {
      id: 3,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Uwaga selektywna",
      options: [
        {
          range: [14, 19],
          result: [
            "Zdolność do reagowania na bodźce w sposób selektywny, gdy kilka z nich występuje jednocześnie, inaczej uwaga selektywna kształtuje się na wysokim poziomie.",
            "Uwaga selektywna jest bardzo dobrze rozwinięta.",
            "Na wysokim poziomie kształtuje się uwaga selektywna, czyli umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie.",
            "Wysoko rozwinięta jest zdolność do skupiania uwagi w sposób selektywny, gdy kilka z bodźców występuje jednocześnie.",
            "Umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie, czyli uwaga selektywna jest bardzo dobrze rozwinięta.",
            "Zdolność świadomego kierowania uwagi na to, co jest istotne dla wykonywanego aktualnie zadania inaczej uwaga selektywn jest wysoko rowinięta.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Zdolność do reagowania na bodźce w sposób selektywny, gdy kilka z nich występuje jednocześnie, inaczej uwaga selektywna kształtuje się na przeciętnym poziomie.",
            "Uwaga selektywna rozwija się na poziomie przeciętnym dla wieku życia.",
            "Adekwatnie do wieku rozwija się uwaga selektywna, czyli umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie.",
            "Prawidłowo rozwinięta jest zdolność do skupiania uwagi w sposób selektywny, gdy kilka z bodźców występuje jednocześnie.",
            "Umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie, czyli uwaga selektywna jest prawidłowo rozwinięta.",
            "Adekwatnie do wieku rozwinęła się uwaga selektywna (zdolność świadomego kierowania uwagi na to, co jest istotne dla wykonywanego aktualnie zadania).",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Zdolność do reagowania na bodźce w sposób selektywny, gdy kilka z nich występuje jednocześnie, inaczej uwaga selektywna kształtuje się na niskim poziomie.",
            "Uwaga selektywna rozwija się na poziomie niskim dla wieku życia.",
            "Znacznie obniżona jest uwaga selektywna, czyli umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie.",
            "Obniżona w stosunku do wieku zdolność do skupiania uwagi w sposób selektywny, gdy kilka z bodźców występuje jednocześnie.",
            "Umiejętność selektywnego reagowania na bodźce, gdy kilka z nich występuje jednocześnie, czyli uwaga selektywna jest niska.",
            "Zdolność świadomego kierowania uwagi na to, co jest istotne dla wykonywanego aktualnie zadania inaczej uwaga selektywn jest obniżona.",
          ],
        },
      ],
    },

    4: {
      id: 4,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Pamięć fonologiczna",
      options: [
        {
          range: [14, 19],
          result: [
            "Pamięć fonologiczna, czyli zapamiętywanie i prawidłowe odtwarzanie kolejności cząstek fonologicznych takich jak sylaby rozwija się na wysokim poziomie.",
            "Pamięć fonologiczna kształtuje się na poziomie wysokim dla wieku.",
            "Wysoko rozwinięta jest pamięć fonologiczna, czyli zapamiętywanie i prawidłowe odtwarzanie kolejności cząstek fonologicznych takich jak sylaby.",
            "Rozwój pamięci fonologicznej (dzięki niej dziecko jest w stanie zapamiętywać brzmienie nowych wyrazów, rozwijając w ten sposób słownik) kształtuje się powyżej norm dla wieku.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Pamięć fonologiczna, czyli zapamiętywanie i prawidłowe odtwarzanie kolejności cząstek fonologicznych takich jak sylaby rozwija się na przeciętnym poziomie.",
            "Pamięć fonologiczna kształtuje się na poziomie przeciętnym dla wieku życia.",
            "Adekwatnie do wieku rozwinięta jest pamięć fonologiczna.",
            "Rozwój pamięci fonologicznej (dzięki niej dziecko jest w stanie zapamiętywać brzmienie nowych wyrazów, rozwijając w ten sposób słownik) jest adekwatny dla wieku.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Pamięć fonologiczna, czyli zapamiętywanie i prawidłowe odtwarzanie kolejności cząstek fonologicznych takich jak sylaby rozwija się na niskim poziomie.",
            "Pamięć fonologiczna jest słabo rozwinięta.",
            "Na poziomie niskim dla wieku rozwinięta jest pamięć fonologiczna.",
            "Rozwój pamięci fonologicznej (dzięki niej dziecko jest w stanie zapamiętywać brzmienie nowych wyrazów, rozwijając w ten sposób słownik) jest obniżony w stosunku do wieku.",
          ],
        },
      ],
    },

    5: {
      id: 5,
      group: "IQ Płynna - zdolności poznawcze",
      title: " Pamięć wzrokowo-przestrzenna",
      options: [
        {
          range: [14, 19],
          result: [
            "Na wysokim poziomie rozwinięta jest pamięć wzrokowo-przestrzennym rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych.",
            "Pamięć wzrokowo-przestrzenna, rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych z uwzględnieniem ich ułożenia w przestrzeni kształtuje się na wysokim poziomie.",
            "Zapamiętywanie bodźców wzrokowych z uwzględnieniem ich ułożenia w przestrzeni, inaczej pamięć wzrokowo-przestrzenna rozwija się na wysokim poziomie.",
            "Zdolność do zapamiętywania i odtwarzania informacji związanej z przestrzenią i obiektami w niej zawartymi, zwana pamięcią wzrokowo-przestrzenną jest bardzo dobrze rozwinięta.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Adekwatnie do wieku rozwinięta jest pamięć wzrokowo-przestrzenna rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych.",
            "Pamięć wzrokowo-przestrzenna rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych z uwzględnieniem ich ułożenia w przestrzeni  rozwija się na poziomie przeciętnym.",
            "Zapamiętywanie bodźców wzrokowych z uwzględnieniem ich ułożenia w przestrzeni, inaczej pamięć wzrokowo-przestrzenna rozwija się adekwatnie do wieku.",
            "Zdolność do zapamiętywania i odtwarzania informacji związanej z przestrzenią i obiektami w niej zawartymi, zwana pamięcią wzrokowo-przestrzenną jest dobrze rozwinięta.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Słabo rozwinięta jest pamięć wzrokowo-przestrzenna rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych.",
            "Pamięć wzrokowo-przestrzenna rozumiana jako zdolność do utrwalania i przypominania sobie informacji wizualnych z uwzględnieniem ich ułożenia w przestrzeni  rozwija się na niskim poziomie.",
            "Zapamiętywanie bodźców wzrokowych z uwzględnieniem ich ułożenia w przestrzeni, inaczej pamięć wzrokowo-przestrzenna rozwija się na poziomie niskim.",
            "Zdolność do zapamiętywania i odtwarzania informacji związanej z obiektami zawartymi w przestrzeni,  zwana pamięcią wzrokowo-przestrzenną jest słabo rozwinięta. Świadczy to o niewielkiej pojemności magazynu pamięci krótkotrwałej w zakresie przechowywania informacji figuralnych.",
          ],
        },
      ],
    },

    6: {
      id: 6,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Rozumowanie przestrzenne",
      options: [
        {
          range: [14, 19],
          result: [
            "Wysoko rozwinięte jest rozumowanie przestrzenne, czyli zdolność do określenia położenia  obiektów w przestrzeni.",
            "Zdolność do określenia położenia obiektów w przestrzeni inaczej rozumowanie przestrzenne kształtuje się na poziomie wysokim dla wieku.",
            "Rozumowanie przestrzenne inaczej umiejętność myślowa, która pozwala analizować, porządkować i manipulować informacjami dotyczącymi przestrzeni i obiektów w niej zawartych rozwija się na poziomie wysokim.",
            "Bardzo dobrze rozwinięta jest zdolność rozumowania przestrzennego (myślenie obrazowe).",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Adekwatnie do wieku rozwinięte jest rozumowanie przestrzenne, czyli zdolność do określenia położenia  obiektów w przestrzeni.",
            "Zdolność do określenia położenia obiektów w przestrzeni inaczej rozumowanie przestrzenne kształtuje się na poziomie przeciętnym dla wieku.",
            "Rozumowanie przestrzenne inaczej umiejętność myślowa, która pozwala analizować, porządkować i manipulować informacjami dotyczącymi przestrzeni i obiektów w niej zawartych rozwija się na poziomie przeciętnym.",
            "Prawidłowo rozwinięta jest zdolność rozumowania przestrzennego (myślenie obrazowe).",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Na poziomie niskim rozwinięte jest rozumowanie przestrzenne, czyli zdolność do określenia położenia  obiektów w przestrzeni.",
            "Zdolność do określenia położenia obiektów w przestrzeni inaczej rozumowanie przestrzenne kształtuje się na poziomie niskim dla wieku życia.",
            "Rozumowanie przestrzenne inaczej umiejętność myślowa, która pozwala analizować, porządkować i manipulować informacjami dotyczącymi przestrzeni i obiektów w niej zawartych rozwija się na poziomie niskim.",
            "Obniżona jest zdolność rozumowania przestrzennego (myślenie obrazowe).",
          ],
        },
      ],
    },

    7: {
      id: 7,
      group: "IQ Płynna - zdolności poznawcze",
      title: "Rozumowanie pojęciowe",
      options: [
        {
          range: [14, 19],
          result: [
            "Rozumowanie pojęciowe, czyli zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii, rozwija się na poziomie wysokim.",
            "Wysoko rozwinięta jest umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe.",
            "Umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe kształtuje się na wysoki poziomie. Dziecko z takim wynikiem potrafi tworzyć klasy łączące różne obiekty.",
            "Zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii,  zwana rozumowaniem pojęciowym jest bardzo dobrze rozwinięta.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Rozumowanie pojęciowe, czyli zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii, jest prawidłowo rozwinięte.",
            "Adekwatnie w stosunku do wieku rozwinięta jest umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe.",
            "Umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe kształtuje się na poziomie przeciętnym.",
            "Zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii,  zwana rozumowaniem pojęciowym jest prawidłowo rozwinięta.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Rozumowanie pojęciowe, czyli zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii,  rozwija się na poziomie niskim.",
            "Znacznie obniżona jest umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe.",
            "Umiejętność tworzenia i rozumienia pojęć słownych, inaczej rozumowanie pojęciowe kształtuje się na niskim poziomie.",
            "Zdolność rozumienia znaczenia słów i umiejętność wyodrębniania z nich istotnych, ogólnych idei lub kategorii,  rozwija się na poziomie niskim. Taki wynik może wskazywać na mniej efektywne przetwarzanie i zapamiętywanie nowych informacji przekazywanych ustnie.",
          ],
        },
      ],
    },

    "iq skrystalizowana": {
      id: "iq skrystalizowana",
      group: "IQ Skrystalizowana",
      title: "IQ Skrystalizowana - interpretacja",
      options: [
        {
          range: [131, 145],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} bardzo wysokie wyniki w zadaniach mierzących inteligencję skrystalizowaną, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie bardzo wysokim kształtuje się inteligencja skrystalizowana, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie bardzo wysokim rozwinięta jest inteligencja skrystalizowana rozumiana jako zdolność do nabywania i reprodukowania wiedzy.",
          ],
        },

        {
          range: [116, 130],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} wysokie wyniki w zadaniach mierzących inteligencję skrystalizowaną, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie wysokim kształtuje się inteligencja skrystalizowana, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie wysokim rozwinięta jest inteligencja skrystalizowana rozumiana jako zdolność do nabywania i reprodukowania wiedzy.",
          ],
        },

        {
          range: [85, 115],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} przeciętne wyniki w zadaniach mierzących inteligencję skrystalizowaną, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie normy wiekowej kształtuje się inteligencja skrystalizowana, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie przeciętnym rozwinięta jest inteligencja skrystalizowana rozumiana jako zdolność do nabywania i reprodukowania wiedzy.",
          ],
        },

        {
          range: [70, 84],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} niskie wyniki w zadaniach mierzących inteligencję skrystalizowaną, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie niskim kształtuje się inteligencja skrystalizowana, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie niskim rozwinięta jest inteligencja skrystalizowana rozumiana jako zdolność do nabywania i reprodukowania wiedzy.",
          ],
        },

        {
          range: [55, 69],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} bardzo niskie wyniki w zadaniach mierzących inteligencję skrystalizowaną, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie bardzo niskim kształtuje się inteligencja skrystalizowana, która jest zależna i podatna na doświadczenia edukacyjne oraz podlega uczeniu.",
            "Na poziomie bardzo niskim rozwinięta jest inteligencja skrystalizowana rozumiana jako zdolność do nabywania i reprodukowania wiedzy.",
          ],
        },
      ],
    },

    12: {
      id: 12,
      group: "IQ Skrystalizowana",
      title: "Rozumowanie logiczno-matematyczne",
      options: [
        {
          range: [14, 19],
          result: [
            "W porównaniu z innymi dziećmi w tym samym wieku {{Imię}} {{uzyskał|uzyskała}} wysokie wyniki w zadaniach mierzących podstawowe kompetencje matematyczne.",
            "Rozumowanie logiczno-matematyczne rozwija się na wysokim poziomie. Dziecko bardzo dobrze radzi sobie z wykonywaniem zadań wymagających rozumienia elementarnych pojęć matematycznych.",
            "Podstawowe kompetencje matematyczne kształtują się na poziomie wysokim.",
            "Bardzo dobrze rozwinięte jest rozumowanie logiczno-matematyczne.",
            "{{Imię}} {{osiągnął|osiągnęła}} wysoki wynik w zadaniach sprawdzających podstawowe umiejętności matematyczne.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "{{Imię}} {{uzyskał|uzyskała}} prawidłowe wyniki w zadaniach mierzących podstawowe kompetencje matematyczne.",
            "Rozumowanie logiczno-matematyczne rozwija się adekwatnie do wieku życia.",
            "Podstawowe kompetencje matematyczne kształtują się na poziomie przeciętnym.",
            "Na poziomie przeciętnym kształtuje się rozumowanie logiczno-matematyczne, inaczej umiejętność logicznego myślenia i rozwiązywania zadań arytmetycznych.",
            "{{Imię}} {{osiągnął|osiągnęła}} prawidłowe wynik w zadaniach sprawdzających podstawowe umiejętności matematyczne.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "W porównaniu z innymi dziećmi w tym samym wieku {{Imię}} {{uzyskał|uzyskała}} niskie wyniki w zadaniach mierzących podstawowe kompetencje matematyczne.",
            "Podstawowe kompetencje matematyczne kształtują się na poziomie niskim.",
            "Rozumowanie logiczno-matematycznego jest słabo rozwinięte.",
            "Na niskim poziomie kształtuje się rozumowanie logiczno-matematyczne.",
            "{{Imię}} {{osiągnął|osiągnęła}} niski wynik w zadaniach sprawdzających podstawowe umiejętności matematyczne.",
          ],
        },
      ],
    },
    13: {
      id: 13,
      group: "IQ Skrystalizowana",
      title: "Mowa czynna",
      options: [
        {
          range: [14, 19],
          result: [
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie wysokim.",
            "Mowa czynna, czyli umiejętność budowania wypowiedzi i zasób słownictwa kształtuje się na wysokim poziomie.",
            "Na poziomie wysokim rozwija się mowa czynna, czyli zdolność formułowania wypowiedzi i zasób słownictwa.",
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi mieści się w przedziale rezultatów wysokich.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się adekwatnie do wieku.",
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest prawidłowo rozwinięta.",
            "Mowa czynna, czyli umiejętność konstrukcji wypowiedzi i zasób słownictwa kształtuje się na poziomie przeciętnym.",
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi mieści się w przedziale rezultatów przeciętnych.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi rozwija się na poziomie niskim.",
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi jest słabo rozwinięta.",
            "Na niskim poziomie rozwija się umiejętność konstrukcji wypowiedzi i zasób słów w mowie czynnej.",
            "Mowa czynna, czyli zasób słów i umiejętność konstrukcji wypowiedzi mieści się w przedziale rezultatów niskich.",
          ],
        },
      ],
    },
    14: {
      id: 14,
      group: "IQ Skrystalizowana",
      title: "Słownik",
      options: [
        {
          range: [14, 19],
          result: [
            "Zasób słownictwa znajduje się na wysokim poziomie.",
            "{{Chłopiec|Dziewczynka}} {{prezentował|prezentowała}} bogaty zasób słownictwa.",
            "{{Imię}} posiada wysoki zasób słów.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Zasób słownictwa znajduje się na poziomie przeciętnym.",
            "{{Chłopiec|Dziewczynka}} {{prezentował|prezentowała}} adekwatnym do wieku zasób słownictwa.",
            "{{Imię}} posiada przeciętny zasób słów.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Zasób słownictwa znajduje się na niskim poziomie.",
            "{{Chłopiec|Dziewczynka}} {{prezentował|prezentowała}}  niski zasób słownictwa.",
            "{{Imię}} posiada niski zasób słów.",
          ],
        },
      ],
    },
    15: {
      id: 15,
      group: "IQ Skrystalizowana",
      title: "Mowa bierna",
      options: [
        {
          range: [14, 19],
          result: [
            "Zdolność rozumienia mowy inaczej mowa bierna rozwija się na wysokim poziomie.",
            "Umiejętność rozumienia mowy, czyli mowa bierna, jest na wysokim poziomie.",
            "Mowa bierna, czyli zdolność rozumienia mowy innych ludzi rozwija się na wysokim poziomie.",
            "Mowa bierna, czyli zdolność rozumienia mowy znajduje się w przedziale wyników wysokich.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Mowa bierna, czyli umiejętność rozumienia mowy innych ludzi rozwija się adekwatnie do wieku życia.",
            "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na poziomie przeciętnym.",
            "Mowa bierna, czyli zdolność rozumienia mowy jest prawidłowo rozwinięta.",
            "Mowa bierna, czyli zdolność rozumienia mowy znajduje się w przedziale wyników przeciętnych.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Słabo rozwinięta jest mowa bierna, czyli zdolność rozumienia mowy innych ludzi.",
            "Umiejętność rozumienia mowy, czyli mowa bierna kształtuje się na niskim poziomie.",
            "Mowa bierna, czyli zdolność rozumienia mowy jest słabo rozwinięta.",
            "Mowa bierna, czyli zdolność rozumienia mowy znajduje się w przedziale wyników niskich.",
          ],
        },
      ],
    },

    "iq ogólne": {
      id: "iq ogólne",
      group: "IQ ogólne",
      title: "IQ ogólne - interpretacja",
      sentence: "",
      options: [
        {
          range: [131, 145],
          result: ["Dziecko prezentuje bardzo wysoki poziom inteligencji ogólnej."],
        },

        {
          range: [116, 130],
          result: ["Dziecko prezentuje wysoki poziom inteligencji ogólnej."],
        },

        {
          range: [85, 115],
          result: [
            "Dziecko prezentuje przeciętny poziom inteligencji ogólnej.",
            "Dziecko posiada adekwatny do wieku poziom inteligencji ogólnej.",
          ],
        },

        {
          range: [70, 84],
          result: ["Dziecko prezentuje niski poziom inteligencji ogólnej."],
        },

        {
          range: [55, 69],
          result: ["Dziecko prezentuje bardzo niski poziom inteligencji ogólnej."],
        },
      ],
    },

    8: {
      id: 8,
      group: "Umiejętności psychomotoryczne",
      title: "Motoryka",
      options: [
        {
          range: [14, 19],
          result: [
            "W sferze motoryki dużej, {{Imię}} cechuje wyższy w stosunku do wieku poziom umiejętności ruchowych w obrębie całego ciała (bardzo dobra zdolność utrzymywania równowagi i koordynacja ruchowa).",
            "Dziecko jest bardzo sprawne fizycznie, bez problemu przeskakuje bokiem przez linę, chodzi wzdłuż liny oraz stoi na jednej nodze.",
            "{{Chłopca|dziewczynkę}} cechuje wysoka sprawność fizyczna - bez trudu przeskakuje bokiem przez linę, chodzi wzdłuż liny oraz stoi na jednej nodze.",
            "{{Chłopiec|Dziewczynka}} wykazuje sprawność fizyczną na poziomie wysokim: chodzi pewnie wzdłuż liny, z łatwością skacze bokiem przez nią, utrzymuje równowagę stojąc na jednej nodze.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "W sferze motoryki dużej, {{Imię}} cechuje prawidłowy w stosunku do wieku poziom umiejętności ruchowych w obrębie całego ciała (prawidłowa zdolność utrzymywania równowagi i koordynacja ruchowa).",
            "Sprawność ruchowa całego ciała kształtuje się na poziomie wieku.",
            "Przeciętnie rozwinięta jest motoryka duża, czyli ogólna sprawność ruchowa całego ciała.",
            "{{Chłopca|Dziewczynkę}} cechuje adekwatny do wieku poziom sprawności ruchowej całego ciała (motoryka duża).",
          ],
        },

        {
          range: [1, 6],
          result: [
            "W sferze motoryki dużej, {{Imię}} cechuje obniżony w stosunku do wieku poziom umiejętności ruchowych w obrębie całego ciała (słaba zdolność utrzymywania równowagi i koordynacja ruchowa).",
            "Motoryka duża, czyli ogólna sprawność ruchowa całego ciała jest mała.",
            "Sprawność ruchowa całego ciała, nazywana motoryką dużą jest niska.",
            "{{Chłopca|Dziewczynkę}} cechuje niski poziom sprawności ruchowej całego ciała (motoryka duża).",
          ],
        },
      ],
    },

    9: {
      id: 9,
      group: "Umiejętności psychomotoryczne",
      title: "Manipulacja",
      options: [
        {
          range: [14, 19],
          result: [
            "Sprawność manipulacyjna w zakresie szybkości oraz precyzji ruchów dłoni i palców (badana za pomocą nawlekania koralików na sznurek) jest duża.",
            "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek zgodnie ze wzorem znajduje się na poziomie wysokim.",
            "Sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek kształtuje się na wysokim poziomie.",
            "Manipulacyjna sprawność ruchowa rąk, dotycząca precyzji i szybkości ruchów podczas nawlekania koralików na sznurek, jest na wysokim poziomie.",
            "Manipulacja drobnymi obiektami inaczej sprawność ruchowa rąk jest duża.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Sprawność manipulacyjna w zakresie szybkości oraz precyzji ruchów dłoni i palców (badana za pomocą nawlekania koralików na sznurek) jest przeciętna.",
            "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek zgodnie ze wzorem rozwija się adekwatnie do wieku życia.",
            "Motoryka mała, odnosząca się do precyzji i szybkości ruchów rąk podczas nawlekania koralików rozwija się adekwatnie do wieku.",
            "Precyzyjność i szybkość ruchów rąk w działaniach takich jak nawlekanie koralików na sznurek, nazywana motoryką małą, kształtuje się na poziomie przeciętnym.",
            "Manipulacja drobnymi obiektami inaczej sprawność ruchowa rąk jest prawidłowa.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Sprawność manipulacyjna w zakresie szybkości oraz precyzji ruchów dłoni i palców (badana za pomocą nawlekania koralików na sznurek) jest obniżona.",
            "Manipulacja inaczej sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek zgodnie ze wzorem  znajduje się na poziomie niskim.",
            "Motoryka mała, czyli sprawność ruchowa rąk w zakresie precyzji i szybkości ruchów podczas nawlekania koralików na sznurek jest mała.",
            "Precyzyjność i szybkość ruchów rąk podczas takich czynności jak nawlekanie koralików jest słabo rozwinięta.",
            "Manipulacja drobnymi obiektami inaczej sprawność ruchowa rąk jest mała.",
          ],
        },
      ],
    },

    10: {
      id: 10,
      group: "Umiejętności psychomotoryczne",
      title: "Koordynacja wzrokowo-ruchowa",
      options: [
        {
          range: [14, 19],
          result: [
            "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest wysoko rozwinięta.",
            "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa jest wysoko rozwinięta.",
            "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową kształtuje się na poziomie wysokim.",
            "Koordynacja wzrokowo-ruchowa, czyli zdolność do zsynchronizowania ruchów ciała z tym co dostrzega wzrok, znajduje się na poziomie wysokim.",
            "W zakresie koordynacji wzrokowo-ruchowej {{chłopiec|dziewczynka}} {{uzyskał|uzyskała}} wysoki wynik. Większość zaprezentowanych dziecku figur została prawidłowo przerysowana.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest prawidłowa dla wieku życia.",
            "Koordynacja wzrokowo-ruchowa rozwija się adekwatnie do wieku życia.",
            "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową kształtuje się na poziomie przeciętnym.",
            "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa rozwija się na przeciętnym poziomie.",
            "W zakresie koordynacji wzrokowo-ruchowej {{chłopiec|dziewczynka}} {{uzyskał|uzyskała}} przeciętny wynik. Większość zaprezentowanych dziecku figur została prawidłowo przerysowana.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Koordynacja wzrokowo-ruchowa inaczej współdziałanie funkcji wzrokowych i ruchowych jest słabo rozwinięta.",
            "Koordynacja wzrokowo-ruchowa rozwija się na poziomie niskim.",
            "Synchronizacja między funkcjami wzrokowymi a ruchowymi, zwana koordynacją wzrokowo-ruchową kształtuje się na poziomie niskim.",
            "Współdziałanie funkcji wzrokowych i ruchowych, czyli koordynacja wzrokowo-ruchowa rozwija się na niskim poziomie.",
            "W zakresie koordynacji wzrokowo-ruchowej {{chłopiec|dziewczynka}} {{uzyskał|uzyskała}} niski wynik. Większość zaprezentowanych dziecku figur została przerysowana w sposób nie dający się zinterpretować.",
          ],
        },
      ],
    },

    11: {
      id: 11,
      group: "Kompetencje społeczno-emocjonalne",
      title: "Kompetencje społeczno-emocjonalne",
      options: [
        {
          range: [14, 19],
          result: [
            "{{Imię}} ma bardzo dobrze rozwinięte kompetencje społeczno-emocjonalne. Dziecko bardzo dobrze rozpoznaje i wskazuje emocje dzieci przedstawionych na fotografiach, rozumie sytuacje społeczne ukazane na obrazkach.",
            "Powyżej normy wiekowej kształtują się kompetencje emocjonalno-społeczne oznacza to, że {{chłopiec|dziewczynka}} bardzo dobrze rozpoznaje i wskazuje emocje dzieci przedstawionych na fotografiach oraz prawidłowo rozumie sytuacje społeczne ukazane na obrazkach.",
            "Na podstawie zdjęć z wyrazami twarzy dzieci i obrazków z sytuacjami społecznymi, {{Imię}} bardzo dobrze rozpoznaje i wskazuje emocje dzieci przedstawionych na fotografiach oraz prawidłowo rozumie sytuacje społeczne ukazane na obrazkach.",
            "{{Imię}} {{uzyskał|uzyskała}} wysoki wynik w obszarze kompetencji społeczno–emocjonalnych. {{Rozpoznał|Rozpoznała}} prezentowane na fotografiach stany emocjonalne innych dzieci. W zadaniu dotyczącym rozumienia sytuacji społecznych właściwie {{ocenił|oceniła}} zamiary i uczucia prezentowanych postaci.",
            "{{Imię}} potrafi precyzyjnie rozpoznać i wskazać emocje dzieci widząc ich twarze na fotografiach  oraz prawidłowo rozumie sytuacje społeczne przedstawione na obrazkach.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "{{Imię}} ma dobrze rozwinięte kompetencje społeczno-emocjonalne, prawidłowo w stosunku do wieku życia rozpoznaje i wskazuje emocje dzieci przedstawionych na fotografiach oraz dokonuje opisu sytuacji i odczuć postaci  z obrazków.",
            "Na poziomie normy wiekowej kształtują się kompetencje emocjonalno-społeczne, oznacza to, że {{chłopiec|dziewczynka}} prawidłowo w stosunku do wieku rozpoznaje i wskazuje emocje dzieci przedstawionych na fotografiach oraz dokonuje opisu sytuacji i odczuć bohaterów z obrazków.",
            "{{Imię}} prawidłowo rozpoznaje i nazywa emocje dzieci, patrząc na ich twarze na zdjęciach i obrazkach z sytuacjami społecznymi.",
            "{{Imię}} {{uzyskał|uzyskała}} prawidłowy wynik w obszarze kompetencji społeczno–emocjonalnych. {{Rozpoznał|Rozpoznała}} większość z prezentowanych na fotografiach stanów emocjonalnych. W zadaniu dotyczącym rozumienia sytuacji społecznych w większości właściwie {{ocenił|oceniła}} zamiary i uczucia prezentowanych postaci.",
            "Na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach i obrazkach z sytuacjami społecznymi, {{Imię}} prawidłowo rozpoznaje ich emocje i zamiary.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "{{Imię}} ma słabo rozwinięte kompetencje społeczno-emocjonalne. Nie rozpoznaje i nie wskazuje emocji na podstawie wyrazu twarzy dzieci przedstawionych na fotografiach, ma trudności z opisaniem sytuacji społecznych i odczuć bohaterów z obrazków.",
            "Poniżej normy wiekowej kształtują się kompetencje emocjonalno-społeczne, oznacza to, że {{chłopiec|dziewczynka}} ma trudności z prawidłowym rozpoznaniem i wskazaniem emocji innych dzieci przedstawionych na fotografiach oraz z dokonaniem opisu sytuacji i odczuć bohaterów z obrazków.",
            "Rozwój kompetencji emocjonalno-społecznych jest niski. {{Imię}} ma trudności aby rozpoznać i wskazać emocje na podstawie wyrazu twarzy dzieci zaprezentowanych na fotografiach oraz opisać sytuacje społeczne z uwzględnieniem odczuć  bohaterów z obrazków.",
            "{{Imię}} ma trudności z rozpoznaniem i wskazaniem emocji dzieci na podstawie ich wyrazów twarzy na fotografiach, a także problem sprawia {{mu|jej}} opisanie sytuacji społecznej oraz odczuć postaci  z obrazków.",
            "{{Imię}} {{uzyskał|uzyskała}} obniżony wynik w obszarze kompetencji społeczno–emocjonalnych. {{Miał|Miała}} trudności z rozpoznaniem prezentowanych na fotografiach stanów emocjonalnych. W zadaniu dotyczącym rozumienia sytuacji społecznych w większości niewłaściwie {{ocenił|oceniła}} zamiary i uczucia prezentowanych postaci.",
          ],
        },
      ],
    },


    Język: {
      id: "Język",
      group: "Język",
      title: "Język",
      options: [
        {
          range: [14, 19],
          result: [
            "Ogólny poziom rozwoju językowego można określić jako wysoki na tle innych dzieci w tym samym wieku.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Ogólny poziom rozwoju językowego można określić jako przeciętny na tle innych dzieci w tym samym wieku.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Ogólny poziom rozwoju językowego można określić jako niski na tle innych dzieci w tym samym wieku.",
          ],
        },
      ],
    },


    motywacja: {
      id: "motywacja",
      group: "Motywacja",
      title: "Motywacja",
      options: [
        {
          range: [14, 19],
          result: [
            "Motywacja dziecka rozumiana jako wytrwałość, umiejętność odraczania nagrody oraz satysfakcja z osiągnięć kształtuje się powyżej normy wiekowej.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Motywacja dziecka rozumiana jako wytrwałość, umiejętność odraczania nagrody oraz satysfakcja z osiągnięć kształtuje się na poziomie normy wiekowej.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Motywacja dziecka rozumiana jako wytrwałość, umiejętność odraczania nagrody oraz satysfakcja z osiągnięć kształtuje się poniżej normy wiekowej.",
          ],
        },
      ],
    },

    16: {
      id: 16,
      group: "Motywacja",
      title: "Odraczanie nagrody",
      options: [
        {
          range: [14, 19],
          result: [
            "{{Chłopiec|Dziewczynka}} {{potrafił|potrafiła}} bardzo cierpliwie czekać na nagrodę, nie otwierając przy tym oczu.",
            "{{Chłopiec|Dziewczynka}} ma wysoką umiejętność wyczekiwania na nagrodę z zamkniętymi oczami.",
            "{{Chłopiec|Dziewczynka}} ma dużą cierpliwość, aby oczekiwać na nagrodę, nie otwierając przy tym oczu i o nic nie pytając.",
            "{{Chłopiec|Dziewczynka}} {{miał|miała}} zdolność do cierpliwego czekania na nagrodę, trzymając oczy zamknięte.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "{{Chłopiec|Dziewczynka}} {{potrafił|potrafiła}} czekać na nagrodę, {{starał|starała}} się nie otwierać przy tym oczu.",
            "{{Chłopiec|Dziewczynka}} {{miał|miała}} umiejętność wyczekiwania na nagrodę z zamkniętymi oczami.",
            "{{Chłopiec|Dziewczynka}} {{był|była}} {{cierpliwy|cierpliwa}} czekając na nagrodę, {{starał|starała}} się nie otwierać przy tym powiek i o nic nie pytać.",
            "{{Chłopiec|Dziewczynka}} ma zdolność do cierpliwego czekania na nagrodę, trzymając oczy zamknięte.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "{{Chłopiec|Dziewczynka}} nie {{potrafił|potrafiła}} cierpliwie czekać na nagrodę, często {{otwierał|otwierała}} oczy i {{zadawał|zadawała}} pytania.",
            "{{Imię}} {{miał|miała}} trudności z cierpliwym oczekiwaniem na nagrodę z zamkniętymi oczami.",
            "{{Chłopiec|Dziewczynka}} nie {{potrafił|potrafiła}} cierpliwie oczekiwać na nagrodę. {{Zadawał|Zadawała}} pytania i {{otwierał|otwierała oczy}}.",
            "Oczekiwanie na nagrodę z zamkniętymi oczami było dla {{Imię}} wyzwaniem, {{miał|miała}} z tym dużą trudność.",
          ],
        },
      ],
    },
    17: {
      id: 17,
      group: "Motywacja",
      title: "Wytrwałość",
      options: [
        {
          range: [14, 19],
          result: [
            "{{Imię}} {{wykazywał|wykazywała}} dużą wytrwałość podczas wykonywania zadań.",
            "{{Imię}} wykonując powierzone {{mu|jej}} zadania {{wykazał|wykazała}} się dużą wytrwałością.",
            "Podczas wykonywania zadań {{wykazał|wykazała}} się dużą wytrwałością.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "{{Imię}} {{wykazywał|wykazywała}} prawidłową wytrwałość podczas wykonywania zadań.",
            "{{Imię}} wykonując powierzone {{mu|jej}} zadania {{wykazał|wykazała}} się prawidłową wytrwałością.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "{{Imię}} {{wykazywał|wykazywała}} brak wytrwałości podczas wykonywania zadań.",
            "Brakowało {{mu|jej}} wytrwałości w trakcie wykonywania zadań.",
            "Podczas wykonywania zadań, brakowało {{mu|jej}} wytrwałości.",
            "{{Miał|Miała}} duże trudności z wytrwałością w wykonywaniu zadań.",
          ],
        },
      ],
    },
    18: {
      id: 18,
      group: "Motywacja",
      title: "Satysfakcja z osiągnięć",
      options: [
        {
          range: [14, 19],
          result: [
            "Bardzo chętnie {{podejmował|podejmowała}} proponowane {{mu|jej}} czynności, widoczna była satysfakcja i radość z ukończonych zadań.",
            "Entuzjastycznie {{podchodził|podchodziła}} do proponowanych zadań, {{reagował|reagowała}} zadowoleniem i radością po skończeniu zadań.",
          ],
        },

        {
          range: [7, 13],
          result: [
            "Chętnie {{podejmował|podejmowała}} proponowane {{mu|jej}} czynności, widoczna była satysfakcja i radość z ukończonych zadań.",
            "Z chęcią {{angażował|angażowała}} się w proponowane {{mu|jej}} działania, co było zauważalne poprzez wyrażoną satysfakcję i radość po skończeniu zadań.",
          ],
        },

        {
          range: [1, 6],
          result: [
            "Dziecko nie wykazywało większego zainteresowania proponowanymi mu czynnościami, widoczna była obojętność w stosunku do wyników badania.",
            "{{Imię}} nie {{przejawiał|przejawiała}} większego zainteresowania proponowanymi {{mu|jej}} działaniami, {{wyrażał|wyrażała}} obojętność wobec wyników badania.",
            "Widoczny był brak zainteresowania ze strony dziecka w stosunku do proponowanych mu aktywności.",
          ],
        },
      ],
    },
  },
};

export default idsPdata;
