<template>
<div class="min-h-full">
 <!-- <Navbar /> -->

   <header class="bg-gray-100 pt-10">
         <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
         <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">{{ testTypeInfo.type }}</h1>
         
         <!-- <h2 class="text-2xl font-medium leading-6 text-gray-900 mt-5">w trakcie przygotowania...</h2>
         <p class="mt-2 max-w-4xl text-sm text-gray-500">Ponformujemy Cię jak tylko będzie możliwość opracowania opisu do pełnego testu.</p>
         -->
         </div>
   </header>
   <main>
            <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <!-- Replace with your content -->
               <div class="px-4 py-4 sm:px-0">

                  <div class="choosingPoints" v-if="isChoosingPoints">
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 pb-10 mb-10 rounded-md shadow-sm">
                  
                        <!-- Content goes here -->
                        <div class="border-b border-gray-200 pb-5 mb-0">
                           <h2 class="text-2xl font-medium leading-6 text-gray-900">Informacje ogólne</h2>
                           <p class="mt-2 max-w-4xl text-sm text-gray-500">Informacje potrzebne do przygotowania poprawnego opisu uwzględniającego normy wiekowe.</p>
                        </div>

                        <div class="mt-0 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                           <div class="sm:col-span-2 sm:col-start-1">
                              <label for="dp-input-dataBadania" class="block text-sm pt-5 font-medium leading-6 text-gray-900">Data badania</label>
                              <VueDatePicker 
                                             v-model="dataBadania" 
                                             :hide-navigation="['month', 'year']" 
                                             :format="dateFormattingInCallendar"
                                             name="dataBadania-date-picker" 
                                             :flow="flowCalendar" 
                                             uid="dataBadania"  
                                             id="dataBadania"  
                                             :enable-time-picker="false"
                                             keep-action-row 
                                             auto-apply 
                                             locale="pl"
                                             now-button-label="Dziś"
                                             position="center" 
                                             :auto-position="true"
                                             :action-row="{ showNow: true, showPreview: false, showCancel: false, showSelect: false,  }"
                                             input-class-name="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                                           ></VueDatePicker>
                           </div>
                        </div>

                        <div class="border-b border-gray-200 pb-0 mb-0">
                           &nbsp;
                        </div>
               
                        <div>
                           <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                 <div class="sm:col-span-2 sm:col-start-1">
                                    <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">Imię</label>
                                    <div class="mt-2">
                                       <input type="text" name="firstName" id="firstName" v-model="firstName" @input="handleInput" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
                                    </div>
                                    <span v-if="errorsData.firstName && !firstName" class="mt-2 text-sm text-red-600" id="firstName-error">{{ errorsData.firstName }}</span>
                                 </div>

                                 <div class="sm:col-span-2">
                                    <label for="dp-input-dataUrodzenia" class="block text-sm font-medium leading-6 text-gray-900">Data urodzenia</label>
                                    <VueDatePicker 
                                       v-model="dataUrodzenia" 
                                       :hide-navigation="['month', 'year']" 
                                       :format="dateFormattingInCallendar"
                                       name="dataUrodzenia-date-picker" 
                                       :flow="flowCalendar" 
                                       uid="dataUrodzenia"  
                                       id="dataUrodzenia"  
                                       :enable-time-picker="false"
                                       keep-action-row 
                                       auto-apply 
                                       locale="pl"
                                       now-button-label="Dziś"
                                       position="center" 
                                       :min-date="minDate" :max-date="maxDate" prevent-min-max-navigation
                                       :auto-position="true"
                                       :action-row="{ showNow: false, showPreview: false, showCancel: false, showSelect: false,  }"
                                       input-class-name="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                       menu-class-name=""
                                       
                                       
                                       ></VueDatePicker>
                                    
                                       <span v-if="errorsData.dataUrodzenia && !dataUrodzenia"  class="mt-2 text-sm text-red-600" id="dataUrodzenia-error">{{ errorsData.dataUrodzenia }}</span>
                                 </div>


                                 <div class="sm:col-span-2"  v-if="dataWiek.wiek">
                                    <label for="age" class="block text-sm font-medium leading-6 text-gray-900">Wiek</label>
                                    <div class="mt-2">
                                       <input
                                          readonly 
                                          type="text"
                                          name="age"
                                          id="age"
                                    
                                          :value="`${dataWiek.wiek.years} lat, ${dataWiek.wiek.months} ${odmienMiesiace(dataWiek.wiek.months)}, ${dataWiek.wiek.days} ${odmienDni(dataWiek.wiek.days)}`"
                                          @input="handleInput"
                                          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                          >
                                    </div>
                                    <span v-if="dataWiek.error" class="mt-2 text-sm text-red-600" id="age-error">{{ dataWiek.error }}</span>
                                 </div>


                                 <div class="sm:col-span-2"  v-if="dataWiek.error">
                                    <label for="age" class="block text-sm font-medium leading-6 text-red-600">Wiek</label>
                                    <div class="mt-2">
                                       <input
                                          type="text"
                                          class="block w-full rounded-md border-0 py-1.5 text-red-600 shadow-sm ring-1 ring-inset ring-red-600 placeholder:text-red-600focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                                          >
                                    </div>
                                    <span v-if="dataWiek.error" class="mt-2 text-sm text-red-600" id="age-empty-error">{{ dataWiek.error }}</span>
                                 </div>

                           </div>
                        </div>
               

                        <div v-if="loading">
                           Loading...
                        </div>
                        <div v-else>
                           <RatioSmallCards class="pt-5" v-model="isMale"></RatioSmallCards>
                        </div>
                     </div>

                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 pb-10 mb-10 rounded-md shadow-sm showSmooth"  v-if="detailsRaw && categoryAge || isLoaded">
                        <!-- Content goes here -->
                           <div class="border-b border-gray-200 pb-5 mb-5">
                              <h2 class="text-2xl font-medium leading-6 text-gray-900">Wprowadź wyniki surowe</h2>
                              <p class="mt-2 max-w-4xl text-sm text-gray-500">Wprowadź w poniższe pola wyniki surowe aby otrzymać wyniki przeliczone oraz opis uwzględniającego normy wiekowe.</p>
                           </div>

                           <div class="home">
                              <div>
                                 <div class="mt-8 flex flex-col">
                                    <div class="-my-2 -mx-4 overflow-x-auto">
                                       <div class="inline-block min-w-full py-2 align-middle">
                                          <div class="overflow-hidden">
                                             <table class="min-w-full divide-y divide-gray-300">
                                                <thead>
                                                   <tr>
                                                      <th scope="col" class="pl-1 py-3.5 text-left text-sm font-semibold text-gray-900"></th>

                                                      <th scope="col" class="pl-1 py-3.5 text-left text-sm font-semibold text-gray-900">Test</th>
                                                      <th scope="col" class="pl-1 py-3.5 text-left text-sm font-semibold text-gray-900">Wynik surowy</th>
                                                      <th scope="col" class="pl-1 py-3.5 text-left text-sm font-semibold text-gray-900">Wynik przeliczony</th>
                                                   </tr>
                                                </thead>
                                                <tbody class="divide-y divide-gray-200 bg-white">
                                                   <template v-for="(item, i) in detailsRaw">

                                                   <tr v:key="item.id" 
                                                   :class="[0, 1, 2, 3, 4, 5, 6, 10, 12, 13, 14].includes(i) ? 'bg-gray-50' : 'bg-white' 
              ">
                                                      <td class="pl-1 py-4 text-base md:text-xl text-gray-400 align-top">{{(item.id)}}</td>

                                                      <td class="px-1 py-4 text-base md:text-xl align-top">{{item.name}}</td>
                                                      <td class="whitespace-nowrap px-0 py-4 text-xl text-gray-900">

                                                            <input 
                                                               type="number"  
                                                               :name="item.name"
                                                               v-model="item.rawValue"  
                                                               @blur="updateValue(item)" 
                                                               :class="{
                                                                  'bg-green-400 ring-green-600': (item.rawValue === 0 || item.rawValue) && !item.error,
                                                                  'bg-orange-200 ring-orange-600': item.error
                                                               }"
                                                               placeholder="" 
                                                               class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-2 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 text-sm font-semibold text-gray-900 transition duration-1000"
                                                               style="transition-property: background-color;"
                                                               >


                                                      </td>
                                                      <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900"
                                                      
                                                      :class="{
                                                                  'text-gray-900 showSmooth': item.rawValue && !item.error,
                                                                  'text-orange-600 showSmooth': item.error
                                                               }"
                                                      
                                                      >{{ item.error != null ? item.error : item.value }}</td>
                                                   </tr>
                                                   <tr v-if="i === 6" :key="'additional-' + item.id" class="bg-gray-50 text-gray-400">
                                                      <td></td>

                                                      <td class="whitespace-nowrap px-3 py-4 text-base md:text-xl pb-12">SUMA WP testów 1—7</td>
                                                      <td></td>
                                                      <td class="whitespace-nowrap px-3 py-4 text-xl pb-12">{{ calculateSumInRange(detailsRaw, 0, 6) }}</td>
                                                   </tr>

                                                   <tr v-if="i === 9" :key="'additional-' + item.id" class="text-gray-400 ">
                                                      <td></td>

                                                      <td class="whitespace-nowrap px-3 py-4 text-base md:text-xl pb-12">SUMA WP testów 8—10</td>
                                                      <td></td>
                                                      <td class="whitespace-nowrap px-3 py-4 text-xl pb-12">{{ calculateSumInRange(detailsRaw, 7, 9) }}</td>
                                                   </tr>
                                                   <tr v-if="i === 14" :key="'additional-' + item.id" class="bg-gray-50 text-gray-400">
                                                      <td></td>

                                                      <td class="whitespace-nowrap px-3 py-4 text-base md:text-xl pb-12">SUMA WP testów 13—15</td>
                                                      <td></td>
                                                      <td class="whitespace-nowrap px-3 py-4 text-xl pb-12">{{ calculateSumInRange(detailsRaw, 12, 14) }}</td>
                                                   </tr>
                                                   <tr v-if="i === 17" :key="'additional-' + item.id" class="text-gray-400">
                                                      <td></td>

                                                      <td class="whitespace-nowrap px-3 py-4 text-base md:text-xl">SUMA WP testów 16—18</td>
                                                      <td></td>
                                                      <td class="whitespace-nowrap px-3 py-4 text-xl pb-12">{{ calculateSumInRange(detailsRaw, 15, 17) }}</td>
                                                   </tr>

                                                   </template> 
                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>  
                     </div>

                     <!-- Placeholder -->
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white pt-7 pb-7 mb-10 rounded-md shadow-sm opacity-50"  v-if="detailsRaw  && !categoryAge  && !isLoaded">
                           <div>
                              <h2 class="text-2xl font-medium leading-6 text-gray-900">Wprowadź wyniki surowe</h2>
                              <p class="mt-2 max-w-4xl text-sm text-gray-500">Wprowadź w poniższe pola wyniki surowe aby otrzymać wyniki przeliczone oraz opis uwzględniającego normy wiekowe.</p>
                           </div>
                     </div>
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white pt-7 pb-7 mb-10 rounded-md shadow-sm opacity-50"  v-if="detailsRaw  && !categoryAge  && !isLoaded">
                           <div>
                              <h2 class="text-2xl font-medium leading-6 text-gray-900">Sfery rozwoju</h2>
                           </div>
                     </div>
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white pt-7 pb-7 mb-10 rounded-md shadow-sm opacity-50"  v-if="detailsRaw  && !categoryAge  && !isLoaded">
                           <div>
                              <h2 class="text-2xl font-medium leading-6 text-gray-900">Skale inteligencji</h2>
                           </div>
                     </div>
                     
                     





                     <!-- Sfery Rozwoju-->
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm"  v-if="detailsRaw && dataUrodzenia">
                        <div>
                           <div class="mt-0 flex flex-col">
                              <div class="">
                                 <h2 class="text-2xl font-medium leading-6 text-gray-900">Sfery rozwoju</h2>
                                 <!-- <p class="mt-2 max-w-4xl text-sm text-gray-500">Wprowadź w poniższe pola wyniki surowe aby otrzymać wyniki przeliczone oraz opis uwzględniającego normy wiekowe.</p> -->
                              </div>
                              <div class="-my-2 -mx-4 overflow-x-auto">
                                 <div class="inline-block min-w-full py-2 align-middle">

                                    <div class="overflow-hidden">

                                       <table class="min-w-full divide-y divide-gray-300">
                                          <thead>
                                             <tr>
                                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">suma WP</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">WP</th>
                                             </tr>
                                          </thead>
                                          <tbody class="divide-y divide-gray-200 bg-white">
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Zdolności poznawcze</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 0, 6) }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ getValueByRawAreas(idspAreas, calculateSumInRange(detailsRaw, 0, 6), 0 ) }}</td>
                                             </tr>

                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Umiejętności psychomotoryczne</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 7, 9) }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ getValueByRawAreas(idspAreas, calculateSumInRange(detailsRaw, 7, 9), 1)  }}</td>
                                             </tr>
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Kompetencje społeczno-emocjonalne</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">—</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 10, 10) }}</td>
                                             </tr>
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Matematyka</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">—</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 11, 11) }}</td>
                                             </tr>
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Język</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 12, 14) }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ getValueByRawAreas(idspAreas, calculateSumInRange(detailsRaw, 12, 14), 1)  }}</td>
                                             </tr>
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Motywacja</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ calculateSumInRange(detailsRaw, 15, 17) }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ getValueByRawAreas(idspAreas, calculateSumInRange(detailsRaw, 15, 17), 1)  }}</td>
                                             </tr>
                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> 
                     </div>

                     <!-- Skale inteligencji-->
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm"  v-if="detailsRaw && dataUrodzenia">
                        <div>
                           <div class="mt-0 flex flex-col">
                              <div class="">
                                 <h2 class="text-2xl font-medium leading-6 text-gray-900">Skale inteligencji</h2>
                                 <!-- <p class="mt-2 max-w-4xl text-sm text-gray-500">Wprowadź w poniższe pola wyniki surowe aby otrzymać wyniki przeliczone oraz opis uwzględniającego normy wiekowe.</p> -->
                              </div>
                              <div class="-my-2 -mx-4 overflow-x-auto">
                                 <div class="inline-block min-w-full py-2 align-middle">

                                    <div class="overflow-hidden">

                                       <table class="min-w-full divide-y divide-gray-300">
                                          <thead>
                                             <tr>
                                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">WP</th>
                                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">IQ</th>
                                             </tr>
                                          </thead>
                                          <tbody class="divide-y divide-gray-200 bg-white">
                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Płynna<p class="text-sm font-light text-gray-400">(suma WP testów 1-7)</p></td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">{{ computedPlynna }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedPlynnaIq }}
                                                </td>
                                             </tr>
                                             
                                             <tr v-if="computedIsMinimumPoints">
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Skrystalizowana
                                                   <p v-if="oneLanguageExerciseIsNull" class="text-sm font-light text-gray-400">(suma WP testów 13-15) : 2 + WP testu 12</p>
                                                   <p v-else class="text-sm font-light text-gray-400">(suma WP testów 13-15) : 3 + WP testu 12</p>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedSkrystalizowana }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                  {{ computedSkrystalizowanaIq }}
                                                </td>
                                             </tr>
                                             <tr v-else>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Skrystalizowana
                                                   <p class="text-sm font-light text-gray-400"> aby obliczyć wynki w "Skali Inteligencji Skrystalizowanej" dziecko musi uzyskać przynajmniej po jednym punkcie surowym w "Rozumowaniu logiczno-matematycznym" i dowolnym teście językowym.</p>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                </td>
                                             </tr>



                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-400 sm:pl-6">Ogólna<p class="text-sm font-light text-gray-400">(suma WP testów 1-7) : 7</p></td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedOgolne1 }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">—</td>
                                             </tr>

                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-400 sm:pl-6">Ogólna
                                                   <p v-if="oneLanguageExerciseIsNull" class="text-sm font-light text-gray-400">[(suma WP testów 13-15) : 2 + WP testu 12] : 2</p>
                                                   <p v-else class="text-sm font-light text-gray-400">[(suma WP testów 13-15) : 3 + WP testu 12] : 2</p>
                                                </td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedOgolne2 }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">—</td>
                                             </tr>


                                             <tr>
                                                <td class="whitespace-normal break-words py-4 pl-4 pr-3 text-sm  font-medium text-gray-900 sm:pl-6">Ogólna<p class="text-sm font-light text-gray-400">Suma średnich</p></td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedOgolne3 }}</td>
                                                <td class="whitespace-nowrap px-3 py-4 text-xl text-gray-900">
                                                   {{ computedOgolnaIq }}
                                                </td>
                                             </tr>

                                          </tbody>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> 
                     </div>




                     <!-- <div v-if="detailsRaw && categoryAge && !isLoaded">
                        <BehaviorQuestions v-model="behaviorSentences" :isMale="isMale" :firstName="firstName"></BehaviorQuestions>
                     </div> -->



                     <!-- error -->
                     <div v-if="errorsData.text" class="mb-5">
                        <div class="rounded-md bg-red-50 p-4">
                           <div class="flex">
                              <div class="flex-shrink-0">
                                 <component :is="icons.XCircleIcon" class="h-5 w-5 text-red-400" aria-hidden="true" ></component>
                              </div>
                              <div class="ml-3">
                                 <h3 class="text-sm font-medium text-red-800">{{errorsData.text}}</h3>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div class="flex justify-center" v-if="!isLoaded && computedPlynna">
                        <button type="button" @click="handleNext('preview', errorsData)" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-dla-600  focus:outline-none focus:ring-2 focus:ring-green-dla-600 focus:ring-offset-2">
                           dalej
                        </button>
                     </div>

                     <div class="flex justify-center opacity-10" v-if="!isLoaded && !computedPlynna">
                        <button type="button"  disabled class="inline-flex items-center rounded-md border border-transparent bg-gray-500 px-6 py-3 text-base font-medium text-white cursor-not-allowed hover:bg-gray-500">
                           dalej
                        </button>
                     </div>

                  </div>



                  <!-- <div>

                     <div class="mx-auto max-w-7xl mt-10 sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm min-h-[200px] sm:min-h-[230px]">
                        <h2 class="text-2xl font-medium leading-6 text-gray-900 showSmooth">TYMCZASOWY TEKST</h2>
                        <p class="mt-2 max-w-4xl text-base text-gray-500 whitespace-pre-wrap" ref="textElementVer1">{{ tymczasowyText }}</p>
                        
                        <transition
                           enter-active-class="transition-opacity ease-in duration-1000"
                           enter-from-class="opacity-0"
                           enter-to-class="opacity-100"
                           leave-active-class="transition-opacity ease-in duration-300"
                           leave-from-class="opacity-100"
                           leave-to-class="opacity-0"
                        >

                        </transition>
                     </div>

                  </div> -->



                  <div v-if="isPreview">

                     <div v-if="!isLoaded && !loadedVersion1">
                  
                        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm">
                           <h5 class="mb-2 animate-pulse text-2xl font-medium leading-6 text-neutral-700  dark:text-white">
                              <span class="inline-block min-h-[1.5em] w-2/12 flex-auto rounded-md cursor-wait  text-neutral-300 bg-current align-middle opacity-50"></span>
                           </h5>
                           <p  class="mb-4 mt-2 max-w-4xl animate-pulse text-base text-neutral-700 dark:text-white">
                              <span class="inline-block min-h-[1em] rounded-md w-10/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-6/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-4/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-7/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; &nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-9/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>
                           
                           </p>
            
                        </div>
                     </div>


                     <div v-if="!isLoaded && loadedVersion1" class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm min-h-[200px] sm:min-h-[230px]">
                        <h2 class="text-2xl font-medium leading-6 text-gray-900 showSmooth">Wersja 1</h2>
                        <p class="mt-2 max-w-4xl text-base text-gray-500 whitespace-pre-wrap" ref="textElementVer1"></p>
                        
                        <transition
                           enter-active-class="transition-opacity ease-in duration-1000"
                           enter-from-class="opacity-0"
                           enter-to-class="opacity-100"
                           leave-active-class="transition-opacity ease-in duration-300"
                           leave-from-class="opacity-100"
                           leave-to-class="opacity-0"
                        >
                           <button  v-if="showCopyBtnVersion1" @click="copyToClipboard(textareaToCopy); copiedTooltip('tooltip0'), markCopied(documentId, 'copied1')" id="tooltip0" type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="material-icons" > content_copy </span> &nbsp;Kopiuj
                           </button>
                        </transition>
                     </div>


                     <div  v-if="isLoaded" class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                        <h2 class="text-2xl font-medium leading-6 text-gray-900" id="opis">Wersja 1</h2>
                        <p class="mt-2 max-w-4xl text-base text-gray-500"> <p class="whitespace-pre-wrap">{{ textareaToCopy }}</p></p>
                        <button  @click="copyToClipboard(textareaToCopy); copiedTooltip('tooltip0'), markCopied(documentId, 'copied1')" id="tooltip0" type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                           <span class="material-icons" > content_copy </span> &nbsp;Kopiuj
                        </button>
                     </div>






                     <div v-if="!isLoaded && !loadedVersion2">
                        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm">
                           <h5 class="mb-2 animate-pulse text-2xl font-medium leading-6 text-neutral-700  dark:text-white">
                              <span class="inline-block min-h-[1.5em] w-2/12 flex-auto rounded-md cursor-wait  text-neutral-300 bg-current align-middle opacity-50"></span>
                           </h5>

                           <p  class="mb-4 mt-2 max-w-4xl animate-pulse text-base text-neutral-700 dark:text-white">
                              <span class="inline-block min-h-[1em] rounded-md w-10/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-6/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-4/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-7/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; &nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md w-9/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                              <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>
                           
                           </p>
                           <!-- <button  @click="copyToClipboard(text); copiedTooltip(`tooltip2`)" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              proszę czekać...
                           </button> -->
                        </div>
                     </div>


                     <div v-if="!isLoaded && loadedVersion2">
                        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                        
                           <h2 class="text-2xl font-medium leading-6 text-gray-900 showSmooth">Wersja 2</h2>
                           <!-- <p class="mt-2 max-w-4xl text-base text-gray-500">  <p>{{ textareaGPT }}</p></p> -->
                           <p class="mt-2 max-w-4xl text-base text-gray-500">  <p ref="textElementVer2" class="whitespace-pre-wrap"></p></p>

                           <transition
                              enter-active-class="transition-opacity ease-in duration-1000"
                              enter-from-class="opacity-0"
                              enter-to-class="opacity-100"
                              leave-active-class="transition-opacity ease-in duration-300"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0"
                           >
                              <button v-if="showCopyBtnVersion2" @click="copyToClipboard(textareaGPT); copiedTooltip(`tooltip2`), markCopied(documentId, 'copied2')" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                 <span class="material-icons"> content_copy </span> &nbsp;Kopiuj
                              </button>
                           </transition>
               
                        </div>
                     </div>

                     <div v-if="isLoaded && textareaGPT">
                        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                        
                           <h2 class="text-2xl font-medium leading-6 text-gray-900" id="v1">Wersja 2</h2>
                           <p class="mt-2 max-w-4xl text-base text-gray-500"><p class="whitespace-pre-wrap">{{ textareaGPT }}</p></p>

                           <button  @click="copyToClipboard(textareaGPT); copiedTooltip(`tooltip2`), markCopied(documentId, 'copied2')" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="material-icons"> content_copy </span> &nbsp;Kopiuj
                           </button>
               
                        </div>
                     </div>
                  </div>
               </div>
            </div>
   </main>
</div>


</template>

<script setup>
import { reactive } from '@vue/reactivity';
import { watchEffect, watch } from '@vue/runtime-core';
import { ref, onMounted, onBeforeUnmount, computed } from "vue";

import { format, subMonths } from 'date-fns';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Navbar from '@/components/Navbar.vue';
import RatioSmallCards from '@/components/RatioSmallCards.vue';
import BehaviorQuestions from '@/components/BehaviorQuestions.vue';



// import ids2data from '@/data/ids-2-pelny-data.js';
// import idsPdata from '@/data/ids-p-ogolny-data.js';
// import ids2dataRaw from '@/data/ids-2-data-raw';
import idspAreas from '@/data/ids-p-data-areas';
import idspScales from '@/data/ids-p-data-scales';
import idsPdataSentences from '@/data/ids-p-data-pelny-sentences.js';



import daneIQczynnikow from '@/data/ids-2-pelny-iloraz-czynnikow.js';
import ids2DataIqOgolnyRaw from '@/data/ids-2-data-iq-ogolny.js';
import getAge from '../composables/getAge.js';
import changeTextBasedOnSex from '../composables/changeTextBasedOnSex.js';
import getDescriptionsById from '../composables/getDescriptionsById';
import getDocById from '../composables/getDocById';
import askGPT from '@/gpt/askGPT-2.js';
import getUser from '../composables/getUser';


import firebaseTimestampToDate from '../composables/detail/firebaseTimestampToDate.js';
import dateFormattingInCallendar from '../composables/detail/dateFormattingInCallendar.js';
import removeMinorVersions from '../composables/detail/removeMinorVersions.js';
import copyToClipboard from '../composables/detail/copyToClipboard.js';
import copiedTooltip from '../composables/detail/copiedTooltip.js';
import increaseDescriptionCount from '../composables/detail/increaseDescriptionCount.js';
import { makeSentencesFull, makeSentences, IDSPmakeSentences } from '../composables/detail/sentences.js';
import { isMarked, chooseOption } from '../composables/detail/options.js';
import { saveDescriptionToDb, saveGPTDescriptionToDb, markCopied } from '../composables/detail/descriptions.js';
import { XCircleIcon } from "@heroicons/vue/24/solid"
import scrollToId from '../composables/detail/scrollToId.js';
import { typeSentence, waitForMs } from '../composables/detail/typeSentence.js';
import validateForm from '../composables/detail/formValidation.js';
import { capitalizeFirstName } from '../composables/detail/capitalizeFirstName.js';
import { odmienMiesiace, odmienDni } from '../composables/detail/dataUrodzeniaText.js';
import { makePrompt } from '../composables/detail/makePrompt.js';
import { calculatePoints, sumExercisesByPairs, sumExercisesByPairsSurowy, getIqByPoints } from '../composables/calculateIQ.js';
import czestioweCzynnikiInteligencjiObj from '../composables/detail/ids2-iloraz-czynnikow.js';

import { adjustGenderAndName } from '../composables/detail/adjustGenderAndName.js';


// Define props
const props = defineProps({
  id: String,
});

// export default {
//    name: 'Home',
//    components: {
//       VueDatePicker, Navbar, RatioSmallCards, BehaviorQuestions
//    },

   
//    props: ["id"],
//    setup(props) {

   
      // const alldata = ref(ids2data);
      // const daneIQczynnikowIloraz = ref(daneIQczynnikow);
      
      // const ids2DataIqOgolny = ref(ids2DataIqOgolnyRaw);

      // const chosenOptions = ref([]);
      const dataWiek = ref({wiek: null, error: null});
      const isChoosingPoints = ref(true);
      const isPreview = ref(false);
      const textareaToCopy = ref('');
      const textareaGPT = ref('');

      const { user } = getUser()
      const dataBadania = ref(null);
      const dataUrodzenia = ref(null);
      const firstName = ref(null);
      const isMale = ref(true);
      const documentId = ref("");
      const iqPrzeliczone = ref(null);
      // const exerciseSums = ref(null);
      // const detailedSums = ref(null);


      const rawDataByAge = ref(null);

      // const klockiSurowy = ref(null);
      // const opowiadanieSurowy = ref(null);
      // const papugiSurowy = ref(null);
      // const szeregiJednorodneSurowy = ref(null);
      // const brylySurowy = ref(null);
      // const matryceSurowy = ref(null);
      // const kategorieSurowy = ref(null);
      // const krazkiSurowy = ref(null);
      // const obrazekSurowy = ref(null);
      // const kwadratySurowy = ref(null);
      // const szeregiMieszaneSurowy = ref(null);
      // const zrotowaneBrylySurowy = ref(null);
      // const wykluczanieSurowy = ref(null);
      // const przeciwienstwaSurowy = ref(null);
      const categoryAge = ref(null);
      // const behaviorSentences = ref(null);

      // const tymczasowyText = ref(null);


      let isLoaded = ref(null);
      const loading = ref(true);
      const errorsData = ref({});
      // const error = ref(false);

      const testTypeInfo = {
         color: "bg-idsp-ogolny",
         type: "IDS-P ogólny surowy",
      }


      const plecOptions = [
         { name: '👨 M', inStock: true, isMale: true },
         { name: '👩 K', inStock: true, isMale: false },
      ]


      const icons = reactive({
      XCircleIcon: XCircleIcon,

    })

      const flowCalendar = ref(['year', 'month', 'calendar']);
      const minDate = computed(() => subMonths(dataBadania.value, 72)); // ilosc miesiecy - tu jest 1 dzien tolerancji dla bezpieczenstwa
      const maxDate = computed(() => subMonths(dataBadania.value, 36));



      const textElementVer1 = ref(null);
      const textElementVer2 = ref(null);

      const loadedVersion1 = ref(false);
      const loadedVersion2 = ref(false);

      const showCopyBtnVersion1 = ref(false);
      const showCopyBtnVersion2 = ref(false);

      const shouldCancel = ref(false);
      
   
      const detailsRaw = ref([
         {name:"Percepcja wzrokowa", area: 'Zdolności poznawcze', id:1, rawValue: null, value: null, error:null},
         {name:"Pamięć słuchowa",  area: 'Zdolności poznawcze', id:2, rawValue: null, value: null, error:null},
         {name:"Uwaga selektywna",  area: 'Zdolności poznawcze', id:3, rawValue: null, value: null, error:null},
         {name:"Pamięć fonologiczna",  area: 'Zdolności poznawcze', id:4, rawValue: null, value: null, error:null},
         {name:"Pamięć wzrokowo-przestrzenna", area: 'Zdolności poznawcze', id:5, rawValue: null, value: null, error:null},
         {name:"Rozumowanie przestrzenne", area: 'Zdolności poznawcze', id:6, rawValue: null, value: null, error:null},
         {name:"Rozumowanie pojęciowe", area: 'Zdolności poznawcze', id:7, rawValue: null, value: null, error:null},
         {name:"Motoryka", area: 'Umiejętności psychomotoryczne', id:8, rawValue: null, value: null, error:null},
         {name:"Manipulacja", area: 'Umiejętności psychomotoryczne', id:9, rawValue: null, value: null, error:null},
         {name:"Koordynacja wzrokowo-ruchowa", area: 'Umiejętności psychomotoryczne', id:10, rawValue: null, value: null, error:null},
         {name:"Kompetencje społeczno-emocjonalne", area: 'Kompetencje społeczno-emocjonalne', id:11, rawValue: null, value: null, error:null},
         {name:"Rozumowanie logiczno-matematyczne", area: 'Matematyka', id:12, rawValue: null, value: null, error:null},
         {name:"Mowa czynna", area: 'Język', id:13, rawValue: null, value: null, error:null},
         {name:"Słownik", area: 'Język', id:14, rawValue: null, value: null, error:null},
         {name:"Mowa bierna", area: 'Język', id:15, rawValue: null, value: null, error:null},
         {name:"Odraczanie nagrody", area: 'Motywacja', id:16, rawValue: null, value: null, error:null},
         {name:"Wytrwałość", area: 'Motywacja', id:17, rawValue: null, value: null, error:null},
         {name:"Satysfakcja z osiągnięć", area: 'Motywacja', id:18, rawValue: null, value: null, error:null},


      ])
   

      const detailsRawIq = ref([
         {name:"iq płynna", area: 'Płynna', id:"iq płynna", rawValue: null, value: null, error:null},
         {name:"iq skrystalizowana", area: 'Skrystalizowana', id:"iq skrystalizowana", rawValue: null, value: null, error:null},
         {name:"iq ogólne", area: 'Ogólna', id:"iq ogólne", rawValue: null, value: null, error:null},
      ])
      

      const ageCategories = [
            {"start": {"years": 2, "months": 11, "days": 0}, "end": {"years": 3, "months": 2, "days": 30}, "category": "2113230"},
            {"start": {"years": 3, "months": 3, "days": 0}, "end": {"years": 3, "months": 5, "days": 30}, "category": "333530"},
            {"start": {"years": 3, "months": 6, "days": 0}, "end": {"years": 3, "months": 11, "days": 30}, "category": "3631130"},
            {"start": {"years": 4, "months": 0, "days": 0}, "end": {"years": 4, "months": 5, "days": 30}, "category": "404530"},
            {"start": {"years": 4, "months": 6, "days": 0}, "end": {"years": 4, "months": 11, "days": 30}, "category": "4641130"},
            {"start": {"years": 5, "months": 0, "days": 0}, "end": {"years": 5, "months": 5, "days": 30}, "category": "505530"},
            {"start": {"years": 5, "months": 6, "days": 0}, "end": {"years": 5, "months": 11, "days": 30}, "category": "5651130"},
      ];




      const oneLanguageExerciseIsNull = ref(null)


   const resetDetailsRaw = () => {
         detailsRaw.value.forEach(item => {
            item.rawValue = null;
            item.value = null;
            item.error = null;
         });
         };



   
      onBeforeUnmount(() => {
         shouldCancel.value = true;
      });
      
      
      
      
      onMounted(async () => {
         try {
            dataBadania.value = new Date();

            if (props.id !== "new") {
               // console.log("props.id: ", props.id)
               isLoaded.value = true;
               isPreview.value = true;


               const description = await getDescriptionsById("descriptions", props.id);
               if (description) {
                  documentId.value = props.id;
                  firstName.value = description.firstName;
                  dataUrodzenia.value = firebaseTimestampToDate(description.dataUrodzenia);
                  dataBadania.value = firebaseTimestampToDate(description.dataBadania);
                  isMale.value = plecOptions.find(option => option.isMale === description.isMale).isMale;
                  iqPrzeliczone.value = description.iqPrzeliczone
                  textareaToCopy.value = description.descriptionGeneric;
                  textareaGPT.value = description.descriptionGPT;
                  detailsRaw.value = description.detailsRaw;

               } 

            } else {
               isLoaded.value = false;
               isPreview.value = false;
            }

            loading.value = false
         } catch (err) {
         console.error(err);
         }
      })







      




      watchEffect(() => {
         if (dataUrodzenia.value && !isLoaded.value) {
            resetDetailsRaw();
         }
      });



      watchEffect(() => {
         const fetchData = async () => {
            if (dataUrodzenia.value && dataBadania.value && !isLoaded.value) {
               dataWiek.value = getAge(dataUrodzenia.value, dataBadania.value);

               if (dataWiek.value) {
               dataWiek.error = null;
               }

               if (dataWiek.error === null) 
               {
               categoryAge.value = getAgeCategory(dataWiek.value, ageCategories);
                  if (!categoryAge.value){
                     dataWiek.error = 'Wyglada na to że ten test nie jest przeznaczony dla osoby w tym wieku.';
                  } else 
                  {
                     const categoryAgeItems = await getDocById("data-IdsP-RAW", categoryAge.value);
                     rawDataByAge.value = categoryAgeItems.items;
                  }
               }
            }
         };

         fetchData().catch(error => console.error(error));
         });





      const handleInput = () => {
         firstName.value = capitalizeFirstName(firstName.value);
      };




      const handleNext = async (option, errorsData) => {

         const validation = validateForm(firstName, dataUrodzenia);

         if (option === 'preview' && validation.isValid) {
            scrollToId('top')
            isChoosingPoints.value = false;
            isPreview.value = true;
         }  
         else if (option === 'preview' && !validation.isValid) {
               errorsData.firstName = validation.errors.firstName;
               errorsData.dataUrodzenia = validation.errors.dataUrodzenia;
               errorsData.text = validation.errorsText;
         }

      };




      watch(isPreview, async(newValue) => {
         await waitForMs(900);
         loadedVersion1.value = true;
         await waitForMs(200);

         if (newValue) {  
            if (!isLoaded.value && loadedVersion1.value && textElementVer1.value !== null) {

               const subjectDetails = {
                  firstName: firstName.value,
                  dataUrodzenia: dataUrodzenia.value,
                  dataBadania: dataBadania.value,
                  selectedSex: isMale.value,
                  isMale: isMale.value,
                  chosenOptions: extractRawValues(detailsRaw.value),
                  // rawValues: extractRawValues(detailsRaw.value), // TODO
                  ...(iqPrzeliczone !== undefined && { iqPrzeliczone: iqPrzeliczone.value }),
                  ...(detailsRaw !== undefined && { detailsRaw: detailsRaw.value })
               }


               let idsPdataSentences;
               if (!idsPdataSentences) {
                  const module = await import('@/data/ids-p-data-pelny-sentences.js');
                  idsPdataSentences = module.default;
               }

               const sentencesTemp = IDSPmakeSentences(detailsRaw.value, detailsRawIq.value, idsPdataSentences.sections, dataBadania.value);
               const doneSentences = adjustGenderAndName(sentencesTemp, !isMale.value, firstName.value)
               textareaToCopy.value = usunSpacjeNaPoczatkuZdan(doneSentences);

               documentId.value = await saveDescriptionToDb(textareaToCopy.value, testTypeInfo, user, subjectDetails);
               await increaseDescriptionCount(user.value.uid) 

               
               const prompt = makePrompt(textareaToCopy, isMale, firstName, dataWiek);
               const typeSentencePromise = typeSentence(textElementVer1, textareaToCopy.value, () => shouldCancel.value);
               const askGPTPromise = askGPT(prompt);
                  
               // Wykonaj obie funkcje równocześnie
               Promise.all([typeSentencePromise, askGPTPromise]).then(async ([isVersion1Written, gptValue]) => {
                     textareaGPT.value = gptValue;
                     await saveGPTDescriptionToDb(textareaGPT.value, documentId.value);
                     
                     showCopyBtnVersion1.value = isVersion1Written;
                     await waitForMs(2000);

                     if (isVersion1Written) {
                        loadedVersion2.value = true;
                        shouldCancel.value = false;
                        await waitForMs(200);
                        showCopyBtnVersion2.value = await typeSentence(textElementVer2, textareaGPT.value, () => shouldCancel.value);
                     }
               }).catch((error) => {
                     console.log("An error occurred:", error);
               });

            } 
         }
      });







function isWithinRange(age, start, end) {
    if (age.years < start.years || age.years > end.years) {
        return false;
    }
    if (age.years === start.years && (age.months < start.months || (age.months === start.months && age.days < start.days))) {
        return false;
    }
    if (age.years === end.years && (age.months > end.months || (age.months === end.months && age.days > end.days))) {
        return false;
    }
    return true;
}


function getAgeCategory(dataWiek, ageCategories) {
    const age = dataWiek.wiek;
    for (const category of ageCategories) {
        if (isWithinRange(age, category.start, category.end)) {
            return category.category;
        }
    }
    return false; // Jeśli wiek nie pasuje do żadnego przedziału
}



function getValueByRaw(rawDataByAge, value, id) {

      const categoryItem = rawDataByAge.find(item => item.id === id);

      if (!categoryItem) {
         console.warn("Nie znaleziono kategorii z id:", id);
         return null;
      }


    if (value === null) {
        return null;
    }

    for (const key in categoryItem.rows) {
        const range = categoryItem.rows[key];
        // Sprawdź czy min i max nie są null przed porównaniem
        if (range.min !== null && range.max !== null) {
            if ((value >= range.min) && (value <= range.max)) {
                // Usuń błąd, jeśli jest
                const itemFound = detailsRaw.value.find(item => item.id === id);
                if (itemFound) {
                    itemFound.error = null; // Tutaj ustawiasz nową wartość dla error
                }
                return key;
            }
        }
    }


    const itemFound = detailsRaw.value.find(item => item.id === id);
    if (itemFound) {
        itemFound.error = "Błędna wartość"; // Tutaj ustawiasz nową wartość dla error
    }

    return null;
}


function getValueByRawAreas(areasData, value, number) {

   const categoryItem = areasData[number];
  
    for (const key in categoryItem) {
        const range = categoryItem[key];

        if (range.min !== null && range.max !== null) {
            if ((value >= range.min) && (value <= range.max)) {
                return key;
            }
        }
    }
   //  console.warn("Nie znaleziono pasującego zakresu dla wartości:", value);
    return null;
}


// TODO wszedzie ta funkcje trzeba zmodyfikowac, najlepiej ja zaimportowac, zmienilem tem.rawValue === null || item.rawValue === ""
   const updateValue = (item) => {
      if (item.rawValue === null || item.rawValue === "") {
         item.value = null;
         return;
      }
      item.value = getValueByRaw(rawDataByAge.value, item.rawValue, item.id);
   
    };



    const calculateSumInRange = (items, startIndex, endIndex) => {
      if (!items) {
         return null;
      }

      // Wyciągamy interesujący nas zakres elementów
      const rangeItems = items.slice(startIndex, endIndex + 1);

      // Filtrujemy, by pominąć elementy, które mają wartość "" lub null
      const filteredItems = rangeItems.filter(item => item.value !== "" && item.value !== null && item.value !== undefined);

      // Jeśli nie ma elementów do zsumowania, zwracamy null
      if (filteredItems.length === 0) {
         return null;
      }

      // Obliczamy sumę dla przefiltrowanych elementów
      return filteredItems.reduce((acc, item) => {
         return acc + (Number.isFinite(Number(item.value)) ? Number(item.value) : 0);
      }, 0);
   };




   function extractRawValues(dataArray) {
      return dataArray.map(item => item.rawValue);
   }


   function updateRawValues(data, rawValues) {
      data.forEach((item, index) => {
         if (index < rawValues.length) {
            item.rawValue = rawValues[index];
         }
      });
      return data;
   }




      const sumRange1_7 = computed(() => calculateSumInRange(detailsRaw.value, 0, 6));
      const sumRange12 = computed(() => calculateSumInRange(detailsRaw.value, 11, 11));
      const sumRange13_15_mean = computed(() => {
         const sumInRange = calculateSumInRange(detailsRaw.value, 12, 14);
         if (sumInRange === null) {
            return null;
         }
         return sumInRange / 3;
      });

      const computedPlynna = computed(() => sumRange1_7.value);
      
      const computedSkrystalizowana = computed(() => {
         if (sumRange13_15_mean.value === null || sumRange12.value === null) {
            return null;
         }
         if (oneLanguageExerciseIsNull.value) {
            return ((sumRange13_15_mean.value * 3 / 2) + sumRange12.value).toFixed(2);
         } else {
            return (sumRange13_15_mean.value + sumRange12.value).toFixed(2);
         }
      });

      const computedPlynnaIq = computed(() => {
         if (sumRange1_7.value === null) {
            return null;
         }
         return getValueByRawAreas(idspScales, sumRange1_7.value, 1);
      });

      const computedSkrystalizowanaIq = computed(() => {
         if (sumRange13_15_mean.value === null || sumRange12.value === null) {
            return null;
         }

         if (oneLanguageExerciseIsNull.value) {
            const result = ((sumRange13_15_mean.value * 3 / 2) + sumRange12.value).toFixed(2);
            return getValueByRawAreas(idspScales, result, 3);
         } else {
            const result = (sumRange13_15_mean.value + sumRange12.value).toFixed(2);
            return getValueByRawAreas(idspScales, result, 2);
         }
      });


      const computedOgolnaIq = computed(() => {
         if (computedPlynnaIq.value === null || computedSkrystalizowanaIq.value === null || sumRange1_7.value === null || sumRange13_15_mean.value === null || sumRange12.value === null) {
            return null;
         }

         const differenceIntPlynnaSkryt = Math.abs(computedPlynnaIq.value - computedSkrystalizowanaIq.value);

         if (differenceIntPlynnaSkryt >= 16) {
            return "—";
         }

         const part1 = sumRange1_7.value / 7;
         const part2 = (sumRange13_15_mean.value + sumRange12.value) / 2;
         if (isNaN(part1) || isNaN(part2)) {
            return null;
         }
         const result = (part1 + part2).toFixed(2);
         return getValueByRawAreas(idspScales, result, 0);
      });


      const computedOgolne1 = computed(() => {
         if (sumRange1_7.value === null) {
            return null;
         }
         return (sumRange1_7.value / 7).toFixed(2);
      });


      const computedOgolne2 = computed(() => {
         if (sumRange13_15_mean.value === null || sumRange12.value === null) {
            return null;
         }
         return ((sumRange13_15_mean.value + sumRange12.value) / 2).toFixed(2);
      });


      const computedOgolne3 = computed(() => {
         if (sumRange1_7.value === null || sumRange13_15_mean.value === null || sumRange12.value === null) {
            return null;
         }
         return (((sumRange1_7.value / 7) + ((sumRange13_15_mean.value + sumRange12.value) / 2)).toFixed(2));
      });



      const computedIsMinimumPoints = computed(() => {
         const isMinMath = detailsRaw.value[11]?.rawValue >= 1;
         const isMinLang =  detailsRaw.value[12]?.rawValue >= 1 || detailsRaw.value[13]?.rawValue >= 1 || detailsRaw.value[14]?.rawValue >= 1;
         return isMinMath && isMinLang;
      });


      watch(
      () => [
         detailsRaw.value[12]?.rawValue,
         detailsRaw.value[13]?.rawValue,
         detailsRaw.value[14]?.rawValue
      ],
      ([value12, value13, value14]) => {
         const nullValuesCount = [value12, value13, value14].filter(value => value === null || value === undefined  || value === "" ).length;

         if (nullValuesCount === 0) {
            oneLanguageExerciseIsNull.value = false;
         } else if (nullValuesCount === 1) {
            oneLanguageExerciseIsNull.value = true;
         } else {
            oneLanguageExerciseIsNull.value = false;
         }
      },
      { deep: true } 
      );



      function usunSpacjeNaPoczatkuZdan(input) {
         return input.replace(/(?!\n)^\s+/gm, '');
      }



      watchEffect(() => {
         if (computedPlynna.value) detailsRawIq.value[0].rawValue = computedPlynna.value;
         if (computedSkrystalizowana.value) detailsRawIq.value[1].rawValue = computedSkrystalizowana.value;
         if (computedOgolne3.value) detailsRawIq.value[2].rawValue = computedOgolne3.value;

         if (computedPlynnaIq.value) detailsRawIq.value[0].value = computedPlynnaIq.value;
         if (computedSkrystalizowanaIq.value) detailsRawIq.value[1].value = computedSkrystalizowanaIq.value;
         if (computedOgolnaIq.value) detailsRawIq.value[2].value = computedOgolnaIq.value;
      });


</script>


<style scope>
/* .section {
   padding: 20px;
   border-radius: 7px;
   margin-top: 0px;
   width: 650px;

   display: flex;
   flex-direction: column;
}
.section h2 {
   text-align: left;
   color: black;
   font-weight: 400;
   font-size: 1.8rem;
   padding-bottom: 00px;
   margin-bottom: 15px;
} */

/* .number-container {
   display: flex;
   flex-direction: row;
} */
/* .number-item {
    width: 30px; 
} */
.number-item span {
   display: flex;
   flex-direction: column;
}
.number-item label {
   font-size: 0.9rem;
}

/* .number-container input[type="checkbox"] {
  transform: scale(2.5);
} */
/* .number-container input {
   cursor: pointer;
   width: 24px ;
   height: 24px ;
} */
/* .number-container input:hover {
} */

a {
   margin-right: 0px;
   padding: 2px 5px;
   border-radius: 7px;
}
a:hover {
   background-color: rgb(223, 223, 223);
   cursor: pointer;
}



.home {
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
}

/* .details {
   padding-top: 35px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
} */
/* 
.normal {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid rgb(196, 196, 196);
   background-color: rgb(196, 196, 196);
   color: rgb(196, 196, 196);
}

.normal span {
   font-size: 1.4rem;
}

.normal:hover {
   border: 2px solid #886f88;
   background-color: #886f88;
   transition-duration: 0.5s;
   color: #886f88;
} */
/* 
.choosen {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid #7d367d;
   background-color: #7d367d;
   color: white;
}
.choosen span {
   font-size: 1.4rem;
}

.choosen:hover {
   background-color: #7d367d;
} */

/* .copy {
   padding: 0 35px;
   display: flex;
   justify-content: space-between;
   flex-flow: row-reverse;
} */

button {
   padding: 15px;
   border: 0px;
   border-radius: 7px;
   color: white;
   margin: 5px;
   width: fit-content;
   font-size: 1.2rem;
   display: flex;
}

button:hover {
   /* background-color: rgb(0, 145, 77); */
   background-color: #1664c9;
   color: white;

   transition-duration: 0.3s;
   cursor: pointer;
}

.preview {
   padding: 25px;
   display: flex;
   justify-content: center;
   flex-flow: column wrap;
}

.preview textarea {
   border-radius: 7px;
   padding: 15px;
   font-size: 1.2rem;
   color: #4b4b4b;
   border: 1px solid #dadce0;
   font-family: Roboto, Helvetica, Arial, sans-serif;
   /* width: 90vh; */
   min-height: 300px;
}

.preview textarea:focus {
   outline: none !important;
   border: 1px solid #dadce0;
   /* box-shadow: 0 0 10px #e4e4e4; */
   -webkit-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   -moz-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
}

.backBtn {
   background-color: rgb(207, 207, 207);
}
.mainBtn {
   /* background-color: rgb(0, 196, 104); */
   background-color: #1a73e8;
   padding: 15px 35px;
}

.dp-custom-menu .dp__now_button {
   padding: 10px 35px;
   margin-bottom: 15px;
   margin-left: auto;
   margin-right: auto;
}

/* .dp-custom-menu {
  box-shadow: 0 0 6px #1976d2;
} */

.plecItem {
   border: 1px solid #ddd;
   border-radius: 4px;
   padding: 3px 12px;
   float: left;

   margin-right: 8px;
   margin-left: 8px;
   width: 81px;
}

.control-group {
   margin-top: 20px;
}

.control {
   font-family: arial;
   display: block;
   position: relative;
   padding-left: 30px;
   margin-bottom: 5px;
   padding-top: 3px;
   cursor: pointer;
   font-size: 16px;
}
.control input {
   position: absolute;
   z-index: -1;
   opacity: 0;
}
.control_indicator {
   position: absolute;
   top: 4px;
   left: 0;
   height: 20px;
   width: 20px;
   background: #e6e6e6;
   border: 0px solid #000000;
   border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
   background: #cccccc;
}

.control input:checked ~ .control_indicator {
   background: #7d367d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
   background: #7d367d;
}
.control input:disabled ~ .control_indicator {
   background: #e6e6e6;
   opacity: 0.6;
   pointer-events: none;
}
.control_indicator:after {
   box-sizing: unset;
   content: '';
   position: absolute;
   display: none;
}
.control input:checked ~ .control_indicator:after {
   display: block;
}
.control-radio .control_indicator {
   border-radius: 50%;
}

.control-radio .control_indicator:after {
   left: 7px;
   top: 7px;
   height: 6px;
   width: 6px;
   border-radius: 50%;
   background: #ffffff;
   transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
   background: #7b7b7b;
}
.control-radio .control_indicator::before {
   content: '';
   display: block;
   position: absolute;
   left: -5px;
   top: -5px;
   width: 4.5rem;
   height: 4.5rem;
   margin-left: -1.3rem;
   margin-top: -1.3rem;
   background: #7d367d;
   border-radius: 3rem;
   opacity: 0.6;
   z-index: 99999;
   transform: scale(0);
}
@keyframes s-ripple {
   0% {
      opacity: 0;
      transform: scale(0);
   }
   20% {
      transform: scale(1);
   }
   100% {
      opacity: 0.01;
      transform: scale(1);
   }
}
@keyframes s-ripple-dup {
   0% {
      transform: scale(0);
   }
   30% {
      transform: scale(1);
   }
   60% {
      transform: scale(1);
   }
   100% {
      opacity: 0;
      transform: scale(1);
   }
}
.control-radio input + .control_indicator::before {
   animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
   animation-name: s-ripple-dup;
}

/* 
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
} */







/* kalendarz */
  /* .dp__overlay_cell_active {
    @apply bg-green-dla-400;

   }

   .dp__action_buttons {
      margin:auto;
   }

   .dp__action_buttons button {
      padding: 5px 15px 25px 15px;
   }

   .dp__action_buttons button:hover {
      @apply bg-green-dla-400;

      @apply border-green-dla-400;
   } */


/* .dp__action_buttons{
   @apply bg-green-dla-400;
   text-align: center;
} */


.showSmooth{
  opacity: 0; /* Początkowa wartość */
  animation: showFull 1s ease-in forwards; /* Animacja trwająca 2 sekundy i zmieniająca właściwość do końcowej wartości */
}

@keyframes showFull {
  to {
    opacity: 1;
  }
}




.dp__theme_light {
    --dp-background-color: #fff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #00d06c;
    --dp-primary-disabled-color: #9acfb6;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-marker-color: #ff6f60;
    --dp-tooltip-color: #fafafa;
    --dp-disabled-color-text: #8e8e8e;
    --dp-highlight-color: rgb(25 118 210 / 10%);
    --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
    --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
    --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}



</style>
