import { intervalToDuration, format } from 'date-fns';

/**
 * Funkcja do obliczania wieku pomiędzy datą urodzenia a datą badania.
 * 
 * @function getAge
 * 
 * @param {Date} dataUrodzin - Data urodzenia jako obiekt Date.
 * @param {Date} dataBadania - Data badania jako obiekt Date.
 * 
 * @returns {Object} Zwraca obiekt zawierający wiek jako interwał czasu i ewentualny błąd.
 * Jeżeli dataBadania jest mniejsza od dataUrodzin, zwraca null jako wiek i komunikat błędu.
 * Jeżeli dataBadania jest większa lub równa dataUrodzin, zwraca obliczony wiek i null jako błąd.
 * 
 * @throws {Error} Rzuca wyjątek, gdy dataUrodzin lub dataBadania nie są instancjami Date lub są niewłaściwe.
 * 
 * @example
 * getAge(new Date('2000-01-01'), new Date('2020-01-01')); 
 * // Zwraca: { wiek: {years: 20}, error: null }
 */
const getAge = (dataUrodzin, dataBadania) => {
    // if (dataUrodzin && dataBadania) {
       if (dataUrodzin instanceof Date && !isNaN(dataUrodzin) && dataBadania instanceof Date && !isNaN(dataBadania)) {
         //  console.log('🟢🟢🟢', dataUrodzin, dataBadania);

       if (dataBadania < dataUrodzin) {
          return {
             wiek: null,
             error: "Ups data badania jest pomylona z datą urodzenia",
          }
        } else {
          const wiekData = intervalToDuration({
             start: dataUrodzin,
             end: dataBadania,
          });
          return {
             wiek: wiekData,
             error: null,
             };
       }
    return null;
    } else {
       throw new Error("Data urodzenia lub data badania jest niepoprawna");
    }
 };


 export default getAge;