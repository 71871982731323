const daneIQczynnikow =
{	2: {1:45, 2:45, 3:45, 4:45, 5:45, 6:45, 7:45},
	3: {1:48, 2:48, 3:51, 4:54, 5:51, 6:50, 7:51},
	4: {1:51, 2:51, 3:54, 4:57, 5:54, 6:53, 7:54},
	5: {1:54, 2:54, 3:57, 4:60, 5:57, 6:56, 7:57},
	6: {1:57, 2:57, 3:60, 4:62, 5:60, 6:59, 7:60},
	7: {1:60, 2:60, 3:63, 4:65, 5:63, 6:62, 7:62},
	8: {1:63, 2:63, 3:66, 4:68, 5:66, 6:65, 7:65},
	9: {1:66, 2:66, 3:69, 4:70, 5:68, 6:67, 7:68},
	10: {1:69, 2:69, 3:71, 4:73, 5:71, 6:70, 7:71},
	11: {1:72, 2:72, 3:74, 4:76, 5:74, 6:73, 7:74},
	12: {1:75, 2:75, 3:77, 4:78, 5:77, 6:76, 7:77},
	13: {1:78, 2:79, 3:80, 4:81, 5:80, 6:79, 7:80},
	14: {1:82, 2:82, 3:83, 4:84, 5:83, 6:82, 7:83},
	15: {1:85, 2:85, 3:86, 4:87, 5:86, 6:85, 7:86},
	16: {1:88, 2:88, 3:89, 4:89, 5:89, 6:88, 7:89},
	17: {1:91, 2:91, 3:91, 4:92, 5:91, 6:91, 7:91},
	18: {1:94, 2:94, 3:94, 4:95, 5:94, 6:94, 7:94},
	19: {1:97, 2:97, 3:97, 4:97, 5:97, 6:97, 7:97},
	20: {1:100, 2:100, 3:100, 4:100, 5:100, 6:100, 7:100},
	21: {1:103, 2:103, 3:103, 4:103, 5:103, 6:103, 7:103},
	22: {1:106, 2:106, 3:106, 4:105, 5:106, 6:106, 7:106},
	23: {1:109, 2:109, 3:109, 4:108, 5:109, 6:109, 7:109},
	24: {1:112, 2:112, 3:111, 4:111, 5:112, 6:112, 7:112},
	25: {1:116, 2:115, 3:114, 4:113, 5:114, 6:115, 7:115},
	26: {1:119, 2:118, 3:117, 4:116, 5:117, 6:118, 7:117},
	27: {1:122, 2:121, 3:120, 4:119, 5:120, 6:121, 7:120},
	28: {1:125, 2:124, 3:123, 4:122, 5:123, 6:124, 7:123},
	29: {1:128, 2:128, 3:126, 4:124, 5:126, 6:127, 7:126},
	30: {1:131, 2:131, 3:129, 4:127, 5:129, 6:130, 7:129},
	31: {1:134, 2:134, 3:134, 4:130, 5:132, 6:132, 7:132},
	32: {1:137, 2:137, 3:134, 4:132, 5:135, 6:135, 7:135},
	33: {1:140, 2:140, 3:137, 4:135, 5:137, 6:138, 7:138},
	34: {1:143, 2:143, 3:140, 4:138, 5:140, 6:141, 7:141},
	35: {1:146, 2:146, 3:143, 4:140, 5:143, 6:144, 7:143},
	36: {1:150, 2:149, 3:146, 4:143, 5:146, 6:147, 7:146},
	37: {1:153, 2:152, 3:149, 4:146, 5:149, 6:150, 7:149},
	38: {1:155, 2:155, 3:155, 4:155, 5:155, 6:155, 7:155},
}



export default daneIQczynnikow;