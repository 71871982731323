
import { daneIQprzeliczoneOgolny, daneIQprzeliczonePelny, daneIQprzeliczonePrzesiewowy } from '../data/ids-2-dane-iq-tabela.js';


function getIqByPoints(wartosc, type) {
   if (type == "ogolny")
   {
      const wiersz = daneIQprzeliczoneOgolny.find(row => wartosc >= row['min'] && wartosc <= row['max']);
      return wiersz ? wiersz.IQ : null;
   }
   if (type == "pelny")
   {
      const wiersz = daneIQprzeliczonePelny.find(row => wartosc >= row['min'] && wartosc <= row['max']);
      return wiersz ? wiersz.IQ : null;
   }
   if (type == "przesiewowy")
   {
      const wiersz = daneIQprzeliczonePrzesiewowy.find(row => wartosc >= row['min'] && wartosc <= row['max']);
      return wiersz ? wiersz.IQ : null;
   }
  }
  


  
  const sumExercisesByPairs = (chosenOptions) => {

   const chosenOptionsCount = Object.keys(chosenOptions).length;
   if (chosenOptionsCount == 14)
   {
      // Definiowanie obiektu, który będzie przechowywać sumy ćwiczeń.
      const exerciseSums = {
      '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0
      };
   
      // Iterowanie przez obiekt chosenOptions, aby sumować wartości par.
      Object.keys(chosenOptions).forEach(klucz => {
      const value = chosenOptions[klucz];
      const [points, exerciseNumber] = value.split('.').map(num => parseInt(num, 10));
      let pairKey = exerciseNumber;
   
      // Mapowanie numerów ćwiczeń do ich par.
      if (exerciseNumber > 7) {
         pairKey = exerciseNumber - 7;
      }
   
      if (exerciseSums.hasOwnProperty(pairKey)) {
         exerciseSums[pairKey] += points;
      } else {
         console.log(`Ćwiczenie numer ${exerciseNumber} nie ma zdefiniowanej pary.`);
      }
      });
   
      // console.log('exerciseSums:', exerciseSums);
      return exerciseSums;
   }
 };


 const sumExercisesByPairsSurowy = (details) => {

   const detailsCount = details.length;
   if (detailsCount === 14) {
     // Definiowanie obiektu, który będzie przechowywać sumy ćwiczeń.
     const exerciseSums = {
       '1': 0, '2': 0, '3': 0, '4': 0, '5': 0, '6': 0, '7': 0
     };
 
     // Iterowanie przez tablicę details, aby sumować wartości par.
     details.forEach(detail => {
       const points = parseInt(detail.value, 10);
       let pairKey = detail.id;
 
       // Mapowanie numerów ćwiczeń do ich par.
       if (pairKey > 7) {
         pairKey = pairKey - 7;
       }
 
       if (exerciseSums.hasOwnProperty(pairKey.toString())) {
         exerciseSums[pairKey.toString()] += points;
       } else {
         console.log(`Ćwiczenie numer ${pairKey} nie ma zdefiniowanej pary.`);
       }
     });
 
     // console.log('exerciseSums:', exerciseSums);
     return exerciseSums;
   }

   if (detailsCount === 7) {
      let exerciseSums = 0

      details.forEach(detail => {
         const points = parseInt(detail.value, 10);
         exerciseSums += points;
      })
      return exerciseSums;

   }
 };
 
 


  const calculatePoints = (chosenOptions, type) => {
   let limit;
   if (type == "ogolny") {limit = 7}
   if (type == "pelny") {limit = 14}
   if (type == "przesiewowy") {limit = 2}

    const chosenOptionsCount = Object.keys(chosenOptions).length;
   //  console.log("chosenOptionsCount:", chosenOptionsCount)

    if (chosenOptionsCount == limit)
    {
       const suma = Object.keys(chosenOptions).reduce((acc, klucz) => {
          const wartosc = chosenOptions[klucz];
          const liczbaPrzedKropka = wartosc.split('.')[0];
          return acc + parseInt(liczbaPrzedKropka, 10);
       }, 0);
      //  console.log("suma:", suma)
      //  console.log('IQ: ', getIqByPoints(suma, type)) // TESTOWE
       return getIqByPoints(suma, type);
    }
 }




export { getIqByPoints, calculatePoints, sumExercisesByPairs, sumExercisesByPairsSurowy };


