<template>
  <div>
    <h2 class="text-sm font-medium text-gray-500">Twoje opracowane opisy</h2>
    
    <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
      
      <a v-for="description in doneDescriptions" :key="description.title" class="col-span-1 flex rounded-md shadow-sm" :href="description.slug">
        

          <div :class="[description.color, 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md']">{{ description.initials }}</div>
          <div class="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
            <div class="flex-1 truncate px-4 py-2 text-sm">
              <a :href="description.slug" class="font-medium text-gray-900 hover:text-gray-600">{{ description.firstName }}</a>
              <p class="text-gray-500">{{ description.type }}</p>
              <p class="text-gray-500">Data urodzenia {{ description.dataUrodzenia}}</p>
              <br>
              <p class="text-gray-500">ID: {{ description.id}}</p>
              <p class="text-gray-500">Edited at: {{ formatDate(description.editedAt)}}</p>
            </div>
            <!-- <div class="flex-shrink-0 pr-2">
              <button type="button" class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Open options</span>
                <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div> -->
          </div>

        </a>
    </ul>

  </div>
</template>



<script setup>

import { defineProps, watchEffect } from 'vue'
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  doneDescriptions: {
    type: Array,
    default: () => [],
  }
});



const formatDate = (timestamp) => {
      // Zamiana timestamp na JavaScript Date object
      const date = timestamp.toDate();
      // Formatowanie daty na żądany format, np. YYYY-MM-DD
      return date.toISOString().slice(0,10);
    }

watchEffect(() => {
  console.log(props.doneDescriptions)
})

</script>