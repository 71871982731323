const ids2DataIqOgolny = [
   {range: [161, Infinity], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się skrajnie wysoki ogólny rozwój intelektualny."},
   {range: [146, 160], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się bardzo wysoki ogólny rozwój intelektualny."},
   {range: [131, 145], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się wysoki rozwój intelektualny."},
   {range: [116, 130], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny powyżej przeciętnej dla wieku."},
   {range: [85, 115], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się przeciętny rozwój intelektualny."},
   {range: [80, 84], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny poniżej przeciętnej dla wieku."},
   {range: [70, 79], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny znajdujący się na pograniczu między normą a niepełnosprawnością intelektualną w stopniu lekkim."},
   {range: [55, 69], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny na poziomie niepełnosprawności intelektualnej w stopniu lekkim."},
   {range: [40, 54], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny na poziomie niepełnosprawności intelektualnej w stopniu umiarkowanym."},
   {range: [0, 39], result: "W wyniku badań psychologicznych IDS-2 z dn. {{dataBadania}} r. stwierdza się rozwój intelektualny na poziomie niepełnosprawności intelektualnej w stopniu znacznym."},
];





export default ids2DataIqOgolny;
