const copiedTooltip = (tooltipId) => {
    var tooltip = document.getElementById(tooltipId);
    tooltip.innerHTML = ` <span class="material-icons">
                               check_circle_outline
                         </span> &nbsp;Skopiowano`;
  
    setTimeout(function () {
       tooltip.innerHTML = `<span class="material-icons"> content_copy </span> &nbsp;Kopiuj`;
    }, 2000);
 }


 export default copiedTooltip;