<template>
  <div class="rounded-md bg-blue-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        
        <component :is="icons.InformationCircleIcon"  class="h-5 w-5 text-blue-400" aria-hidden="true" ></component>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-blue-700">{{info}}</h3>
      </div>
    </div>
  </div>
</template>





<script>
import { ref, reactive } from 'vue';
// import { XCircleIcon } from '@heroicons/vue/outline';
import { InformationCircleIcon } from "@heroicons/vue/24/solid"


export default {
  props: ["info"],

  setup(props) {

    const icons = reactive({
      InformationCircleIcon: InformationCircleIcon,

    })

    const info = ref("");
    info.value = props.info;

    return { info, icons };
  },
};
</script>
