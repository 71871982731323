
function partialIq(points, testNumber, data) {
    // Pobranie danych dla danego wieku
    const pointsData = data[points];
  
    // Jeżeli nie ma danych dla danego wieku lub testu, zwróć undefined
    if (!pointsData || pointsData[testNumber] === undefined) {
      return undefined;
    }
    // Zwróć wynik dla podanego wieku i numeru testu
    return pointsData[testNumber];
  }



  const czestioweCzynnikiInteligencjiObj = (input, data) => {
    // Mapowanie czynników oraz tekstów do nowego obiektu
    const factors = {
       1: "Przetwarzanie wzrokowe",
       2: "Pamięć długotrwała",
       3: "Szybkość przetwarzania",
       4: "Krótkotrwała pamięć słuchowa",
       5: "Krótkotrwała pamięć wzrokowo-przestrzenna",
       6: "Rozumowanie abstrakcyjne",
       7: "Rozumowanie werbalne"
    };

    return Object.keys(input).reduce((acc, key) => {
       const suma = input[key];
       const iq = partialIq(suma, key, data);

       acc[key] = {
             czynnik: factors[key],
             sumaText: `Suma WP testów ${key} i ${parseInt(key, 10) + 7}`,
             suma: suma,
             iq: iq // Wynik funkcji partialIq dodany do obiektu
       };
       return acc;
    }, {});
};  






export default czestioweCzynnikiInteligencjiObj;  

