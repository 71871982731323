<template>
   <div class="min-h-full">
 <!-- <Navbar /> -->


 <header class="bg-gray-100 pt-10">
      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">{{ testTypeInfo.type }}</h1>
      </div>
</header>
    <main>
         <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
         <!-- Replace with your content -->
            <div class="px-4 py-4 sm:px-0">


               <div class="choosingPoints" v-if="isChoosingPoints">
                  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 pb-10 mb-10 rounded-md shadow-sm">
               

               <!-- Content goes here -->
               <div class="border-b border-gray-200 pb-5 mb-0">
                  <h2 class="text-2xl font-medium leading-6 text-gray-900">Informacje ogólne</h2>
                  <p class="mt-2 max-w-4xl text-sm text-gray-500">Informacje potrzebne do przygotowania poprawnego opisu uwzględniającego normy wiekowe.</p>
               </div>



               <div class="mt-0 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-2 sm:col-start-1">
                     <label for="dp-input-dataBadania" class="block text-sm pt-5 font-medium leading-6 text-gray-900">Data badania</label>
                     <VueDatePicker 
                                    v-model="dataBadania" 
                                    :hide-navigation="['month', 'year']" 
                                    :format="dateFormattingInCallendar"
                                    name="dataBadania-date-picker" 
                                    :flow="flowCalendar" 
                                    uid="dataBadania"  
                                    id="dataBadania"  
                                    :enable-time-picker="false"
                                    keep-action-row 
                                    auto-apply 
                                    locale="pl"
                                    now-button-label="Dziś"
                                    position="center" 
                                    :auto-position="true"
                                    :action-row="{ showNow: true, showPreview: false, showCancel: false, showSelect: false,  }"
                                    input-class-name="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                                  ></VueDatePicker>
                     </div>
               </div>



               <div class="border-b border-gray-200 pb-0 mb-0">
                  &nbsp;
               </div>
      
               <div>
                  <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div class="sm:col-span-2 sm:col-start-1">
                           <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">Imię</label>
                           <div class="mt-2">
                              <input type="text" name="firstName" id="firstName" v-model="firstName" @input="handleInput" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
                           </div>
                           
                           <span v-if="errorsData.firstName && !firstName" class="mt-2 text-sm text-red-600" id="firstName-error">{{ errorsData.firstName }}</span>
                           <!-- <label for="firstName" class="block text-sm font-medium leading-6 text-gray-900">Imię</label>
                           <div class="mt-2 rounded-md">
                              <input type="text" name="firstName" id="firstName" class="shadow-sm rounded-md border-0 py-1.5 pl-2 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6" placeholder="Maurycy">
                           </div> -->
                        </div>

                        <div class="sm:col-span-2">
                           <!-- <label for="region" class="block text-sm font-medium leading-6 text-gray-900">State / Province</label>
                           <div class="mt-2">
                              <input type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
                           </div> -->

                           <label for="dp-input-dataUrodzenia" class="block text-sm font-medium leading-6 text-gray-900">Data urodzenia</label>
                           <VueDatePicker 
                              v-model="dataUrodzenia" 
                              :hide-navigation="['month', 'year']" 
                              :format="dateFormattingInCallendar"
                              name="dataUrodzenia-date-picker" 
                              :flow="flowCalendar" 
                              uid="dataUrodzenia"  
                              id="dataUrodzenia"  
                              :enable-time-picker="false"
                              keep-action-row 
                              auto-apply 
                              locale="pl"
                              now-button-label="Dziś"
                              position="center" 
                              :min-date="minDate" :max-date="maxDate" prevent-min-max-navigation
                              :auto-position="true"
                              :action-row="{ showNow: false, showPreview: false, showCancel: false, showSelect: false,  }"
                              input-class-name="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                              menu-class-name=""
                              
                              
                              ></VueDatePicker>
                            
                              <span v-if="errorsData.dataUrodzenia && !dataUrodzenia"  class="mt-2 text-sm text-red-600" id="dataUrodzenia-error">{{ errorsData.dataUrodzenia }}</span>
                              <!-- calendar-cell-class-name="dp-custom-cell" -->
                              <!-- calendar-class-name="dp-custom-calendar" -->
                              <!-- year-cell-class-name="dp-custom-cell" -->




                           <!-- <div class="relative mb-3 mt-2 xl:w-96" data-te-datepicker-init="" data-te-input-wrapper-init="" id="datepicker-translated-dataurodzenia">
                              <input type="text" v-model="dataUrodzenia" class="peer block min-h-[auto] w-full rounded-md border-0 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-green-dla-400 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-green-dla-400 [&amp;:not([data-te-input-placeholder-active])]:placeholder:opacity-0" placeholder="Wybierz datę">
                              
                              <button id="datepicker-toggle-211337" type="button" class="flex items-center justify-content-center [&amp;>svg]:w-5 [&amp;>svg]:h-5 absolute outline-none border-none bg-transparent right-0.5 top-1/2 -translate-x-1/2 -translate-y-1/2 hover:text-green-dla-500 focus:text-green-dla-400 dark:hover:text-green-dla-500 dark:focus:text-green-dla-400 dark:text-neutral-200" data-te-datepicker-toggle-button-ref="" data-te-datepicker-toggle-ref="">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                 <path fill-rule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clip-rule="evenodd"></path>
                                 </svg>  
                              </button>
                              <div class="group flex absolute left-0 top-0 w-full max-w-full h-full text-left pointer-events-none" data-te-input-notch-ref="">
                                   <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none left-0 top-0 h-full w-2 border-r-0 rounded-l-[0.25rem] group-data-[te-input-focused]:border-r-0 group-data-[te-input-state-active]:border-r-0 border-neutral-300 dark:border-neutral-600  group-data-[te-input-focused]:border-green-dla-400" data-te-input-notch-leading-ref="" style="width: 9px;"></div>
                                   <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-r-0 border-l-0 group-data-[te-input-state-active]:border-x-0 border-neutral-300 dark:border-neutral-600 group-data-[te-input-focused]:border-green-dla-400 " data-te-input-notch-middle-ref="" style="width: 81.6px;"></div>
                                   <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-l-0 rounded-r-[0.25rem] group-data-[te-input-focused]:border-l-0 group-data-[te-input-state-active]:border-l-0 border-neutral-300 dark:border-neutral-600 group-data-[te-input-focused]:border-green-dla-400" data-te-input-notch-trailing-ref=""></div>
                              </div>
                           </div> -->

                        </div>


                        <div class="sm:col-span-2"  v-if="dataWiek.wiek">
                           <label for="age" class="block text-sm font-medium leading-6 text-gray-900">Wiek</label>
                           <div class="mt-2">
                              <input
                                 readonly 
                                 type="text"
                                 name="age"
                                 id="age"
                             
                                 :value="`${dataWiek.wiek.years} lat, ${dataWiek.wiek.months} ${odmienMiesiace(dataWiek.wiek.months)}, ${dataWiek.wiek.days} ${odmienDni(dataWiek.wiek.days)}`"
                                 @input="handleInput"
                                 class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                 >
                           </div>
                           
                           <span v-if="dataWiek.error" class="mt-2 text-sm text-red-600" id="age-error">{{ dataWiek.error }}</span>
                           <!--  -->
                        </div>


                        <div class="sm:col-span-2"  v-if="dataWiek.error">
                           <label for="age" class="block text-sm font-medium leading-6 text-red-600">Wiek</label>
                           <div class="mt-2">
                              <input
                                 type="text"
                                 class="block w-full rounded-md border-0 py-1.5 text-red-600 shadow-sm ring-1 ring-inset ring-red-600 placeholder:text-red-600focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                                 >
                           </div>
                           
                           <span v-if="dataWiek.error" class="mt-2 text-sm text-red-600" id="age-empty-error">{{ dataWiek.error }}</span>
                           <!--  -->
                        </div>





                  </div>
               </div>
      

               <div v-if="loading">
                  Loading...
               </div>
               <div v-else>
                  <RatioSmallCards class="pt-5" v-model="isMale"></RatioSmallCards>
               </div>

               

               

            </div>



                  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 pb-10 mb-10 rounded-md shadow-sm">
                     <!-- Content goes here -->

                        <div class="border-b border-gray-200 pb-5 mb-5">
                           <h2 class="text-2xl font-medium leading-6 text-gray-900">Wyniki testu</h2>
                           <p class="mt-2 max-w-4xl text-sm text-gray-500">Informacje potrzebne do przygotowania poprawnego opisu uwzględniającego normy wiekowe.</p>
                        </div>

                        <div class="home">
                           <div v-for="section in alldata.sections" :key="section.id">
                              <h2 class="text-xl font-medium mt-7 mb-4 leading-6 text-gray-900">{{ section.title }}</h2>
                              <ul class="isolate w-full flex flex-wrap justify-center">
                                 <li class="w-11 h-11 mb-1  rounded-full flex items-center justify-center mr-1 text-sm font-semibold text-gray-900 ring-2 ring-inset ring-gray-300 hover:text-gray-900  hover:ring-green-600 hover:bg-green-400 focus:z-20 focus:outline-offset-0 cursor-pointer"
                                    :class="{
                                          'bg-green-400 ring-green-600': isMarked(section.id, index, chosenOptions),
                                          'text-gray-300': !isMarked(section.id, index, chosenOptions),
                                    }"
                                    v-for="(item, index) in section.options"
                                    :key="item"
                                    @click="handleChooseOption(section.id, index)" :id="index + '.' + section.id" :name="index"
                                 >
                                    {{ index }}
                                 </li>
                              </ul>
                           </div>

                        </div>
                  </div>





                  <!-- IQ -->
                  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm">
               

               <!-- Content goes here -->
               <div class="pb-0 mb-0">
                  <h2 class="text-2xl font-medium leading-6 text-gray-900">IQ przeliczone</h2>
                  <p class="mt-2 max-w-4xl text-sm text-gray-500">W poniższym polu proszę podać przeliczoną wartość IQ</p>
               </div>
      
               <div>
                  <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                        <div class="sm:col-span-2 sm:col-start-1">
                           <!-- <label for="iqPrzeliczone" class="block text-sm font-medium leading-6 text-gray-900">IQ przeliczone</label> -->
                           <div class="mt-2">
                              <input type="text" name="iqPrzeliczone" id="iqPrzeliczone" v-model="iqPrzeliczone" placeholder="wpisz wartość" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                           </div>
    
                        </div>

                  </div>


               </div>


            </div>




                  <!-- error -->
                  <div v-if="errorsData.text" class="mb-5">
                     <div class="rounded-md bg-red-50 p-4">
                     <div class="flex">
                        <div class="flex-shrink-0">
                           <component :is="icons.XCircleIcon" class="h-5 w-5 text-red-400" aria-hidden="true" ></component>
                        </div>
                        <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">{{errorsData.text}}</h3>
                        </div>
                     </div>
                  </div>

                  </div>



                  <div class="flex justify-center" v-if="!isLoaded">
     
                     <button type="button" @click="handleNext('preview', errorsData)" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        dalej
                        <!-- <ArrowRightIcon class="ml-3 -mr-1 h-5 w-5" aria-hidden="true" /> -->
                     </button>
                  </div>



               </div>

               <div v-if="isPreview">

                  <div v-if="!isLoaded && !loadedVersion1">
                 
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm">
                        <h5 class="mb-2 animate-pulse text-2xl font-medium leading-6 text-neutral-700  dark:text-white">
                           <span class="inline-block min-h-[1.5em] w-2/12 flex-auto rounded-md cursor-wait  text-neutral-300 bg-current align-middle opacity-50"></span>
                        </h5>
                        <p  class="mb-4 mt-2 max-w-4xl animate-pulse text-base text-neutral-700 dark:text-white">
                           <span class="inline-block min-h-[1em] rounded-md w-10/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-6/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-4/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-7/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; &nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-9/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>
                        
                        </p>

                        <!-- <button  @click="copyToClipboard(text); copiedTooltip(`tooltip2`)" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                           proszę czekać...
                        </button> -->
            
                     </div>
                  </div>


                  <div v-if="!isLoaded && loadedVersion1" class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm min-h-[200px] sm:min-h-[230px]">
                     <h2 class="text-2xl font-medium leading-6 text-gray-900 showSmooth">Wersja 1</h2>
                     <p class="mt-2 max-w-4xl text-base text-gray-500" ref="textElementVer1"></p>
                     
                     <transition
                        enter-active-class="transition-opacity ease-in duration-1000"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="transition-opacity ease-in duration-300"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                     >
                        <button  v-if="showCopyBtnVersion1" @click="copyToClipboard(textareaToCopy); copiedTooltip('tooltip0'), markCopied(documentId, 'copied1')" id="tooltip0" type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                           <span class="material-icons" > content_copy </span> &nbsp;Kopiuj
                        </button>
                     </transition>
                  </div>


                  <div  v-if="isLoaded" class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                     <h2 class="text-2xl font-medium leading-6 text-gray-900" id="opis">Wersja 1</h2>
                     <p class="mt-2 max-w-4xl text-base text-gray-500">  <p>{{ textareaToCopy }}</p></p>
                     <button  @click="copyToClipboard(textareaToCopy); copiedTooltip('tooltip0'), markCopied(documentId, 'copied1')" id="tooltip0" type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span class="material-icons" > content_copy </span> &nbsp;Kopiuj
                     </button>
                  </div>






                  <div v-if="!isLoaded && !loadedVersion2">
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm">
                        <h5 class="mb-2 animate-pulse text-2xl font-medium leading-6 text-neutral-700  dark:text-white">
                           <span class="inline-block min-h-[1.5em] w-2/12 flex-auto rounded-md cursor-wait  text-neutral-300 bg-current align-middle opacity-50"></span>
                        </h5>

                        <p  class="mb-4 mt-2 max-w-4xl animate-pulse text-base text-neutral-700 dark:text-white">
                           <span class="inline-block min-h-[1em] rounded-md w-10/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-6/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-4/12 flex-auto cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-7/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; &nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md w-9/12 cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50"></span>&nbsp; 
                           <span class="inline-block min-h-[1em] rounded-md cursor-wait bg-current align-middle text-base text-neutral-300 opacity-50 dark:text-neutral-50" style="width:25%;"></span>
                        
                        </p>
                        <!-- <button  @click="copyToClipboard(text); copiedTooltip(`tooltip2`)" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                           proszę czekać...
                        </button> -->
                     </div>
                  </div>


                  <div v-if="!isLoaded && loadedVersion2">
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                     
                        <h2 class="text-2xl font-medium leading-6 text-gray-900 showSmooth">Wersja 2</h2>
                        <!-- <p class="mt-2 max-w-4xl text-base text-gray-500">  <p>{{ textareaGPT }}</p></p> -->
                        <p class="mt-2 max-w-4xl text-base text-gray-500">  <p ref="textElementVer2"></p></p>

                        <transition
                           enter-active-class="transition-opacity ease-in duration-1000"
                           enter-from-class="opacity-0"
                           enter-to-class="opacity-100"
                           leave-active-class="transition-opacity ease-in duration-300"
                           leave-from-class="opacity-100"
                           leave-to-class="opacity-0"
                        >
                           <button v-if="showCopyBtnVersion2" @click="copyToClipboard(textareaGPT); copiedTooltip(`tooltip2`), markCopied(documentId, 'copied2')" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                              <span class="material-icons"> content_copy </span> &nbsp;Kopiuj
                           </button>
                        </transition>
            
                     </div>
                  </div>

                  <div v-if="isLoaded && textareaGPT">
                     <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 bg-white p-5 pt-7 mb-10 rounded-md shadow-sm  min-h-[200px] sm:min-h-[230px]">
                     
                        <h2 class="text-2xl font-medium leading-6 text-gray-900" id="v1">Wersja 2</h2>
                        <p class="mt-2 max-w-4xl text-base text-gray-500">  <p>{{ textareaGPT }}</p></p>

                        <button  @click="copyToClipboard(textareaGPT); copiedTooltip(`tooltip2`), markCopied(documentId, 'copied2')" :id="`tooltip2`"  type="button" class="inline-flex items-center rounded-md border border-transparent bg-green-dla-500 px-6 py-3 mt-4 text-base font-medium text-white shadow-sm hover:bg-green-dla-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                           <span class="material-icons"> content_copy </span> &nbsp;Kopiuj
                        </button>
            
                     </div>
                  </div>





               </div>

            </div>
         </div>
      </main>
   </div>


</template>

<script>
import { reactive } from '@vue/reactivity';
import { watchEffect, watch } from '@vue/runtime-core';
import { ref, onMounted, onBeforeUnmount, computed } from "vue";

import { format, subMonths } from 'date-fns';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Navbar from '@/components/Navbar.vue';
import RatioSmallCards from '@/components/RatioSmallCards.vue';


import ids2data from '@/data/ids-2-ogolny-data.js';

import getAge from '../composables/getAge.js';
import getDescriptionsById from '../composables/getDescriptionsById';
import getDocById from '../composables/getDocById';

import askGPT from '@/gpt/askGPT-2.js';
import getUser from '../composables/getUser';


import firebaseTimestampToDate from '../composables/detail/firebaseTimestampToDate.js';
import dateFormattingInCallendar from '../composables/detail/dateFormattingInCallendar.js';
import removeMinorVersions from '../composables/detail/removeMinorVersions.js';
import copyToClipboard from '../composables/detail/copyToClipboard.js';
import copiedTooltip from '../composables/detail/copiedTooltip.js';
import increaseDescriptionCount from '../composables/detail/increaseDescriptionCount.js';
import { makeSentencesDynamic } from '../composables/detail/sentences.js';
import { isMarked, chooseOption } from '../composables/detail/options.js';
import { saveDescriptionToDb, saveGPTDescriptionToDb, markCopied } from '../composables/detail/descriptions.js';
import { XCircleIcon } from "@heroicons/vue/24/solid"
import scrollToId from '../composables/detail/scrollToId.js';
import { typeSentence, waitForMs } from '../composables/detail/typeSentence.js';
import validateForm from '../composables/detail/formValidation.js';
import { capitalizeFirstName } from '../composables/detail/capitalizeFirstName.js';
import { odmienMiesiace, odmienDni } from '../composables/detail/dataUrodzeniaText.js';
import { makePrompt } from '../composables/detail/makePrompt.js';
import { calculatePoints } from '../composables/calculateIQ.js';

import { adjustGenderAndName } from '../composables/detail/adjustGenderAndName.js';




export default {
   name: 'Home',
   components: {
      VueDatePicker, Navbar, RatioSmallCards
   },

   
   props: ["id"],
   setup(props) {

   
      const alldata = ref(ids2data);

      const chosenOptions = ref([]);
      const dataWiek = ref({wiek: null, error: null});
      const isChoosingPoints = ref(true);
      const isPreview = ref(false);
      const textareaToCopy = ref('');
      const textareaGPT = ref('');

      const { user } = getUser()
      const dataBadania = ref(null);
      const dataUrodzenia = ref(null);
      const firstName = ref(null);
      const isMale = ref(true);
      const documentId = ref("");
      const iqPrzeliczone = ref(null);

      let isLoaded = ref(null);
      const loading = ref(true);
      const errorsData = ref({});

      const testTypeInfo = {
         color: "bg-ids-ogolny", 
         type: "IDS-2 ogólny",
      }


      const plecOptions = [
         { name: '👨 M', inStock: true, isMale: true },
         { name: '👩 K', inStock: true, isMale: false },
      ]


      const icons = reactive({
      XCircleIcon: XCircleIcon,

    })

      const flowCalendar = ref(['year', 'month', 'calendar']);
      const minDate = computed(() => subMonths(dataBadania.value, 252)); // tu jest 1 dzien tolerancji dla bezpieczenstwa
      const maxDate = computed(() => subMonths(dataBadania.value, 60));


      const textElementVer1 = ref(null);
      const textElementVer2 = ref(null);

      const loadedVersion1 = ref(false);
      const loadedVersion2 = ref(false);

      const showCopyBtnVersion1 = ref(false);
      const showCopyBtnVersion2 = ref(false);

      const shouldCancel = ref(false);
      



      const detailsRaw = ref([
         {name:"Klocki",  id:1, rawValue: null, value: null, error:null},
         {name:"Opowiadanie",  id:2, rawValue: null, value: null, error:null},
         {name:"Papugi",  id:3, rawValue: null, value: null, error:null},
         {name:"Szeregi jednorodne",  id:4, rawValue: null, value: null, error:null},
         {name:"Bryły", id:5, rawValue: null, value: null, error:null},
         {name:"Matryce",  id:6, rawValue: null, value: null, error:null},
         {name:"Kategorie",  id:7, rawValue: null, value: null, error:null},
         // {name:"Krążki", id:8, rawValue: null, value: null, error:null},
         // {name:"Obrazek",  id:9, rawValue: null, value: null, error:null},
         // {name:"Kwadraty",  id:10, rawValue: null, value: null, error:null},
         // {name:"Szeregi mieszane",  id:11, rawValue: null, value: null, error:null},
         // {name:"Zrotowane bryły",  id:12, rawValue: null, value: null, error:null},
         // {name:"Wykluczanie",  id:13, rawValue: null, value: null, error:null},
         // {name:"Przeciwieństwa", id:14, rawValue: null, value: null, error:null},
      ])



   
      function usunSpacjeNaPoczatkuZdan(input) {
         return input.replace(/(?!\n)^\s+/gm, '');
      }

   
      onBeforeUnmount(() => {
      shouldCancel.value = true;
      });
      
      
      
      
      onMounted(async () => {
         try {
            dataBadania.value = new Date();

            if (props.id !== "new") {
               // console.log("props.id: ", props.id)
               isLoaded.value = true;
               isPreview.value = true;


               const description = await getDescriptionsById("descriptions", props.id);
               if (description) {
                  documentId.value = props.id;
                  firstName.value = description.firstName;
                  dataUrodzenia.value = firebaseTimestampToDate(description.dataUrodzenia);
                  dataBadania.value = firebaseTimestampToDate(description.dataBadania);
                  isMale.value = plecOptions.find(option => option.isMale === description.isMale).isMale;
                  chosenOptions.value = removeMinorVersions(description.chosenOptions);
                  iqPrzeliczone.value =description.iqPrzeliczone
                  textareaToCopy.value = description.descriptionGeneric;
                  textareaGPT.value = description.descriptionGPT;

               } 



            } else {
               isLoaded.value = false;
               isPreview.value = false;
            }

            loading.value = false
         } catch (err) {
         console.error(err);
         }
      })



 
       

      const handleChooseOption = (sectionId, optionId) => {
         chosenOptions.value = chooseOption(sectionId, optionId, chosenOptions.value)
         iqPrzeliczone.value = calculatePoints(chosenOptions.value, "ogolny")
      };



      watchEffect(() => {
         if (dataUrodzenia.value && dataBadania.value) {
            dataWiek.value = getAge(dataUrodzenia.value, dataBadania.value);
         }
      });
      




      const handleInput = () => {
         firstName.value = capitalizeFirstName(firstName.value);
      };




      const handleNext = async (option, errorsData) => {

         const validation = validateForm(firstName, dataUrodzenia);

         if (option === 'preview' && validation.isValid) {
            scrollToId('top')
            isChoosingPoints.value = false;
            isPreview.value = true;
         }  
         else if (option === 'preview' && !validation.isValid) {
               errorsData.firstName = validation.errors.firstName;
               errorsData.dataUrodzenia = validation.errors.dataUrodzenia;
               errorsData.text = validation.errorsText;
         }

      };




      watch(isPreview, async(newValue) => {
         await waitForMs(900);
         loadedVersion1.value = true;
         await waitForMs(200);

         if (newValue) {  
            if (!isLoaded.value && loadedVersion1.value && textElementVer1.value !== null) {

               const subjectDetails = {
                  firstName: firstName.value,
                  dataUrodzenia: dataUrodzenia.value,
                  dataBadania: dataBadania.value,
                  selectedSex: isMale.value,
                  isMale: isMale.value,
                  chosenOptions: chosenOptions.value,
                  ...(iqPrzeliczone !== undefined && { iqPrzeliczone: iqPrzeliczone.value })
               }

               let ids2DataIqOgolny;

               if (!ids2DataIqOgolny) {
                  const module = await import('@/data/ids-2-data-iq-ogolny.js');
                  ids2DataIqOgolny = module.default;
               }

               let ids2dataSentences;
               if (!ids2dataSentences) {
                  ids2dataSentences = await getDocById("sentences", 'ids-2-data-ogolny-sentences');
               }

               const sentencesTemp = makeSentencesDynamic(chosenOptions.value, ids2dataSentences, iqPrzeliczone.value, ids2DataIqOgolny, dataBadania.value, dataWiek.value, detailsRaw.value);
               const doneSentences = adjustGenderAndName(sentencesTemp, !isMale.value, firstName.value)
               textareaToCopy.value = usunSpacjeNaPoczatkuZdan(doneSentences);

           
               documentId.value = await saveDescriptionToDb(textareaToCopy.value, testTypeInfo, user, subjectDetails);
               await increaseDescriptionCount(user.value.uid) 

               
               const prompt = makePrompt(textareaToCopy, isMale, firstName, dataWiek);
               const typeSentencePromise = typeSentence(textElementVer1, textareaToCopy.value, () => shouldCancel.value);
               const askGPTPromise = askGPT(prompt);
                  
               Promise.all([typeSentencePromise, askGPTPromise]).then(async ([isVersion1Written, gptValue]) => {
                     textareaGPT.value = gptValue;
                     await saveGPTDescriptionToDb(textareaGPT.value, documentId.value);

                     showCopyBtnVersion1.value = isVersion1Written;
                     await waitForMs(2000);

                     if (isVersion1Written) {
                        loadedVersion2.value = true;
                        shouldCancel.value = false;
                        await waitForMs(200);
                        showCopyBtnVersion2.value = await typeSentence(textElementVer2, textareaGPT.value, () => shouldCancel.value);
                     }
               }).catch((error) => {
                     console.log("An error occurred:", error);
               });

            } 
         }
      });










      return {
         flowCalendar,
         alldata,
         handleChooseOption,
         chosenOptions,
         isMarked,
         copyToClipboard,
         dataBadania,
         dataUrodzenia,
         getAge,
         format,
         dateFormattingInCallendar,
         dataWiek,
         handleNext,
         isChoosingPoints,
         isPreview,
         textareaToCopy,
         textareaGPT,
         isMale,
         firstName,
         handleInput,
         copiedTooltip,
         loading,
         isLoaded,
         documentId,
         iqPrzeliczone,
         dataWiek,
         testTypeInfo,
         errorsData,
         icons,
         textElementVer1,
         textElementVer2,
         loadedVersion1,
         loadedVersion2,
         showCopyBtnVersion1,
         showCopyBtnVersion2,
         markCopied,
         minDate,
         maxDate,
         odmienMiesiace,
         odmienDni,
         
      };
   },
};
</script>


<style scope>
.section {
   padding: 20px;
   border-radius: 7px;
   margin-top: 0px;
   width: 650px;

   display: flex;
   flex-direction: column;
}
.section h2 {
   text-align: left;
   color: black;
   font-weight: 400;
   font-size: 1.8rem;
   padding-bottom: 00px;
   margin-bottom: 15px;
}

.number-container {
   display: flex;
   flex-direction: row;
}

.number-item span {
   display: flex;
   flex-direction: column;
}
.number-item label {
   font-size: 0.9rem;
}


.number-container input {
   cursor: pointer;
   width: 24px ;
   height: 24px ;
}


a {
   margin-right: 0px;
   padding: 2px 5px;
   border-radius: 7px;
}
a:hover {
   background-color: rgb(223, 223, 223);
   cursor: pointer;
}

.testheader {
   height: 50px;
   background-color: #7d367d;
   color: black;
   padding: 25px;
   text-align: left;
   font-size: 3rem;
   font-weight: bold;
}
.testheader span {
   color: white;
}

.home {
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
}

.details {
   padding-top: 35px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-items: center;
}

.normal {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid rgb(196, 196, 196);
   background-color: rgb(196, 196, 196);
   color: rgb(196, 196, 196);
}

.normal span {
   font-size: 1.4rem;
}

.normal:hover {
   border: 2px solid #886f88;
   background-color: #886f88;
   transition-duration: 0.5s;
   color: #886f88;
}

.choosen {
   display: block;
   padding: 0px;
   width: 22px;
   height: 22px;
   margin: 3px;
   border: 2px solid #7d367d;
   background-color: #7d367d;
   color: white;
}
.choosen span {
   font-size: 1.4rem;
}

.choosen:hover {
   background-color: #7d367d;
}

.copy {
   padding: 0 35px;
   display: flex;
   justify-content: space-between;
   flex-flow: row-reverse;
}

button {
   padding: 15px;
   border: 0px;
   border-radius: 7px;
   color: white;
   margin: 5px;
   width: fit-content;
   font-size: 1.2rem;
   display: flex;
}

button:hover {
   background-color: #1664c9;
   color: white;
   transition-duration: 0.3s;
   cursor: pointer;
}

.preview {
   padding: 25px;
   display: flex;
   justify-content: center;
   flex-flow: column wrap;
}

.preview textarea {
   border-radius: 7px;
   padding: 15px;
   font-size: 1.2rem;
   color: #4b4b4b;
   border: 1px solid #dadce0;
   font-family: Roboto, Helvetica, Arial, sans-serif;
   min-height: 300px;
}

.preview textarea:focus {
   outline: none !important;
   border: 1px solid #dadce0;
   -webkit-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   -moz-box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
   box-shadow: 10px 11px 30px -12px rgba(218, 218, 218, 1);
}

.backBtn {
   background-color: rgb(207, 207, 207);
}
.mainBtn {
   background-color: #1a73e8;
   padding: 15px 35px;
}

.dp-custom-menu .dp__now_button {
   padding: 10px 35px;
   margin-bottom: 15px;
   margin-left: auto;
   margin-right: auto;
}



.plecItem {
   border: 1px solid #ddd;
   border-radius: 4px;
   padding: 3px 12px;
   float: left;

   margin-right: 8px;
   margin-left: 8px;
   width: 81px;
}

.control-group {
   margin-top: 20px;
}

.control {
   font-family: arial;
   display: block;
   position: relative;
   padding-left: 30px;
   margin-bottom: 5px;
   padding-top: 3px;
   cursor: pointer;
   font-size: 16px;
}
.control input {
   position: absolute;
   z-index: -1;
   opacity: 0;
}
.control_indicator {
   position: absolute;
   top: 4px;
   left: 0;
   height: 20px;
   width: 20px;
   background: #e6e6e6;
   border: 0px solid #000000;
   border-radius: undefinedpx;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
   background: #cccccc;
}

.control input:checked ~ .control_indicator {
   background: #7d367d;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
   background: #7d367d;
}
.control input:disabled ~ .control_indicator {
   background: #e6e6e6;
   opacity: 0.6;
   pointer-events: none;
}
.control_indicator:after {
   box-sizing: unset;
   content: '';
   position: absolute;
   display: none;
}
.control input:checked ~ .control_indicator:after {
   display: block;
}
.control-radio .control_indicator {
   border-radius: 50%;
}

.control-radio .control_indicator:after {
   left: 7px;
   top: 7px;
   height: 6px;
   width: 6px;
   border-radius: 50%;
   background: #ffffff;
   transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
   background: #7b7b7b;
}
.control-radio .control_indicator::before {
   content: '';
   display: block;
   position: absolute;
   left: -5px;
   top: -5px;
   width: 4.5rem;
   height: 4.5rem;
   margin-left: -1.3rem;
   margin-top: -1.3rem;
   background: #7d367d;
   border-radius: 3rem;
   opacity: 0.6;
   z-index: 99999;
   transform: scale(0);
}
@keyframes s-ripple {
   0% {
      opacity: 0;
      transform: scale(0);
   }
   20% {
      transform: scale(1);
   }
   100% {
      opacity: 0.01;
      transform: scale(1);
   }
}
@keyframes s-ripple-dup {
   0% {
      transform: scale(0);
   }
   30% {
      transform: scale(1);
   }
   60% {
      transform: scale(1);
   }
   100% {
      opacity: 0;
      transform: scale(1);
   }
}
.control-radio input + .control_indicator::before {
   animation: s-ripple 250ms ease-out;
}
.control-radio input:checked + .control_indicator::before {
   animation-name: s-ripple-dup;
}


.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}




.showSmooth{
  opacity: 0; 
  animation: showFull 1s ease-in forwards; 
}

@keyframes showFull {
  to {
    opacity: 1;
  }
}

</style>
