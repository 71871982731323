// firebase imports
import { db } from '../firebase/config';
import { doc, getDoc } from "firebase/firestore"; 

const getDocById = async (c, id) => {
    // console.log('getDocById()');
    // console.log('collection: ', c);
    // console.log('id: ', id);

    const docRef = doc(db, c, id);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data();
    } else {
        console.log("No such document!");
        return null;
    }
}


export default getDocById;