import { serverTimestamp, increment } from 'firebase/firestore';
import updateDocument from '../updateDocument.js';

const increaseDescriptionCount = async (userUid) => {
    await updateDocument("users", userUid, { //user.value.uid
       doneDescCount: increment(1),  // Użyj increment z Firebase
       editedAt: serverTimestamp()
    });
 };

 export default increaseDescriptionCount;