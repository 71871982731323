const scrolToId = (id) => {
    // Twoja logika funkcji handleNext
    const element = document.getElementById(id);
    if (element) {
       const rect = element.getBoundingClientRect();
       window.scrollTo({
          top: rect.top + window.scrollY,
          behavior: "smooth"
       });
    }
 };


 export default scrolToId;