<template>
     <div class="min-h-full">
   <!-- <Navbar /> -->

   


   <header class="bg-white shadow">
      <div class="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold leading-tight tracking-tight text-gray-900">Wybierz test</h1>
      </div>
    </header>
    <main>
      <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div class="px-4 py-4 sm:px-0">
          <ListComponent :psychotests="psychotests" />
        </div>
      </div>
    </main>
   
</div>

</template>

<script>
import { ref } from 'vue';
import getCollection from '@/composables/getCollection';
import getUser from '@/composables/getUser';

// firebase imports
import { db } from '../firebase/config';
import { doc, deleteDoc, updateDoc, collection, getDoc } from 'firebase/firestore';

// import Navbar from '@/components/Navbar.vue';
import Navbar from '@/components/Navbar.vue';
import ListComponent  from '@/components/ListComponent .vue';

export default {
   name: 'Home',
   components: { Navbar, ListComponent  },
   setup() {
      // const psychotests = ref([]);

      const { user } = getUser();
      const { documents: psychotests } = getCollection('psychotests'); //

      return { psychotests };
   },
};
</script>
