const idspAreas = [
  //  Zdolnosci poznawczych
  {
    1: { min: 7, max: 31 },
    2: { min: 32, max: 36 },
    3: { min: 37, max: 40 },
    4: { min: 41, max: 45 },
    5: { min: 46, max: 49 },
    6: { min: 50, max: 54 },
    7: { min: 55, max: 58 },
    8: { min: 59, max: 63 },
    9: { min: 64, max: 67 },
    10: { min: 68, max: 72 },
    11: { min: 73, max: 76 },
    12: { min: 77, max: 81 },
    13: { min: 82, max: 85 },
    14: { min: 86, max: 90 },
    15: { min: 91, max: 94 },
    16: { min: 95, max: 99 },
    17: { min: 100, max: 103 },
    18: { min: 104, max: 108 },
    19: { min: 109, max: 133 },
  },

  //Umiejetnosci psychomotoryczne, Jezyk, Motywacja
  {
    1: { min: 3, max: 10 },
    2: { min: 11, max: 12 },
    3: { min: 13, max: 14 },
    4: { min: 15, max: 17 },
    5: { min: 18, max: 19 },
    6: { min: 20, max: 21 },
    7: { min: 22, max: 24 },
    8: { min: 25, max: 26 },
    9: { min: 27, max: 28 },
    10: { min: 29, max: 31 },
    11: { min: 32, max: 33 },
    12: { min: 34, max: 35 },
    13: { min: 36, max: 38 },
    14: { min: 39, max: 40 },
    15: { min: 41, max: 42 },
    16: { min: 43, max: 45 },
    17: { min: 46, max: 47 },
    18: { min: 48, max: 49 },
    19: { min: 50, max: 57 },
  },
];

export default idspAreas;
