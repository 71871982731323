const idspScales = [
  // Zamiany sumy srednich wyników przeliczonych (WP) na ilorazy inteligencji w skali inteligencji ogolnej
  {
    "≤55": { min: 0, max: 8.42 },
    56: { min: 8.43, max: 8.68 },
    57: { min: 8.69, max: 8.94 },
    58: { min: 8.95, max: 9.2 },
    59: { min: 9.21, max: 9.46 },
    60: { min: 9.47, max: 9.72 },
    61: { min: 9.73, max: 9.98 },
    62: { min: 9.99, max: 10.24 },
    63: { min: 10.25, max: 10.5 },
    64: { min: 10.51, max: 10.75 },
    65: { min: 10.76, max: 11.02 },
    66: { min: 11.03, max: 11.28 },
    67: { min: 11.29, max: 11.54 },
    68: { min: 11.55, max: 11.8 },
    69: { min: 11.81, max: 12.06 },
    70: { min: 12.07, max: 12.32 },
    71: { min: 12.33, max: 12.58 },
    72: { min: 12.59, max: 12.84 },
    73: { min: 12.85, max: 13.1 },
    74: { min: 13.11, max: 13.36 },
    75: { min: 13.37, max: 13.62 },
    76: { min: 13.63, max: 13.88 },
    77: { min: 13.89, max: 14.14 },
    78: { min: 14.15, max: 14.4 },
    79: { min: 14.41, max: 14.66 },
    80: { min: 14.67, max: 14.92 },
    81: { min: 14.93, max: 15.18 },
    82: { min: 15.19, max: 15.44 },
    83: { min: 15.45, max: 15.7 },
    84: { min: 15.71, max: 15.96 },
    85: { min: 15.97, max: 16.22 },
    86: { min: 16.23, max: 16.48 },
    87: { min: 16.49, max: 16.74 },
    88: { min: 16.75, max: 17 },
    89: { min: 17.01, max: 17.26 },
    90: { min: 17.27, max: 17.52 },
    91: { min: 17.53, max: 17.78 },
    92: { min: 17.79, max: 18.04 },
    93: { min: 18.05, max: 18.3 },
    94: { min: 18.31, max: 18.56 },
    95: { min: 18.57, max: 18.82 },
    96: { min: 18.83, max: 19.08 },
    97: { min: 19.09, max: 19.34 },
    98: { min: 19.35, max: 19.6 },
    99: { min: 19.61, max: 19.86 },
    100: { min: 19.87, max: 20.12 },
    101: { min: 20.13, max: 20.38 },
    102: { min: 20.39, max: 20.64 },
    103: { min: 20.65, max: 20.9 },
    104: { min: 20.91, max: 21.16 },
    105: { min: 21.17, max: 21.42 },
    106: { min: 21.43, max: 21.68 },
    107: { min: 21.69, max: 21.94 },
    108: { min: 21.95, max: 22.2 },
    109: { min: 22.21, max: 22.46 },
    110: { min: 22.47, max: 22.72 },
    111: { min: 22.73, max: 22.98 },
    112: { min: 22.99, max: 23.24 },
    113: { min: 23.25, max: 23.5 },
    114: { min: 23.51, max: 23.76 },
    115: { min: 23.77, max: 24.02 },
    116: { min: 24.03, max: 24.28 },
    117: { min: 24.29, max: 24.54 },
    118: { min: 24.55, max: 24.8 },
    119: { min: 24.81, max: 25.06 },
    120: { min: 25.07, max: 25.32 },
    121: { min: 25.33, max: 25.58 },
    122: { min: 25.59, max: 25.84 },
    123: { min: 25.85, max: 26.1 },
    124: { min: 26.11, max: 26.36 },
    125: { min: 26.37, max: 26.62 },
    126: { min: 26.63, max: 26.88 },
    127: { min: 26.89, max: 27.14 },
    128: { min: 27.15, max: 27.4 },
    129: { min: 27.41, max: 27.66 },
    130: { min: 27.67, max: 27.92 },
    131: { min: 27.93, max: 28.18 },
    132: { min: 28.19, max: 28.44 },
    133: { min: 28.45, max: 28.7 },
    134: { min: 28.71, max: 28.96 },
    135: { min: 28.97, max: 29.22 },
    136: { min: 29.23, max: 29.48 },
    137: { min: 29.49, max: 29.74 },
    138: { min: 29.75, max: 30 },
    139: { min: 30.01, max: 30.26 },
    140: { min: 30.27, max: 30.52 },
    141: { min: 30.53, max: 30.78 },
    142: { min: 30.79, max: 31.04 },
    143: { min: 31.05, max: 31.3 },
    144: { min: 31.31, max: 31.56 },
    "≥145": { min: 31.57, max: 100 },
  },

  // zamiany sum wynlkow przeliczonych (WP) na ilorazy inteligencj w skalach inteligencji plynnej
  {
    "≤55": { min: 0, max: 30 },
    56: { min: 31, max: 31 },
    57: { min: 32, max: 32 },
    58: { min: 33, max: 33 },
    60: { min: 34, max: 34 },
    61: { min: 35, max: 35 },
    62: { min: 36, max: 36 },
    63: { min: 37, max: 37 },
    64: { min: 38, max: 38 },
    65: { min: 39, max: 39 },
    66: { min: 40, max: 40 },
    67: { min: 41, max: 41 },
    69: { min: 42, max: 42 },
    70: { min: 43, max: 43 },
    71: { min: 44, max: 44 },
    72: { min: 45, max: 45 },
    73: { min: 46, max: 46 },
    74: { min: 47, max: 47 },
    75: { min: 48, max: 48 },
    76: { min: 49, max: 49 },
    78: { min: 50, max: 50 },
    79: { min: 51, max: 51 },
    80: { min: 52, max: 52 },
    81: { min: 53, max: 53 },
    82: { min: 54, max: 54 },
    83: { min: 55, max: 55 },
    84: { min: 56, max: 56 },
    85: { min: 57, max: 57 },
    87: { min: 58, max: 58 },
    88: { min: 59, max: 59 },
    89: { min: 60, max: 60 },
    90: { min: 61, max: 61 },
    91: { min: 62, max: 62 },
    92: { min: 63, max: 63 },
    93: { min: 64, max: 64 },
    94: { min: 65, max: 65 },
    96: { min: 66, max: 66 },
    97: { min: 67, max: 67 },
    98: { min: 68, max: 68 },
    99: { min: 69, max: 69 },
    100: { min: 70, max: 70 },
    101: { min: 71, max: 71 },
    102: { min: 72, max: 72 },
    103: { min: 73, max: 73 },
    104: { min: 74, max: 74 },
    106: { min: 75, max: 75 },
    107: { min: 76, max: 76 },
    108: { min: 77, max: 77 },
    109: { min: 78, max: 78 },
    110: { min: 79, max: 79 },
    111: { min: 80, max: 80 },
    112: { min: 81, max: 81 },
    113: { min: 82, max: 82 },
    115: { min: 83, max: 83 },
    116: { min: 84, max: 84 },
    117: { min: 85, max: 85 },
    118: { min: 86, max: 86 },
    119: { min: 87, max: 87 },
    120: { min: 88, max: 88 },
    121: { min: 89, max: 89 },
    122: { min: 90, max: 90 },
    124: { min: 91, max: 91 },
    125: { min: 92, max: 92 },
    126: { min: 93, max: 93 },
    127: { min: 94, max: 94 },
    128: { min: 95, max: 95 },
    129: { min: 96, max: 96 },
    130: { min: 97, max: 97 },
    131: { min: 98, max: 98 },
    133: { min: 99, max: 99 },
    134: { min: 100, max: 100 },
    135: { min: 101, max: 101 },
    136: { min: 102, max: 102 },
    137: { min: 103, max: 103 },
    138: { min: 104, max: 104 },
    139: { min: 105, max: 105 },
    140: { min: 106, max: 106 },
    142: { min: 107, max: 107 },
    143: { min: 108, max: 108 },
    144: { min: 109, max: 109 },
    "≥145": { min: 110, max: 999 },
  },

  // Zamiany sum wynikow przeliczonych (WP) na ilorazy inteligencj w skalach inteligencji skrystalizowanej
  {
    "≤55": { min: 0, max: 6.33 },
    56: { min: 6.67, max: 6.67 },
    57: { min: 7, max: 7 },
    58: { min: null, max: null },
    59: { min: 7.33, max: 7.33 },
    60: { min: 7.67, max: 7.67 },
    61: { min: 8, max: 8 },
    62: { min: 8.33, max: 8.33 },
    63: { min: 8.67, max: 8.67 },
    64: { min: 9, max: 9 },
    65: { min: 9.33, max: 9.33 },
    66: { min: 9.67, max: 9.67 },
    67: { min: 10, max: 10 },
    68: { min: 10.33, max: 10.33 },
    69: { min: 10.67, max: 10.67 },
    70: { min: null, max: null },
    71: { min: 11, max: 11 },
    72: { min: 11.33, max: 11.33 },
    73: { min: 11.67, max: 11.67 },
    74: { min: 12, max: 12 },
    75: { min: 12.33, max: 12.33 },
    76: { min: 12.67, max: 12.67 },
    77: { min: 13, max: 13 },
    78: { min: 13.33, max: 13.33 },
    79: { min: 13.67, max: 13.67 },
    80: { min: 14, max: 14 },
    81: { min: 14.33, max: 14.33 },
    82: { min: null, max: null },
    83: { min: 14.67, max: 14.67 },
    84: { min: 15, max: 15 },
    85: { min: 15.33, max: 15.33 },
    86: { min: 15.67, max: 15.67 },
    87: { min: 16, max: 16 },
    88: { min: 16.33, max: 16.33 },
    89: { min: 16.67, max: 16.67 },
    90: { min: 17, max: 17 },
    91: { min: 17.33, max: 17.33 },
    92: { min: 17.67, max: 17.67 },
    93: { min: 18, max: 18 },
    94: { min: null, max: null },
    95: { min: 18.33, max: 18.33 },
    96: { min: 18.67, max: 18.67 },
    97: { min: 19, max: 19 },
    98: { min: 19.33, max: 19.33 },
    99: { min: 19.67, max: 19.67 },
    100: { min: 20, max: 20 },
    101: { min: 20.33, max: 20.33 },
    102: { min: 20.67, max: 20.67 },
    103: { min: 21, max: 21 },
    104: { min: 21.33, max: 21.33 },
    105: { min: 21.67, max: 21.67 },
    106: { min: null, max: null },
    107: { min: 22, max: 22 },
    108: { min: 22.33, max: 22.33 },
    109: { min: 22.67, max: 22.67 },
    110: { min: 23, max: 23 },
    111: { min: 23.33, max: 23.33 },
    112: { min: 23.67, max: 23.67 },
    113: { min: 24, max: 24 },
    114: { min: 24.33, max: 24.33 },
    115: { min: 24.67, max: 24.67 },
    116: { min: 25, max: 25 },
    117: { min: 25.33, max: 25.33 },
    118: { min: null, max: null },
    119: { min: 25.67, max: 25.67 },
    120: { min: 26, max: 26 },
    121: { min: 26.33, max: 26.33 },
    122: { min: 26.67, max: 26.67 },
    123: { min: 27, max: 27 },
    124: { min: 27.33, max: 27.33 },
    125: { min: 27.67, max: 27.67 },
    126: { min: 28, max: 28 },
    127: { min: 28.33, max: 28.33 },
    128: { min: 28.67, max: 28.67 },
    129: { min: 29, max: 29 },
    130: { min: null, max: null },
    131: { min: 29.33, max: 29.33 },
    132: { min: 29.67, max: 29.67 },
    133: { min: 30, max: 30 },
    134: { min: 30.33, max: 30.33 },
    135: { min: 30.67, max: 30.67 },
    136: { min: 31, max: 31 },
    137: { min: 31.33, max: 31.33 },
    138: { min: 31.67, max: 31.67 },
    139: { min: 32, max: 32 },
    140: { min: 32.33, max: 32.33 },
    141: { min: 32.67, max: 32.67 },
    142: { min: null, max: null },
    143: { min: 33, max: 33 },
    144: { min: 33.33, max: 33.33 },
    "≥145": { min: 33.67, max: 999 },
  },

  // skrystalizowana bez cwiczenia jezykowego
  {
    "≤55": { min: 0, max: 6.33 },
    56: { min: 6.5, max: 6.5 },
    57: { min: 7, max: 7 },
    58: { min: null, max: null },
    59: { min: 7.5, max: 7.5 },
    60: { min: 7.67, max: 7.67 },
    61: { min: 8, max: 8 },
    62: { min: 8.5, max: 8.5 },
    63: { min: 8.67, max: 8.67 },
    64: { min: 9, max: 9 },
    65: { min: 9.33, max: 9.33 },
    66: { min: 9.5, max: 9.5 },
    67: { min: 10, max: 10 },
    68: { min: 10.33, max: 10.33 },
    69: { min: 10.5, max: 10.5 },
    70: { min: null, max: null },
    71: { min: 11, max: 11 },
    72: { min: 11.5, max: 11.5 },
    73: { min: 11.67, max: 11.67 },
    74: { min: 12, max: 12 },
    75: { min: 12.5, max: 12.5 },
    76: { min: 12.67, max: 12.67 },
    77: { min: 13, max: 13 },
    78: { min: 13.33, max: 13.33 },
    79: { min: 13.5, max: 13.5 },
    80: { min: 14, max: 14 },
    81: { min: 14.33, max: 14.33 },
    82: { min: 14.5, max: 14.5 },
    83: { min: 14.67, max: 14.67 },
    84: { min: 15, max: 15 },
    85: { min: 15.5, max: 15.5 },
    86: { min: 15.67, max: 15.67 },
    87: { min: 16, max: 16 },
    88: { min: 16.33, max: 16.33 },
    89: { min: 16.5, max: 16.5 },
    90: { min: 17, max: 17 },
    91: { min: 17.33, max: 17.33 },
    92: { min: 17.5, max: 17.5 },
    93: { min: 18, max: 18 },
    94: { min: null, max: null },
    95: { min: 18.5, max: 18.5 },
    96: { min: 18.67, max: 18.67 },
    97: { min: 19, max: 19 },
    98: { min: 19.5, max: 19.5 },
    99: { min: 19.67, max: 19.67 },
    100: { min: 20, max: 20 },
    101: { min: 20.33, max: 20.33 },
    102: { min: 20.5, max: 20.5 },
    103: { min: 21, max: 21 },
    104: { min: 21.33, max: 21.33 },
    105: { min: 21.5, max: 21.5 },
    106: { min: null, max: null },
    107: { min: 22, max: 22 },
    108: { min: 22.5, max: 22.5 },
    109: { min: 22.67, max: 22.67 },
    110: { min: 23, max: 23 },
    111: { min: 23.5, max: 23.5 },
    112: { min: 23.67, max: 23.67 },
    113: { min: 24, max: 24 },
    114: { min: 24.33, max: 24.33 },
    115: { min: 24.5, max: 24.5 },
    116: { min: 25, max: 25 },
    117: { min: 25.33, max: 25.33 },
    118: { min: 25.5, max: 25.5 },
    119: { min: 25.67, max: 25.67 },
    120: { min: 26, max: 26 },
    121: { min: 26.5, max: 26.5 },
    122: { min: 26.67, max: 26.67 },
    123: { min: 27, max: 27 },
    124: { min: 27.5, max: 27.5 },
    125: { min: 27.67, max: 27.67 },
    126: { min: 28, max: 28 },
    127: { min: 28.33, max: 28.33 },
    128: { min: 28.5, max: 28.5 },
    129: { min: 29, max: 29 },
    130: { min: null, max: null },
    131: { min: 29.5, max: 29.5 },
    132: { min: 29.67, max: 29.67 },
    133: { min: 30, max: 30 },
    134: { min: 30.5, max: 30.5 },
    135: { min: 30.67, max: 30.67 },
    136: { min: 31, max: 31 },
    137: { min: 31.33, max: 31.33 },
    138: { min: 31.5, max: 31.5 },
    139: { min: 32, max: 32 },
    140: { min: 32.33, max: 32.33 },
    141: { min: 32.5, max: 32.5 },
    142: { min: null, max: null },
    143: { min: 33, max: 33 },
    144: { min: 33.5, max: 33.5 },
    "≥145": { min: 33.67, max: 999 },
  },
];

export default idspScales;
